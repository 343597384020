import React, { useState, useContext, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dropdown } from 'semantic-ui-react';
import { Line, Pie } from 'react-chartjs-2';
import GenericLoader from '../generic/GenericLoader';
import { AppContext } from '../../AppContext';
import { getPnPSportsOfArena } from '../../apiclients/BookingApiClient';
import { getOccupancyAnalyticsData } from '../../apiclients/AnalyticsClient';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Card, Grid } from '@material-ui/core';
import moment from 'moment';
import { ANALYTICS_COLORS } from '../../utils/constants';

const OccupancyAnalytics = () => {

  const {
    selectedFacility,
    selectedArena,
    arenaData,
    selectedSportDetails,
    dispatch, user
  } = useContext(AppContext);

  const sports = useRef([]);
  const [selectedSport, setSelectedSport] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState();
  const [graphData, setGraphData] = useState();
  const [occupancyGraphData, setOccupancyGraphData] = useState();
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  const arenaIndex = user.facilityIncharge.findIndex(x => x.arenaId === selectedArena.arenaId);



  const classes = useStyles();
  const loadData = (selectedFacility, selectedArena, selectedSport) => {
    setisLoading(true);
    return getOccupancyAnalyticsData(
      selectedFacility, selectedArena, selectedSport
    ).then((response) => {
      const analyticsData = response.data;
      setAnalyticsData(analyticsData);
      let graphsData = createLineItemData(analyticsData);
      let occupancyGraphData = createLineItemOccupancyData(analyticsData);
      setOccupancyGraphData(occupancyGraphData);
      setGraphData(graphsData);
      setisLoading(false);
    });
  };

  // useEffect(() => {
  //   if (selectedFacility && selectedArena){
  //     getPnPSportsOfArena(selectedFacility, selectedArena.arenaId).then(
  //       (response) => {
  //         const sports = response.data;
  //         dispatch({
  //           type: "addCurrentFacilitySports",
  //           payload: sports,
  //         });
  //       }
  //     );

  //   }

  // }, [selectedFacility, selectedArena.arenaId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedArena && arenaData[selectedArena.arenaId]) {
      let dropdownSportsData = arenaData[selectedArena.arenaId]?.sports.map(
        (x, index) => ({
          key: x.sportId,
          value: x.sportId,
          text: x.sportName,
        })
      );
      if (user.facilityIncharge[arenaIndex].sportIds) { // that means the user access is restricted to a sport
        const listOfSports = user.facilityIncharge[arenaIndex].sportIds;
        dropdownSportsData = dropdownSportsData.filter(x => listOfSports.includes(x.key))
      }
      sports.current = dropdownSportsData;
      setSelectedSport(dropdownSportsData[0]);
      loadData(selectedFacility, selectedArena.arenaId, dropdownSportsData[0].value);
    }
  }, [selectedFacility, selectedArena.arenaId]); // eslint-disable-line react-hooks/exhaustive-deps


  const changeSport = (event, { value }) => {

    const data = sports.current.find((facility) => facility.value === value);
    setSelectedSport(data);
    loadData(selectedFacility, selectedArena.arenaId, data.value);
  };

  const { totalColor, playAllColor, hudleColor, playAllAppColor, playoColor } = ANALYTICS_COLORS;

  const allColors = [playAllColor, hudleColor, playAllAppColor, playoColor, totalColor];

  const createLineItemData = (data) => {
    let labels = [];
    let totalDataSet = {
      label: 'Total Bookings',
      data: [],
      borderColor: [totalColor],
      backgroundColor: [totalColor],
      tension: 0.3,
      fill: false,
    };
    let hudleDataset = {
      label: 'Hudle',
      data: [],
      borderColor: [hudleColor],
      backgroundColor: [hudleColor],
      tension: 0.3,
      fill: false,
    };
    let playAllAppDataSet = {
      label: 'PlayAll App',
      data: [],
      borderColor: [playAllAppColor],
      backgroundColor: [playAllAppColor],
      tension: 0.3,
      fill: false,
    };
    let playoDataSet = {
      label: 'Playo',
      data: [],
      borderColor: [playoColor],
      backgroundColor: [playoColor],
      tension: 0.3,
      fill: false,
    };
    if (data && data.lastFiftyTwoWeeklyAnalytics) {
      data.lastFiftyTwoWeeklyAnalytics.forEach(x => {
        labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY'));
        totalDataSet.data.push((x.data.weekDayData.totalBooking + x.data.weekEndData.totalBooking));
        hudleDataset.data.push((x.data.weekDayData.hudleBookings + x.data.weekEndData.hudleBookings));
        if (x.data.weekDayData.playAllAppBookings === undefined || x.data.weekEndData.playAllAppBookings === undefined) {
          playAllAppDataSet.data.push(0);
        } else {
          playAllAppDataSet.data.push((x.data.weekDayData.playAllAppBookings + x.data.weekEndData.playAllAppBookings));
        }
        playoDataSet.data.push((x.data.weekDayData.playoBookings + x.data.weekEndData.playoBookings));
      });
    }
    let lineData = {
      labels: labels,
      datasets: [
        totalDataSet, hudleDataset, playAllAppDataSet, playoDataSet
      ]
    };
    return lineData;
  }

  const createLineItemOccupancyData = (data) => {
    let labels = [];
    let dataSets = [];
    let slots = data.lastFiftyTwoWeeklyAnalytics[data.lastFiftyTwoWeeklyAnalytics.length - 1].data.weekDayData.slotWiseOccupancy;
    for (var i = 0; i < slots.length; i++) {
      var time = slots[i].time;
      var remainder = Math.floor(i % 4);
      let dataForSlot = {
        label: time,
        data: [],
        borderColor: [allColors[remainder]],
        backgroundColor: [allColors[remainder]],
        tension: 0.3,
        fill: false,
        hidden: true,
      };
      if (data && data.lastFiftyTwoWeeklyAnalytics) {
        data.lastFiftyTwoWeeklyAnalytics.forEach(x => {
          // adds an element to the array if it does not already exist using a comparer 
          // function

          var dateInString = moment(x.endDate.seconds * 1000).format('DD-MMM-YY');
          if (labels.indexOf(dateInString) > -1) {
            //In the array!
          } else {
            labels.push(dateInString);
            //Not in the array
          }
          let occupancyData = 0;
          if (x.data.weekDayData.slotWiseOccupancy[i] !== undefined && x.data.weekEndData.slotWiseOccupancy[i] !== undefined) {
            occupancyData = parseFloat(((x.data.weekDayData.slotWiseOccupancy[i].occupancy * 5) + (x.data.weekEndData.slotWiseOccupancy[i].occupancy * 2)) * 100 / 7).toFixed(2);
          };
          dataForSlot.data.push(occupancyData);
        });
      }
      dataSets.push(dataForSlot);
    }


    let lineData = {
      labels: labels,
      datasets: dataSets
    };
    return lineData;
  }


  return isLoading ? <GenericLoader /> :
    (<div style={{ padding: "16px" }}>
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>
              Occupancy Analytics </span>
          </div>
          <div style={{ alignItems: "center" }}>
            <div style={{ fontSize: "14px" }}>
              <Dropdown
                onChange={changeSport}
                placeholder='Select...'
                openOnFocus
                selection
                value={selectedSport && selectedSport.value}
                options={sports.current}
              />

            </div>
          </div>
        </div>
      </div>
      <br />
      <div>
        <Grid container spacing={9}>
          <Grid item md={3} spacing={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Yesterday
                </Typography>
                <Typography variant="h5" component="h2">
                  <span style={{ color: "grey" }}>Occupancy - </span>{parseFloat(analyticsData.yesterdayAnalytics.totalOccupancy * 100).toFixed(2)} %
                  <br />
                  <span style={{ color: "grey" }}>Bookings - </span># {analyticsData.yesterdayAnalytics.totalBooking}
                </Typography>
                <Pie
                  width={30}
                  height={8}
                  data={{
                    labels: [
                      'PlayAll',
                      'Hudle',
                      'PlayAll App',
                      'Playo'
                    ],
                    datasets: [{
                      label: 'My First Dataset',
                      data: [analyticsData.yesterdayAnalytics.playAllBookings,
                      analyticsData.yesterdayAnalytics.hudleBookings,
                      analyticsData.yesterdayAnalytics.playAllAppBookings ? analyticsData.yesterdayAnalytics.playAllAppBookings : 0,
                      analyticsData.yesterdayAnalytics.playoBookings,],
                      backgroundColor: [
                        playAllColor,
                        hudleColor,
                        playAllAppColor,
                        playoColor
                      ],
                      hoverOffset: 4
                    }]
                  }}
                />
              </CardContent>
            </Card>

          </Grid>
          <Grid item md={3} spacing={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Last Week
                </Typography>
                <Typography variant="h5" component="h2">
                  <span style={{ color: "grey" }}>Occupancy - </span>{parseFloat(analyticsData.lastWeekAnalytics.totalOccupancy * 100).toFixed(2)} %
                  <br />
                  <span style={{ color: "grey" }}> Bookings - </span># {analyticsData.lastWeekAnalytics.totalBooking}
                </Typography>
                <Pie
                  width={30}
                  height={10}
                  data={{
                    labels: [
                      'PlayAll',
                      'Hudle',
                      'PlayAll App',
                      'Playo'
                    ],
                    datasets: [{
                      label: 'My First Dataset',
                      data: [analyticsData.lastWeekAnalytics.playAllBookings,
                      analyticsData.lastWeekAnalytics.hudleBookings,
                      analyticsData.lastWeekAnalytics.playAllAppBookings ? analyticsData.lastWeekAnalytics.playAllAppBookings : 0,
                      analyticsData.lastWeekAnalytics.playoBookings,],
                      backgroundColor: [
                        playAllColor,
                        hudleColor,
                        playAllAppColor,
                        playoColor
                      ],
                      hoverOffset: 4
                    }]
                  }}
                /> </CardContent>
            </Card>

          </Grid>
          <Grid item md={3} spacing={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Last Month
                </Typography>
                <Typography variant="h5" component="h2">
                  <span style={{ color: "grey" }}>Occupancy - </span>{parseFloat(analyticsData.lastMonthAnalytics.totalOccupancy * 100).toFixed(2)} %
                  <br />
                  <span style={{ color: "grey" }}> Bookings - # </span>{analyticsData.lastMonthAnalytics.totalBooking}
                </Typography>
                <Pie
                  width={30}
                  height={10}
                  data={{
                    labels: [
                      'PlayAll',
                      'Hudle',
                      'playAllApp',
                      'Playo'
                    ],
                    datasets: [{
                      label: 'My First Dataset',
                      data: [analyticsData.lastMonthAnalytics.playAllBookings,
                      analyticsData.lastMonthAnalytics.hudleBookings,
                      analyticsData.lastMonthAnalytics.playAllAppBookings ? analyticsData.lastMonthAnalytics.playAllAppBookings : 0,
                      analyticsData.lastMonthAnalytics.playoBookings,],
                      backgroundColor: [
                        playAllColor,
                        hudleColor,
                        playAllAppColor,
                        playoColor
                      ],
                      hoverOffset: 4
                    }]
                  }}
                />
              </CardContent>
            </Card>

          </Grid>
        </Grid>

        <h3>Last Year Booking Data</h3>
        <Line
          data={graphData}
          width={100}
          height={20}
          pointRadius={1}
          showLine={false}

          options={{
            // maintainAspectRatio: false,
            scales: {
              xAxes: [{
                gridLines: {
                  show: true
                }
              }],
              yAxes: [{
                gridLines: {
                  show: false
                }
              }]
            }
          }}
        />

        <h3>Last Year Occupancy Data (%)</h3>
        <Line
          data={occupancyGraphData}
          width={100}
          height={40}
          pointRadius={1}
          showLine={false}

          options={{
            // maintainAspectRatio: false,
            scales: {
              xAxes: [{
                gridLines: {
                  show: true
                }
              }],
              yAxes: [{
                gridLines: {
                  show: false
                }
              }]
            }
          }}
        />

      </div>
    </div>
    );
}

export default OccupancyAnalytics;