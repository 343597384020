import React, { useState, useContext, useEffect } from 'react';
import {
    Button,
    Form, Checkbox, Dropdown
} from "semantic-ui-react";
import { colors } from "@material-ui/core";
import { AppContext } from '../../AppContext';
import ModalPortal from '../generic/ModalPortal';
import DatesSelection from '../common/DatesSelection';
import { addCoupon,updateCoupon } from '../../apiclients/WalletApiClient';
import moment from 'moment';

const AddCoupons = (props) => {
    const { showModal, setReload, setMessage, isLoading,data,edit } = props;
    const context = useContext(AppContext);
    const arenaList = context?.arenas;
    const sportList = context?.facilitySports;
    const [couponName, setCouponName] = useState();
    const [description, setDescription] = useState();
    const [arenas, setArenas] = useState([]);
    const [sports, setSports] = useState([]);
    const [arenaListOption, setArenaListOption] = useState([]);
    const [sportsListOption, setSportsListOption] = useState([]);
    const [selectedSports, setSelectedSports] = useState([]);
    const [selectedArenas, setSelectedArenas] = useState([]);
    const [isItPercentage, setIsItPercentage] = useState(true);
    const [percentageDiscount, setPercentageDiscount] = useState();
    const [amountDiscount, setAmountDiscount] = useState();
    const [maxDiscount, setMaxDiscount] = useState();
    const [users, setUsers] = useState();
    const [isVisible, setIsVisible] = useState(false);
    const [isNewUser, setIsNewUser] = useState(false);
    const [isUsageLimited, setIsUsageLimited] = useState(false);
    const [usageLimit, setUsageLimit] = useState();
    const [isUsageLimitedPerUser, setIsUsageLimitedPerUser] = useState(false);
    const [usageLimitPerUser, setUsageLimitPerUser] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const isSubmitAllowed = couponName && description && selectedArenas.length > 0 && selectedSports.length > 0 && (percentageDiscount || amountDiscount) && startDate && endDate;

    useEffect(() => {
        if (context?.facilitySports?.length > 0) {
            const options = context?.facilitySports?.map(sport => ({
                key: sport.sportId,
                text: sport.sportName,
                value: sport.sportId
            }));
            setSportsListOption(options);
        }
        if (context?.arenas?.length > 0) {
            const options = context?.arenas?.map(arena => ({
                key: arena.arenaId,
                text: arena.arenaName,
                value: arena.arenaId
            }));
            setArenaListOption(options);
        }

    }, [context]);

    useEffect(() => {
        if(edit && data){
            console.log("data",data)
            setCouponName(data.couponName)
            setDescription(data.description)
            setArenas(data.arenas)
            setSports(data.sports)
            setIsItPercentage(data.isItPercentage)
            setPercentageDiscount(data.percentageDiscount)
            setAmountDiscount(data.amountDiscount)
            setMaxDiscount(data.maxDiscount)
            setUsers(data.users)
            setIsVisible(data.isVisible)
            setIsNewUser(data.isNewUser)
            setIsUsageLimited(data.isUsageLimited)
            setIsUsageLimitedPerUser(data.isUsageLimitedPerUser)
            setStartDate(firestoreTimestampToDate(data.startDate))
            setEndDate(firestoreTimestampToDate(data.endDate))
            setSelectedSports(data?.sportIds)
            setSelectedArenas(data?.arenaIds)
        }
    }, [data]);

    function firestoreTimestampToDate(timestamp) {
        const milliseconds = timestamp._seconds * 1000 + timestamp._nanoseconds / 1000000;
        return moment(milliseconds).format('YYYY-MM-DD HH:mm:ss'); // Format as desired
      }

    const changeDate = (isStart) => value => {
        if (isStart) {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
    }

    const updateAllArena = (checked) => {
        if (checked) {
            setArenas(arenaList);
        } else {
            setArenas([]);
        }
    };

    const updateArenas = (obj, arenaId) => {
        if (arenas.some(o => o.arenaId === arenaId)) {
            setArenas(arenas.filter(o => o.arenaId !== arenaId));
        } else {
            setArenas([...arenas, obj]);
        }
    };

    const updateAllSports = (checked) => {
        if (checked) {
            setSports(sportList);
        } else {
            setSports([]);
        }
    };

    const updateSports = (obj, sportId) => {
        if (sports.some(o => o.sportId === sportId)) {
            setSports(sports.filter(o => o.sportId !== sportId));
        } else {
            setSports([...sports, obj]);
        }
    };

    const handleChangeSports = (e, { value }) => {
        const deselectedSports = selectedSports.filter(sport => !value.includes(sport));
        const newlySelectedSports = value.filter(sport => !selectedSports.includes(sport));

        setSelectedSports(value);

        if (deselectedSports.length > 0) {
            console.log('Deselected sports:', deselectedSports);
        }
        if (newlySelectedSports.length > 0) {
            console.log('Newly selected sports:', newlySelectedSports);
        }
    };

    const handleChangeArenas = (e, { value }) => {
        const deselectedArenas = selectedArenas.filter(arena => !value.includes(arena));
        const newlySelectedArenas = value.filter(arena => !selectedArenas.includes(arena));

        setSelectedArenas(value);

        if (deselectedArenas.length > 0) {
            console.log('Deselected sports:', deselectedArenas);
        }
        if (newlySelectedArenas.length > 0) {
            console.log('Newly selected sports:', newlySelectedArenas);
        }
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Coupon Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={couponName}
                            placeholder='Name'
                            onChange={(evt) => setCouponName(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Description</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={description}
                            placeholder='Name'
                            onChange={(evt) => setDescription(evt.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group >
                    <Form.Field width={3} >
                        <label>Type Of Discount</label>
                        <b>Rs. </b>
                        <Checkbox
                            checked={isItPercentage}
                            style={{ marginRight: "7px", marginLeft: "7px" }}
                            onClick={(e, { checked }) => {
                                setIsItPercentage(checked);
                            }}
                            toggle
                        /> <b> % </b>
                    </Form.Field>
                    {isItPercentage ? <Form.Field width={4}>
                        <label> Discount %</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={percentageDiscount}
                            placeholder='Please enter a round %. E.g. 20'
                            onChange={(evt) => setPercentageDiscount(evt.target.value)}
                        />
                    </Form.Field  > :
                        <Form.Field width={4} >
                            <label> Discount Amount</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='number'
                                value={amountDiscount}
                                placeholder='Please enter an amount'
                                onChange={(evt) => setAmountDiscount(evt.target.value)}
                            />
                        </Form.Field  >
                    }

                    {isItPercentage && <Form.Field width={4} >
                        <label> Max Discount</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={maxDiscount}
                            placeholder='Please enter an amount'
                            onChange={(evt) => setMaxDiscount(evt.target.value)}
                        />
                    </Form.Field  >}
                    <Form.Field width={5} >
                        <label>Visibility</label>
                        <b> Hidden </b>
                        <Checkbox
                            checked={isVisible}
                            style={{ marginRight: "7px", marginLeft: "7px" }}
                            onClick={(e, { checked }) => {
                                setIsVisible(checked);
                            }}
                            toggle
                        /> <b> Visible </b>
                    </Form.Field>
                    <Form.Field width={5} >
                        <label>New Users</label>
                        <b> All </b>
                        <Checkbox
                            checked={isNewUser}
                            style={{ marginRight: "7px", marginLeft: "7px" }}
                            onClick={(e, { checked }) => {
                                setIsNewUser(checked);
                            }}
                            toggle
                        /> <b> New </b>
                    </Form.Field>
                </Form.Group>
                <Form.Group >
                    <Form.Field width={5} >
                        <label>Usage Limit</label>
                        <b>Unlimited </b>
                        <Checkbox
                            checked={isUsageLimited}
                            style={{ marginRight: "7px", marginLeft: "7px" }}
                            onClick={(e, { checked }) => {
                                setIsUsageLimited(checked);
                            }}
                            toggle
                        /> <b> Limited </b>
                    </Form.Field>
                    {isUsageLimited && <Form.Field width={6}>
                        <label> Usuage Limit</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={usageLimit}
                            placeholder='Please enter a round %. E.g. 20'
                            onChange={(evt) => setUsageLimit(evt.target.value)}
                        />
                    </Form.Field  >}
                    <Form.Field width={5} >
                        <label>Usage Limit Per User</label>
                        <b>Unlimited </b>
                        <Checkbox
                            checked={isUsageLimitedPerUser}
                            style={{ marginRight: "7px", marginLeft: "7px" }}
                            onClick={(e, { checked }) => {
                                setIsUsageLimitedPerUser(checked);
                            }}
                            toggle
                        /> <b> Limited </b>
                    </Form.Field>
                    {isUsageLimitedPerUser && <Form.Field width={6}>
                        <label> Usage Limit Per User</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={usageLimitPerUser}
                            placeholder='Please enter a round %. E.g. 20'
                            onChange={(evt) => setUsageLimitPerUser(evt.target.value)}
                        />
                    </Form.Field  >}
                </Form.Group >
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <DatesSelection
                            startDate={startDate}
                            endDate={endDate}
                            changeDate={changeDate}
                            disablePast={true}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Users</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={users}
                            placeholder='Phone number comma separated'
                            onChange={(evt) => setUsers(evt.target.value)}
                        />
                    </Form.Field>

                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>
                            Arenas
                            {/* <Checkbox
                                checked={
                                    arenaList.length === arenas.length
                                }
                                style={{ marginRight: "7px", marginLeft: "7px" }}
                                onClick={(e, { checked }) => {
                                    updateAllArena(checked);
                                }}
                            />


                            <span style={{ fontWeight: "lighter" }}> All Arenas </span> */}
                        </label>
                        {/* {arenaList.map((x) => {
                            const isActive = arenas.some(
                                (mode) => mode.arenaId === x.arenaId
                            );
                            return (
                                <Button
                                    key={x.arenaId}
                                    onClick={() => { updateArenas(x, x.arenaId) }}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: isActive
                                            ? colors.orange[700]
                                            : colors.grey[200],
                                        color: isActive ? colors.blue[50] : colors.black,
                                    }}
                                >
                                    {x.arenaName}
                                </Button>
                            );
                        })} */}
                        <Dropdown
                            placeholder='Search Arenas'
                            fluid
                            multiple
                            search
                            selection
                            options={arenaListOption}
                            onChange={handleChangeArenas}
                            value={selectedArenas}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>
                            Sports
                            {/* <Checkbox
                                checked={
                                    sportList.length === sports.length
                                }
                                style={{ marginRight: "7px", marginLeft: "7px" }}
                                onClick={(e, { checked }) => {
                                    updateAllSports(checked);
                                }}
                            />


                            <span style={{ fontWeight: "lighter" }}> All Sports </span> */}
                        </label>
                        {/* {sportList.map((x) => {
                            const isActive = sports.some(
                                (mode) => mode.sportId === x.sportId
                            );
                            return (
                                <Button
                                    key={x.sportId}
                                    onClick={() => { updateSports(x, x.sportId) }}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: isActive
                                            ? colors.orange[700]
                                            : colors.grey[200],
                                        color: isActive ? colors.blue[50] : colors.black,
                                    }}
                                >
                                    {x.sportName}
                                </Button>
                            );
                        })} */}
                        <Dropdown
                            placeholder='Search Sports'
                            fluid
                            multiple
                            selection
                            search
                            options={sportsListOption}
                            onChange={handleChangeSports}
                            value={selectedSports}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button
                     disabled={!isSubmitAllowed}
                    primary onClick={edit ?updateCouponFunction:addCouponFunction}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addCouponFunction = () => {
        isLoading(true);
        showModal(false);
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
        console.log("selectedArenas", selectedArenas)
        console.log("selectedSports", selectedSports)
        let sports = []
        for (let i = 0; i < selectedSports.length; i++) {
            for (let j = 0; j < sportList.length; j++) {
                if (selectedSports[i] === sportList[j].sportId) {
                    sports.push({ sportId: sportList[j].sportId, sportName: sportList[j].sportName })
                }
            }
        }
        let arenas = []
        for (let i = 0; i < selectedArenas.length; i++) {
            for (let j = 0; j < arenaList.length; j++) {
                if (selectedArenas[i] === arenaList[j].arenaId) {
                    arenas.push({ arenaId: arenaList[j].arenaId, arenaName: arenaList[j].arenaName })
                }
            }
        }
        let postBody = {
            facilityId: context.selectedFacility,
            createdBy,
            couponName,
            description,
            // arenas: arenas,
            // sports: sports.map(x => ({ sportId: x.sportId, sportName: x.sportName })),
            arenas: arenas,
            sports: sports,
            isItPercentage,

            users: users,
            isVisible,
            isNewUser,
            isUsageLimited,
            isUsageLimitedPerUser,
            startDate,
            endDate,
        };
        // console.log("postBody", postBody)
        if (amountDiscount) {
            postBody.amountDiscount = Number(amountDiscount);
        }
        if (percentageDiscount) {
            postBody.percentageDiscount = Number(percentageDiscount);
        }
        if (usageLimit) {
            postBody.usageLimit = Number(usageLimit);
        }
        if (maxDiscount) {
            postBody.maxDiscount = Number(maxDiscount);
        }
        if (usageLimitPerUser) {
            postBody.usageLimitPerUser = Number(usageLimitPerUser);
        }

        addCoupon(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                isLoading(false);
                setMessage({
                    header: "Coupon Added",
                    message: "Coupon has been added successfully.",
                    color: "green",
                });
            } else {
                isLoading(false);
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            isLoading(false);
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });


    }

    const updateCouponFunction = () => {
        isLoading(true);
        showModal(false);
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
        // console.log("selectedArenas", selectedArenas)
        // console.log("selectedSports", selectedSports)
        let sports = []
        for (let i = 0; i < selectedSports.length; i++) {
            for (let j = 0; j < sportList.length; j++) {
                if (selectedSports[i] === sportList[j].sportId) {
                    sports.push({ sportId: sportList[j].sportId, sportName: sportList[j].sportName })
                }
            }
        }
        let arenas = []
        for (let i = 0; i < selectedArenas.length; i++) {
            for (let j = 0; j < arenaList.length; j++) {
                if (selectedArenas[i] === arenaList[j].arenaId) {
                    arenas.push({ arenaId: arenaList[j].arenaId, arenaName: arenaList[j].arenaName })
                }
            }
        }
        let postBody = {
            couponId: data.couponId,
            facilityId: context.selectedFacility,
            createdBy,
            couponName,
            description,
            arenas: arenas,
            sports: sports,
            isItPercentage,

            users: users,
            isVisible,
            isNewUser,
            isUsageLimited,
            isUsageLimitedPerUser,
            startDate,
            endDate,
        };
        // console.log("postBody", postBody)
        if (amountDiscount) {
            postBody.amountDiscount = Number(amountDiscount);
        }
        if (percentageDiscount) {
            postBody.percentageDiscount = Number(percentageDiscount);
        }
        if (usageLimit) {
            postBody.usageLimit = Number(usageLimit);
        }
        if (maxDiscount) {
            postBody.maxDiscount = Number(maxDiscount);
        }
        if (usageLimitPerUser) {
            postBody.usageLimitPerUser = Number(usageLimitPerUser);
        }

        updateCoupon(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                isLoading(false);
                setMessage({
                    header: "Coupon updated",
                    message: "Coupon has been updated successfully.",
                    color: "green",
                });
            } else {
                isLoading(false);
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            isLoading(false);
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });


    }
    
    return (
        <>
            <ModalPortal
                title={edit?'Edit Coupon':'Create Coupon'}
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddCoupons;