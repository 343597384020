export const BOOKING_SOURCES = {
  PLAY_ALL_WEB: "Play.All",
};
export const ROLES = {
  ADMIN: "admin",
  MANAGER: "Manager",
  COACH: "Coach",
  REGIONAL_MANAGER : "Regional Manager",
  PARTNER : 'Partner',
  USER : 'User',
  HR : 'HR',
  ACCOUNTANT: 'Accountant',
  SALES_MANAGER : 'Sales Manager',
  MARKETING_MANAGER : 'Marketing Manager',
  VIEW_ONLY : 'View Only',
  AUDITOR : 'Auditor',
  QC_MANAGER: 'QC Manager',

};

export const SKILLS = [
  {key : 0, value : 'Beginner',text : 'Beginner' },
  {key : 1, value : 'Intermediate',text : 'Intermediate' },
  {key : 2, value : 'Advance',text : 'Advance' },
  {key : 3, value : 'Expert',text : 'Expert' },
];

export const DAYS_OF_WEEK = [
  {key : 1, value : 'Monday',text : 'Monday' },
  {key : 2, value : 'Tuesday',text : 'Tuesday' },
  {key : 3, value : 'Wednesday',text : 'Wednesday' },
  {key : 4, value : 'Thursday',text : 'Thursday' },
  {key : 5, value : 'Friday',text : 'Friday' },
  {key : 6, value : 'Saturday',text : 'Saturday' },
  {key : 0, value : 'Sunday',text : 'Sunday' },
];

export const EXPENSE_CATEGORY = [
  {key : 0, value : 'Cafe Purchases',text : 'Cafe Purchases' },
  {key : 1, value : 'Repair & Maintenance',text : 'Repair & Maintenance' },
  {key : 2, value : 'Housekeeping',text : 'Housekeeping' },
  {key : 3, value : 'Drinking Water',text : 'Drinking Water' },
  {key : 4, value : 'Fuel',text : 'Fuel' },
  {key : 5, value : 'Bank Deposit',text : 'Bank Deposit' },
  {key : 6, value : 'Tournament',text : 'Tournament' },
  {key : 7, value : 'Purchase',text : 'Purchase' },
  {key : 8, value : 'Conveyance',text : 'Conveyance' },
  {key : 9, value : 'Printing & Stationery',text : 'Printing & Stationery' },
  {key : 10, value : 'Misc',text : 'Misc' },
];

export const RECEIPT_CATEGORY = [
  {key : 0, value : 'playAllTournament',text : 'Play All Tournament' },
  {key : 1, value : 'offlineCredits',text : 'Offline Credits'},
  {key : 2, value : 'misc',text : 'Misc'},
];

export const LEAD_SOURCES = [
  {key : 0, value : 'Google Maps',text : 'Google Maps' },
  {key : 1, value : 'Walk-in',text : 'Walk-in'},
  {key : 2, value : 'Website',text : 'Website'},
  {key : 3, value : 'Referral',text : 'Referral'},
  {key : 4, value : 'Pamphlet',text : 'Pamphlet'},
  {key : 5, value : 'Hoardings',text : 'Hoardings'},
  {key : 5, value : 'Google Ads',text : 'Google Ads'},
  {key : 6, value : 'Others',text : 'Others'},
]

export const ACADEMY_LOST_LEAD_REASONS = [
  {key : 0, value : 'Coaching',text : "Didn't like the Coaching" },
  {key : 1, value : 'Infrastructure',text : "Didn't like the Infrastructure"},
  {key : 2, value : 'Fees',text : 'Fees is High'},
  {key : 3, value : 'Alternative',text : 'Joined some other academy'},
  {key : 4, value : 'Others',text : "Others"},
]

export const  PRODUCT_SUBTYPES = {
  add : 'Add',
  discard : 'Discard',
  assingToAcademy : 'Assigned To Academy',
  sell : 'Sold',
  rent : 'Rented',
  complimentary : 'Complimentary',
}

export const CATEGORY_TYPE = {
  product : 'product',
  cafe : 'cafe'
}

export const COUPON_TYPE = {
active : 'active',
  inactive : 'inactive',
  disabled: 'disabled',
}
export const TRANSACTION_TYPE = {
  booking: "booking",
  product: "product",
  academy: "academy",
  expenses: "expenses",
  receipt: "receipt",
  cafe: "cafe",
  flexiMembership : "flexiMembership",
  wallet : "walletRecharge",
};

export const TRANSACTION_SUBTYPES = {
  regularBooking: "regularBooking",
  bulkBooking: "bulkBooking",
  thirdPartyBooking: "thirdPartyBooking",
  productSell: "sell", // need gst at product level
  productRent: "rent",// need gst at product level
  productUpdated: "productUpdated",
  productSellRent: "sell-rent",
  individualBooking: "individual",
  groupBooking: "group",
  cafeProductSell: "cafeSell",// need gst at product level
  productDiscard: "productDiscard",// need gst at product level
  assignToAcademy: "assignToAcademy",// need gst at product level
  academyProductDiscard: "academyProductDiscard",// need gst at product level
  cafeProductDiscard: "cafeProductDiscard",// need gst at product level
  complimentaryProduct : "complimentaryProduct",// need gst at product level
  partPayment : 'partPayment',
  academyEnrollment : 'enrollment',
  academyPartPayment : 'due-amount-paid',
  receiptPlayAllTournament : 'playAllTournament',
  receiptOfflineCredits : 'offlineCredits',
  receiptMisc : 'misc',
  expenseFuel : 'Fuel',
  expenseRepairAndMaintenance : 'Repair & Maintenance',
  expenseHousekeeping : 'Housekeeping',
  expenseDrinkingWater : 'Drinking Water',
  expenseDisposableUtensils : 'Disposable Utensils',
  expenseBankDeposit : 'Bank Deposit',
  expenseTournament : 'Tournament',
  expensePurchase : 'Purchase',
  expenseConveyance : 'Conveyance',
  expensePrintingAndStationery : 'Printing & Stationery',
  expenseMisc : 'Misc',
};

export const BOOKING_STATUS = {
  BOOKED : 'booked',
  BULK_BOOKING : "bulk",
  BLOCKED : 'blocked',
  EXTENDED : 'extended',
}

export const THIRD_PARTY_BOOKING_SOURCE = {
  HUDLE : 'Hudle',
  PLAYO : 'Playo',
  KHELO_MORE : 'Khelo More',
}

export const HUDLE_BOOKING_SOURCES ={
  ONLINE : 'Online',
  PASS : 'Hudle Pass',
  VENUE_CREDITS : 'Venue Credits',
  HUDLE_CREDITS : 'Hudle Credits',
};

export const ANALYTICS_COLORS ={
   totalColor : 'rgba(56,121,109)',
   playAllColor : 'rgba(26,60,54)',
   hudleColor : 'rgba(86,168,226)',
   playAllAppColor : 'rgba(138,200,183)',
   playoColor : 'rgba(208,231,214)',
};

export const ARENA_ANALYTICS_COLORS = {
  play0001 : '#13005A',
  play0002 : '#F55050',
  play0003 : '#1C82AD',
  play0004 : '#03C988',
  play0005 : '#A75D5D',
  play0006 : '#FF7B54',
  play0007 : '#FFB26B',
  play0008 : '#D3756B',
  play0009 : '#FFD56F',
  play0010 : '#939B62',
  play0011 : '#F0997D',
  play0012 : '#7B2869',
  play0013 : '#1A120B',
  play0014 : '#D5CEA3',
  play0015 : '#E5E5CB',
  play0016 : '#FFC3A1',
  play0017 : '#9D3C72',
  play0018 : '#3C2A21',
  play0019 : '#C85C8E',
  play0020 : '#FFD4B2',
  play0021 : '#FFF6BD',
  play0022 : '#FFF6BD',
  play0023 : '#CEEDC7',
  play0024 : '#F56EB3',
  play0025 : '#7F167F',
  play0026 : '#460C68',
  play0027 : '#86C8BC',
  play0028 : '#FFBABA',
};

export const SPORTS = {
  SP001 :'Badminton',
  SP002 :'Futsal',
  SP003 :'Box-Cricket',
  SP004 :'Table-Tennis',
}

export const PLAYALL_WALLET = {
  id : "playAllWallet",
  name : "PlayAll Wallet"
}

export const SUB_SOURCES = {
  WEB : 'Web',
  ANDROID : 'Android',
  IOS : 'iOS',
  ADMIN : 'Admin App',
}

export const PAYMENT_STATUS = {
  PENDING : 'pending',
  COMPLETED : 'completed',
  FAILED : 'failed',
  REFUNDED : 'refunded',
  CANCELLED : 'cancelled',
  PARTIAL : 'partial'
};