import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import GenericLoader from '../generic/GenericLoader';
import {  Table } from 'semantic-ui-react';
import moment from 'moment';
import { getCashBook } from '../../apiclients/AccountsClient';
import { DatePicker } from '@material-ui/pickers';
import TransactionDetails from '../reports/TransactionDetails';
import { colors } from '@material-ui/core';

const CashBook = (props) => {
    const today = moment();
    const { history } = props;
    const context = useContext(AppContext);
    const { selectedFacility, selectedArena } = context;
    const [isLoading, setisLoading] = useState(false);
    const [openingBalance, setOpeningBalance] = useState();
    const [closingBalance, setClosingBalance] = useState();
    const [date, setDate] = useState(today);
    const [listOfTransaction, setListOfTransaction] = useState([]);
    const [showDetails, setShowDetails]=useState(false);

    const openDetail = (data) => () => {
        setShowDetails(data);
    };

    const loadData = () => {
        setisLoading(true);
        return getCashBook(
            selectedFacility,
            selectedArena.arenaId,
            date
        ).then((response) => {
            const result = response.data;
            console.log(result);
            setOpeningBalance(result.openingBalance);
            setClosingBalance(result.closingBalance);
            setListOfTransaction(result.data);
            setisLoading(false);
        }).catch((err)=>{
            history.push("/extranet/dashboard");
        });
    };



    useEffect(() => {
        if (selectedFacility && selectedArena) {
          loadData();
        }
    }, [selectedFacility,selectedArena,date]);// eslint-disable-line react-hooks/exhaustive-deps


    return isLoading ? (
        <GenericLoader />
    ) : (
        <div style={{ padding: "16px" }}>

            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Cash Book 
                            <h6>(Will be updated at 3 AM)</h6>
                            </span>
                    </div>
                   
                    <div style={{ width: "200px" }}>
          <DatePicker
            autoOk
            variant='inline'
            value={date}
            placeholder='Select Date...'
            onChange={setDate}
            format='DD MMM yyyy'
            style={{ padding: "10px" }}
            disableFuture
          />
        </div>
                </div>
            </div>
       
            <div style={{ paddingTop: "16px" }}>
            <Table  celled padded>
            <Table.Header>
           {openingBalance >=0 &&  <Table.Row>
                
            <Table.HeaderCell > <h5 class="ui center aligned ">Opening Balance - <span style={{fontSize:"25px"}}>Rs. {openingBalance} /-</span></h5></Table.HeaderCell>
            <Table.HeaderCell > <h5 class="ui center aligned ">Closing Balance - <span style={{fontSize:"25px"}}>Rs. {closingBalance} /-</span></h5></Table.HeaderCell>
            </Table.Row>}
                </Table.Header>
                </Table>

                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>S.No</Table.HeaderCell>
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Time</Table.HeaderCell>
                            <Table.HeaderCell>Cash Amount</Table.HeaderCell>
                            <Table.HeaderCell>Sport</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell>Subtype</Table.HeaderCell>
                            {openingBalance >0 && <Table.HeaderCell>Closing Balance</Table.HeaderCell>}
                            <Table.HeaderCell>Created By</Table.HeaderCell>
                            <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        { listOfTransaction.map((x, index) => {
                            return (
                                <Table.Row key={index} >
                                    <Table.Cell>{index + 1}</Table.Cell>
                                    <Table.Cell>{new Date(x.createdDate).toLocaleDateString()}</Table.Cell>
                                    <Table.Cell>{x.createdTime}</Table.Cell>
                                     <Table.Cell>Rs. {x.cashAmount} /-</Table.Cell>
                                     <Table.Cell>{x.alternativeSport ? x.alternativeSport.text : x.sport?.sportName} </Table.Cell>
                                     
                                     <Table.Cell>{x.type} </Table.Cell>
                                     <Table.Cell>{x.subtype} </Table.Cell>
                                     {openingBalance >0 && <Table.Cell>Rs. {x.closingBalance} /-</Table.Cell> }
                                    <Table.Cell>{x.createdBy.userName} </Table.Cell>
                                    <Table.Cell><div
                                        style={{
                                            textDecoration: "underline",
                                            color: colors.blue[500],
                                        }}
                                        onClick={openDetail(x)}
                                    >
                                        {x.id}
                                    </div> </Table.Cell>              
                                </Table.Row>

                            );
                        })}
                    </Table.Body>
               
                </Table>
            
            </div>
            {!!showDetails ? (
        <TransactionDetails
          selectedData={showDetails}
          setShowDetails={setShowDetails}
        /> ): null }
            <br />
            How to Use Cash Book ?
            <div className="player">
                <iframe width="300" height="150" src="https://www.youtube.com/embed/p2IsLhVl6r0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
            </div>
            

        </div>
    );
};

export default CashBook;