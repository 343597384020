import React from 'react';
import './HorizontalLineWithText.css';

const HorizontalLineWithText = ({ text }) => {
  return (
    <div className="line-container">
      <span className="line-text">{text}</span>
    </div>
  );
};

export default HorizontalLineWithText;
