import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";

import { colors } from "@material-ui/core";
import { Button, Divider, Form, Icon, Tab, Table } from "semantic-ui-react";
import "../dashboard/style.scss";
import "./BulkBooking.scss";
import {
  cancelBulkBooking,
  getBulkBookings,
  updateBulkBookingPayment,
  cancelBulkBookingAmount,
} from "../../apiclients/BulkBookingApiClient";
import { AppContext } from "../../AppContext";
import {
  convertFirstoreDate,
  objectToArray,
} from "../../utils/helperFunctions";
import GenericLoader from "../generic/GenericLoader";
import CustomModal from "../generic/CustomModal";
import Payment from "../dashboard/Payment";

const BookingDataTable = ({ type }) => {
  const context = useContext(AppContext);

  const arenaData = context.arenaData[context.selectedArena.arenaId];
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [cancelInfo, setCancelInfo] = useState(false);
  const [paymentArray, setPaymentArray] = useState([]);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (!reload) {
      setReload(true);
    }
  }, [context.selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showCancel) {
      setLoader(true);
      cancelBulkBookingAmount(
        context.selectedFacility,
        context.selectedArena.arenaId,
        showCancel.key
      ).then((res) => {
        setCancelInfo(res.data);
        setLoader(false);
      });
    }
  }, [showCancel]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reload) {
      setLoader(true);
      getBulkBookings(
        context.selectedFacility,
        context.selectedArena.arenaId,
        type
      ).then((response) => {
        setLoader(false);
        setReload(false);
        const arr = objectToArray(response.data.result);
        setData(arr);
      });
    }
  }, [type, reload, context.selectedFacility, context.selectedArena]);

  const updateBookingPayment = () => {
    const createdBy =  {
      userName: context.user.userName,
      userId: context.user.userId,
  };
    setLoader(true);
    updateBulkBookingPayment(
      context.selectedFacility,
      context.selectedArena.arenaId,
      showPayment.key,
      paymentArray,
      createdBy,arenaData
    ).then(() => {
      setReload(true);
      setShowPayment(false);
    });
  };

  const cancelBookingPayment = () => {
    setLoader(true);
    cancelBulkBooking(
      context.selectedFacility,
      context.selectedArena.arenaId,
      showCancel.key,
      showCancel.sportId,
      cancelInfo.cancellationAmount
    ).then(() => {
      setReload(true);
      setShowCancel(false);
    });
  };
  const totalPayment = paymentArray
    .map((x) => +x.amount)
    .reduce((x, sum) => sum + x, 0);
  return loader ? (
    <GenericLoader />
  ) : (
    <>
      {showCancel ? (
        <CustomModal
          header={"Cancel Booking"}
          content={
            <div style={{ paddingBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>
                Total Amount to be refunded: Rs.
              </span>
              <span>
                <input
                  value={cancelInfo?.cancellationAmount}
                  onChange={(data) =>
                    setCancelInfo({
                      ...cancelInfo,
                      cancellationAmount: data.target.value,
                    })
                  }
                />
              </span>
            </div>
          }
          footer={
            <Button onClick={cancelBookingPayment}>CANCEL BOOKING</Button>
          }
          setOpen={setShowCancel}
          open={showCancel}
        />
      ) : null}
      {showPayment ? (
        <CustomModal
          header={"Update Payment"}
          content={
            <Form>
              <Divider horizontal>
                <span style={{ color: colors.blue["400"] }}>Payment</span>
              </Divider>
              <div style={{ paddingBottom: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Total Amount to be paid: Rs.
                </span>
                <span>{showPayment.amountDue}/-</span>
              </div>
              <Payment
                paymentArray={paymentArray}
                setPaymentArray={setPaymentArray}
              />
              <div style={{ paddingTop: "10px" }}>
                <span style={{ fontWeight: "bold" }}>Total Payment: Rs.</span>
                <span>{totalPayment}/-</span>
              </div>
            </Form>
          }
          footer={<Button onClick={updateBookingPayment}>Submit</Button>}
          setOpen={setShowPayment}
          open={showPayment}
        />
      ) : null}
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Parent User</Table.HeaderCell>
            <Table.HeaderCell>Parent Number</Table.HeaderCell>
            <Table.HeaderCell> Dates</Table.HeaderCell>
            <Table.HeaderCell>Slots</Table.HeaderCell>
            <Table.HeaderCell>Sport</Table.HeaderCell>
            <Table.HeaderCell>Amount Due</Table.HeaderCell>
            <Table.HeaderCell>Bulk Booking ID</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((x) => {
            const currentSport =
              context.facilitySports &&
              context.facilitySports.find((s) => s.sportId === x.sportId);
            return (
              <Table.Row>
              <Table.Cell>{x.isPickupGame ? "Pickup Game" :"Bulk-Booking"}</Table.Cell>
                <Table.Cell>{x.members && x.members[0].name}</Table.Cell>
                <Table.Cell>{x.members && x.members[0].number}</Table.Cell>
                <Table.Cell>{x.startDate}-{x.endDate}</Table.Cell>
                <Table.Cell>{x.slotDuration}</Table.Cell>
                <Table.Cell>
                  {currentSport && currentSport.sportName}
                </Table.Cell>
                <Table.Cell>Rs. {x.amountDue}/-</Table.Cell>
                <Table.Cell>
                  <div>
                    <NavLink
                      style={{
                        textDecoration: "underline",
                        color: colors.blue[500],
                      }}
                      to={`/extranet/bulkBooking/${x.key}`}
                    >
                      {x.key}
                    </NavLink>
                    {x.expiringIn ? (
                      <div style={{ color: "red" }}>
                        Expiring in {x.expiringIn} days
                      </div>
                    ) : null}
                  </div>
                  <div>
                    {x.history
                      ? "Created on: " +
                        convertFirstoreDate(x.history[0].creationDate)
                      : ""}
                  </div>
                </Table.Cell>
                {type === "expired" ? (
                  <Table.Cell
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    {" "}
                    <div style={{ color: colors.red["500"] }}>
                      BOOKING EXPIRED
                    </div>{" "}
                  </Table.Cell>
                ) : (
                  <Table.Cell
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    {x.amountDue ? (
                      <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                        }}
                        onClick={() => setShowPayment(x)}
                      >
                        Update Payment
                      </Button>
                    ) : null}

                    {x.cancelled ? (
                      <div style={{ color: colors.red["500"] }}>CANCELLED</div>
                    ) : (
                      <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                        }}
                        negative
                        onClick={() => setShowCancel(x)}
                      >
                        Cancel Booking
                      </Button>
                    )}
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

const panes = [
  {
    menuItem: "Expiring Soon Bulk Bookings",
    render: () => (
      // <div>
      <Tab.Pane className={"tabBox"}>
        <BookingDataTable type={"expiringsoon"} />
      </Tab.Pane>
      // </div>
    ),
  },
  {
    menuItem: "All Bulk Bookings",
    render: () => <BookingDataTable type={"running"}/>,
  },
  {
    menuItem: "Expired Bulk Bookings",
    render: () => (
      <Tab.Pane className={"tabBox"}>
        <BookingDataTable type={"expired"} />
      </Tab.Pane>
    ),
  },
];
const BulkBooking = (props) => {
  const { history } = props;

  return (
    <div style={{ padding: "16px" }}>
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>Bulk Booking</span>
          </div>
          <div style={{ alignItems: "center" }}>
            <span>
              <Button
                onClick={() => history.push("/extranet/addbulkbooking")}
                name={"Add Booking"}
              >
                <Icon name={"add"} />
                Add Booking
              </Button>
            </span>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "16px" }}>
        <Tab
          renderActiveOnly={true}
          menu={{
            color: "orange",
            pointing: true,
          }}
          panes={panes}
        />
      </div>
    </div>
  );
};

export default BulkBooking;
