import React, { useEffect, useContext, useState } from "react";
import {
  Accordion,
  Button,
  Dropdown,
  Form,
  Grid,
  Icon,
  Segment,
  Table,
} from "semantic-ui-react";
import { getProducts } from "../../apiclients/DashboardApiClient";
import {
  getProductHistory,
  saveProductUpdatedData,
} from "../../apiclients/ProductApiClient";
import { AppContext } from "../../AppContext";
import GenericLoader from "../generic/GenericLoader";
import { convertFirstoreDate } from "../../utils/helperFunctions";
import RentOrSaleProduct from "../dashboard/booked-booking/RentOrSaleProduct";
import CustomModal from "../generic/CustomModal";

const ProductsMain = (props) => {
  const { history } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [products, setProducts] = useState(0);
  const [loader, setLoader] = useState(true);
  const [editType, setEditType] = useState();
  const [updateQuantityBy, setUpdateQuantityBy] = useState(0);
  const [showRentOrSell, setShowRentOrSell] = useState(false);
  const [reload, setReload] = useState(true);

  const context = useContext(AppContext);

  useEffect(() => {
    if (!reload) {
      setReload(true);
    }
  }, [context.selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reload) {
      getProducts(context.selectedFacility, context.selectedArena.arenaId).then(
        (response) => {
          const dataWithValue = response.data.products.map((x) => ({
            ...x,
            text: x.productName,
            value: x.productName,
            key: x.productId,
            isRenting: !!x.rent,
          }));
          setProducts(dataWithValue);
          setLoader(false);
          setReload(false);
        }
      );
    }
  }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

  const getHistoryDetail = (productId) => {
    getProductHistory(
      context.selectedFacility,
      context.selectedArena.arenaId,
      productId
    ).then((data) => {
      console.log("Data", data);
      // setHistoryDetail(data.data.history);
      setProducts((state) => {
        return state.map((x) => {
          if (x.productId === productId) {
            return { ...x, historyDetail: data.data.history };
          }
          return x;
        });
      });
    });
  };
  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };

  const handleNumberChange = (type) => (evt) => {
    const numericValue = evt.target.validity.valid ? evt.target.value : 0;
    if (type === "updateQuantityBy") {
      setUpdateQuantityBy(+numericValue);
    } else {
      setEditType((st) => ({ ...st, [type]: +numericValue }));
    }
  };

  const saveDetails = () => {
    let postBdy = {
      price: editType.price,
      updateQuantityBy,
      quantity: editType.quantity,
      rent: editType.isRenting ? editType.rent || 0 : 0,
    };

    console.log("Data", postBdy, editType.productId);
    setLoader(true);
    saveProductUpdatedData(
      context.selectedFacility,
      context.selectedArena.arenaId,
      editType.productId,
      postBdy
    ).then(() => {
      setEditType(null);
      setReload(true);
      setLoader(false);
    });
  };

  const isEditing = !!editType;

  return (
    <div style={{ padding: "16px" }}>
      <div className='dashboard' style={{ marginBottom: "16px" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>Products</span>
          </div>
          <div style={{ alignItems: "center" }}>
            <span>
              <Button
                onClick={() => history.push("/extranet/addproduct")}
                name={"Add Product"}
              >
                <Icon name={"add"} />
                Add Product
              </Button>
            </span>
            <span>
              <Button
                onClick={() => setShowRentOrSell(true)}
                name={"Add Product"}
              >
                <Icon name={"add"} />
                Rent/Sell
              </Button>
            </span>
          </div>
        </div>
      </div>
      {loader ? (
        <GenericLoader />
      ) : (
        <Accordion fluid styled>
          {products.map((x, index) => {
            const isCurrentEdit =
              editType && editType.productId === x.productId;
            const isRenting = editType ? editType.isRenting : x.isRenting;
            return (
              <>
                <Accordion.Title
                  active={activeIndex === index}
                  index={index}
                  onClick={handleClick}
                >
                  <Icon name='dropdown' />
                  {x.productName}
                </Accordion.Title>
                <Accordion.Content active={activeIndex === index}>
                  <Segment.Group style={{ padding: "12px" }}>
                    <Grid>
                      <Icon
                        onClick={() => setEditType(editType ? null : x)}
                        name={"pencil alternate"}
                        color={isEditing ? "grey" : "orange"}
                        style={{
                          margin: "12px",
                          marginBottom: "0px",
                          marginTop: "14px",
                          display: "flex",
                          flex: 1,
                          justifyContent: "flex-end",
                        }}
                      />
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <span style={{ fontWeight: "bold" }}>
                            Product Id:
                          </span>
                        </Grid.Column>
                        <Grid.Column width={12}>
                          <span>{x.productId}</span>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <span style={{ fontWeight: "bold" }}>
                            Product name:
                          </span>
                        </Grid.Column>
                        <Grid.Column width={12}>
                          <span>{x.productName}</span>
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <span style={{ fontWeight: "bold" }}>Quantity:</span>
                        </Grid.Column>
                        <Grid.Column width={12}>
                          <span>{x.quantity} left</span>
                          {isCurrentEdit && (
                            <span>
                              <Form>
                                <Form.Group>
                                  <Form.Field>
                                    <span
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flex: 1,
                                      }}
                                    >
                                      <input
                                        style={{ marginRight: "6px" }}
                                        type='text'
                                        value={updateQuantityBy}
                                        placeholder='5'
                                        onInput={handleNumberChange(
                                          "updateQuantityBy"
                                        )}
                                      />
                                      <Button
                                        onClick={() =>
                                          setUpdateQuantityBy((st) => st + 1)
                                        }
                                      >
                                        +
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          setUpdateQuantityBy((st) => st - 1)
                                        }
                                      >
                                        -
                                      </Button>
                                    </span>
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                            </span>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <span style={{ fontWeight: "bold" }}>
                            Is renting allowed? :
                          </span>
                        </Grid.Column>
                        <Grid.Column width={12}>
                          {isCurrentEdit ? (
                            <Dropdown
                              onChange={(event, { value }) => {
                                setEditType((st) => ({
                                  ...st,
                                  isRenting: value,
                                }));
                              }}
                              placeholder='Select...'
                              openOnFocus
                              selection
                              value={editType.isRenting}
                              options={[
                                { key: "yes", text: "Yes", value: true },
                                { key: "no", text: "No", value: false },
                              ]}
                            />
                          ) : (
                            <span>{x.isRenting ? `Yes` : "No"}</span>
                          )}
                        </Grid.Column>
                      </Grid.Row>
                      {isRenting && (
                        <Grid.Row>
                          <Grid.Column width={4}>
                            <span style={{ fontWeight: "bold" }}>
                              Rental Price:
                            </span>
                          </Grid.Column>
                          <Grid.Column width={12}>
                            {isCurrentEdit ? (
                              <Form>
                                <Form.Group>
                                  <Form.Field>
                                    <span
                                      style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        flex: 1,
                                      }}
                                    >
                                      <input
                                        style={{ marginRight: "6px" }}
                                        type='text'
                                        value={editType.rent}
                                        placeholder='50'
                                        onInput={handleNumberChange("rent")}
                                      />
                                    </span>
                                  </Form.Field>
                                </Form.Group>
                              </Form>
                            ) : (
                              <span>Rs.{x.rent}/-</span>
                            )}
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      <Grid.Row>
                        <Grid.Column width={4}>
                          <span style={{ fontWeight: "bold" }}>
                            Product Price:
                          </span>
                        </Grid.Column>
                        <Grid.Column width={12}>
                          {isCurrentEdit ? (
                            <Form>
                              <Form.Group>
                                <Form.Field>
                                  <span
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      flex: 1,
                                    }}
                                  >
                                    <input
                                      style={{ marginRight: "6px" }}
                                      type='text'
                                      value={editType.price}
                                      placeholder='50'
                                      onInput={handleNumberChange("price")}
                                    />
                                  </span>
                                </Form.Field>
                              </Form.Group>
                            </Form>
                          ) : (
                            <span>Rs.{x.price}/-</span>
                          )}
                        </Grid.Column>
                      </Grid.Row>

                      <Grid.Row>
                        <Grid.Column>
                          <span>
                            {isCurrentEdit && (
                              <Button
                                style={{
                                  backgroundColor: "#e8632b",
                                  color: "#fff",
                                }}
                                onClick={() => saveDetails()}
                              >
                                Save
                              </Button>
                            )}
                            <Button
                              primary
                              onClick={() => getHistoryDetail(x.productId)}
                            >
                              History
                            </Button>
                          </span>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Segment.Group>
                  {!!x.historyDetail && (
                    <Table celled padded>
                      <Table.Header>
                        <Table.Row>
                          <Table.HeaderCell>Quantity</Table.HeaderCell>
                          <Table.HeaderCell>Type</Table.HeaderCell>
                          <Table.HeaderCell>Last updated</Table.HeaderCell>
                        </Table.Row>
                      </Table.Header>
                      <Table.Body>
                        {x.historyDetail.map((y) => (
                          <Table.Row>
                            <Table.Cell>
                              <div>{y.quantity}</div>
                            </Table.Cell>
                            <Table.Cell>
                              <div style={{ textTransform: "capitalize" }}>
                                {y.type}
                              </div>
                            </Table.Cell>
                            <Table.Cell>
                              <div>{convertFirstoreDate(y.createdAt)}</div>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </Table.Body>
                    </Table>
                  )}
                </Accordion.Content>
              </>
            );
          })}
        </Accordion>
      )}
      {showRentOrSell ? (
        <CustomModal
          header={"Rent/Sell Products"}
          content={
            <RentOrSaleProduct
              facility={{
                ...context.selectedArena,
                id: context.selectedFacility,
              }}
              onSaveComplete={() => setReload(true)}
              setOpen={setShowRentOrSell}
              setType={() => setShowRentOrSell(false)}
            />
          }
          comp={true}
          setOpen={setShowRentOrSell}
          open={showRentOrSell}
        />
      ) : null}
    </div>
  );
};

export default ProductsMain;
