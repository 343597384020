import React from 'react';
import { colors, Icon } from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import moment from 'moment';

const DatesSelection = ({ startDate, endDate, changeDate,disablePast, disableFuture }) => {
console.log('startDate',startDate)

  return (
    <>
      <div
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "row",
          // marginTop: "20px",
        }}
      >
        <div style={{ marginRight: "50px" }}>
          <div
            style={{

              fontWeight: "bold",
            }}
          >
            Start Date
          </div>
          <DatePicker
            autoOk
            disablePast={disablePast}
            disableFuture={disableFuture}
            id='startDate'
            name='startDate'
            placeholder='Select Date...'
            format='DD MMM yyyy'
            onChange={changeDate(true)}
            value={startDate}
          />
        </div>
        <div>
          <div style={{  fontWeight: "bold" }}>
            End Date
          </div>
          <DatePicker
            autoOk
            disablePast={disablePast}
            id='endDate'
            name='endDate'
            placeholder='Select Date...'
            format='DD MMM yyyy'
            onChange={changeDate(false)}
            value={endDate}
          />
        </div>
      </div>
      {startDate && moment(startDate).isAfter(endDate) && endDate ? (
        <div
          style={{
            flex: 1,
            fontWeight: "bold",
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            color: colors.red["500"],
          }}
        >
          Start Date can not be greater than End Date. Please select again.
        </div>
      ) : null}
    </>
  );
};

export default DatesSelection;
