import React, { useContext, useState} from 'react';
import {
    Button,
} from "semantic-ui-react";

import { colors } from "@material-ui/core";
import { Form, Divider, Dropdown } from 'semantic-ui-react';
import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { lostLeadTrial } from "../../../apiclients/AcademyClient";
import { ACADEMY_LOST_LEAD_REASONS } from '../../../utils/constants';

const LostLead = (props) => {
    const {  showModal, setReload, trialData, setMessage,isLoading, trialId, sportId } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const [reason, setReason]= useState();
    const [remarks, setRemarks]= useState();

    const isSubmitAllowed = reason && remarks;

    const changeReason = (event, { value }) => {
        setReason(value);
    };
    const modalContent = (
        <div>
            Are you sure you  want to mark the Trial for - {trialData.parentName} as a Lost Lead ?
            <Form>
                
                <Divider horizontal>
                    <span style={{ color: colors.blue["400"] }}>Lost Lead Details</span>
                </Divider>
                <Form.Group widths='equal'>
                 
                    <Form.Field  >

                        <label>Reason</label>
                        <Dropdown
                            onChange={changeReason}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={reason}
                            options={ACADEMY_LOST_LEAD_REASONS}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Remarks</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={remarks}
                            placeholder='Add more details'
                            onChange={(evt) => setRemarks(evt.target.value)}

                        />
                    </Form.Field>
                </Form.Group>

            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            
                <Button  style={{
                                                backgroundColor: colors.orange["800"],
                                                color: "#fff",
                                            }} disabled = {!isSubmitAllowed}
                                             onClick={markLostLead}>  Mark Lost Lead</Button>
                </React.Fragment>
        );
    };

    const markLostLead = () => {

        showModal(false);
        isLoading(true);

        const postBody = {
            facilityId, arenaId, sportId, trialId, reason, remarks,
            statusUpdatedBy: {
                userName: context.user.userName,
                userId: context.user.userId,
            }
        };

        lostLeadTrial(
            postBody
        ).then((response) => {
            if( response.status === 200 || response.status === 201){
                setMessage({
                    header: "Trial Updated",
                    message: "Trial Marked as a Lost Lead.",
                    color: "grey",
                  });
            }  else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
              });
            // ..
          });
    }
    return (
        <>
            <ModalPortal
                title='Lost Lead Confirmation'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default LostLead;