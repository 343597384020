import React from "react";
import ReactExport from "react-export-excel";
import { Button, Icon } from "semantic-ui-react";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const SheetData = (columns) => {
  return columns.map((column) => {
    return <ExcelColumn label={column.label} value={column.value} />;
  });
};

const ExportToExcel = ({ sheets, disabled, fileName, buttonName }) => {
  return (
    <ExcelFile
      filename={fileName}
      element={
        <Button disabled={disabled}>
          <Icon name={"download"} />
          {buttonName}
        </Button>
      }
    >
      {sheets.map((sheet) => {
        return (
          <ExcelSheet data={sheet.dataSet} name={sheet.name}>
            {SheetData(sheet.columns)}
          </ExcelSheet>
        );
      })}
    </ExcelFile>
  );
};

export default ExportToExcel;
