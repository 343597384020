import React, { useContext, useRef, useState, useEffect } from 'react';
import GenericLoader from '../../generic/GenericLoader';
import { Dropdown, Button, Icon, Table, Tab } from 'semantic-ui-react';
import { AppContext } from '../../../AppContext';
import { getAllAcademySportsOfArena } from '../../../apiclients/AcademyClient';
import AddTrial from "./AddTrial";
import Toast from '../../generic/Toast';
import { getTrialData } from '../../../apiclients/AcademyClient';
import { colors } from '@material-ui/core';
import TrialDetails from './TrialDetails';

const TrialDataTable = ({
    listOfTrials,
    setReload,
    setMessage,
    isLoading,
    history,
     types
}) => {
    const [showDetails, setShowDetails] = useState(false); // to show student detail modal
    const [trialId, setTrialId] = useState();


    const openTrialDetail = (data, id) => () => {
        setShowDetails(data);
        setTrialId(id);
    };

    return (
        <>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>S.No</Table.HeaderCell>
                        <Table.HeaderCell>Student Name</Table.HeaderCell>
                        <Table.HeaderCell>Mobile</Table.HeaderCell>
                        <Table.HeaderCell>Parent's Name</Table.HeaderCell>
                        <Table.HeaderCell>Trial Date & Time</Table.HeaderCell>
                        <Table.HeaderCell>Skill</Table.HeaderCell>
                        <Table.HeaderCell>Via</Table.HeaderCell>
                        <Table.HeaderCell>Source</Table.HeaderCell>
                        <Table.HeaderCell>Stage</Table.HeaderCell>
                        <Table.HeaderCell>Detail</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {listOfTrials && listOfTrials.map((y, i) => {
                        var x = y.data;
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{x.studentName}</Table.Cell>
                                <Table.Cell>{x.mobile}</Table.Cell>
                                <Table.Cell>{x.parentName}</Table.Cell>
                                <Table.Cell>{new Date(x.trialDate.seconds * 1000).toLocaleDateString()} | {new Date(x.trialDate.seconds * 1000).toLocaleTimeString()}</Table.Cell>
                                <Table.Cell>{x.skill}</Table.Cell>
                                <Table.Cell>{x.via}</Table.Cell>
                                <Table.Cell>{x.source}</Table.Cell>
                                <Table.Cell>{x.stage}</Table.Cell>
                                <Table.Cell >
                                    <div
                                        style={{
                                            textDecoration: "underline",
                                            color: colors.blue[500],
                                        }}
                                        onClick={openTrialDetail(x, y.id)}
                                    >
                                        {y.id}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            
            </Table>


            {!!showDetails ? (
                <TrialDetails
                    trialData={showDetails}
                    trialId={trialId}
                    showModal={setShowDetails}
                    setReload={setReload}
                    setMessage={setMessage}
                    isLoading={isLoading}
                    history={history}
                    type={types}
                />
            ) : null}
        </>
    );
};

const TrialManagement = (props) => {
    const { history } = props;
    const context = useContext(AppContext);
    const { selectedFacility, selectedArena, } = context;
    const [activeIndex, setActiveIndex] = useState(0);
    const [addModal, setAddModal] = useState(false);
    const sports = useRef([]);
    const [selectedSport, setSelectedSport] = useState({});
    const [allAcademySports, setAllAcademySports] = useState([]);
    const [listOfTrials, setListOfTrials] = useState([]);
    const [reload, setReload] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});
    const types = ['upcoming',  'complete','incomplete',];

    useEffect(() => {
        if (selectedFacility && selectedArena) {
            getAllAcademySportsOfArena(selectedFacility, selectedArena.arenaId).then(
                (response) => {
                    if (response.status === 200) {
                        const sport = response.data;
                        const dropdownSportsData = sport.map(
                            (x, index) => ({
                                key: x.sportId,
                                value: x.sportId,
                                text: x.sportName,
                            })
                        );
                        setAllAcademySports(dropdownSportsData);
                        sports.current = dropdownSportsData;
                        setSelectedSport(dropdownSportsData[0]);
                        loadData(dropdownSportsData[0]);
                    } else {
                        history.push("/extranet/dashboard");
                    }
                }
            ).catch((err) => {
                history.push("/extranet/dashboard");
            });

        }
    }, [selectedFacility, selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        loadData(selectedSport);
    }, [activeIndex]);

    useEffect(() => {
        if (reload) {
            loadData(selectedSport).then(() => {
                setReload(false);
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 2000);
            });
        }
    }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

    const changeSport = (event, { value }) => {
        const data = sports.current.find((facility) => facility.value === value);
        setSelectedSport(data);
        loadData(data);
    };

    const loadData = async (sportData) => {
        setisLoading(true);
        if(sportData.value !== undefined){

        console.log('the data is ', 
        selectedFacility,
        selectedArena.arenaId,
        sportData.value,
        types[activeIndex],);
            return await getTrialData(
                selectedFacility,
                selectedArena.arenaId,
                sportData.value,
                types[activeIndex],
            ).then((response) => {
                const { result } = response.data;
                setListOfTrials(result);
            }).then(() => {
                setisLoading(false);
            });
        } else {

            setisLoading(false);
        }
    };

    const panes = [
        {
            menuItem: "Upcoming Trials",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <TrialDataTable
                        listOfTrials={listOfTrials}
                        setReload={setReload}
                        sport={selectedSport}
                        history={history}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                        types={types[activeIndex]}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Completed Trials (Last 30 days)",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <TrialDataTable
                        listOfTrials={listOfTrials}
                        sport={selectedSport}
                        setReload={setReload}
                        history={history}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                        types={types[activeIndex]}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Incomplete Trials (Last 30 days)",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <TrialDataTable
                        listOfTrials={listOfTrials}
                        sport={selectedSport}
                        setReload={setReload}
                        history={history}
                        types={types[activeIndex]}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                    />
                </Tab.Pane>
            ),
        },

    ];

    return isLoading ? (
        <GenericLoader />
    ) : (
        <div style={{ padding: "16px" }}>
            <Toast
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                message={messageContent.message}
                messageHeader={messageContent.header}
                color={messageContent.color}
            />
            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Trial Management</span>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>
                            <Dropdown
                                onChange={changeSport}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={selectedSport && selectedSport.value}
                                options={sports.current}
                            />
                            <Button
                                style={{ marginLeft: "20px" }}
                                onClick={() => setAddModal(true)}
                                name={"Add Package"}
                            >
                                <Icon name={"add"} />
                                Schedule a Trial
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            {isLoading ? (
                <GenericLoader />
            ) : (
                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    renderActiveOnly={true}
                    menu={{
                        color: "orange",
                        pointing: true,
                    }}
                    panes={panes}
                />
            )}

           

            <br />

            How to Add a Trial, manage or Edit a Trial ?
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Update Lead on Play All Partner App</Table.HeaderCell>
                        <Table.HeaderCell>Add a Trial</Table.HeaderCell>
                        <Table.HeaderCell>Edit Trials</Table.HeaderCell>
                        <Table.HeaderCell>Manage a Trial</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                <Table.Row key={0}>
                <Table.Cell><div className="player2">
                <iframe width="200" height="100" src="https://www.youtube.com/embed/DaUxo6HEDRw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
                 </div></Table.Cell>
                <Table.Cell><div className="player2">
                <iframe width="200" height="100" src="https://www.youtube.com/embed/Jr9EudkGUEo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
                 </div></Table.Cell>
                <Table.Cell><div className="player2">
                <iframe width="200" height="100" src="https://www.youtube.com/embed/k5ymMuHaGoo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
                 </div></Table.Cell>
                <Table.Cell><div className="player2">
                <iframe width="200" height="100" src="https://www.youtube.com/embed/_5UZLYXj1Z0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
                 </div></Table.Cell>
                </Table.Row>
                </Table.Body>
            
            </Table>
            
            {addModal ? (
                <AddTrial
                    history={history}
                    showModal={setAddModal}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                    selectedSport={selectedSport}
                />
            ) : null}
        </div>
    );
}

export default TrialManagement;