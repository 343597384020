import React, { useContext, useEffect, useState, useRef } from 'react';
import { colors } from "@material-ui/core";
import { DatePicker } from '@material-ui/pickers';
import { DateTimePicker } from '@material-ui/pickers';
import {
    Button,
    Dropdown,
    Form,
    Divider
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { editTrial } from "../../../apiclients/AcademyClient";
import { SKILLS, LEAD_SOURCES } from '../../../utils/constants';
import NoShow from './NoShow';
import LostLead from './LostLead';
import EnrollStudent from '../studmentManagement/EnrollStudent';

const TrialDetails = (props) => {
    const { showModal, setReload, setMessage, isLoading, trialData, trialId, history , type} = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const sportId = trialData.sportId;
    const dateOfTrial = trialData.trialDate === undefined ? null : new Date(trialData.trialDate.seconds * 1000);
    const birthday = trialData.dateOfBirth === undefined ? null : new Date(trialData.dateOfBirth.seconds * 1000);
    const disablePastDates = type === 'incomplete' ? false : true;
    const [mobile, setMobile] = useState(trialData.mobile);
    const [parentName, setParentName] = useState(trialData.parentName);
    const [studentName, setStudentName] = useState(trialData.studentName);
    const [email, setEmail] = useState(trialData.email);
    const [dateOfBirth, setDateOfBirth] = useState(birthday);
    const [skill, setSkill] = useState(trialData.skill);
    const [trialDate, setTrialDate] = useState(dateOfTrial);
    const [source, setSource] = useState(trialData.source);
    const [remarks, setRemarks] = useState(trialData.remarks.remarks);
    const [isEditAllowed, setIsEditAllowed] = useState(false);
    const [showNoShowModal, setShowNoShowModal] = useState(false);
    const [showLostLeadModal, setShowLostLeadModal] = useState(false);
    const [showEnrollLeadModal, setShowEnrollLeadModal] = useState(false);
  const firstUpdate = useRef(true);

    
    const isSubmitAllowed = mobile && skill && parentName && studentName && email && dateOfBirth && trialDate && source && remarks;

    useEffect(() => {
        if (firstUpdate.current) {
          firstUpdate.current = false;
          return;
        }
        setIsEditAllowed(mobile && skill && parentName && studentName && email && dateOfBirth && trialDate && source && remarks);
 
      });
        
 
    const changeSkill = (event, { value }) => {
        setSkill(value);
    };

    const changeSource = (event, { value }) => {
        setSource(value);
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Mobile Number</label>
                        <input
                            style={{ marginRight: "6px" }}
                            disabled
                            type='number'
                            value={mobile}
                            pattern='[0-9]{10}$*'
                            placeholder='9911XXXXXX'
                        // onFocus={}
                        // onChange={(evt) => fetchParentData(evt.target.value)}


                        />
                    </Form.Field>

                    <Form.Field  >
                        <label>Student Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={studentName}
                            placeholder="Enter Student's Name"
                            onChange={(evt) => setStudentName(evt.target.value)}

                        />
                    </Form.Field>





                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Parent Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={parentName}
                            placeholder="Enter Parent's Name"
                            onChange={(evt) => setParentName(evt.target.value)}

                        />
                    </Form.Field>

                    <Form.Field  >
                        <label>Email</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='email'
                            value={email}
                            placeholder="Enter Parent's Email"
                            onChange={(evt) => setEmail(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Date of Birth</label>
                        <DatePicker
                            autoOk
                            disableFuture
                            variant='inline'
                            value={dateOfBirth}
                            placeholder='Select Date...'
                            onChange={setDateOfBirth}
                            format='DD MMM yyyy'
                        />
                    </Form.Field>


                </Form.Group>
                <Divider horizontal>
                    <span style={{ color: colors.blue["400"] }}>Trial Details</span>
                </Divider>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Trial Date & Time</label>
                        <DateTimePicker
                            autoOk
                            disablePast={disablePastDates}
                            variant='inline'
                            value={trialDate}
                            placeholder='Select Date...'
                            onChange={setTrialDate}
                            format='DD MMM yyyy h:mm'
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Skills</label>
                        <Dropdown
                            onChange={changeSkill}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={skill}
                            options={SKILLS}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Source</label>
                        <Dropdown
                            onChange={changeSource}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={source}
                            options={LEAD_SOURCES}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>
                </Form.Group>

                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Remarks</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={remarks}
                            placeholder='More detail about the lead'
                            onChange={(evt) => setRemarks(evt.target.value)}

                        />
                    </Form.Field>
                </Form.Group>


            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
               {type === 'complete' ? null: <><Button style={{ backgroundColor: colors.orange["800"],  color: "#fff",}}  disabled={!isSubmitAllowed} primary onClick={()=>setShowEnrollLeadModal(true)}>  Enroll Student</Button>
                <Button className='ui button' onClick={()=>setShowNoShowModal(true)}>  No Show</Button>
                <Button className='ui button' onClick={()=>setShowLostLeadModal(true)}>  Lost Lead</Button>
                <Button disabled={!isEditAllowed} primary onClick={editATrial}>  Edit Details</Button> </>} 
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            </React.Fragment>
        );
    };
  


    const showSubModal = () =>{
        setShowNoShowModal(false);
        setShowLostLeadModal(false);
        showModal(false);
    }
    const editATrial = () => {

        showModal(false);
        isLoading(true);
        const postBody = {
            facilityId, arenaId, sportId, parentName, studentName, email, dateOfBirth, trialDate, remarks,
            editedBy: {
                userName: context.user.userName,
                userId: context.user.userId,
            }, source, skill, trialId
        };
        console.log('post body is ', postBody);

        editTrial(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Trial Edited",
                    message: "Trial has been added Edited.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });
    }
    return (
        <>
           

            {showNoShowModal ?  <NoShow
                    showModal={showSubModal}
                    setReload={setReload}
                    trialData={trialData}
                    trialId={trialId}
                    setMessage={setMessage}
                    isLoading={isLoading}
                    sportId={sportId}
                /> : showLostLeadModal ? 
                <LostLead
                    showModal={showSubModal}
                    setReload={setReload}
                    trialData={trialData}
                    trialId={trialId}
                    setMessage={setMessage}
                    isLoading={isLoading}
                    sportId={sportId}
                />: showEnrollLeadModal ? 
                    <EnrollStudent
                        history={history}
                        showModal={showSubModal}
                        setReload={setReload}
                        setMessage={setMessage}
                        isLoading={isLoading}
                        selectedSport={{key : sportId}}
                        isRenewal={false}
                        renewalDetails={null}
                        leadDetails={trialData}
                        trialId={trialId}
                    />
                :<ModalPortal
                title='Trial Details'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />}
        </>

    );


}

export default TrialDetails;