import React, { useEffect, useContext, useState, useReducer } from "react";
import { Button, Icon, Modal, Table, Checkbox, Form, } from "semantic-ui-react";

import "../dashboard/style.scss";
import { AppContext } from "../../AppContext";
import { loadBlockingReport, loadEnquiryTransactions, loadReleaseReport } from "../../apiclients/ReportsApiClient";
import {
  convertFirstoreDate,
  convertFirstoreDateForExcel,
} from "../../utils/helperFunctions";
import CustomModal from "../generic/CustomModal";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import {
  filterReducer,
  FILTERS_ACTION_TYPE,
  initialFilterState,
} from "./bookingReportReducer";
import GenericLoader from "../generic/GenericLoader";
import ExportToExcel from "../generic/ExportToExcel";
import { ROLES } from "../../utils/constants";
import moment from "moment";
import Toast from "../generic/Toast";
import EnquiryDetails from "./EnquiryDetails";





const FilterFooter = ({ setShowFilter, onApply }) => {
  return (
    <Modal.Actions>
      <Button className={"ui button booked-footer"} onClick={onApply}>
        Apply
      </Button>
      <Button onClick={() => setShowFilter(false)}>Cancel</Button>
    </Modal.Actions>
  );
};

const FilterContent = ({ onApply, setShowFilter, selectedFilters, role }) => {
  const [filters, dispatch] = useReducer(filterReducer, {
    ...initialFilterState,
    ...selectedFilters,
  });
  const { startDate, endDate, } = filters;

  const onReset = () => {
    dispatch({ type: FILTERS_ACTION_TYPE.RESET });
  };
  const onFilterApplyClicked = () => {
    onApply(filters);
  };



  return (
    <>
      <Modal.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Filters</span>
          <span
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              color: colors.orange[500],
            }}
            onClick={onReset}
          >
            Clear all
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Enquiry Date
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Start Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={startDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_START_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}

              maxDate={moment()}
            />
            <div style={{ fontWeight: "bold" }}>End Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={endDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_END_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}


              minDate={!(role === ROLES.ADMIN) ?
                role === ROLES.HR ? moment().subtract(30, 'days')
                  : moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
          </div>

        </div>
      </Modal.Content>
      <FilterFooter
        onApply={onFilterApplyClicked}
        setShowFilter={setShowFilter}
      />
    </>
  );
};

const BookingFilters = ({
  setShowFilter,
  showFilter,
  selectedFilters,
  onApply,
  role
}) => {
  return (
    <CustomModal
      comp={true}
      content={
        <FilterContent
          onApply={onApply}
          selectedFilters={selectedFilters}
          setShowFilter={setShowFilter}
          role={role}
        />
      }
      setOpen={setShowFilter}
      open={showFilter}
    />
  );
};


const BlockingSummaryReport = () => {
  const { selectedFacility, selectedArena, user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [showFilter, setShowFilter] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});
  const [totalSlotBlocked, settotalSlotBlocked] = useState(0);
  const [showBlockingReleased, setShowBlockingReleased] = useState(false);

  useEffect(() => {
    if (!loadData) {
      setLoadData(true);
    }
  }, [selectedArena, showBlockingReleased]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadData) {
      const params = {
        startDate: selectedFilters.startDate,
        endDate: selectedFilters.endDate,
        releaseData: showBlockingReleased
      };

      loadBlockingReport(
        selectedFacility,
        params
      ).then((res) => {
        const theData = res.data.data;
        setData(theData);
        settotalSlotBlocked(theData.length);
        setLoadData(false);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [loadData]); // eslint-disable-line react-hooks/exhaustive-deps



  let tableHeader = [
    {
      label: "Created Date",
      value: (col) => new Date(col.createdDate.seconds * 1000).toLocaleDateString(),
    },
    {
      label: "Created Time",
      value: (col) => new Date(col.createdDate.seconds * 1000).toLocaleTimeString(),
    },
    {
      label: "Arena",
      value: (col) => col.arenaName && col.arenaName,
    },
    {
      label: "Sport",
      value: (col) => col.sportName && col.sportName,
    },
    {
      label: "Courts",
      value: (col) => col.courtNameArray && col.courtNameArray.join(', '),
    },
    {
      label: "Dates",
      value: (col) => col.datePeriod && col.datePeriod.length === 1 ? col.datePeriod[0] : col.datePeriod[0] + " - " + col.datePeriod[col.datePeriod.length - 1],
    },
    {
      label: "Days",
      value: (col) => col.days && col.days.join(', '),
    },
    {
      label: "Slots",
      value: (col) => col.slots && col.slots,
    },
    {
      label: "Type",
      value: (col) => col.bookingType && col.bookingType,
    },
    {
      label: "Reason",
      value: (col) => col.bookingReason && col.bookingReason,
    },
    {
      label: "Created By",
      value: (col) => col.createdBy && col.createdBy.userName,
    },
    {
      label: "Id",
      value: (col) => col.id && col.id,
    },
  ];



  const onApply = (filters) => {
    setLoadData(true);
    setSelectedFilters(filters);
    setShowFilter(false);
  };

  const openFilter = () => {
    setShowFilter(true);
  };



  const startDate = convertFirstoreDate(
    selectedFilters.startDate,
    "DD MMM YYYY"
  );
  const endDate = convertFirstoreDate(selectedFilters.endDate, "DD MMM YYYY");
  const fileName = (showBlockingReleased ?'Blocking_Release_Summary ' :'Blocking_Summary ' )+ startDate + " - " + endDate;
  const sheetName = moment().format('MMMM Do YYYY, h_mm a');

  const sheets = [
    {
      name: sheetName,
      columns: tableHeader,
      dataSet: data,
    },
  ];


  return (
    <div style={{ padding: "16px" }}>
      <Toast
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        message={messageContent.message}
        messageHeader={messageContent.header}
        color={messageContent.color}
      />
      <div style={{ paddingBottom: "16px" }}>
        <div className='dashboard'>
        <div>
              <Form>  <Form.Field  >
                    <label> Show Released Blockings</label>
                      <Checkbox
                        checked={showBlockingReleased}
                        style={{ marginRight: "7px", marginLeft: "7px", marginTop: "20px" }}
                        onClick={(e, { checked }) => {
                          setShowBlockingReleased(checked);
                        }}
                        toggle
                      />
                  </Form.Field>
              </Form>
            </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span > {(showBlockingReleased ? "Blocking Release " : "Blocking ") + "Summary Report"}</span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingRight: "10px",
                }}
              >
                ( Range: {startDate}- {endDate})
              </span>
            </div>
            
            <div style={{ alignItems: "center" }}>
              <span>

                <span>
                  <ExportToExcel disabled={data.length === 0 || !(user.role === ROLES.ADMIN || user.role === ROLES.REGIONAL_MANAGER)} sheets={sheets}
                    fileName={fileName}
                    buttonName="Download Report" />
                </span>
                <Button onClick={openFilter} name={"filter"}>
                  <Icon
                    color={selectedFilters.isFilterApplied ? "orange" : "grey"}
                    name={"filter"}
                  />
                  Filter
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>

      {
        showFilter ? (
          <BookingFilters
            onApply={onApply}
            selectedFilters={selectedFilters}
            setLoadData={setLoadData}
            setShowFilter={setShowFilter}
            showFilter
            role={user.role}
          />
        ) : null
      }

      {
        loadData ? (
          <GenericLoader />
        ) : data.length === 0 ? (
          "No data available"
        ) : (
          <div>
            <Table celled padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Total Blocking</Table.HeaderCell>
                  <Table.HeaderCell>{totalSlotBlocked}</Table.HeaderCell>
                </Table.Row>
              </Table.Header>

              <Table.Body>

              </Table.Body>
            </Table>
            <Table celled padded>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Creation Date</Table.HeaderCell>
                  <Table.HeaderCell>Creation Time</Table.HeaderCell>
                  <Table.HeaderCell>Arena</Table.HeaderCell>
                  <Table.HeaderCell>Sport</Table.HeaderCell>
                  <Table.HeaderCell>Courts</Table.HeaderCell>
                  <Table.HeaderCell>Blocking Dates</Table.HeaderCell>
                  <Table.HeaderCell>Days</Table.HeaderCell>
                  <Table.HeaderCell>Slots</Table.HeaderCell>
                  <Table.HeaderCell>Type</Table.HeaderCell>
                  <Table.HeaderCell>Reason</Table.HeaderCell>
                  <Table.HeaderCell>Created By</Table.HeaderCell>
                  <Table.HeaderCell>Id</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data && data.map((x) => {
                  return (
                    <Table.Row>
                      <Table.Cell>
                        <div>
                          {new Date(x.createdDate.seconds * 1000).toLocaleDateString()}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <div>
                          {new Date(x.createdDate.seconds * 1000).toLocaleTimeString()}
                        </div>
                      </Table.Cell>
                      <Table.Cell>

                        {x.arenaName && x.arenaName}
                      </Table.Cell>
                      <Table.Cell>
                        {x.sportName && x.sportName}
                      </Table.Cell>

                      <Table.Cell>
                        {x.courtNameArray && x.courtNameArray.join(', ')}
                      </Table.Cell>

                      <Table.Cell>
                        {x.datePeriod && x.datePeriod.length === 1 ? x.datePeriod[0] : x.datePeriod[0] + " - " + x.datePeriod[x.datePeriod.length - 1]}

                      </Table.Cell>
                      <Table.Cell>
                        {x.days && x.days.join(', ')}
                      </Table.Cell>
                      <Table.Cell>
                        {x.slots && x.slots}
                      </Table.Cell>

                      <Table.Cell>
                        {x.bookingType && x.bookingType}
                      </Table.Cell>


                      <Table.Cell>
                        {x.bookingReason && x.bookingReason}
                      </Table.Cell>
                      <Table.Cell>
                        {x.createdBy && x.createdBy.userName}
                      </Table.Cell>
                      <Table.Cell>
                        {x.id && x.id}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </div>
        )
      }
    </div >
  );
};

export default BlockingSummaryReport;
