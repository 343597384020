import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import moment from "moment";
import { ROLES } from "../../../../utils/constants";
import { Button } from "semantic-ui-react";
import { cancelRegularBooking } from "../../../../apiclients/BookingApiClient";

const CancelCapacitySportBooking = (props) => {
    const { selectedSportDetails, user } =  useContext(AppContext);
    const context =  useContext(AppContext);
    const {userData,bookingDetails,setOpen, reload,setMessage,setIsLoading,setShowCancellationModal} = props;
    const [policyRule, setPolicyRule] = useState({});


    useEffect(() => {
        const refundArray = [];
        const data = selectedSportDetails.detail.policies.cancellation.rules;
        const today = moment();
        const ms = moment(
            moment(bookingDetails.bookingDate, "MM-DD-YYYY").format("DD-MM-YYYY") +
            " " + bookingDetails.interval.start.toString() + ":00", "DD/MM/YYYY HH:mm:ss"
        ).diff(moment(today, "DD/MM/YYYY HH:mm:ss"));
        const d = moment.duration(ms);

        data.forEach((doc) => {
            if (
                (Math.floor(d.asHours()) > doc.hoursBefore && Math.floor(d.asHours()) > 0) ||
                user.role === ROLES.ADMIN  // if admin has allowed cancellation
            ) {
                refundArray.push(doc.refund);
            }
        });

        let refundPercentage = refundArray.length === 0 ? 0 : Math.max(...refundArray);
        if (refundPercentage === 0 && user.role !== ROLES.ADMIN) { // if admin has allowed cancellation
            setPolicyRule({ isAllowed: false });
        } else {
            setPolicyRule({ isAllowed: true, refund: refundPercentage });
        }
    }, []);

    const cancelBooking = () => {
    
        const arenaData = context.arenaData[context.selectedArena.arenaId];
        let postBody =   {
          transactionId: userData.id,
          sport: context.selectedSportDetails.sportId,
          refundAmount: (userData.amountPaid * policyRule.refund) / 100,
          role: user.role,
          createdBy: {
            userName: context.user.userName,
            userId: context.user.userId,
          },
          cancellationAllowed :true,
          arenaData,
        };

        setIsLoading(true);
        setShowCancellationModal(false);
        cancelRegularBooking(
          context.selectedFacility,
          context.selectedArena.arenaId,
          postBody,
        )
          .then(() => {
            setOpen(false);
            setMessage({
              header: "Booking Cancelled!",
              message: "You booking has been cancelled.",
              color: "green",
            });

            setIsLoading(false);
            reload(true);
          })
          .catch((err) => {
            setOpen(false);
            setMessage({
              header: "Error : Booking not Cancelled!",
              message: `${err.response.data}`,
              color: "red",
            });
            reload(true);
          });
      };

    return (
        <div style={{ padding: "10px" }}>
            {policyRule.isAllowed
              ? "Are you sure you want to cancel this booking?"
              : "Cancellation not allowed."}
            <div style={{ fontWeight: "bold" }}>
              {policyRule.isAllowed
                ? `Refund Percent :  ${policyRule.refund}%`
                : null}
            </div>
            <Button
          disabled={!policyRule.isAllowed}
          negative
          onClick={cancelBooking}
        >
          Cancel Booking
        </Button>
          </div>
          
    )
};

export default CancelCapacitySportBooking;