import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Segment,
  Divider,
  Modal,
  Button,
} from "semantic-ui-react";
import { getLastReceiptNumber } from "../../../apiclients/DashboardApiClient";
import Payment from "../Payment";
import { colors } from "@material-ui/core";
import { rentProducts } from "../../../apiclients/ProductApiClient";
import { AppContext } from "../../../AppContext";
import ProductSelection from "../ProductSelection";
import GenericLoader from "../../generic/GenericLoader";
import CustomerDetail from "../../common/CustomerDetail";

const Footer = ({ setOpen, type, setType, disabled, onConfirm }) => {
  return (
    <>
      <Button onClick={() => setType("request")}>Go Back</Button>
      <Button primary disabled={disabled} onClick={onConfirm}>
        Save
      </Button>
    </>
  );
};

const RentOrSaleProduct = ({
  facility,
  booking,
  setOpen,
  setType,
  reload,
  setMessage,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [receiptNo, setReceiptNo] = useState();
  const [paymentArray, setPaymentArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState();
  const [number, setNumber] = useState();

  const context = useContext(AppContext);

  useEffect(() => {
    getLastReceiptNumber(facility.id, facility.arenaId).then((response) => {
      setReceiptNo(response.data.nextReceipt);
    });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const onSaveClicked = () => {
    setLoader(true);
    let totalAmountBeforeDiscount = 0;
    let totalDiscount = 0;
    selectedItems.forEach(function (item){
      const cost = item.isRenting ?item.rent : item.price;
      totalAmountBeforeDiscount = totalAmountBeforeDiscount+ cost;
      totalDiscount = item.discount ? totalDiscount + (item.discount*cost/100): totalDiscount + 0;
    });
    const overAllDiscount = totalDiscount*100/totalAmountBeforeDiscount;
    const products = selectedItems.map((x) => ({
      id: x.productId,
      name: x.productName,
      quantity: +x.quantityAdded,
      discount: +x.discount ? +x.discount : 0,
      isRental: !!x.isRenting,
      price: x.isRenting ? x.rent : x.price,
      cgst : x.cgst ? x.cgst : 0,
      sgst : x.sgst ? x.sgst : 0,
      igst : x.igst ? x.igst : 0,
      cess : x.cess ? x.cess : 0,
      hsn : x.hsn ? x.hsn : '9996',
    }));

    console.log('products are 2 ', products);
    let postBody = {
      arenaId: facility.arenaId,
      sport: context.selectedSportDetails.sportId,
      products: JSON.stringify(products),
      courtInfo: {
        courtId : booking && booking.courtId ? booking.courtId : 'NA',
        courtName :booking && booking.courtName? booking.courtName : 'NA'
      },
      receiptNumber: Number(receiptNo),
      paymentInfo: JSON.stringify(paymentArray),
      discount : overAllDiscount,
      totalAmount : totalAmountBeforeDiscount,
    };

    postBody.userName = booking ? booking.userName : name;
    postBody.mobileNumber = booking ? booking.userMobileNumber : number;
    postBody.bookingId = booking ? booking.id : undefined;
    postBody.createdBy = {
      userName: context.user.userName,
      userId: context.user.userId,
    };
    rentProducts(facility.id, facility.arenaId, postBody)
      .then(() => {
        setLoader(false);
        setOpen(false);
        setMessage &&
          setMessage({
            header: "Product Sold/Rented!",
            message: "You transaction has been recorded.",
            color: "teal",
          });
          reload(true);
      })
      .catch((err) => {
        setLoader(false);
        setMessage &&
          setMessage({
            header: "Error",
            message: `${err.response}`,
            color: "red",
          });
          reload(true);
      });
  };
  const getPrice = (x) => {
    // to do
    const price = x.isRenting ? x.rent : x.price;
    return (
      x.quantityAdded * price * (x.discount ? (100 - x.discount) / 100 : 1)
    );
  };
  const totalAmount = selectedItems
    .filter((x) => !!x.quantityAdded)
    .map(getPrice)
    .reduce((x, sum) => sum + x, 0);

  const totalPayment = paymentArray
    .map((x) => +x.amount)
    .reduce((x, sum) => sum + x, 0);

  function handleNumberChange(evt) {
    const numericValue = evt.target.validity.valid ? evt.target.value : number;
    setNumber(numericValue);
  }
  return ( 
    <>

{loader ? <GenericLoader /> : null}
      <Modal.Content>
        {booking ? (
          <Segment.Group>
            <div className={"info-cell"}>
              <div className={"pr10-bold"}>Reciept Number:</div>
              <div>{receiptNo}</div>
            </div>
            <div className={"info-cell"}>
              <div className={"pr10-bold"}>User Name:</div>
              <div>{booking.userName}</div>
            </div>
            <div className={"info-cell"}>
              <div className={"pr10-bold"}>Mobile Number:</div>
              <div>{booking.userMobileNumber}</div>
            </div>
          </Segment.Group>
        ) : (
          <Form>
            <CustomerDetail
              name={name}
              setName={setName}
              number={number}
              setNumber={handleNumberChange}
            />
          </Form>
        )}
        <Form>
          <ProductSelection
            facility={facility}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            requestFromBookingModal={false}
          />

          {totalAmount > 0 ? (
            <>
              <Divider horizontal>
                <span style={{ color: colors.blue["400"] }}>Payment</span>
              </Divider>
              <div style={{ paddingBottom: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Total Amount to be paid:{" "}
                </span>
                <span>Rs.{totalAmount}/-</span>
              </div>
              <Payment
                paymentArray={paymentArray}
                setPaymentArray={setPaymentArray}
              />
              <div style={{ paddingTop: "10px" }}>
                <span style={{ fontWeight: "bold" }}>Total Payment: </span>
                <span>Rs.{totalPayment}/-</span>
              </div>
            </>
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Footer
          onConfirm={onSaveClicked}
          setType={setType}
          setOpen={setOpen}
          disabled={
            totalAmount === 0 ||
            totalAmount !== totalPayment ||
            (!booking && (!name || !number))
          }
        />
      </Modal.Actions>
    </>
 );
};

export default RentOrSaleProduct;
