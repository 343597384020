import React, { useEffect, useContext, useState } from "react";

import { colors } from "@material-ui/core";
import { Button, Icon, Table, Tab } from "semantic-ui-react";
import "../dashboard/style.scss";

import { AppContext } from "../../AppContext";
import GenericLoader from "../generic/GenericLoader";
import {
  getWalletPackages,
  deleteWalletPackage,
  disableWalletPackage
} from "../../apiclients/WalletApiClient";
import AssignWalletCreditsModal from "./AssignWalletCredits";
import AssignSpecialCreditsModal from "./AssignSpecialCredits";
import AddPackages from "./AddPackages";
import Toast from "../generic/Toast";
import { ROLES } from "../../utils/constants";

const WalletPackage = (props) => {
  const { history } = props;
  const context = useContext(AppContext);
  const [reload, setReload] = useState(true);
  const [packages, setPackages] = useState([]);
  const [assignData, setAssignData] = useState(null);

  const [addModal, setAddModal] = useState(false); // to show add modal
  const [showWalletAssignModal, setShowWalletAssignModal] = useState(false);
  const [showSpecialAssignModal, setShowSpecialAssignModal] = useState(false);


  const [isLoading, setisLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});
  const [editPackage, setEditPackage] = useState({ show: false, data: {} });
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    getWalletPackages(
      context.selectedFacility,
      context.selectedArena.arenaId,
      activeIndex == 0 ? true : false
    ).then((response) => {
      const pacakgeData = response.data.walletData;
      console.log(pacakgeData);
      setPackages(pacakgeData);
      setisLoading(false);
      setReload(false);
    });
  }, [context.selectedArena.arenaId, context.selectedFacility, reload, activeIndex]);

  const deletePackage = (packageId) => {
    setisLoading(true);
    console.log(packageId);
    deleteWalletPackage(
      context.selectedFacility,
      context.selectedArena.arenaId,
      packageId
    ).then(() => {
      setReload(true);
    });
  };

  const disblePackage = (id) => {
    // console.log("disblePackage",id);
    setisLoading(true);
    // console.log(packageId);
    disableWalletPackage(
      context.selectedFacility,
      id
    ).then(() => {
      setReload(true);
    }).catch((error) => {
      console.log(error);
      setisLoading(false);
    });
  };

  const showPlayAllWalletAssignmentModal = () => {
    setAssignData(null);
    setShowWalletAssignModal(true);
  };

  const showPlayAllSpecialAssignmentModal = () => {
    setAssignData(null);
    setShowSpecialAssignModal(true);
  };

  const showCreditModal = (packageDetails) => {
    setAssignData(packageDetails);
    setShowWalletAssignModal(true);
  };

  const CouponTable = (props) => {
    return (

      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>S.No</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Credits</Table.HeaderCell>
            <Table.HeaderCell>Amount</Table.HeaderCell>
            <Table.HeaderCell>Validity</Table.HeaderCell>
            <Table.HeaderCell>Sports</Table.HeaderCell>
            <Table.HeaderCell>Advance Booking</Table.HeaderCell>
            <Table.HeaderCell>Details</Table.HeaderCell>
            <Table.HeaderCell>Created By</Table.HeaderCell>
            {!props.disable && (
              <Table.HeaderCell>Actions</Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {packages.length > 0 ? packages.map((x, index) => {

            // if (props.disable) {
            //     if (x.active) {
            //   return null;
            // }
            // } else{
            //   if (!x.active) {
            //     return null;
            //   }
            // }

            return (
              <Table.Row key={x.id}>
                <Table.Cell>{index + 1}</Table.Cell>
                <Table.Cell>{x.packageName && x.packageName}</Table.Cell>
                <Table.Cell>Rs. {x.credits} worth</Table.Cell>
                <Table.Cell>Rs. {x.amount}/-</Table.Cell>
                <Table.Cell>{x.validity} days </Table.Cell>
                <Table.Cell>{x.sports.map(x => x.sportName + ', ')}</Table.Cell>
                <Table.Cell>{x.advanceBooking}</Table.Cell>
                <Table.Cell>{x.details}</Table.Cell>
                <Table.Cell>{x.createdBy && x.createdBy.userName}</Table.Cell>
                {!props.disable && (
                  <Table.Cell
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    <Button
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        marginBottom: "8px",
                        backgroundColor: colors.orange["800"],
                        color: "#fff",
                      }}
                      onClick={() => showCreditModal(x)}
                    >
                      Enroll
                    </Button>
                    <Button
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        marginBottom: "8px",
                        // backgroundColor: colors.orange["800"],
                        // color: "#fff",
                      }}
                      onClick={() => { setEditPackage({ show: true, data: x }) }}
                      disabled={context.user.role === ROLES.ADMIN || context.user.role === ROLES.REGIONAL_MANAGER ? false : true}

                    >
                      Edit
                    </Button>
                    <Button
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        marginBottom: "8px",

                      }}
                      onClick={() => disblePackage(x?.id
                      )}
                      disabled={context.user.role === ROLES.ADMIN || context.user.role === ROLES.REGIONAL_MANAGER ? false : true}

                    >
                      Disable
                    </Button>

                    {/* <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                        }}
                        onClick={() => deletePackage(x.id)}
                        disabled={ context.user.role === ROLES.ADMIN || context.user.role === ROLES.REGIONAL_MANAGER  ? false : true}
                      >
                        Disable
                      </Button> */}
                  </Table.Cell>
                )}
              </Table.Row>
            );
          }) : <Table.Row>
            <Table.Cell colSpan='9' style={{ textAlign: 'center' }}>No data found</Table.Cell>
          </Table.Row>}
        </Table.Body>
      </Table>

    );
  };

  const panes = [
    {
      menuItem: "Active Wallet Packages",
      render: () => (
        <Tab.Pane className={"tabBox"}>
          <CouponTable
          //  disable={false}
          />
        </Tab.Pane>
      ),
    },

    {
      menuItem: "Disabled Wallet Packages",
      render: () => (
        <Tab.Pane className={"tabBox"}>
          <CouponTable
            disable={true}
          />
        </Tab.Pane>
      ),
    },

  ];

  return (
    <div style={{ padding: "16px" }}>

      <div className='dashboard'>
        <Toast
          showMessage={showMessage}
          setShowMessage={setShowMessage}
          message={messageContent.message}
          messageHeader={messageContent.header}
          color={messageContent.color}
        />
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",

          }}
        >

          <div>
            <span>Wallet Packages</span>
          </div>
          <div style={{ alignItems: "center" }}>
            <div style={{ fontSize: "14px" }}>
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => showPlayAllSpecialAssignmentModal()}
                disabled={context.user.role === ROLES.ADMIN ? false : true}
              > <Icon name={"add"} />
                Assign Venue Credits to user
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => showPlayAllWalletAssignmentModal()}
                disabled={context.user.role === ROLES.ADMIN ? false : true}
              > <Icon name={"add"} />
                Assign Credits to PlayAll Wallet
              </Button>
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => setAddModal(true)}
                name={"Add Package"}
                disabled={context.user.role === ROLES.ADMIN || context.user.role === ROLES.REGIONAL_MANAGER ? false : true}

              >
                <Icon name={"add"} />
                Add a Package
              </Button>



            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <GenericLoader />
      ) : (
        <div style={{ marginTop: "20px" }}>
          <Tab
            activeIndex={activeIndex}
            onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
            renderActiveOnly={true}
            menu={{
              color: "orange",
              pointing: true,
            }}
            panes={panes}
          />
        </div>
      )}

      {addModal ? (
        <AddPackages
          history={history}
          showModal={setAddModal}
          setReload={() => { setReload(true) }}
          setMessage={setMessageContent}
          isLoading={setisLoading}
          setShowMessage={setShowMessage}
        />
      ) : null}

      {editPackage.show && (<AddPackages
        history={history}
        showModal={(value) => { setEditPackage({ show: false, data: {} }) }}
        setReload={() => { setReload(true) }}
        setMessage={setMessageContent}
        isLoading={setisLoading}
        editPackage={editPackage}
        setShowMessage={setShowMessage}
      />)}

      {showWalletAssignModal ? (
        <AssignWalletCreditsModal
          history={history}
          setShowWalletAssignModal={setShowWalletAssignModal}
          setReload={setReload}
          isLoading={setisLoading}
          assignData={assignData}
          setMessage={setMessageContent}
          setShowMessage={setShowMessage}
        />
      ) : null}

      {showSpecialAssignModal ? (
        <AssignSpecialCreditsModal
          history={history}
          setShowWalletAssignModal={setShowSpecialAssignModal}
          setReload={setReload}
          isLoading={setisLoading}
          assignData={assignData}
          setMessage={setMessageContent}
          setShowMessage={setShowMessage}
        />
      ) : null}

    </div>
  );
};

export default WalletPackage;
