import React from "react";
import { Dimmer, Loader } from "semantic-ui-react";
import "./loader.css";
// import "../dashboard/style.scss";

const GenericLoader = () => {
  const funnyLoaderText = [
    "Swapping time and space...",
    "Spinning violently around the y-axis...",
    "Tokenizing real life...",
    "Bending the spoon...",
    "Filtering morale...",
    "Don't think of purple hippos...",
    "We need a new fuse...",
    "Upgrading Windows, your PC will restart several times. Sit back and relax.",
    "(Pay no attention to the man behind the curtain)",
    "Don't worry - a few bits tried to escape, but we caught them",
    "Would you like fries with that?",
    "Go ahead -- hold your breath!",
    "...at least you're not on hold...",
    "We're testing your patience",
    "As if you had any other choice",
    "Why don't you order a sandwich?",
    "My other loading screen is much faster.",
    "The bits are flowing slowly today",
    "Are we there yet?",
    "Have you lost weight?",
    "Just count to 10",
    "Why so serious?",
    "It's not you. It's me.",
    "Do not run! We are your friends!",
    "We're making you a cookie.",
    "Spinning the wheel of fortune...",
    "Computing chance of success",
    "All your web browser are belong to us",
    "Don't break your screen yet!",
    "I swear it's almost done.",
    "Let's take a mindfulness minute...",
    "Where did all the internets go",
    "Wait, do you smell something burning?",
    "When nothing is going right, go left!!...",
    "I love my job only when I'm on vacation...",
  ];

  const showFunnyLoaderText = () => {
    const selectedText =
      funnyLoaderText[Math.floor(Math.random() * funnyLoaderText.length - 1)];

    return (
      <div style={{ color: "orange", fontSize: "15px", fontWeight: "bold" }}>
        {selectedText}
      </div>
    );
  };

  return (
    <Dimmer style={{ height: window.outerHeight + 50 }} active inverted>
      <Loader size='massive'>{showFunnyLoaderText()}</Loader>
    </Dimmer>
  );
};

export default GenericLoader;
