import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";

export const getOccupancyAnalyticsData = (facilityId, arenaId, sportId) => {
    const url = getURLWithQueryParams(
        API_URLS.GET_OCCUPANCY_ANALYTICS_DATA, undefined, {
            facilityId, arenaId, sportId 
        }
    );

    return tokenAxiosInstance.get(url);
}

export const getWeeklyAnalyticsData = (facilityId,) => {
    const url = getURLWithQueryParams(
        API_URLS.GET_WEEKLY_ANALYTICS_DATA, undefined, {
            facilityId, 
        }
    );

    return tokenAxiosInstance.get(url);
}

export const getMonthlyAnalyticsData = (facilityId,) => {
    const url = getURLWithQueryParams(
        API_URLS.GET_MONTHLY_ANALYTICS_DATA, undefined, {
            facilityId, 
        }
    );

    return tokenAxiosInstance.get(url);
}

export const getSlotWiseOccupancyData = (facilityId, arenaId, sportId, startDate, endDate, daysOfWeek) => {
    const url = getURLWithQueryParams(
        API_URLS.SLOT_WISE_OCCUPANCY_DATA, undefined, {
            facilityId, arenaId, sportId ,  daysOfWeek
        }
    );

    return tokenAxiosInstance.get(url, {
        params:{
            startDate,
            endDate
        },
    });
}

export const getAcademyAnalyticsData = (facilityId, arenaId, sportId) => {
    const url = getURLWithQueryParams(
        API_URLS.GET_ACADEMY_ANALYTICS_DATA, undefined, {
            facilityId, arenaId, sportId 
        }
    );

    return tokenAxiosInstance.get(url);
}

export const getTransactionAnalyticsData = (facilityId, arenaId, sportId) => {
    const url = getURLWithQueryParams(
        API_URLS.GET_TRANSACTION_ANALYTICS_DATA, undefined, {
            facilityId, arenaId, sportId 
        }
    );

    return tokenAxiosInstance.get(url);
}

export const getDateWiseTransactionData = (facilityId, arenaId, sportId, startDate, endDate) => {
    const url = getURLWithQueryParams(
        API_URLS.DATE_WISE_TRANSACTION_DATA, undefined, {
            facilityId, arenaId, sportId , 
        }
    );

    return tokenAxiosInstance.get(url, {
        params:{
            startDate,
            endDate
        },
    });
}

export const getLeadAnalyticsData = (facilityId, arenaId ) => {
    const url = getURLWithQueryParams(
        API_URLS.GET_LEAD_ANALYTICS_DATA, undefined, {
            facilityId, arenaId, 
        }
    );

    return tokenAxiosInstance.get(url);
}