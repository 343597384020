import React, { useState, useContext, useRef, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Dropdown } from 'semantic-ui-react';
import { Line, Pie } from 'react-chartjs-2';
import GenericLoader from '../generic/GenericLoader';
import { AppContext } from '../../AppContext';
import { getAllSportsOfArena } from '../../apiclients/BookingApiClient';
import { getTransactionAnalyticsData } from '../../apiclients/AnalyticsClient';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { Card, Grid } from '@material-ui/core';
import moment from 'moment';

const TransactionAnalytics = () => {

  const {
    selectedFacility,
    selectedArena,
    arenaData,
    selectedSportDetails,
    dispatch,
  } = useContext(AppContext);

  const sports = useRef([]);
  const [selectedSport, setSelectedSport] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState();
  const [typeGraphData, setTypeGraphData] = useState();
  const [sourceGraphData, setSourceGraphData] = useState();
  const [hudleGraphData, setHudleGraphData] = useState();
  const [expenseGraphData, setExpenseGraphData] = useState();
  const [otherReceiptGraphData, setOtherReceiptGraphData] = useState();
  const [modeGraphData, setModeGraphData] = useState();


  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });

  var formatter = new Intl.NumberFormat('en-IN', {
    style: 'currency',
    currency: 'INR',
 });
  const classes = useStyles();
  const loadData = (selectedFacility, selectedArena, selectedSport) => {
    setisLoading(true);
    return getTransactionAnalyticsData(
      selectedFacility, selectedArena, selectedSport
    ).then((response) => {
      const analyticsData = response.data;
      setAnalyticsData(analyticsData);
      let typegraphsData = createLineItemDataForType(analyticsData);
      setTypeGraphData(typegraphsData);

      let sourcegraphsData = createLineItemDataForBookingSource(analyticsData);
      setSourceGraphData(sourcegraphsData);

      let hudlegraphsData = createLineItemDataForHudleSource(analyticsData);
      setHudleGraphData(hudlegraphsData);

      let expensegraphsData = createLineItemExpenseData(analyticsData);
      setExpenseGraphData(expensegraphsData);

      let otherReceiptgraphsData = createLineItemDataForOtherReceipt(analyticsData);
      setOtherReceiptGraphData(otherReceiptgraphsData);

      let modegraphsData = createLineItemModeData(analyticsData);
      setModeGraphData(modegraphsData);

      setisLoading(false);
    });
  };

  useEffect(() => {
    if (selectedFacility && selectedArena){
      getAllSportsOfArena(selectedFacility, selectedArena.arenaId).then(
        (response) => {
          const listOfSports = response.data;
          const dropdownSportsData = listOfSports.map(
            (x, index) => ({
              key: x.sportId,
              value: x.sportId,
              text: x.sportName,
            })
          );
           dropdownSportsData.unshift({
            key: 'arena',
            value: 'arena',
            text: 'Arena level',
          })
          sports.current = dropdownSportsData;

          setSelectedSport(dropdownSportsData[0]);
          loadData(selectedFacility, selectedArena.arenaId, dropdownSportsData[0].value);
        }
      );
    }
      
  }, [selectedFacility, selectedArena.arenaId]); // eslint-disable-line react-hooks/exhaustive-deps

 

  const changeSport = (event, { value }) => {

    const data = sports.current.find((facility) => facility.value === value);
    setSelectedSport(data);
    loadData(selectedFacility, selectedArena.arenaId, data.value);
  };

  const colorOne = 'rgba(56,121,109)';
  const colorTwo = 'rgba(26,60,54)';
  const colorThree = 'rgba(86,168,226)';
  const colorFour = 'rgba(138,200,183)';
  const colorFive = 'rgba(208,231,214)';
  const allColors = [colorTwo, colorThree, colorFour, colorFive, colorOne];

  const createLineItemDataForType = (data) => {
    let labels = [];
    let totalDataSet = {
      label: 'Net Receipt',
      data: [],
      borderColor: [colorOne],
      backgroundColor: [colorOne],
      tension: 0.3,
      fill: false,
    };
    let bookingDataSet = {
      label: 'Bookings',
      data: [],
      borderColor: [colorTwo],
      backgroundColor: [colorTwo],
      tension: 0.3,
      fill: false,
    };
    let bulkBookingDataSet = {
      label: 'Bulk-Bookings',
      data: [],
      borderColor: [colorThree],
      backgroundColor: [colorThree],
      tension: 0.3,
      fill: false,
    };
    let academyDataSet = {
      label: 'Academy',
      data: [],
      borderColor: [colorFour],
      backgroundColor: [colorFour],
      tension: 0.3,
      fill: false,
    };
    let productDataSet = {
      label: 'Products',
      data: [],
      borderColor: [colorFive],
      backgroundColor: [colorFive],
      tension: 0.3,
      fill: false,
    };
    if (data && data.weeklyData) {
      data.weeklyData.forEach(x => {
        labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY'));
        totalDataSet.data.push(x.totalReceipts -x.totalCancellationRefund);
        bookingDataSet.data.push((x.transactionType.bookingTransaction ));
        bulkBookingDataSet.data.push((x.transactionType.bulkBookingTransaction + x.transactionType.groupMembershipTransaction + x.transactionType.individualMembershipTransaction));
        academyDataSet.data.push((x.transactionType.academyTransaction));
        productDataSet.data.push((x.transactionType.productRentalTransaction + x.transactionType.productSaleTransaction));
      });
    }
    let lineData = {
      labels: labels,
      datasets: [
        totalDataSet, bookingDataSet, bulkBookingDataSet, academyDataSet, productDataSet
      ]
    };
    return lineData;
  }

  const createLineItemDataForBookingSource = (data) => {
    let labels = [];
    let playAllDataSet = {
      label: 'Play All',
      data: [],
      borderColor: [colorOne],
      backgroundColor: [colorOne],
      tension: 0.3,
      fill: false,
    };
    let hudleDataSet = {
      label: 'Hudle',
      data: [],
      borderColor: [colorTwo],
      backgroundColor: [colorTwo],
      tension: 0.3,
      fill: false,
    };
    let playoDataSet = {
      label: 'Playo',
      data: [],
      borderColor: [colorThree],
      backgroundColor: [colorThree],
      tension: 0.3,
      fill: false,
    };
    let sportidoDataSet = {
      label: 'Sportido',
      data: [],
      borderColor: [colorFour],
      backgroundColor: [colorFour],
      tension: 0.3,
      fill: false,
    };
    if (data && data.weeklyData) {
      data.weeklyData.forEach(x => {
        labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY'));
        playAllDataSet.data.push(x.source.playAllTransaction);
        hudleDataSet.data.push((x.source.hudleTransaction ));
        playoDataSet.data.push((x.source.playoTransaction ));
        sportidoDataSet.data.push((x.source.sportidoTransaction ));
      });
    }
    let lineData = {
      labels: labels,
      datasets: [
        playAllDataSet, hudleDataSet, playoDataSet, sportidoDataSet
      ]
    };
    return lineData;
  }

  const createLineItemDataForHudleSource = (data) => {
    let labels = [];
    let onlineDataSet = {
      label: 'Online',
      data: [],
      borderColor: [colorOne],
      backgroundColor: [colorOne],
      tension: 0.3,
      fill: false,
    };
    let passDataSet = {
      label: 'Pass',
      data: [],
      borderColor: [colorTwo],
      backgroundColor: [colorTwo],
      tension: 0.3,
      fill: false,
    };
    let playAllCreditDataSet = {
      label: 'Play All Credit',
      data: [],
      borderColor: [colorThree],
      backgroundColor: [colorThree],
      tension: 0.3,
      fill: false,
    };
    let hudleCreditDataSet = {
      label: 'Hudle Credit',
      data: [],
      borderColor: [colorFour],
      backgroundColor: [colorFour],
      tension: 0.3,
      fill: false,
    };
    if (data && data.weeklyData) {
      data.weeklyData.forEach(x => {
        labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY'));
        onlineDataSet.data.push(x.hudleType.hudleOnlineTransaction);
        passDataSet.data.push((x.hudleType.hudlePassTransaction ));
        playAllCreditDataSet.data.push((x.hudleType.hudleVenueCreditTrans ));
        hudleCreditDataSet.data.push((x.hudleType.hudleCreditTrans ));
      });
    }
    let lineData = {
      labels: labels,
      datasets: [
        onlineDataSet, passDataSet, playAllCreditDataSet, hudleCreditDataSet
      ]
    };
    return lineData;
  }

  const createLineItemExpenseData = (data) => {
    let labels = [];
    let totalExpensesDataSet = {
      label: 'Total Expenses',
      data: [],
      borderColor: [colorOne],
      backgroundColor: [colorOne],
      tension: 0.3,
      fill: false,
      hidden: true,
    };
    let bankDepositsDataSet = {
      label: 'Bank Deposits',
      data: [],
      borderColor: [colorTwo],
      backgroundColor: [colorTwo],
      tension: 0.3,
      fill: false,
      hidden: true,
    };
    let fuelExpensesDataSet = {
      label: 'Fuel Expense',
      data: [],
      borderColor: [colorThree],
      backgroundColor: [colorThree],
      tension: 0.3,
      fill: false,
    };
    let repairAndMaintenanceExpensesDataSet = {
      label: 'Repair & Maintenance',
      data: [],
      borderColor: [colorFour],
      backgroundColor: [colorFour],
      tension: 0.3,
      fill: false,
    };

    let housekeepingExpensesDataSet = {
      label: 'Housekeeping Expense',
      data: [],
      borderColor: [colorFive],
      backgroundColor: [colorFive],
      tension: 0.3,
      fill: false,
    };
    let drinkingWaterDataSet = {
      label: 'Drinking Water',
      data: [],
      borderColor: [colorOne],
      backgroundColor: [colorOne],
      tension: 0.3,
      fill: false,
    };
    let tournamentExpensesDataSet = {
      label: 'Tournament Expenses',
      data: [],
      borderColor: [colorTwo],
      backgroundColor: [colorTwo],
      tension: 0.3,
      fill: false,
    };
    let disposablesDataSet = {
      label: 'Disposables',
      data: [],
      borderColor: [colorThree],
      backgroundColor: [colorThree],
      tension: 0.3,
      fill: false,
    };
    let miscExpensesDataSet = {
      label: 'Misc Expenses',
      data: [],
      borderColor: [colorFour],
      backgroundColor: [colorFour],
      tension: 0.3,
      fill: false,
    };
    if (data && data.weeklyData) {
      data.weeklyData.forEach(x => {
        labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY'));
        totalExpensesDataSet.data.push(x.expenseType.totalExpenses*-1);
        bankDepositsDataSet.data.push(x.expenseType.bankDeposits*-1);
        fuelExpensesDataSet.data.push(x.expenseType.fuelExpenses*-1);
        repairAndMaintenanceExpensesDataSet.data.push(x.expenseType.repairAndMaintenanceExpenses*-1);
        housekeepingExpensesDataSet.data.push(x.expenseType.housekeepingExpenses*-1);
        drinkingWaterDataSet.data.push(x.expenseType.drinkingWater*-1);
        tournamentExpensesDataSet.data.push(x.expenseType.tournamentExpenses*-1);
        disposablesDataSet.data.push(x.expenseType.disposables*-1);
        miscExpensesDataSet.data.push(x.expenseType.miscExpenses*-1);
      });
    }
    let lineData = {
      labels: labels,
      datasets: [
        totalExpensesDataSet, 
        bankDepositsDataSet,fuelExpensesDataSet,repairAndMaintenanceExpensesDataSet,
        housekeepingExpensesDataSet,drinkingWaterDataSet,tournamentExpensesDataSet,
        disposablesDataSet,miscExpensesDataSet
      ]
    };
    return lineData;
  }

  const createLineItemDataForOtherReceipt = (data) => {
    let labels = [];
    let totalReceiptDataSet = {
      label: 'Total Receipt',
      data: [],
      borderColor: [colorOne],
      backgroundColor: [colorOne],
      tension: 0.3,
      fill: false,
    };
    let offlineCreditsDataSet = {
      label: 'Offline Credit',
      data: [],
      borderColor: [colorTwo],
      backgroundColor: [colorTwo],
      tension: 0.3,
      fill: false,
    };
    let playAllTournamentReceiptDataSet = {
      label: 'Tournament Receipt',
      data: [],
      borderColor: [colorThree],
      backgroundColor: [colorThree],
      tension: 0.3,
      fill: false,
    };
    let miscReceiptDataSet = {
      label: 'Misc Receipts',
      data: [],
      borderColor: [colorFour],
      backgroundColor: [colorFour],
      tension: 0.3,
      fill: false,
    };
    if (data && data.weeklyData) {
      data.weeklyData.forEach(x => {
        labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY'));
        totalReceiptDataSet.data.push(x.receiptType.totalReceipt);
        offlineCreditsDataSet.data.push(x.receiptType.offlineCredits);
        playAllTournamentReceiptDataSet.data.push(x.receiptType.playAllTournamentReceipt);
        miscReceiptDataSet.data.push(x.receiptType.miscReceipt);
      });
    }
    let lineData = {
      labels: labels,
      datasets: [
        totalReceiptDataSet, offlineCreditsDataSet, playAllTournamentReceiptDataSet, miscReceiptDataSet
      ]
    };
    return lineData;
  }
  
  const createLineItemModeData = (data) => {
    let labels = [];
    let onlineTransactionDataSet = {
      label: 'Online Transaction',
      data: [],
      borderColor: [colorOne],
      backgroundColor: [colorOne],
      tension: 0.3,
      fill: false,
      hidden: true,
    };
    let cashTransactionDataSet = {
      label: 'Cash',
      data: [],
      borderColor: [colorTwo],
      backgroundColor: [colorTwo],
      tension: 0.3,
      fill: false,
    };
    let paytmTransactionDataSet = {
      label: 'Paytm',
      data: [],
      borderColor: [colorThree],
      backgroundColor: [colorThree],
      tension: 0.3,
      fill: false,
    };
    let otherTransactionDataSet = {
      label: 'Others',
      data: [],
      borderColor: [colorFour],
      backgroundColor: [colorFour],
      tension: 0.3,
      fill: false,
    };

    let mixedTransactionDataSet = {
      label: 'Multi-Source',
      data: [],
      borderColor: [colorFive],
      backgroundColor: [colorFive],
      tension: 0.3,
      fill: false,
    };
    let cardTransactionDataSet = {
      label: 'Cards',
      data: [],
      borderColor: [colorOne],
      backgroundColor: [colorOne],
      tension: 0.3,
      fill: false,
    };
  
    if (data && data.weeklyData) {
      data.weeklyData.forEach(x => {
        labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY'));
        onlineTransactionDataSet.data.push(x.transactionMode.onlineTransaction);
        cashTransactionDataSet.data.push(x.transactionMode.cashTransaction);
        paytmTransactionDataSet.data.push(x.transactionMode.paytmTransaction);
        otherTransactionDataSet.data.push(x.transactionMode.otherTransaction);
        mixedTransactionDataSet.data.push(x.transactionMode.mixedTransaction);
        cardTransactionDataSet.data.push(x.transactionMode.cardTransaction);
      });
    }
    let lineData = {
      labels: labels,
      datasets: [
        onlineTransactionDataSet, cashTransactionDataSet,paytmTransactionDataSet,otherTransactionDataSet,
        mixedTransactionDataSet, cardTransactionDataSet
      ]
    };
    return lineData;
  }

  return isLoading ? <GenericLoader /> :
    (<div style={{ padding: "16px" }}>
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>
              Transaction Analytics </span>
          </div>
          <div style={{ alignItems: "center" }}>
            <div style={{ fontSize: "14px" }}>
              <Dropdown
                onChange={changeSport}
                placeholder='Select...'
                openOnFocus
                selection
                value={selectedSport && selectedSport.value}
                options={sports.current}
              />

            </div>
          </div>
        </div>
      </div>
      <br />
      <div>
        <Grid container spacing={9}>
          <Grid item md={3} spacing={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Last Week
                </Typography>
                <Typography variant="h5" component="h2">
                  <span style={{ color: "grey" }}>Net Receipts - </span>{formatter.format(analyticsData.weeklyData[analyticsData.weeklyData.length-1].totalReceipts + analyticsData.weeklyData[analyticsData.weeklyData.length-1].totalCancellationRefund)} /-
                  
                </Typography>
                <Pie
                  width={30}
                  height={8}
                  data={{
                    labels: [
                      'Bookings',
                      'Bulk-Booking',
                      'Academy',
                      'Product'
                    ],
                    datasets: [{
                      label: 'My First Dataset',
                      data: [
                      analyticsData.weeklyData[analyticsData.weeklyData.length-1].transactionType.bookingTransaction,
                      analyticsData.weeklyData[analyticsData.weeklyData.length-1].transactionType.bulkBookingTransaction + analyticsData.weeklyData[analyticsData.weeklyData.length-1].transactionType.groupMembershipTransaction +analyticsData.weeklyData[analyticsData.weeklyData.length-1].transactionType.individualMembershipTransaction ,
                      analyticsData.weeklyData[analyticsData.weeklyData.length-1].transactionType.academyTransaction,
                      analyticsData.weeklyData[analyticsData.weeklyData.length-1].transactionType.productRentalTransaction + analyticsData.weeklyData[analyticsData.weeklyData.length-1].transactionType.productSaleTransaction,
                    ],
                      backgroundColor: [
                        colorTwo,
                        colorThree,
                        colorFour,
                        colorFive
                      ],
                      hoverOffset: 4
                    }]
                  }}
                />
              </CardContent>
            </Card>

          </Grid>
          <Grid item md={3} spacing={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                 Second Last Week
                </Typography>
                <Typography variant="h5" component="h2">
                  <span style={{ color: "grey" }}>Net Receipts - </span>{formatter.format(analyticsData.weeklyData[analyticsData.weeklyData.length-2].totalReceipts + analyticsData.weeklyData[analyticsData.weeklyData.length-2].totalCancellationRefund)} /-
                  
                </Typography>
                <Pie
                  width={30}
                  height={8}
                  data={{
                    labels: [
                      'Bookings',
                      'Bulk-Booking',
                      'Academy',
                      'Product'
                    ],
                    datasets: [{
                      label: 'My First Dataset',
                      data: [
                      analyticsData.weeklyData[analyticsData.weeklyData.length-2].transactionType.bookingTransaction,
                      analyticsData.weeklyData[analyticsData.weeklyData.length-2].transactionType.bulkBookingTransaction + analyticsData.weeklyData[analyticsData.weeklyData.length-2].transactionType.groupMembershipTransaction +analyticsData.weeklyData[analyticsData.weeklyData.length-2].transactionType.individualMembershipTransaction ,
                      analyticsData.weeklyData[analyticsData.weeklyData.length-2].transactionType.academyTransaction,
                      analyticsData.weeklyData[analyticsData.weeklyData.length-2].transactionType.productRentalTransaction + analyticsData.weeklyData[analyticsData.weeklyData.length-2].transactionType.productSaleTransaction,
                    ],
                      backgroundColor: [
                        colorTwo,
                        colorThree,
                        colorFour,
                        colorFive
                      ],
                      hoverOffset: 4
                    }]
                  }}
                />
              </CardContent>
            </Card>

          </Grid>
          <Grid item md={3} spacing={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Third Last Week
                </Typography>
                <Typography variant="h5" component="h2">
                  <span style={{ color: "grey" }}>Net Receipts - </span>{formatter.format(analyticsData.weeklyData[analyticsData.weeklyData.length-3].totalReceipts + analyticsData.weeklyData[analyticsData.weeklyData.length-3].totalCancellationRefund)} /-
                  
                </Typography>
                <Pie
                  width={30}
                  height={8}
                  data={{
                    labels: [
                      'Bookings',
                      'Bulk-Booking',
                      'Academy',
                      'Product'
                    ],
                    datasets: [{
                      label: 'My First Dataset',
                      data: [
                      analyticsData.weeklyData[analyticsData.weeklyData.length-3].transactionType.bookingTransaction,
                      analyticsData.weeklyData[analyticsData.weeklyData.length-3].transactionType.bulkBookingTransaction + analyticsData.weeklyData[analyticsData.weeklyData.length-3].transactionType.groupMembershipTransaction +analyticsData.weeklyData[analyticsData.weeklyData.length-3].transactionType.individualMembershipTransaction ,
                      analyticsData.weeklyData[analyticsData.weeklyData.length-3].transactionType.academyTransaction,
                      analyticsData.weeklyData[analyticsData.weeklyData.length-3].transactionType.productRentalTransaction + analyticsData.weeklyData[analyticsData.weeklyData.length-3].transactionType.productSaleTransaction,
                    ],
                      backgroundColor: [
                        colorTwo,
                        colorThree,
                        colorFour,
                        colorFive
                      ],
                      hoverOffset: 4
                    }]
                  }}
                />
              </CardContent>
            </Card>

          </Grid>
        </Grid>

        <h5>Last Year Data</h5>
        <h3>Transaction Type</h3>
        <Line
          data={typeGraphData}
          width={100}
          height={20}
          pointRadius={1}
          showLine={false}

          options={{
            // maintainAspectRatio: false,
            scales: {
              xAxes: [{
                gridLines: {
                  show: true
                }
              }],
              yAxes: [{
                gridLines: {
                  show: false
                }
              }]
            }
          }}
        />

<h3>Booking Source</h3>
        <Line
          data={sourceGraphData}
          width={100}
          height={20}
          pointRadius={1}
          showLine={false}

          options={{
            // maintainAspectRatio: false,
            scales: {
              xAxes: [{
                gridLines: {
                  show: true
                }
              }],
              yAxes: [{
                gridLines: {
                  show: false
                }
              }]
            }
          }}
        />

<h3>Hudle Sub Source</h3>
        <Line
          data={hudleGraphData}
          width={100}
          height={20}
          pointRadius={1}
          showLine={false}

          options={{
            // maintainAspectRatio: false,
            scales: {
              xAxes: [{
                gridLines: {
                  show: true
                }
              }],
              yAxes: [{
                gridLines: {
                  show: false
                }
              }]
            }
          }}
        />

<h3>Expense Transactions</h3>
        <Line
          data={expenseGraphData}
          width={100}
          height={20}
          pointRadius={1}
          showLine={false}

          options={{
            // maintainAspectRatio: false,
            scales: {
              xAxes: [{
                gridLines: {
                  show: true
                }
              }],
              yAxes: [{
                gridLines: {
                  show: false
                }
              }]
            }
          }}
        />

<h3>Other Receipts Transactions</h3>
        <Line
          data={otherReceiptGraphData}
          width={100}
          height={20}
          pointRadius={1}
          showLine={false}

          options={{
            // maintainAspectRatio: false,
            scales: {
              xAxes: [{
                gridLines: {
                  show: true
                }
              }],
              yAxes: [{
                gridLines: {
                  show: false
                }
              }]
            }
          }}
        />

<h3>Transaction Mode</h3>
        <Line
          data={modeGraphData}
          width={100}
          height={20}
          pointRadius={1}
          showLine={false}

          options={{
            // maintainAspectRatio: false,
            scales: {
              xAxes: [{
                gridLines: {
                  show: true
                }
              }],
              yAxes: [{
                gridLines: {
                  show: false
                }
              }]
            }
          }}
        />

      </div>
    </div>
    );
}

export default TransactionAnalytics;