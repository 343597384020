import React, { useState, useContext } from "react";
import { Segment, Accordion, Icon } from "semantic-ui-react";
import { AppContext } from "../../AppContext";
import { getKeyValue } from "../../utils/helperFunctions";

const BookinInfo = ({ booking, facility, openAccordion }) => {
  const [showContent, setShowContent] = useState(openAccordion);
  const context = useContext(AppContext);
  const timings = getKeyValue(context, "selectedSportDetails.detail.timings");
  const currentSlotTime = timings
    ? timings.find((t) => t.timeId === booking.slotTiming)
    : undefined;
  return (
    <Accordion fluid>
      <Accordion.Title
        active={showContent}
        onClick={() => setShowContent(!showContent)}
      >
        <div style={{ fontWeight: "bold" }}>
          <Icon name='dropdown' />
          Booking Information
        </div>
      </Accordion.Title>
      <Accordion.Content active={showContent}>
        <Segment.Group>
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Location:</div>
            <div>{facility.arenaName}</div>
          </div>
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Court:</div>
            <div>{booking.courtName}</div>
          </div>
          {currentSlotTime ? (
            <div className={"info-cell"}>
              <div className={"pr10-bold"}>Slot timings:</div>
              <div>
                {currentSlotTime.interval?.start +
                  " - " +
                  currentSlotTime.interval?.end}
              </div>
            </div>
          ) : null}
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Booking Amount:</div>
            <div>Rs. {booking.bookingPrice}/-</div>
          </div>
        </Segment.Group>
      </Accordion.Content>
    </Accordion>
  );
};

export default BookinInfo;
