import React, { useEffect, useContext, useState, useRef } from "react";
import { AppContext } from "../../../AppContext";
import GenericLoader from "../../generic/GenericLoader";
import { Button, Icon, Table, Dropdown, Tab, } from "semantic-ui-react";
import "../../dashboard/style.scss";
import { colors } from "@material-ui/core";
import moment from "moment";
import { getAllAcademySportsOfArena } from "../../../apiclients/AcademyClient";
import EnrollStudent from "./EnrollStudent";
import { getStudentData, deactiveStudent } from "../../../apiclients/AcademyClient";
import DeleteStudent from "./DeleteStudent";
import Toast from "../../generic/Toast";
import StudentDetail from "./StudentDetails";

const StudentDataTable = ({
    listOfStudents,
    setReload,
    setMessage,
    isLoading,
    sport,
    history,
    type,
}) => {
    const context = useContext(AppContext);
    const [deleteModal, setDeleteModal] = useState(false); // to show delete modal
    const [showDetails, setShowDetails] = useState(false); // to show student detail modal
    const [selectedStudent, setSelectedStudent] = useState();
    const deleteStudent = (x) => {
        setSelectedStudent(
            {
                name: x.studentName,
                id: x.studentId
            }
        );
        setDeleteModal(true);
    };


    const openStudentDetail = (data) => () => {
        setShowDetails(data);
    };

    return (
        <>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>S.No</Table.HeaderCell>
                        <Table.HeaderCell>Student Name</Table.HeaderCell>
                        <Table.HeaderCell>Mobile</Table.HeaderCell>
                        <Table.HeaderCell>Parent's Name</Table.HeaderCell>
                        <Table.HeaderCell>Batch Name</Table.HeaderCell>
                        <Table.HeaderCell>End Date</Table.HeaderCell>
                        <Table.HeaderCell>Days Remaining</Table.HeaderCell>
                        <Table.HeaderCell>Available Classes</Table.HeaderCell>
                        <Table.HeaderCell>Amount Due</Table.HeaderCell>
                        <Table.HeaderCell>Detail</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {listOfStudents && listOfStudents.map((y, i) => {
                        var x = y.data;
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{x.studentName}</Table.Cell>
                                <Table.Cell>{x.mobile}</Table.Cell>
                                <Table.Cell>{x.parentName}</Table.Cell>
                                <Table.Cell>{x.batchName}</Table.Cell>
                                <Table.Cell>{new Date(x.endDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
                                <Table.Cell>{
                                    x.daysRemaining < 0 ? <span style={{ color: 'red' }}>{x.daysRemaining}</span> : x.daysRemaining
                                }</Table.Cell>
                                <Table.Cell>{
                                    x.availableNoOfClasses < 0 ? <span style={{ color: 'red' }}>{x.availableNoOfClasses}</span> : x.availableNoOfClasses
                                }</Table.Cell>
                                <Table.Cell>
                                    {x.amountDue > 0 ?
                                        <div><span style={{ color: colors.red[500] }}> Rs. {parseFloat(x.amountDue).toFixed(2)}/-</span></div>
                                        :
                                        <div> Rs. {parseFloat(x.amountDue).toFixed(2)}/-</div>}

                                </Table.Cell>

                                <Table.Cell >
                                    <div
                                        style={{
                                            textDecoration: "underline",
                                            color: colors.blue[500],
                                        }}
                                        onClick={openStudentDetail(x)}
                                    >
                                        {y.id}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>

            {deleteModal ? (
                <DeleteStudent
                    history={history}
                    showModal={setDeleteModal}
                    setReload={setReload}
                    studentData={selectedStudent}
                    setMessage={setMessage}
                    isLoading={isLoading}
                    selectedSport={sport}
                />
            ) : null}

            {!!showDetails ? (
                <StudentDetail
                    selectedData={showDetails}
                    setShowDetails={setShowDetails}
                    setReload={setReload}
                    history={history}
                    setMessage={setMessage}
                    isLoading={isLoading}
                    selectedSport={sport}
                    type={type}
                />
            ) : null}
        </>
    );
};

const StudentManagement = (props) => {

    const { history } = props;
    const context = useContext(AppContext);
    const sports = useRef([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [allAcademySports, setAllAcademySports] = useState([]);
    const [listOfStudents, setListOfStudents] = useState([]);
    const [selectedSport, setSelectedSport] = useState({});
    const [addModal, setAddModal] = useState(false); // to show add modal
    const [reload, setReload] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});
    const types = ['active', 'inactive', 'old', 'deleted'];
    useEffect(() => {
        if (reload) {
            loadData(selectedSport).then(() => {
                setReload(false);
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 2000);
            });
        }
    }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (context.selectedFacility && context.selectedArena) {
            getAllAcademySportsOfArena(context.selectedFacility, context.selectedArena.arenaId).then(
                (response) => {
                    if (response.status === 200) {
                        const sport = response.data;
                        const dropdownSportsData = sport.map(
                            (x, index) => ({
                                key: x.sportId,
                                value: x.sportId,
                                text: x.sportName,
                            })
                        );
                        setAllAcademySports(dropdownSportsData);
                        sports.current = dropdownSportsData;
                        setSelectedSport(dropdownSportsData[0]);
                        loadData(dropdownSportsData[0]);
                    } else {
                        history.push("/extranet/dashboard");
                    }

                }
            ).catch((err) => {
                history.push("/extranet/dashboard");
            });

        }
    }, [context.selectedFacility, context.selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

    const loadData = async (sportData) => {
        setisLoading(true);
        return await getStudentData(
            context.selectedFacility,
            context.selectedArena.arenaId,
            sportData.value,
            types[activeIndex],
        ).then((response) => {
            const { result } = response.data;
            setListOfStudents(result);
        }).then(() => {
            setisLoading(false);
        });
    };

    const changeSport = (event, { value }) => {
        const data = sports.current.find((facility) => facility.value === value);
        setSelectedSport(data);
        loadData(data);
    };

    const enrollAStudent = () => {
        setAddModal(true);
    }
    useEffect(() => {
        loadData(selectedSport);
    }, [activeIndex]);



    const panes = [
        {
            menuItem: "Active Students",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <StudentDataTable
                        listOfStudents={listOfStudents}
                        setReload={setReload}
                        sport={selectedSport}
                        history={history}
                        type={types[activeIndex]}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Inactive Students(Last 30 Days) ",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <StudentDataTable
                        listOfStudents={listOfStudents}
                        sport={selectedSport}
                        setReload={setReload}
                        history={history}
                        type={types[activeIndex]}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Old Students ",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <StudentDataTable
                        listOfStudents={listOfStudents}
                        sport={selectedSport}
                        setReload={setReload}
                        history={history}
                        type={types[activeIndex]}

                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Deleted Students ",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <StudentDataTable
                        listOfStudents={listOfStudents}
                        sport={selectedSport}
                        setReload={setReload}
                        history={history}
                        type={types[activeIndex]}

                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                    />
                </Tab.Pane>
            ),
        },

    ];

    return (
        <div style={{ padding: "16px" }}>
            <Toast
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                message={messageContent.message}
                messageHeader={messageContent.header}
                color={messageContent.color}
            />
            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Academy Students </span>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>
                         
                            <Dropdown
                                onChange={changeSport}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={selectedSport && selectedSport.value}
                                options={sports.current}
                            />
                            <Button
                                style={{ marginLeft: "20px" }}
                                onClick={() => enrollAStudent()}
                                name={"Add Package"}
                            >
                                <Icon name={"add"} /> Enroll Student
                            </Button>

                        </div>
                    </div>
                </div>


            </div>
            <br />
            {isLoading ? (
                <GenericLoader />
            ) : (
                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    renderActiveOnly={true}
                    menu={{
                        color: "orange",
                        pointing: true,
                    }}
                    panes={panes}
                />
            )}

            <br />
            How to enroll, renew, add payment, edit or delete Student Data ?
            <div className="player">
                <iframe width="300" height="150" src="https://www.youtube.com/embed/zrqcFgti7sg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
            </div>
            {addModal ? (
                <EnrollStudent
                    history={history}
                    showModal={setAddModal}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                    selectedSport={selectedSport}
                    isRenewal={false}
                    renewalDetails={null}
                />
            ) : null}
        </div>
    );
}

export default StudentManagement;