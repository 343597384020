import React, { useEffect, useContext, useState, useReducer } from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";

import "../dashboard/style.scss";
import { AppContext } from "../../AppContext";
import {  loadBDTransactions } from "../../apiclients/ReportsApiClient";
import {
  convertFirstoreDate,
  convertFirstoreDateForExcel,
} from "../../utils/helperFunctions";
import CustomModal from "../generic/CustomModal";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import {
  filterReducer,
  FILTERS_ACTION_TYPE,
  
} from "./bookingReportReducer";
import GenericLoader from "../generic/GenericLoader";
import ExportToExcel from "../generic/ExportToExcel";
import {  ROLES } from "../../utils/constants";
import moment from "moment";
import Toast from "../generic/Toast";
const initialFilterState = {
  startDate:  moment().subtract(7, 'days'),
  endDate: moment(),
  
};




const FilterFooter = ({ setShowFilter, onApply }) => {
  return (
    <Modal.Actions>
      <Button className={"ui button booked-footer"} onClick={onApply}>
        Apply
      </Button>
      <Button onClick={() => setShowFilter(false)}>Cancel</Button>
    </Modal.Actions>
  );
};

const FilterContent = ({ onApply, setShowFilter, selectedFilters, role }) => {
  const initialFilterState = {
    startDate:  moment().subtract(7, 'days'),
    endDate: moment(),
    
  };
  const [filters, dispatch] = useReducer(filterReducer, {
    ...initialFilterState,
    ...selectedFilters,
  });
  const { startDate, endDate,  } = filters;

  const onReset = () => {
    dispatch({ type: FILTERS_ACTION_TYPE.RESET });
  };
  const onFilterApplyClicked = () => {
    onApply(filters);
  };
  


  return (
    <>
      <Modal.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Filters</span>
          <span
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              color: colors.orange[500],
            }}
            onClick={onReset}
          >
            Clear all
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Enquiry Date
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Start Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={startDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_START_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}

              minDate={!(role === ROLES.ADMIN) ? 
                role === ROLES.HR ? moment().subtract(30, 'days')
                :moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
            <div style={{ fontWeight: "bold" }}>End Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={endDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_END_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}


              minDate={!(role === ROLES.ADMIN) ? 
                role === ROLES.HR ? moment().subtract(30, 'days')
                :moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
          </div>
          
        </div>
      </Modal.Content>
      <FilterFooter
        onApply={onFilterApplyClicked}
        setShowFilter={setShowFilter}
      />
    </>
  );
};

const BookingFilters = ({
  setShowFilter,
  showFilter,
  selectedFilters,
  onApply,
  role
}) => {
  return (
    <CustomModal
      comp={true}
      content={
        <FilterContent
          onApply={onApply}
          selectedFilters={selectedFilters}
          setShowFilter={setShowFilter}
          role={role}
        />
      }
      setOpen={setShowFilter}
      open={showFilter}
    />
  );
};
const BDReport = () => {
  const { selectedFacility, selectedArena, user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [showFilter, setShowFilter] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});
  const [totalCalls, setTotalCalls] = useState(0);
  const [missedCalls, setMissedCalls] = useState(0);
  const [openLeads, setOpenLeads] = useState(0);

  useEffect(() => {
    if (!loadData) {
      setLoadData(true);
    }
  }, [selectedArena]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadData) {
      const params = {
        startDate: selectedFilters.startDate,
        endDate: selectedFilters.endDate,
      };
    

      loadBDTransactions(
        selectedFacility,
        params
      ).then((res) => {
         setData(res.data.data);
        console.log("res.data.data",res.data.data)
        // let summary = { totalCalls: 0, missedCalls: 0, openLeads: 0 };
        // for (var i = 0; i < res.data.data.length; i++) {
        //   const leadData = res.data.data[i];
        //   summary.totalCalls = summary.totalCalls + 1;
        //   if(leadData.callType !== "completed"){
        //     summary.missedCalls = summary.missedCalls + 1;
        //   }
        //   if(leadData.leadOpen === true){
        //     summary.openLeads = summary.openLeads + 1;
        //   }
        // }
       
        // setTotalCalls(summary.totalCalls);
        // setMissedCalls(summary.missedCalls);
        // setOpenLeads(summary.openLeads);
         setLoadData(false);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [loadData]); // eslint-disable-line react-hooks/exhaustive-deps

  const createRemarksText = (data) => {
    let remarkText = "";
    for (var i=0; i<data.remarks.length;i++){
      remarkText = "Remark "+(i+1) +" - " + new Date(data.createdAt.seconds * 1000).toLocaleDateString() 
      + " : "+  new Date(data.createdAt.seconds * 1000).toLocaleTimeString() + " - " + data.remarks[i].remarks;
      if(i !== data.remarks.length-1){
        remarkText = remarkText + " | ";
      }
    }
    return remarkText;
  }
 
  let tableHeader = [
      {
        label: "Date",
        value: (col) => new Date(col.createdDate.seconds * 1000).toLocaleDateString(),
      },
      {
        label: "Time",
        value: (col) => new Date(col.createdDate.seconds * 1000).toLocaleTimeString(),
      },

      {
        label: "Name",
        value: (col) => col.name,
      },

      {
        label: "Email",
        value: (col) => col.email &&  col.email ,
      },

      
      {
        label: "Mobile",
        value: (col) => col.mobile &&  col.mobile ,
      },
      {
        label: "City",
        value: (col) => col.city &&  col.city ,
      },
      {
        label: "Location",
        value: (col) => col.location &&  col.location ,
      },
      {
        label: "School Name",
        value: (col) => col.schoolName  ,
      },
      {
        label: "Designation",
        value: (col) => col.designation ,
      },
      {
        label: "Remarks",
        value: (col) => col.remarks,
      },
     
    ];
  


  const onApply = (filters) => {
    setLoadData(true);
    setSelectedFilters(filters);
    setShowFilter(false);
  };

  const openFilter = () => {
    setShowFilter(true);
  };

 

  const startDate = convertFirstoreDate(
    selectedFilters.startDate,
    "DD MMM YYYY"
  );
  const endDate = convertFirstoreDate(selectedFilters.endDate, "DD MMM YYYY");
  const fileName = 'Enquiries ' + startDate + " - " + endDate;
  const sheetName = moment().format('MMMM Do YYYY, h_mm a');

  const sheets = [
    {
      name: sheetName,
      columns: tableHeader,
      dataSet: data,
    },
  ];


  return (
    <div style={{ padding: "16px" }}>
      <Toast
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        message={messageContent.message}
        messageHeader={messageContent.header}
        color={messageContent.color}
      />
      <div style={{ paddingBottom: "16px" }}>
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span >BD Report </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingRight: "10px",
                }}
              >
                ( Range: {startDate}- {endDate})
              </span>
            </div>
            <div style={{ alignItems: "center" }}>
              <span><span>
                <ExportToExcel disabled={data.length === 0 || !(user.role === ROLES.ADMIN || user.role === ROLES.HR|| user.role === ROLES.REGIONAL_MANAGER)} sheets={sheets}
                  fileName={fileName}
                  buttonName="Download Report" />
              </span>
                <Button onClick={openFilter} name={"filter"}>
                  <Icon
                    color={selectedFilters.isFilterApplied ? "orange" : "grey"}
                    name={"filter"}
                  />
                  Filter
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>

      {showFilter ? (
        <BookingFilters
          onApply={onApply}
          selectedFilters={selectedFilters}
          setLoadData={setLoadData}
          setShowFilter={setShowFilter}
          showFilter
          role={user.role}
        />
      ) : null}
     
      {loadData ? (
        <GenericLoader />
      ) : data.length === 0 ? (
        "No data available"
      ) : (
        <div>
          
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Time</Table.HeaderCell>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Mobile</Table.HeaderCell>
                <Table.HeaderCell>City</Table.HeaderCell>
                <Table.HeaderCell>Location</Table.HeaderCell>
                <Table.HeaderCell>School Name</Table.HeaderCell>
                <Table.HeaderCell>Designation</Table.HeaderCell>
                <Table.HeaderCell>Remarks</Table.HeaderCell>
             
                 
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((x) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <div>
                        {new Date(x.createdDate.seconds * 1000).toLocaleDateString()}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        {new Date(x.createdDate.seconds * 1000).toLocaleTimeString()}
                      </div>
                    </Table.Cell>
                    <Table.Cell>

                    {x.name}
                     </Table.Cell>
                    <Table.Cell>
                      {x.email}
                    </Table.Cell>
                    
                    <Table.Cell>
                      {x.mobile }
                    </Table.Cell>
                    
                    <Table.Cell>
                      {x.city  }
                    </Table.Cell>
                    <Table.Cell>
                      {x.location && x.location}
                    </Table.Cell>

                    <Table.Cell>
                      {x.schoolName && x.schoolName}
                    </Table.Cell>
                    <Table.Cell>
                      {x.designation }
                    </Table.Cell>
                    <Table.Cell>
                      {x.remarks && x.remarks}
                    </Table.Cell>
                 
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};

export default BDReport;
