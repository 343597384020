import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams} from "./url";

export const getAllExpenses = (facilityId, arenaId, startDate, endDate) => {
    const url = getURLWithQueryParams(API_URLS.GET_EXPENSES_DATA, undefined, {
        facilityId, arenaId
    });
    return tokenAxiosInstance.get(url, {
        params : {
           startDate : startDate.format("DD-MM-YYYY"), 
           endDate : endDate.format("DD-MM-YYYY")
        }
    });
};

export const addExpenses = (postBody) => {
    const url = getURLWithQueryParams(API_URLS.ADD_EXPENSES);
      return tokenAxiosInstance.post(url, postBody);
};

export const updateGST = (postBody) => {
    const url = getURLWithQueryParams(API_URLS.UPDATE_GST);
      return tokenAxiosInstance.post(url, postBody);
};

export const updateBookingCommission = (postBody) => {
    const url = getURLWithQueryParams(API_URLS.UPDATE_COMMISSION);
      return tokenAxiosInstance.post(url, postBody);
};
export const deleteExpenseOrReceipt = (postBody) => {
    const url = getURLWithQueryParams(API_URLS.DELETE_EXPENSE_OR_RECEIPT);
      return tokenAxiosInstance.delete(url, {data : postBody});
};

export const getAllReceipt = (facilityId, arenaId, startDate, endDate) => {
    const url = getURLWithQueryParams(API_URLS.GET_RECEIPT_DATA, null, {
        facilityId, arenaId
    });
    return tokenAxiosInstance.get(url,{
        params : {
            startDate : startDate.format("DD-MM-YYYY"), 
            endDate : endDate.format("DD-MM-YYYY")
         }
    });
};

export const addReceipt = (postBody) => {
    const url = getURLWithQueryParams(API_URLS.ADD_RECEIPT);
      return tokenAxiosInstance.post(url, postBody);
};


export const getCashBook = (facilityId, arenaId, date) => {
    const url = getURLWithQueryParams(API_URLS.GET_CASH_BOOK_DATA, null, {
        facilityId, arenaId, date : date.format("DD-MM-YYYY")
    });
    return tokenAxiosInstance.get(url);
};

export const getNonCashBook = (facilityId, arenaId, date, type) => {
    const url = getURLWithQueryParams(API_URLS.GET_NON_CASH_BOOK_DATA, null, {
        facilityId, arenaId, date : date.format("DD-MM-YYYY"), type
    });
    return tokenAxiosInstance.get(url);
};