import React, { useContext, useState, useEffect } from 'react';
import { colors } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import {
    Button,
    Dropdown,
    Form,
    Checkbox,
    Divider,
    Grid
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { enrollStudent, getDataForExistingParent } from "../../../apiclients/AcademyClient";
import { getBatches } from "../../../apiclients/AcademyClient";
import { DAYS_OF_WEEK } from '../../../utils/constants';
import { getNumberOfDays } from '../../../utils/helperFunctions';
import Payment from "../../dashboard/Payment";
import DatesSelection from '../../common/DatesSelection';

const EnrollStudent = (props) => {
    const { showModal, setReload, setMessage, isLoading, selectedSport, isRenewal, renewalDetails, leadDetails, trialId } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const arenaData = context.arenaData[arenaId];
    const sportId = selectedSport.key;

    console.log('the arena data is ', arenaData);
    const [mobile, setMobile] = useState();
    const [parentName, setParentName] = useState();
    const [studentName, setStudentName] = useState();
    const [studentFirstName, setStudentFirstName] = useState();
    const [studentLastName, setStudentLastName] = useState();
    const [email, setEmail] = useState();
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [batchName, setBatchName] = useState();
    const [selectedBatch, setSelectedBatch] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [fees, setFees] = useState();
    const [days, setDays] = useState([]);
    const [remarks, setRemarks] = useState();
    const [listOfBatches, setListOfBatches] = useState([]);
    const [numberOfClasses, setNumberOfClasses] = useState();
    const [showNumberOfClasses, setShowNumberOfClasses] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [disableDates, setDisableDates] = useState(false);
    const [discount, setDiscount] = useState(0);
    const [totalAmount, setTotalAmount] = useState();
    const [netAmount, setNetAmount] = useState();
    const [paymentArray, setPaymentArray] = useState([]);
    const [totalPayment, setTotalPayment] = useState();
    const [newParent, setNewParent] = useState(true);
    const [showMultipleStudents, setShowMultipleStudents] = useState(false);
    const [listOfStudents, setListOfStudents] = useState([]);
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [disableStudentDetails, setDisableStudentDetails] = useState(false);
    const [ddstudentName, setDdStudentName] = useState();
    const [registrationFees, setRegistrationFees] = useState(false);
    const [disableParentNameAndEmail, setDisableParentNameAndEmail] = useState(false);

    const isSubmitAllowed = mobile && parentName && studentFirstName && studentLastName
        && email && dateOfBirth != null &&
        startDate != null && endDate != null && days.length > 0 && fees
        && batchName != null && discount != null && netAmount != null && totalPayment != null && totalPayment <= netAmount;
    const showBatchesOptions = mobile && parentName && studentFirstName && studentLastName
        && email && dateOfBirth != null;

    useEffect(() => {
        if (isRenewal) {
            setMobile(renewalDetails.mobile);
            setParentName(renewalDetails.parentName);
            setStudentName(renewalDetails.studentName);
            setStudentFirstName(renewalDetails.studentName.split(' ')[0]);
            setStudentLastName(renewalDetails.studentName.split(' ')[1]);
            setEmail(renewalDetails.email);
            setDateOfBirth(new Date(renewalDetails.dateOfBirth.seconds * 1000));
            setDisableParentNameAndEmail(true);
            setDisableStudentDetails(true);
        }
        if (leadDetails) {
            setMobile(leadDetails.mobile);
            setParentName(leadDetails.parentName);
            setStudentName(leadDetails.studentName);
            setStudentFirstName(leadDetails.studentName.split(' ')[0]);
            setStudentLastName(leadDetails.studentName.split(' ')[1]);
            setEmail(leadDetails.email);
            setDateOfBirth(new Date(leadDetails.dateOfBirth.seconds * 1000));
            setDisableParentNameAndEmail(true);
            setDisableStudentDetails(true);
        }
        getBatches(
            facilityId,
            arenaId,
            sportId,
        ).then((response) => {
            let data = response.data;
            data = data.map((x, i) => {
                return {
                    key: i,
                    value: x.createdAt,
                    text: x.batchName,
                    fees: x.fees,
                    numberOfClasses: x.numberOfDays,
                    registrationFees: x.registrationFees,
                }
            });
            setListOfBatches(data);
        })
    }, []);


    const changeBatch = (event, { value, options }) => {
        const batchName = options.filter(x => x.value.seconds === value.seconds)[0].text;
        const numberOfClass = Number(options.filter(x => x.value.seconds === value.seconds)[0].numberOfClasses);
        const registrationFees = Number(options.filter(x => x.value.seconds === value.seconds)[0].registrationFees);
        let fees = Number(options.filter(x => x.value.seconds === value.seconds)[0].fees);
        if (startDate && endDate && days.length > 0) {
            const daysNumber = getNumberOfDays(startDate, endDate, days);
            fees = Math.round((fees * daysNumber) / numberOfClass);
            setShowErrorMessage(false);
            setShowNumberOfClasses(true);
            setNumberOfClasses(daysNumber);
            setBatchName(batchName);
            setSelectedBatch(value);
            setFees(fees);
            setDisableDates(true);
            if (!isRenewal) {
                setRegistrationFees(registrationFees);
                setNetAmount(fees + registrationFees);
                setTotalAmount(fees + registrationFees);
            } else {
                setRegistrationFees(0);
                setNetAmount(fees);
                setTotalAmount(fees);
            }
        } else {
            setShowErrorMessage(true);
            setBatchName('');
        }

    };
   
    const addDiscount = (value) => {
        setDiscount(value);
        let total = Math.round((fees + registrationFees) * (100 - Number(value)) / 100);
        setNetAmount(total);
    }

    const updatePaymentArray = (updatedArray) => {
        const amountPaid = updatedArray.reduce((a, b) => a + Number(b.amount), 0);
        setTotalPayment(amountPaid);
        setPaymentArray(updatedArray);

    };

    const updateAllDays = (checked) => {
        if (checked) {
            setDays(DAYS_OF_WEEK);
        } else {
            setDays([]);
        }
    };

    const updateDays = (selectedDay) => {
        if (days.some(o => o.key === selectedDay.key)) {
            setDays(days.filter(o => o.key !== selectedDay.key));
        } else {
            setDays([...days, selectedDay]);
        }

    };

    const changeDate = (isStart) => value => {
        if (isStart) {
            setStartDate(value);
        } else {
            setEndDate(value);
        }
    }

    const fetchParentData = (mobiles) => {
        if (mobiles && mobiles.length === 10) {

            setMobile(mobiles);
            getDataForExistingParent(facilityId, arenaId, sportId, mobiles)
                .then((response) => {
                    console.log('the response is ', response.data);
                    let data = response.data;
                    data.push({ key: data.length, value: 'Others', text: 'Others' })
                    if (response.data.length > 1) {
                        console.log('the response is ', response.data);
                        setListOfStudents(data);
                        setNewParent(false);
                        setShowMultipleStudents(true);
                    }
                });
        }
    }

    const changeStudent = (event, { value, options }) => {
        if (value === 'Others') {
            setIsOtherSelected(true);
            const parentName = options[0].parentName;
            const email = options[0].email;
            setDdStudentName(value);
            setStudentName(null);
            setStudentFirstName(null);
            setStudentLastName(null);
            setParentName(parentName);
            setEmail(email)
            setDateOfBirth(null);
            setDisableParentNameAndEmail(true);
            setDisableStudentDetails(false);
        } else {
            const studentName = options.filter(x => x.value === value)[0].text;
            const email = options.filter(x => x.value === value)[0].email;
            const parentName = options.filter(x => x.value === value)[0].parentName;
            const dob = new Date(options.filter(x => x.value === value)[0].dateOfBirth.seconds * 1000);
            setDdStudentName(value);
            setStudentName(studentName);
            setStudentFirstName(studentName.split(' ')[0]);
            setStudentLastName(studentName.split(' ')[studentName.split(' ').length-1]);
            setEmail(email);
            setParentName(parentName);
            setDateOfBirth(dob);
            setIsOtherSelected(false);
            setDisableParentNameAndEmail(true);
            setDisableStudentDetails(true);
        }

    };
    const modalContent = (
        <div>
            <Form>
               
               <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Mobile Number</label>
                        <input
                            style={{ marginRight: "6px" }}
                            disabled={disableStudentDetails}
                            type='number'
                            value={mobile}
                            pattern='[0-9]{10}$*'
                            placeholder='9911XXXXXX'
                            // onFocus={}
                            onChange={(evt) => fetchParentData(evt.target.value)}

                        />
                    </Form.Field>
                    {showMultipleStudents ?
                        <Form.Field  >

                            <label>Select Students</label>
                            <Dropdown
                                onChange={changeStudent}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={ddstudentName}
                                options={listOfStudents}
                                style={{ marginRight: "30px" }}
                            />
                        </Form.Field>
                        :
                        <>
                        <Form.Field  >
                            <label>Student First Name</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='text'
                                value={studentFirstName}
                                disabled={disableStudentDetails}
                                placeholder='Shaurya'
                                onChange={(evt) => setStudentFirstName(evt.target.value)}

                            />
                        </Form.Field> 
                        <Form.Field  >
                            <label>Student Last Name</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='text'
                                value={studentLastName}
                                placeholder='Jain'
                                onChange={(evt) => setStudentLastName(evt.target.value)}

                            />
                        </Form.Field>
                        </>

                    }
                    {isOtherSelected ?  <>
                        <Form.Field  >
                            <label>Student First Name</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='text'
                                value={studentFirstName}
                                disabled={disableStudentDetails}
                                placeholder='Shaurya'
                                onChange={(evt) => setStudentFirstName(evt.target.value)}

                            />
                        </Form.Field> 
                        <Form.Field  >
                            <label>Student Last Name</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='text'
                                value={studentLastName}
                                disabled={disableStudentDetails}
                                placeholder='Jain'
                                onChange={(evt) => setStudentLastName(evt.target.value)}

                            />
                        </Form.Field>
                        </>: null}


                </Form.Group> 
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Parent Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={parentName}
                            placeholder='Vishal Jain'
                            onChange={(evt) => setParentName(evt.target.value)}
                            disabled={disableParentNameAndEmail}

                        />
                    </Form.Field>

                    <Form.Field  >
                        <label>Email</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='email'
                            value={email}
                            placeholder='shaurya@gmail.com'
                            onChange={(evt) => setEmail(evt.target.value)}

                            disabled={disableParentNameAndEmail}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Date of Birth</label>
                        <DatePicker
                            autoOk
                            disableFuture
                            disabled={disableStudentDetails}
                            variant='inline'
                            value={dateOfBirth}
                            placeholder='Select Date...'
                            onChange={setDateOfBirth}
                            format='DD MMM yyyy'
                        />
                    </Form.Field>


                </Form.Group>
               
                {showBatchesOptions ?
                    <>
                        <Divider horizontal>
                            <span style={{ color: colors.blue["400"] }}>Batch Details</span>
                        </Divider>
                        <Form.Group widths='equal'>

                            <Form.Field  >
                                <DatesSelection startDate={startDate} endDate={endDate} changeDate={changeDate}
                                    disablePast={false}
                                    disableFuture={false} />
                            </Form.Field>

                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field  >
                                <label>
                                    Days
                                    <Checkbox

                                        checked={
                                            DAYS_OF_WEEK.length === days.length
                                        }
                                        style={{ marginRight: "7px", marginLeft: "7px" }}
                                        onClick={(e, { checked }) => {
                                            if (disableDates) {
                                                checked = !checked;
                                            } else {
                                                updateAllDays(checked);
                                            }
                                        }}
                                    />


                                    <span style={{ fontWeight: "lighter" }}> All Days </span>
                                </label>
                                {DAYS_OF_WEEK.map((x) => {
                                    const isActive = days.some(
                                        (mode) => mode.key === x.key
                                    );
                                    return (
                                        <Button

                                            disabled={disableDates}
                                            key={x.key}
                                            onClick={() => { updateDays(x) }}
                                            style={{
                                                marginRight: "10px",
                                                backgroundColor: isActive
                                                    ? colors.orange[700]
                                                    : colors.grey[200],
                                                color: isActive ? colors.blue[50] : colors.black,
                                            }}
                                        >
                                            {x.text}
                                        </Button>
                                    );
                                })}

                            </Form.Field>
                        </Form.Group>
                        <Form.Group widths='equal'>
                            <Form.Field  >

                                <label>Batches</label>
                                <Dropdown
                                    onChange={changeBatch}
                                    placeholder='Select...'
                                    openOnFocus
                                    selection
                                    value={selectedBatch}
                                    options={listOfBatches}
                                    style={{ marginRight: "30px" }}
                                />
                            </Form.Field>

                            {showNumberOfClasses ?
                                <>
                                    <Form.Field  >

                                        <label>Number of Days</label>
                                        <input
                                            style={{ marginRight: "6px" }}
                                            disabled
                                            type='number'
                                            value={numberOfClasses}
                                            placeholder='12'
                                            onChange={(evt) => setNumberOfClasses(evt.target.value)}

                                        />
                                    </Form.Field>
                                    <Form.Field  >

                                        <label>Fees</label>
                                        <input
                                            style={{ marginRight: "6px" }}
                                            disabled
                                            type='number'
                                            value={fees}
                                            placeholder='12'
                                            onChange={(evt) => setFees(evt.target.value)}

                                        />
                                    </Form.Field>
                                    <Form.Field  >

                                        <label>Registration Fees</label>
                                        <input
                                            style={{ marginRight: "6px" }}
                                            disabled
                                            type='number'
                                            value={registrationFees}
                                            placeholder='12'
                                            onChange={(evt) => setRegistrationFees(evt.target.value)}

                                        />
                                    </Form.Field>

                                </>
                                : null}
                        </Form.Group>

                        {showNumberOfClasses ?
                            <Form.Group widths='equal'>

                                    <Form.Field  >
                                        <label>Enrollment Discount</label>
                                        <input
                                            style={{ marginRight: "6px" }}
                                            type='text'
                                            pattern='^\d*\.?\d{0,4}$'
                                            value={discount}
                                            placeholder='0 % Applicable on only Fees'
                                            onInput={(evt) => addDiscount(evt.target.value)}
                                        />
                                    </Form.Field  >
                                    <Form.Field  >
                                        <label>Total Enrollment amount</label>
                                        <input
                                            style={{ marginRight: "6px" }}
                                            disabled
                                            type='number'
                                            value={netAmount}
                                            placeholder='0 % Applicable on only Fees'
                                            onChange={()=>{}}
                                        />
                                    </Form.Field  >
                                   
                                    <Form.Field  >

                                        <label>Remarks</label>
                                        <input
                                            style={{ marginRight: "6px" }}
                                            type='text'
                                            value={remarks}
                                            placeholder='Additional information'
                                            onChange={(evt) => setRemarks(evt.target.value)}

                                        />
                                    </Form.Field>
                            </Form.Group> : null}
                        {showErrorMessage ?
                            <div>
                                <span style={{ color: "red" }}>Please select the Start/End date and days first and then select the batch.</span>
                            </div>
                            : null}
                        {showNumberOfClasses ? <>

                            <Divider horizontal>
                                <span style={{ color: colors.blue["400"] }}>Payment</span>
                            </Divider>
                            <div style={{ marginBottom: "7px", fontWeight: "bold" }}>
                                Total Amount - Rs. {netAmount}/-
                            </div>
                            <div style={{ marginBottom: "7px", fontWeight: "bold" }}>
                                Payment Breakup
                            </div>
                            <Payment
                                paymentArray={paymentArray}
                                setPaymentArray={updatePaymentArray}
                            />
                            <div style={{ paddingTop: "10px" }}>
                                <span style={{ fontWeight: "bold" }}>Total Payment: Rs. </span>
                                <span>{totalPayment}/-</span>
                            </div>
                        </>
                            : null}
                    </>
                    : null}
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button primary disabled={!isSubmitAllowed} onClick={addStudent}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addStudent = () => {
        showModal(false);
        isLoading(true);
        const completeStudentName = studentFirstName+" "+studentLastName;
        let body = {
            facilityId, arenaId, sportId, batchName, mobile, parentName, studentName:completeStudentName, email, fees, days, dateOfBirth,
            startDate, endDate, discount, totalAmount, netAmount, paymentInfo: paymentArray, numberOfClasses, newParent, remarks,
            registrationFees,
            createdBy: {
                userName: context.user.userName,
                userId: context.user.userId,
            },
            isRenewal, trialId,
            arenaData
        };
        const postBody = isRenewal ? { ...body, oldEnrollmentId: renewalDetails.enrollmentId } : body;

        enrollStudent(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Student Added",
                    message: "Student has been added successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });


    }
    return (
        <>
            <ModalPortal
                title='Add Student'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
            
        </>

    );


}
export default EnrollStudent;