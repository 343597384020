import React, { useContext, useEffect, useState } from 'react';
import { getWeeklyAnalyticsData } from '../../apiclients/AnalyticsClient';
import { AppContext } from '../../AppContext';
import { Line } from 'react-chartjs-2';
import { ANALYTICS_COLORS, ARENA_ANALYTICS_COLORS } from '../../utils/constants';
import moment from 'moment';
import GenericLoader from '../generic/GenericLoader';
import { Tab, } from "semantic-ui-react";


const OccupancyDashboard = ({ data, occupancy }) => {
    const occupancyGraphData = data;
    return (
        <div style={{ color: "#000" }}>
            <h3>{occupancy ? "Slots Booked Daily" : "New Booking %"} </h3>
            <h2>Badminton </h2>
            {occupancyGraphData && <Line
                data={occupancyGraphData.SP001}
                width={100}
                height={15}
                pointRadius={1}
                showLine={false}

                options={{
                    // maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                show: true
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                show: false
                            }
                        }]
                    }
                }}
            />}
            <h2>Box-Cricket </h2>
            {occupancyGraphData &&
                <Line
                    data={occupancyGraphData.SP003}
                    width={100}
                    height={15}
                    pointRadius={1}
                    showLine={false}

                    options={{
                        // maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    show: true
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    show: false
                                }
                            }]
                        }
                    }}
                />}



            <h2>Futsal </h2>
            {occupancyGraphData && <Line
                data={occupancyGraphData.SP002}
                width={100}
                height={15}
                pointRadius={1}
                showLine={false}

                options={{
                    // maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                show: true
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                show: false
                            }
                        }]
                    }
                }}
            />}

            <h2>Pickle Ball</h2>
            {occupancyGraphData && <Line
                data={occupancyGraphData.SP012}
                width={100}
                height={15}
                pointRadius={1}
                showLine={false}

                options={{
                    // maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                show: true
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                show: false
                            }
                        }]
                    }
                }}
            />}


        </div>
    )
}



const WeeklyDashboard = () => {

    const { selectedFacility, arenas } = useContext(AppContext);
    console.log('arenas ', arenas);
    const [occupancyGraphData, setOccupancyGraphData] = useState();
    const [newBookingRatio, setNewBookingRatio] = useState();
    const [loading, setLoading] = useState(true);

    const [activeIndex, setActiveIndex] = useState(0);


    useEffect(() => {
        getWeeklyAnalyticsData(selectedFacility).then(response => {
            console.log('response ', response);
            let occupancyGraphData = createLineItemOccupancyData(response.data);
            console.log('occupancyGraphData ', occupancyGraphData);
            let newBookingGraphData = createLineItemNewBookingData(response.data);
            console.log('newBookingGraphData ', newBookingGraphData);
            setOccupancyGraphData(occupancyGraphData);
            setNewBookingRatio(newBookingGraphData);
            setLoading(false);
        })
    }, []);

    const createLineItemOccupancyData = (data) => {
        let lineData = {};
        // use the data to update  in a format that we get arrays of data for each arena for each sport
        const { sportsDetail } = data[data.length - 1];
        for (var i = 0; i < sportsDetail.length; i++) {
            let labels = [];
            let dataSets = [];

            const facilities = sportsDetail[i].facilityIds;
            for (var u = 0; u < facilities.length; u++) {
                if (facilities[u] !== "play0009" && facilities[u] !== "play0008") {
                    console.log('facilities[u] ', facilities[u]);
                    console.log(' arenas.find(x => x.arenaId === facilities[u]).arenaName ', arenas.find(x => x.arenaId === facilities[u]).arenaName);
                    const facilityName = arenas.find(x => x.arenaId === facilities[u]).arenaName;
                    let dataForSlot = {
                        label: facilityName,
                        data: [],
                        borderColor: ARENA_ANALYTICS_COLORS[facilities[u]],
                        backgroundColor: ARENA_ANALYTICS_COLORS[facilities[u]],
                        tension: 0.3,
                        fill: false,
                        hidden: true,
                    };
                    for (var z = 0; z < data.length; z++) {

                        if (data[z][sportsDetail[i].id]) {
                            labels.length < data.length && labels.push(data[z].id);
                            const analyticsData = data[z][sportsDetail[i].id].find(x => x.facilityId === facilities[u]);
                            const aData = analyticsData ? analyticsData.slotsBookedDaily : 0
                            dataForSlot.data.push(aData);
                        }
                    }
                    dataSets.push(dataForSlot);
                };
            }
            lineData[sportsDetail[i].id] = {
                labels: labels,
                datasets: dataSets
            };
        }
        return lineData;
    }

    const createLineItemNewBookingData = (data) => {
        let lineData = {};
        // use the data to update  in a format that we get arrays of data for each arena for each sport
        const { sportsDetail } = data[data.length - 1];
        for (var i = 0; i < sportsDetail.length; i++) {
            let labels = [];
            let dataSets = [];

            const facilities = sportsDetail[i].facilityIds;
            for (var u = 0; u < facilities.length; u++) {

                if (facilities[u] !== "play0009" && facilities[u] !== "play0008") {
                    const facilityName = arenas.find(x => x.arenaId === facilities[u]).arenaName;
                    let dataForSlot = {
                        label: facilityName,
                        data: [],
                        borderColor: ARENA_ANALYTICS_COLORS[facilities[u]],
                        backgroundColor: ARENA_ANALYTICS_COLORS[facilities[u]],
                        tension: 0.3,
                        fill: false,
                        hidden: false,
                    };
                    for (var z = 0; z < data.length; z++) {

                        if (data[z][sportsDetail[i].id]) {
                            labels.length < data.length && labels.push(data[z].id);
                            const analyticsData = data[z][sportsDetail[i].id].find(x => x.facilityId === facilities[u]);
                            // console.log('analyticsData ',analyticsData);
                            analyticsData && dataForSlot.data.push(analyticsData.newBookingRatio * 100);
                        }
                    }
                    dataSets.push(dataForSlot);
                }
                lineData[sportsDetail[i].id] = {
                    labels: labels,
                    datasets: dataSets
                };
            };
        }
        return lineData;
    }

    const panes = [
        {
            menuItem: "Occupancy Data",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <OccupancyDashboard
                        data={occupancyGraphData}
                        occupancy={true}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "New Booking Ratio",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <OccupancyDashboard
                        data={newBookingRatio}
                        occupancy={false}
                    />
                </Tab.Pane>
            ),
        },
    ];


    return loading ? (
        <GenericLoader />
    ) : (
        <div style={{ paddingTop: "16px" }}>
            <Tab
                activeIndex={activeIndex}
                onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                renderActiveOnly={true}
                menu={{
                    color: "orange",
                    pointing: true,
                }}
                panes={panes}
            />
        </div>

    );
};

export default WeeklyDashboard;