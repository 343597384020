import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Form, Segment } from "semantic-ui-react";
import NumberInput from "../../lib/NumberInput";
import { getProducts } from "../../apiclients/DashboardApiClient";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { Input } from "@material-ui/core";


const ProductSelections = ({
  facility,
  selectedItems,
  setSelectedItems,
  requestFromBookingModal,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {

    if (data.length === 0) {
      getProducts(facility.id, facility.arenaId).then((response) => {
        const dataWithValue = response.data.products.map((x) => ({
          ...x,
          text: x.productName,
          value: x.id,
          key: x.id,
          isRenting: x.availableForRent,
        }));
        console.log('data  with value is ', dataWithValue);
        setData(dataWithValue);
      });
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps



  const updateProductArray = (type, id, quantityAvailable) => (value) => {
    // if (value <= quantityAvailable) {
      const updatedItems = selectedItems.map((x) => {
        if (x.key === id) {
          return { ...x, [type]: type === "isRenting" ? !x.isRenting : value };
        }
        return x;
      });
      setSelectedItems(updatedItems);
    // }
  };

  const backUpText = requestFromBookingModal ? null : (
    <div>
      No Products added yet. Please add product from Rates and Inventory
      section.
    </div>
  );
  return data.length > 0 ? (
    <Form style={{ marginBottom: "12px" }}>

      <Form.Group widths='equal'>
        <Form.Field>
          <label>Search for a Product</label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="productName"
            multiple
            onChange={setSelectedItems}
            options={data}
            placeholder="Type a product..."
            selected={selectedItems}
          />
        </Form.Field>
      </Form.Group>
      {selectedItems &&
        selectedItems.map((x) => {
          return (
            <Segment key={x.id}>
              {x.availableForRent && x.sellingPrice ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Rent ?</div>
                  <Checkbox
                    checked={x.isRenting}
                    style={{ marginLeft: "10px" }}
                    toggle
                    onClick={updateProductArray("isRenting", x.id)}
                  />
                </div>
              ) : null}
              <div>Product Name: {x.text}</div>
              <div>
                {x.isRenting ? "Rental Price" : "Selling Price"}: Rs.
                {x.isRenting ? x.rentalPrice : x.sellingPrice}/-
              </div>

              <Form.Group wproductIdths='equal'>
                <Form.Field>
                  <label>Quantity</label>
                  <NumberInput
                    placeholder={'Available stock ' + x.productQuantity}
                    onChange={updateProductArray("quantityAdded", x.id, x.productQuantity)}
                  />
                 
                </Form.Field>
                <Form.Field>
                  <label>Discount</label>
                  <NumberInput
                    placeholder='Discount'
                    onChange={updateProductArray("discount", x.id)}
                  />
                </Form.Field>
              </Form.Group>
            </Segment>
          );
        })}
    </Form>
  ) : (
    backUpText
  );
};

export default ProductSelections;
