import React, { useEffect, useContext, useState } from "react";
import { Button, Divider, Form, Table } from "semantic-ui-react";

import "../dashboard/style.scss";
import { AppContext } from "../../AppContext";
import {
  convertFirstoreDate,
} from "../../utils/helperFunctions";
import { colors } from "@material-ui/core";
// import { initialFilterState } from "./bookingReportReducer";
import GenericLoader from "../generic/GenericLoader";
import {
  getMemberships,
  updateMembershipPayment,
  cancelMembership,
  getCancelAmountForMembership,
} from "../../apiclients/MembershipApiClient";
import Payment from "../dashboard/Payment";
import CustomModal from "../generic/CustomModal";

const EnrolledMemberships = ({ type, match, history, location }) => {
  const { selectedFacility, selectedArena, facilitySports, selectedSportDetails  } =
    useContext(AppContext);
    const context = useContext(AppContext);
    const arenaData = context.arenaData[context.selectedArena.arenaId];
  const [data, setData] = useState([]);
  const [showPaymentModal, setShowPaymentModal] = useState();
  const [loadData, setLoadData] = useState(true);
  const [paymentArray, setPaymentArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [cancelInfo, setCancelInfo] = useState(false);
  const [currentSport, setCurrentSport] = useState();
  console.log('the selectedSportDetails is ', selectedSportDetails);
  console.log('the match sport is ', match);
  useEffect(() => {
    if (showCancel) {
      setLoader(true);
      getCancelAmountForMembership(
        selectedFacility,
        selectedArena.arenaId,
        showCancel.id
      ).then((res) => {
        setCancelInfo(res.data);
        setLoader(false);
      });
    }
  }, [showCancel]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadData) {
      getMemberships(
        selectedFacility,
        selectedArena.arenaId,
        match.params?.sportId,
        location.state.isIndividualBooking
      ).then((res) => {
        setData(res.data.data);
        setLoadData(false);
      });
    }
    const data = facilitySports.find(
      (sp) => sp.sportId === match.params?.sportId
    );
    setCurrentSport(data);
  }, [loadData, selectedFacility, selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps



  const updateBookingPayment = () => {
    setLoader(true);
    updateMembershipPayment(
      selectedFacility,
      selectedArena.arenaId,
      showPaymentModal.id,
      paymentArray,
      arenaData
    ).then(() => {
      setLoadData(true);
      setLoader(false);
      setShowPaymentModal(false);
    });
  };

  const postCancelMembership = () => {
    setLoader(true);
    setShowCancel(false);
    cancelMembership(
      selectedFacility,
      selectedArena.arenaId,
      showCancel.id,
      showCancel.sportId,
      cancelInfo.cancellationAmount
    ).then((response) => {
      setLoadData(true);
      setLoader(false);
    });
  };

  const totalPayment = paymentArray
    .map((x) => +x.amount)
    .reduce((x, sum) => sum + x, 0);
  return (
    <>
      {showCancel ? (
        <CustomModal
          header={"Cancel Membership"}
          content={
            <div style={{ paddingBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>
                Total Amount to be refunded: Rs.
              </span>
              <span>
                <input
                  value={cancelInfo?.cancellationAmount}
                  onChange={(data) =>
                    setCancelInfo({
                      ...cancelInfo,
                      cancellationAmount: data.target.value,
                    })
                  }
                />
              </span>
            </div>
          }
          footer={
            <Button onClick={postCancelMembership}>CANCEL MEMBERSHIP</Button>
          }
          setOpen={setShowCancel}
          open={showCancel}
        />
      ) : null}
      {showPaymentModal ? (
        <CustomModal
          closeOnDimmerClick={true}
          header={"Update Payment"}
          content={
            <Form>
              <Divider horizontal>
                <span style={{ color: colors.blue["400"] }}>Payment</span>
              </Divider>
              <div style={{ paddingBottom: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Total Amount to be paid: Rs.
                </span>
                <span>{showPaymentModal.amountDue}/-</span>
              </div>
              <Payment
                paymentArray={paymentArray}
                setPaymentArray={setPaymentArray}
              />
              <div style={{ paddingTop: "10px" }}>
                <span style={{ fontWeight: "bold" }}>Total Payment: Rs.</span>
                <span>{totalPayment}/-</span>
              </div>
            </Form>
          }
          footer={<Button onClick={updateBookingPayment}>Submit</Button>}
          setOpen={setShowPaymentModal}
          open={showPaymentModal}
        />
      ) : null}
      <div style={{ padding: "16px" }}>
        <div style={{ paddingBottom: "16px" }}>
          <div className='dashboard'>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div>
                <span>Enrolled Membership: {type} </span>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            textDecoration: "underline",
            color: colors.blue[500],
            cursor: "pointer",
          }}
          onClick={() => history.goBack()}
        >
          {"< Back"}
        </div>
        {loadData || loader? (
          <GenericLoader />
        ) : (
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Mobile</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>End Date</Table.HeaderCell>
                <Table.HeaderCell>Slot</Table.HeaderCell>
                <Table.HeaderCell>Court</Table.HeaderCell>
                <Table.HeaderCell>Amount Due</Table.HeaderCell>
                <Table.HeaderCell>Amount Paid</Table.HeaderCell>

                <Table.HeaderCell>ID</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((x) => {
                const slot = currentSport && currentSport.timings.find(
                  (t) => t.timeId === x.slotTime
                );
                const court =currentSport&& currentSport.inventoryCourt.find(
                  (t) => t.courtId === x.courtNo
                );
                return (
                  <Table.Row>
                    <Table.Cell>{x.members[0].name}</Table.Cell>
                    <Table.Cell>{x.members[0].number}</Table.Cell>
                    <Table.Cell>
                      {convertFirstoreDate(
                        x.startDate,
                        undefined,
                        "DD-MM-YYYY"
                      )}
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        {convertFirstoreDate(
                          x.endDate,
                          undefined,
                          "DD-MM-YYYY"
                        )}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        {slot
                          ? slot.interval.start + " - " + slot.interval.end
                          : "-"}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>{court?.courtName || "-"}</div>
                    </Table.Cell>
                    <Table.Cell>Rs.{x.amountDue}/-</Table.Cell>
                    <Table.Cell>{x.amountPaid}</Table.Cell>
                    <Table.Cell>
                      <div>
                        {x.id}
                        <div
                          style={{
                            color: colors.red["500"],
                            textTransform: "uppercase",
                          }}
                        >
                          {x.cancelled && "CANCELLED"}
                        </div>
                      </div>
                    </Table.Cell>
                    <Table.Cell
                      style={{
                        flex: 1,
                        justifyContent: "center",
                        flexDirection: "column",
                        display: "flex",
                      }}
                    >
                      {x.amountDue > 0 && (
                        <Button
                          primary
                          style={{
                            padding: "8px",
                            fontSize: "12px",
                            marginBottom: "8px",
                          }}
                          onClick={() => setShowPaymentModal(x)}
                        >
                          Update Payment
                        </Button>
                      )}
                      {/* <Button
                      style={{
                        padding: "8px",
                        fontSize: "12px",
                        marginBottom: "8px",
                        backgroundColor: colors.orange["800"],
                        color: "#fff",
                      }}
                      // onClick={() => setShowPayment(x)}
                    >
                      View Details
                    </Button> */}

                      {!x.cancelled && (
                        <Button
                          style={{
                            padding: "8px",
                            fontSize: "12px",
                            marginBottom: "8px",
                          }}
                          onClick={() => setShowCancel(x)}
                        >
                          Cancel
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        )}
      </div>
    </>
  );
};

export default EnrolledMemberships;
