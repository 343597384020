import React, { useState, useContext, useEffect } from 'react';
import { Table, Tab, Button, Icon } from 'semantic-ui-react';
import Toast from '../generic/Toast';
import GenericLoader from '../generic/GenericLoader';

import { AppContext } from '../../AppContext';
import { colors } from '@material-ui/core';
import { getAllCategories } from '../../apiclients/ProductApiClient';
import {CATEGORY_TYPE} from '../../utils/constants';
import AddCategory from './AddCategories';
import DeleteCategory from './DeleteCategory';

const CategoryTable = ({
    setReload,
    setMessage,
    isLoading,
    history,
    type,
}) => {
    const context = useContext(AppContext);
    const [deleteModal, setDeleteModal] = useState(false); // to show delete modal
    const [categoriesData, setCategoriesData] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState();
    const [addModal, setAddModal] = useState(false); // to show add modal
    const deleteCategory = (x) => {
        setSelectedCategory(x);
        setDeleteModal(true);
    };
    useEffect(() => {
        getAllCategories(type).then(
            (response) => {
                console.log('response is ', response);
                if (response.status === 200) {
                    const categories = response.data.data.categories;
                    setCategoriesData(categories);
                } else {
                    history.push("/extranet/dashboard");
                }
            }
        ).catch((err) => {
            history.push("/extranet/dashboard");
        });
    }, [type]);// eslint-disable-line react-hooks/exhaustive-deps


    const createSubCategoryText = (subCat) => {
        let subCatText = "";
        for (var i = 0; i < subCat.length; i++) {
            if (i === subCat.length - 1) { // for last entry
                subCatText = subCatText + subCat[i].name + ' ('+ subCat[i].id + ')';
            } else {
                subCatText = subCatText + subCat[i].name + ' ('+ subCat[i].id + '), ';
            }
        }
        return subCatText;
    }
    return (
        <><div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span> </span>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>
                            
                            <Button
                                style={{ marginLeft: "20px" }}
                                onClick={() => setAddModal(true)}
                                name={"Add Package"}
                            >
                                <Icon name={"add"} /> Add a Category
                            </Button>

                        </div>
                    </div>
                </div>


            </div>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>S.No</Table.HeaderCell>
                        <Table.HeaderCell>Category Name</Table.HeaderCell>
                        <Table.HeaderCell>Sub-Categories</Table.HeaderCell>
                        <Table.HeaderCell>Id</Table.HeaderCell>
                        <Table.HeaderCell>Delete</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {categoriesData && categoriesData.map((x, i) => {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{x.name}</Table.Cell>
                                <Table.Cell>{x.subCategories ? createSubCategoryText(x.subCategories) : null}</Table.Cell>
                                <Table.Cell>{x.id}</Table.Cell>

                                <Table.Cell >
                                    <div
                                        style={{
                                            textDecoration: "underline",
                                            color: colors.red[500],
                                        }}
                                    onClick={()=>deleteCategory(x)}
                                    >
                                        Delete
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            </Table>
            {addModal ? (
                <AddCategory
                    showModal={setAddModal}
                    setReload={setReload}
                    setMessage={setMessage}
                    isLoading={isLoading}
                    type={type}
                />
            ) : null}
            {deleteModal ? (
                <DeleteCategory
                    showModal={setDeleteModal}
                    setReload={setReload}
                    setMessage={setMessage}
                    isLoading={isLoading}
                    type={type}
                    categoryData={selectedCategory}
                />
            ) : null}


        </>
    );
};

const CategoryManagement = (props) => {
    const { history } = props;
    const context = useContext(AppContext);
    const [activeIndex, setActiveIndex] = useState(0);
    const [reload, setReload] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});
    const types = [CATEGORY_TYPE.product, CATEGORY_TYPE.cafe];

    const panes = [
        {
            menuItem: "Product Category",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <CategoryTable
                        setReload={setReload}
                        history={history}
                        type={types[activeIndex]}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Cafe Category",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <CategoryTable
                        setReload={setReload}
                        history={history}
                        type={types[activeIndex]}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                    />
                </Tab.Pane>
            ),
        },

    ];

    useEffect(() => {
        if (reload) {
            console.log('this was reacheed ....');
                setReload(false);
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 2000);
        }
    }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div style={{ padding: "16px" }}>
            <Toast
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                message={messageContent.message}
                messageHeader={messageContent.header}
                color={messageContent.color}
            />
            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Category Management </span>
                    </div>
                </div>


            </div>
            <br />
            {isLoading ? (
                <GenericLoader />
            ) : (
                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    renderActiveOnly={true}
                    menu={{
                        color: "orange",
                        pointing: true,
                    }}
                    panes={panes}
                />
            )}


        </div>
    )
};

export default CategoryManagement;