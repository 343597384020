import React, { useEffect, useReducer, useState, useContext } from "react";
import { Segment, Form, Button, Divider, Dropdown, Checkbox, } from "semantic-ui-react";
import "./style.scss";
import {
  getLastReceiptNumber,
  createBooking,
} from "../../apiclients/DashboardApiClient";
import CustomModal from "../generic/CustomModal";
import Payment from "./Payment";
import ProductSelections from "./ProductSelections";
import { colors } from "@material-ui/core";
import GenericLoader from "../generic/GenericLoader";
import CustomerDetail from "../common/CustomerDetail";
import moment from "moment";
import { AppContext } from "../../AppContext";
import { ROLES } from "../../utils/constants";
import NumberInput from "../../lib/NumberInput";
import AddMembersBooking from "../membership/flexMembership/AddMembersBooking";

const getTotalAmount = (paymentArray) => {
  return (
    paymentArray &&
    paymentArray.length > 0 &&
    paymentArray.map((x) => +x.amount).reduce((x, sum) => sum + x)
  );
};

const initialState = {
  name: "",
  number: "",
  receiptNo: "",
  paymentArray: [],
  isDisabled: true,
  selectedItems: [],
};
const ACTION_TYPE = {
  ADD_RECEIPT_NO: "addReceiptNo",
  ADD_NAME: "addName",
  ADD_NUMBER: "addMobileNumber",
  ADD_PAYMENT_ARRAY: "addPaymentArray",
  ADD_ITEMS: "addItems",
};
const reducer = (state, action) => {
  if (action.type === ACTION_TYPE.ADD_RECEIPT_NO) {
    return { ...state, receiptNo: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_NAME) {
    return {
      ...state,
      name: action.payload,
    };
  }
  if (action.type === ACTION_TYPE.ADD_NUMBER) {
    return {
      ...state,
      number: action.payload,
    };
  }
  if (action.type === ACTION_TYPE.ADD_ITEMS) {
    return {
      ...state,
      selectedItems: action.payload,
    };
  }
  if (action.type === ACTION_TYPE.ADD_PAYMENT_ARRAY) {
    return {
      ...state,
      ...action.payload,
    };
  }
  return state;
};

const getPrice = (x) => {
  // to do
  const price = x.isRenting ? x.rentalPrice : x.sellingPrice;
  return x.quantityAdded * price * (x.discount ? (100 - x.discount) / 100 : 1);
};

const getTotalAmountForProducts = (selectedItems) => {
  return selectedItems
    .filter((x) => !!x.quantityAdded)
    .map(getPrice)
    .reduce((x, sum) => sum + x, 0);
};
const RegularBookingBody = ({ state, dispatch, booking, facility, sport, selectedSport, setSelectedSport, totalAmount, setTotalAmount, numberOfCustomer, setNumberOfCustomer,setSelectedSlot,setShowLoader,setMessage,reload }) => {
  const { name, number, receiptNo, paymentArray, selectedItems } = state;
  const { user, selectedSportDetails, arenaData } = useContext(AppContext);
  const [isMemberBooking, setIsMemberBooking] = useState(false);
  const flexiMembershipAvailable = arenaData[facility.arenaId].modules.flexiMembership ? (arenaData[facility.arenaId].modules.flexiMembership.applicable && arenaData[facility.arenaId].modules.flexiMembership.sports.includes(sport.value)) : false;
  useEffect(() => {
    getLastReceiptNumber(facility.id, facility.arenaId).then((response) => {
      dispatch({
        type: ACTION_TYPE.ADD_RECEIPT_NO,
        payload: response.data.nextReceipt,
      });
    });
    setTotalAmount(+booking.bookingPrice * numberOfCustomer + +getTotalAmountForProducts(selectedItems));
  }, [facility.id]);

  const updatePaymentArray = (updatedArray) => {
    dispatch({
      type: ACTION_TYPE.ADD_PAYMENT_ARRAY,
      payload: {
        paymentArray: updatedArray,
      },
    });
  };

  const setName = (n) => {
    dispatch({
      type: ACTION_TYPE.ADD_NAME,
      payload: n,
    });
  };
  function handleNumberChange(evt) {
    const numericValue = evt.target.validity.valid ? evt.target.value : number;
    dispatch({ type: ACTION_TYPE.ADD_NUMBER, payload: numericValue });
  }
  const totalPayment = getTotalAmount(paymentArray);

  // const totalAmount = +booking.bookingPrice*numberOfCustomer + +getTotalAmountForProducts(selectedItems);
  const slotTime = moment(booking.bookingDate).format("DD-MM-YYYY") + " " + booking.interval.end;
  // bookings can only be made for today by non admin
  const SportsOption = () => {
    if (selectedSportDetails.detail.alternativeSports) {
      const otherSports = selectedSportDetails.detail.alternativeSports.map(x => {
        return {
          key: x.id,
          value: x.id,
          text: x.name
        };
      })

      let options = [sport, ...otherSports];
      const selectSport = (event, { value }) => {
        let selectedSport = options.find(x => x.value === value);
        setSelectedSport(selectedSport)
      }
      return <>
        <Dropdown
          onChange={selectSport}
          placeholder='Select Sport'
          openOnFocus
          selection
          value={selectedSport && selectedSport.value}
          options={options}
          style={{ marginRight: "30px" }}
        />
      </>
    } else {
      setSelectedSport(sport);
      return sport.text;
    }
  }

  // for updating total amount and booking amount
  const updateNumberOfCustomer = (value) => {
    setNumberOfCustomer(value);
    setTotalAmount(+booking.bookingPrice * value + +getTotalAmountForProducts(selectedItems));
  }

  const selectMembershipBooking = (checked) => {
    setIsMemberBooking(checked)
  }
 

  if (
    user.role === ROLES.ADMIN ||
    user.role === ROLES.REGIONAL_MANAGER || user.role === ROLES.USER ||
    moment().isSameOrBefore(moment(slotTime, "DD-MM-YYYY hh:mm"))
  ) {
    return (
      <>
        <Segment.Group>
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Location:</div>
            <div>{facility.arenaName}</div>
          </div>
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Sport:</div>
            <SportsOption />
          </div>
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Court:</div>
            <div>{booking.courtName}</div>
          </div>
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Booking Date:</div>
            <div>{moment(booking.bookingDate).format('DD-MMM-YY')}</div>
          </div>
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Slot timings:</div>
            <div>{booking.interval.start + " - " + booking.interval.end}</div>
          </div>
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Booking Amount:</div>
            <div>Rs. {booking.bookingPrice}/-</div>
          </div>
          {selectedSportDetails.detail && selectedSportDetails.detail.capacityPerSlot && !isMemberBooking &&
            <div className={"info-cell"}>
              <div className={"pr10-bold"}>Number of Person:</div>
              <NumberInput
                value={numberOfCustomer}
                placeholder='# of person'
                onChange={(value) => updateNumberOfCustomer(value)}
              />
            </div>
          }


        </Segment.Group>
        {flexiMembershipAvailable && <>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div>Is it a Member's Booking ?</div>
            <Checkbox
              checked={isMemberBooking}
              style={{ marginLeft: "10px" }}
              toggle
              onClick={() => selectMembershipBooking(!isMemberBooking)}
            />
          </div>
        </>}
        {!isMemberBooking ? <Form>

          <CustomerDetail
            name={name}
            setName={setName}
            number={number}
            setNumber={handleNumberChange}
          />

          {totalAmount > 0 ? (
            <>
              <Divider horizontal>
                <span style={{ color: colors.blue["400"] }}>Payment</span>
              </Divider>
              <div style={{ paddingBottom: "10px" }}>

                <span style={{ fontWeight: "bold" }}>
                  Total Amount to be paid: Rs.
                </span>
                <span>{totalAmount}/-</span>
              </div>
              <Payment
                paymentArray={paymentArray}
                setPaymentArray={updatePaymentArray}
              />
              <div style={{ paddingTop: "10px" }}>
                <span style={{ fontWeight: "bold" }}>Total Payment: Rs.</span>
                <span>{totalPayment}/-</span>
              </div>
            </>
          ) : null}
        </Form> :
          <AddMembersBooking
            number={number}
            setNumber={handleNumberChange}
            bookingDate={booking.bookingDate}
            bookingDetails={booking}
            setShowLoader = {setShowLoader}
            setSelectedSlot={setSelectedSlot}
            setMessage={setMessage}
            reload={reload}
          />}

      </>
    );
  } else {
    return (
      <>
        <Segment.Group>
          <div className={"info-cell"}>
            <div className={"pr10-bold"}>Booking not allowed!!</div>
          </div>
        </Segment.Group>
      </>
    );
  }
};

const RegularBookingFooter = ({ setOpen, disabled, onConfirm }) => {
  return (
    <>
      <Button onClick={() => setOpen(false)}>Cancel</Button>
     {!disabled &&  <Button disabled={disabled} primary onClick={onConfirm}>
        Confirm
      </Button>}
    </>
  );
};

const hasAllValues = (state, booking, selectedSport, totalAmount) => {
  const { name, number, receiptNo, paymentArray, selectedItems, } = state;
  return (
    name && number.length >= 10 &&
    number && (1 * number) >= 1000000000 &&
    receiptNo &&
    paymentArray.length > 0 &&
    getTotalAmount(paymentArray) === +totalAmount &&
    selectedSport
  );
};
const RegularBooking = ({
  facility,
  setSelectedSlot,
  selectedSlot,
  sport,
  selectedDate,
  reload,
  setMessage,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showLoader, setShowLoader] = useState(false);
  const [selectedSport, setSelectedSport] = useState();
  const [totalAmount, setTotalAmount] = useState(0);
  const [numberOfCustomer, setNumberOfCustomer] = useState(1);

  const context = useContext(AppContext);
  const arenaData = context.arenaData[facility.arenaId];
  const selectedSportDetails = context.selectedSportDetails;
  let alternateSport = sport === selectedSport ? false : true;
  const onConfirm = () => {
    setShowLoader(true);
    let postBody = {
      sport: sport.value,
      numberOfCustomer: Number(numberOfCustomer),
      arenaId: facility.arenaId,
      bookingDate: selectedDate.format("DD-MM-YYYY"),
      slotInfo: selectedSlot.timeId,
      courtInfo: selectedSlot.courtId,
      userName: state.name,
      mobileNumber: state.number,
      receiptNumber: state.receiptNo,
      slotPrice: selectedSlot.bookingPrice,
      bookingMode: "playall",
      products: state.selectedItems
        .filter((x) => !!x.quantityAdded)
        .map((item) => {
          const price = item.isRenting ? item.rentalPrice : item.sellingPrice;
          const discount = +item.discount ? +item.discount / 100 : 0;
          const netAmount = Math.round(item.quantityAdded * price * (1 - discount) * 100) / 100;
          const totalGST = Math.round((item.cgst + item.sgst + item.igst) * 100) / 100;
          const totalTax = Math.round((item.cgst + item.sgst + item.igst + item.cess) * 100) / 100;
          const totalAmount = item.quantityAdded * price;
          const cgst = item.cgst ? Math.round(item.cgst * (netAmount / (1 + totalTax)) * 100) / 100 : 0;
          const sgst = item.sgst ? Math.round(item.sgst * (netAmount / (1 + totalTax)) * 100) / 100 : 0;
          const igst = item.igst ? Math.round(item.igst * (netAmount / (1 + totalTax)) * 100) / 100 : 0;
          const cess = item.cess ? Math.round(item.cess * (netAmount / (1 + totalTax)) * 100) / 100 : 0;
          const taxableAmount = Math.round((netAmount - cgst - sgst - igst - cess) * 100) / 100;

          return {
            id: item.id, name: item.productName, quantity: +item.quantityAdded,
            discount, isRental: !!item.isRenting, totalAmount, netAmount,
            price, cgst, sgst, igst, cess, taxableAmount, hsn: item.hsn ? item.hsn : '9996', gst: totalGST ? totalGST : 0

          }
        }),
      paymentInfo: JSON.stringify(state.paymentArray),
      createdBy: {
        userName: context.user.userName,
        userId: context.user.userId,
      },
      arenaData
    };

    //if a court/turf is used for alternative sports all the following details in the post body
    if (alternateSport) {
      postBody = { ...postBody, alternativeSport: selectedSport }
    }

    // if the sports can accomodate multiple player in one slot
    if (selectedSportDetails.detail.capacityPerSlot) {
      postBody.capacityPerSlot = selectedSportDetails.detail.capacityPerSlot;
    }


    createBooking(facility.id, postBody)
      .then((response) => {
        setSelectedSlot(false);
        setShowLoader(false);
        setMessage({
          header: "Successful Booking!",
          message: "You booking has been added.",
          color: "green",
        });
        reload(true);
      })
      .catch((error) => {
        setShowLoader(false);
      });
  };
  return showLoader ? (
    <GenericLoader />
  ) : (
    <CustomModal
      header={"Booking Confirmation"}
      content={
        <RegularBookingBody
          booking={selectedSlot}
          facility={facility}
          state={state}
          sport={sport}
          dispatch={dispatch}
          selectedSport={selectedSport}
          setSelectedSport={setSelectedSport}
          setTotalAmount={setTotalAmount}
          totalAmount={totalAmount}
          numberOfCustomer={numberOfCustomer}
          setNumberOfCustomer={setNumberOfCustomer}
          setSelectedSlot={setSelectedSlot}
          setShowLoader={setShowLoader}
          setMessage={setMessage}
          reload={reload}
        />
      }
      footer={
        <RegularBookingFooter
          onConfirm={onConfirm}
          setOpen={setSelectedSlot}
          disabled={!hasAllValues(state, selectedSlot, selectedSport, totalAmount)}
        />
      }
      setOpen={setSelectedSlot}
      open={!!selectedSlot}
    />
  );
};
export default RegularBooking;
