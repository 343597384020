import React, { useContext} from 'react';
import {
    Button,
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { deleteProductsFromMaster } from '../../../apiclients/ProductApiClient';
const DeleteProductFromMaster = (props) => {
    const {  showModal, setReload, productData, setMessage,isLoading } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;

    const modalContent = (
        <div>
            Are you sure you  want to delete the account for {productData.productName} ?
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            
                <Button  primary onClick={deleteAProductFromMaster}>  Submit</Button>
                </React.Fragment>
        );
    };

    const deleteAProductFromMaster = () => {

        showModal(false);
        isLoading(true);

        const postBody = {
             facilityId, productId : productData.id
        };

        deleteProductsFromMaster(
            context.selectedFacility,
            postBody
        ).then((response) => {
            if( response.status === 200 || response.status === 201){
                setMessage({
                    header: "Product Deleted",
                    message: "Product has been successfully deleted.",
                    color: "green",
                  });
            }  else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
              });
            // ..
          });
    }
    return (
        <>
            <ModalPortal
                title='Delete Product from Master'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default DeleteProductFromMaster;