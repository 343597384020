import React, { useContext, useRef, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { getAcademyAnalyticsData } from '../../apiclients/AnalyticsClient';
import GenericLoader from '../generic/GenericLoader';
import { Dropdown } from 'semantic-ui-react';
import { Card, Grid } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Line, Pie } from 'react-chartjs-2';
import { getAllAcademySportsOfArena } from '../../apiclients/AcademyClient';
import moment from 'moment';

const AcademyAnalytics = (props) => {

  const { history } = props;
  const {
    selectedFacility,
    selectedArena,
  } = useContext(AppContext);


  const sports = useRef([]);
  const [selectedSport, setSelectedSport] = useState();
  const [isLoading, setisLoading] = useState(true);
  const [analyticsData, setAnalyticsData] = useState();
  const [overallAnalyticsData, setOverallAnalyticsData] = useState();
  const [allAcademySports, setAllAcademySports] = useState([]);
  const [graphData, setGraphData] = useState();
  const [occupancyGraphData, setOccupancyGraphData] = useState();
  const colorOne = 'rgba(26,60,54)';
  const colorTwo = 'rgba(86,168,226)';
  const colorThree = 'rgba(138,200,183)';
  const colorFour = 'rgba(208,231,214)';
  const colorFive = 'rgba(56,121,109)';
  const allColors = [colorOne, colorTwo, colorThree, colorFour, colorFive];
  const useStyles = makeStyles({
    root: {
      minWidth: 275,
    },
    bullet: {
      display: 'inline-block',
      margin: '0 2px',
      transform: 'scale(0.8)',
    },
    title: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
  });


  const classes = useStyles();

  const loadData = (sportData) => {
    if (sportData !== undefined) {
      setisLoading(true);
      return getAcademyAnalyticsData(
        selectedFacility, selectedArena.arenaId,
        sportData.value,
      ).then((response) => {
        const analyticsData = response.data.todayData;
        const overallDataAnalyticsData = response.data.overallData;
        setOverallAnalyticsData(overallDataAnalyticsData);
        let graphsData = createLineItemData(overallDataAnalyticsData);
        setGraphData(graphsData);
        setAnalyticsData(analyticsData);
        setisLoading(false);
      });
    }
  };

  useEffect(() => {
    if (selectedFacility && selectedArena) {
      getAllAcademySportsOfArena(selectedFacility, selectedArena.arenaId).then(
        (response) => {
          if (response.status === 200) {
            const sport = response.data;
            const dropdownSportsData = sport.map(
              (x, index) => ({
                key: x.sportId,
                value: x.sportId,
                text: x.sportName,
              })
            );
            setAllAcademySports(dropdownSportsData);
            sports.current = dropdownSportsData;
            setSelectedSport(dropdownSportsData[0]);
            loadData(dropdownSportsData[0]);
          } else {
            console.log('this was called');
            history.push("/extranet/dashboard");
          }

        }
      ).catch((err) => {
        history.push("/extranet/dashboard");
      });

    }
  }, [selectedFacility, selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

  const changeSport = (event, { value }) => {
    const data = sports.current.find((facility) => facility.value === value);
    setSelectedSport(data);
    loadData(data);
  };

  const createLineItemData = (data) => {
    let labels = [];
    let activeStudentDataSet = {
      label: 'Active Students',
      data: [],
      borderColor: [colorOne],
      backgroundColor: [colorOne],
      tension: 0.3,
      fill: false,
    };
    let inActiveStudentDataSet = {
      label: 'Inactive Students',
      data: [],
      borderColor: [colorTwo],
      backgroundColor: [colorTwo],
      tension: 0.3,
      fill: false,
    };
    if (data) {
      data.forEach(x => {
        labels.push(moment(x.createdDate.seconds * 1000).format('DD-MMM-YY'));
        activeStudentDataSet.data.push((x.activeStudents));
        inActiveStudentDataSet.data.push((x.inactiveStudents));
      });
    }
    let lineData = {
      labels: labels,
      datasets: [
        activeStudentDataSet, inActiveStudentDataSet,
      ]
    };
    return lineData;
  }

  return isLoading ? <GenericLoader /> :
    (<div style={{ padding: "16px" }}>
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>
              Academy Analytics </span>
          </div>
          <div style={{ alignItems: "center" }}>
            <div style={{ fontSize: "14px" }}>
              <Dropdown
                onChange={changeSport}
                placeholder='Select...'
                openOnFocus
                selection
                value={selectedSport && selectedSport.value}
                options={sports.current}
              />

            </div>
          </div>
        </div>
      </div>
      <br />
      <div>
        <Grid container spacing={9}>
          <Grid item md={3} spacing={3}>
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Today Student Data
                </Typography>
                <Typography variant="h5" component="h2">
                  <span style={{ color: "grey" }}>Active Students - </span> # {analyticsData.activeStudentArray.length}
                  <br />
                  <span style={{ color: "grey" }}>Inactive Students - </span># {analyticsData.inactiveStudentArray.length}
                </Typography>
                <Pie
                  width={30}
                  height={8}
                  data={{
                    labels: [
                      'Active',
                      'Inactive',
                    ],
                    datasets: [{
                      label: 'My First Dataset',
                      data: [
                        analyticsData.activeStudentArray.length,
                        analyticsData.inactiveStudentArray.length,],
                      backgroundColor: [
                        colorOne,
                        colorTwo
                      ],
                      hoverOffset: 4
                    }]
                  }}
                />
              </CardContent>
            </Card>
          </Grid>


          <Grid item md={3} spacing={3}>

            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                  Last Month - Student Data
                </Typography>
                <Typography variant="h5" component="h2">
                  <span style={{ color: "grey" }}>Active Students - </span> # {overallAnalyticsData[overallAnalyticsData.length - 4].activeStudents}
                  <br />
                  <span style={{ color: "grey" }}>Inactive Students - </span># {overallAnalyticsData[overallAnalyticsData.length - 4].inactiveStudents}
                </Typography>
                <Pie
                  width={30}
                  height={8}
                  data={{
                    labels: [
                      'Active',
                      'Inactive',
                    ],
                    datasets: [{
                      label: 'My First Dataset',
                      data: [
                        overallAnalyticsData[overallAnalyticsData.length - 4].activeStudents,
                        overallAnalyticsData[overallAnalyticsData.length - 4].inactiveStudents,],
                      backgroundColor: [
                        colorOne,
                        colorTwo
                      ],
                      hoverOffset: 4
                    }]
                  }}
                />
              </CardContent>
            </Card>
          </Grid>

        </Grid>

        <h3>Last Year Student Data (updates every Monday)</h3>
        <Line
          data={graphData}
          width={100}
          height={20}
          pointRadius={1}
          showLine={false}

          options={{
            // maintainAspectRatio: false,
            scales: {
              xAxes: [{
                gridLines: {
                  show: true
                }
              }],
              yAxes: [{
                gridLines: {
                  show: false
                }
              }]
            }
          }}
        />

      </div>
    </div>
    );
}

export default AcademyAnalytics;