import React, { useEffect, useContext, useState } from "react";
import ModalPortal from '../generic/ModalPortal';
import { colors } from "@material-ui/core";
import { Button, Table, Form, Divider } from "semantic-ui-react";
import "../dashboard/style.scss";
import NumberInput from "../../lib/NumberInput";


import { AppContext } from "../../AppContext";
import GenericLoader from "../generic/GenericLoader";
import { getWalletDetails, reduceWalletCredits } from "../../apiclients/WalletApiClient";

const WalletDetails = (props) => {
    const { history } = props;
    const context = useContext(AppContext);
    const [reload, setReload] = useState(true);
    const [listOfWallets, setListOfWallets] = useState([]);
    const [credits, setCredits] = useState();
    const [outstandingCredits, setOutstandingCredits] = useState();
    const [remarks, setRemarks] = useState('');
    const [selectedWalletId, setSelectedWalletId] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [loader, setLoader] = useState(false);

    const isSubmitAllowed = credits > 0 && credits <= outstandingCredits;
    useEffect(() => {
        getWalletDetails(
            context.selectedFacility,
            context.selectedArena.arenaId
        ).then((response) => {
            const walletData = response.data;
            setListOfWallets(walletData);
            setReload(false);
        });
    }, [context.selectedArena.arenaId, context.selectedFacility, reload]);

    const reduceCreditsOfUsers = () => {
        console.log('this was called');
        setShowModal(false);
        setLoader(true);

        const postBody = {
            creditsToBeReduced: credits, outstandingCredits, walletID: selectedWalletId, remarks
        };
        reduceWalletCredits(
            context.selectedFacility,
            context.selectedArena.arenaId,
            postBody
        ).then((response) => {
            setReload(true);
            setLoader(false);
            console.log("Membership created successfully!");
        });
    }

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button disabled={!isSubmitAllowed} primary onClick={reduceCreditsOfUsers}>  Submit</Button>
                <Button onClick={() => { setShowModal(false) }} className='ui button'>Cancel</Button>
            </React.Fragment>
        );
    };
    const modalContent = (
        <div>
            <Form>
                <Divider horizontal>
                    <span style={{ color: colors.orange[700] }}>Total Outstanding Credits : {outstandingCredits}</span>
                </Divider>
                <Form.Group>
                    <span
                        style={{
                            marginBottom: "10px",
                            marginTop: "10px",
                            fontWeight: "bold",
                        }}
                    >
                        Credits :
                        </span>
                    <Form.Field>
                        <NumberInput
                            placeholder='Credits to be reduced'
                            onChange={setCredits}
                            value={credits}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group>
                    <span
                        style={{
                            marginBottom: "10px",
                            marginTop: "10px",
                            fontWeight: "bold",
                        }}
                    >
                        Remarks :
                        </span>
                    <Form.Field>
                        <span
                            style={{
                                display: "flex",
                                flexDirection: "row",
                                flex: 1,
                            }}
                        >
                            <input
                                style={{ marginRight: "6px" }}
                                type='text'
                                value={remarks}
                                placeholder='remarks'
                                onChange={(evt) => setRemarks(evt.target.value)}
                            />
                        </span>
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    );
    const reduceCredits = (walletDetail, walletId) => {
        setSelectedWalletId(walletId);
        setOutstandingCredits(walletDetail.outstandingCredits);
        setShowModal(true);
    };

    return (
        <div style={{ padding: "16px" }}>

            {loader ? <GenericLoader /> : null}
            {showModal ? (
                <ModalPortal
                    title='Reduce Credits'
                    content={modalContent}
                    action={renderAction()}
                    onDismiss={() => { setShowModal(false) }}
                />
            ) : null}

            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Wallet Packages</span>
                    </div>
                </div>
            </div>
            {reload ? (
                <GenericLoader />
            ) : (
                <div style={{ paddingTop: "16px" }}>
                    <Table celled padded>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>S.No</Table.HeaderCell>
                                <Table.HeaderCell>Mobile</Table.HeaderCell>
                                <Table.HeaderCell>Outstanding Credits</Table.HeaderCell>
                                <Table.HeaderCell>Amount</Table.HeaderCell>
                                <Table.HeaderCell>Start Date</Table.HeaderCell>
                                <Table.HeaderCell>End Date</Table.HeaderCell>
                                <Table.HeaderCell>Validity</Table.HeaderCell>
                                <Table.HeaderCell>Sports</Table.HeaderCell>
                                <Table.HeaderCell>Details</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {listOfWallets.map((y, index) => {
                                const x = y.data;

                                return (
                                    <Table.Row key={y.id}>
                                        <Table.Cell>{index + 1}</Table.Cell>
                                        <Table.Cell>{x.userMobile}  </Table.Cell>
                                        <Table.Cell>Rs. {x.outstandingCredits} worth</Table.Cell>
                                        <Table.Cell>Rs. {x.amountPaid}/-</Table.Cell>
                                        <Table.Cell>{x.startDate}  </Table.Cell>
                                        <Table.Cell>{x.endDate}  </Table.Cell>
                                        <Table.Cell>{x.validity}  </Table.Cell>
                                        <Table.Cell>
                                            {x.sportsDetail.map((sport) => {
                                                return `${sport.sportsName}, `;
                                            })}

                                        </Table.Cell>
                                        <Table.Cell>{x.details}</Table.Cell>
                                        <Table.Cell
                                            style={{
                                                flex: 1,
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                display: "flex",
                                            }}
                                        >
                                            <Button
                                                style={{
                                                    padding: "8px",
                                                    fontSize: "12px",
                                                    marginBottom: "8px",
                                                    backgroundColor: colors.orange["800"],
                                                    color: "#fff",
                                                }}
                                                onClick={() =>
                                                    history.push({
                                                        pathname: "/extranet/walletHistory",
                                                        state: { transactions: x.transactions, mobile: x.userMobile },
                                                    })
                                                }
                                            >
                                                Show History
                  </Button>

                                            <Button
                                                style={{
                                                    padding: "8px",
                                                    fontSize: "12px",
                                                    marginBottom: "8px",
                                                }}
                                                onClick={() => reduceCredits(x, y.id)}
                                            >
                                                Reduce Credit
                  </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                );
                            })}
                        </Table.Body>
                    </Table>

                </div>
            )}
        </div>
    );
}


export default WalletDetails;