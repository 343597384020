import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Segment,
  Divider,
  Modal,
  Button,
} from "semantic-ui-react";
import { getLastReceiptNumber } from "../../../apiclients/DashboardApiClient";
import Payment from "../Payment";
import { colors } from "@material-ui/core";
import { rentProducts } from "../../../apiclients/ProductApiClient";
import { AppContext } from "../../../AppContext";
import ProductSelections from "../ProductSelections";
import GenericLoader from "../../generic/GenericLoader";
import CustomerDetail from "../../common/CustomerDetail";

const Footer = ({ setOpen, type, setType, disabled, onConfirm }) => {
  return (
    <>
      <Button onClick={() => setType("request")}>Go Back</Button>
      <Button primary disabled={disabled} onClick={onConfirm}>
        Save
      </Button>
    </>
  );
};

const RentSaleProducts = ({
  facility,
  booking,
  setOpen,
  setType,
  reload,
  setMessage,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [receiptNo, setReceiptNo] = useState();
  const [paymentArray, setPaymentArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [disableButton, setDisableButton] = useState(false);

  const context = useContext(AppContext);

  useEffect(() => {
    getLastReceiptNumber(facility.id, facility.arenaId).then((response) => {
      setReceiptNo(response.data.nextReceipt);
    });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const onSaveClicked = () => {
    setDisableButton(true);
    setLoader(true);
    let totalAmountBeforeDiscount, totalDiscount
    // ,totalCgst,totalSgst,totalIgst,totalCess,totalTaxableAmount
    ; 
    totalAmountBeforeDiscount= totalDiscount=0;
    // totalCgst=totalSgst=totalIgst=totalCess=totalTaxableAmount=0;
  
    const quantityProducts = selectedItems.filter(x=>x.quantityAdded > 0);
    const products = quantityProducts.map((x) => { 
      const price = x.isRenting ? x.rentalPrice : x.sellingPrice;
      const discount = +x.discount ? +x.discount/100 : 0;
      const netAmount = Math.round(x.quantityAdded*price*(1-discount)*100)/100;
      const totalGST = Math.round((x.cgst+x.sgst+x.igst)*100)/100;
      const totalTax = Math.round((x.cgst+x.sgst+x.igst+x.cess)*100)/100;
      const totalAmount =  x.quantityAdded*price;
      const cgst = x.cgst ? Math.round(x.cgst*(netAmount/(1+totalTax))*100)/100 : 0;
      const sgst = x.sgst ? Math.round(x.sgst*(netAmount/(1+totalTax))*100)/100 : 0;
      const igst = x.igst ? Math.round(x.igst*(netAmount/(1+totalTax))*100)/100 : 0;
      const cess = x.cess ? Math.round(x.cess*(netAmount/(1+totalTax))*100)/100 : 0;
      const taxableAmount = Math.round((netAmount - cgst - sgst - igst - cess)*100)/100;
      totalAmountBeforeDiscount = totalAmountBeforeDiscount + totalAmount;
      totalDiscount = totalDiscount + discount*totalAmount;
      // totalCgst = totalCgst + cgst; totalSgst = totalSgst + sgst; totalIgst = totalIgst + igst; totalCess = totalCess + cess; totalTaxableAmount = totalTaxableAmount + taxableAmount;
      return {id: x.id,name: x.productName,
      quantity: +x.quantityAdded,discount,isRental: !!x.isRenting,totalAmount,netAmount,
      price,cgst,sgst,igst,cess,taxableAmount,hsn : x.hsn ? x.hsn : '9996',gst:totalGST ? totalGST : 0,
    }
  });
  const overAllDiscount = Math.round(totalDiscount*100/totalAmountBeforeDiscount)/100;

    let postBody = {
      arenaId: facility.arenaId,
      sport: context.selectedSportDetails.sportId,
      products: JSON.stringify(products),
      courtInfo: {
        courtId : booking && booking.courtId ? booking.courtId : 'NA',
        courtName :booking && booking.courtName? booking.courtName : 'NA'
      },
      slot : booking && booking.slot ? booking.slot : 'NA',
      receiptNumber: Number(receiptNo),
      paymentInfo: JSON.stringify(paymentArray),
      discount : overAllDiscount,
      totalAmount : totalAmountBeforeDiscount,
      // cgst : totalCgst,
      // sgst : totalSgst,
      // igst : totalIgst,
      // cess : totalCess,
      // taxableAmount : totalTaxableAmount,
    };

    postBody.userName = booking ? booking.userName : name;
    postBody.mobileNumber = booking ? booking.userMobileNumber : number;
    postBody.bookingId = booking ? booking.id : undefined;
    postBody.createdBy = {
      userName: context.user.userName,
      userId: context.user.userId,
    };
    rentProducts(facility.id, facility.arenaId, postBody)
      .then(() => {
        setLoader(false);

    setDisableButton(false);
        setOpen(false);
        setMessage &&
          setMessage({
            header: "Product Sold/Rented!",
            message: "You transaction has been recorded.",
            color: "teal",
          });
          reload(true);
      })
      .catch((err) => {
        setLoader(false);
        setMessage &&
          setMessage({
            header: "Error",
            message: `${err.response}`,
            color: "red",
          });
          reload(true);
      });
  };
  const getPrice = (x) => {
    // to do
    const price = x.isRenting ? x.rentalPrice : x.sellingPrice;
    return (
      x.quantityAdded * price * (x.discount ? (100 - x.discount) / 100 : 1)
    );
  };
  const totalAmount = selectedItems
    .filter((x) => !!x.quantityAdded)
    .map(getPrice)
    .reduce((x, sum) => sum + x, 0);

  const totalPayment = paymentArray
    .map((x) => +x.amount)
    .reduce((x, sum) => sum + x, 0);

  function handleNumberChange(evt) {
    const numericValue = evt.target.validity.valid ? evt.target.value : number;
    setNumber(numericValue);
  }
  return ( 
    <>

{loader ? <GenericLoader /> : null}
      <Modal.Content>
        {booking ? (
          <Segment.Group>
            <div className={"info-cell"}>
              <div className={"pr10-bold"}>User Name:</div>
              <div>{booking.userName}</div>
            </div>
            <div className={"info-cell"}>
              <div className={"pr10-bold"}>Mobile Number:</div>
              <div>{booking.userMobileNumber}</div>
            </div>
          </Segment.Group>
        ) : (
          <Form>
            <CustomerDetail
              name={name}
              setName={setName}
              number={number}
              setNumber={handleNumberChange}
            />
          </Form>
        )}
        <Form>
          <ProductSelections
            facility={facility}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
            requestFromBookingModal={false}
          />

          {totalAmount > 0 ? (
            <>
              <Divider horizontal>
                <span style={{ color: colors.blue["400"] }}>Payment</span>
              </Divider>
              <div style={{ paddingBottom: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Total Amount to be paid:{" "}
                </span>
                <span>Rs.{totalAmount}/-</span>
              </div>
              <Payment
                paymentArray={paymentArray}
                setPaymentArray={setPaymentArray}
              />
              <div style={{ paddingTop: "10px" }}>
                <span style={{ fontWeight: "bold" }}>Total Payment: </span>
                <span>Rs.{totalPayment}/-</span>
              </div>
            </>
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Footer
          onConfirm={onSaveClicked}
          setType={setType}
          setOpen={setOpen}
          disabled={
            // totalAmount === 0 ||
            selectedItems.filter(item => item.quantityAdded > 0).length !== selectedItems.length || // to ensure quantity is added
            totalAmount !== totalPayment ||
            (!booking && (!name || !number))||
            disableButton
          }
        />
      </Modal.Actions>
    </>
 );
};

export default RentSaleProducts;
