import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";

export const getAssetLink = () => {
    const url = getURLWithQueryParams(
        API_URLS.ASSET_LINK
    );

    return tokenAxiosInstance.get(url,{});
}

export const getAssetAppleLink = () => {
    console.log('URL is called');
    const url = getURLWithQueryParams(
        API_URLS.ASSET_APPLE_LINK
    );
    return tokenAxiosInstance.get(url,{});
}


