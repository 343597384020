import React, { useContext, useState } from 'react';
import { colors } from "@material-ui/core";
import { DatePicker } from '@material-ui/pickers';
import { DateTimePicker } from '@material-ui/pickers';
import {
    Button,
    Dropdown,
    Form,
    Checkbox,
    Divider
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { addTrial } from "../../../apiclients/AcademyClient";
import { DAYS_OF_WEEK, SKILLS, LEAD_SOURCES } from '../../../utils/constants';
import { getDataForExistingParent } from '../../../apiclients/AcademyClient';

const AddTrial = (props) => {
    const { showModal, setReload, setMessage, isLoading, selectedSport } = props;
    console.log('selected sport ', selectedSport);
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const sportId = selectedSport.key;

    const [mobile, setMobile] = useState();
    const [parentName, setParentName] = useState();
    const [studentName, setStudentName] = useState();
    const [email, setEmail] = useState();
    const [dateOfBirth, setDateOfBirth] = useState(null);
    const [skill, setSkill] = useState();
    const [trialDate, setTrialDate] = useState(null);
    const [source, setSource] = useState();
    const [remarks, setRemarks] = useState();
    const [isOtherSelected, setIsOtherSelected] = useState(false);
    const [disableStudentDetails, setDisableStudentDetails] = useState(false);
    const [newParent, setNewParent] = useState(true);
    const [showMultipleStudents, setShowMultipleStudents] = useState(false);
    const [listOfStudents, setListOfStudents] = useState([]);
    const [ddstudentName, setDdStudentName] = useState();
    const [disableParentNameAndEmail, setDisableParentNameAndEmail] = useState(false);


    const isSubmitAllowed = mobile && skill && parentName && studentName && email && dateOfBirth && trialDate  && source && remarks;
    const showTrialData = mobile && parentName && studentName
        && email && dateOfBirth != null;

    const fetchParentData = (mobiles) => {
        if (mobiles && mobiles.length === 10) {

            setMobile(mobiles);
            getDataForExistingParent(facilityId, arenaId, sportId, mobiles)
                .then((response) => {
                    console.log('the response is ', response.data);
                    let data = response.data;
                    data.push({ key: data.length, value: 'Others', text: 'Others' })
                    if (response.data.length > 1) {
                        console.log('the response is ', response.data);
                        setListOfStudents(data);
                        setNewParent(false);
                        setShowMultipleStudents(true);
                    }
                });
        }
    }

    const changeStudent = (event, { value, options }) => {
        if (value === 'Others') {
            setIsOtherSelected(true);
            console.log('the value is ', value);
            console.log('the options is ', options);
            const parentName = options[0].parentName;
            const email = options[0].email;
            setDdStudentName(value);
            setStudentName(null);
            setParentName(parentName);
            setEmail(email)
            setDateOfBirth(null);
            setDisableParentNameAndEmail(true);
            setDisableStudentDetails(false);
        } else {
            const studentName = options.filter(x => x.value === value)[0].text;
            const email = options.filter(x => x.value === value)[0].email;
            const parentName = options.filter(x => x.value === value)[0].parentName;
            const dob = new Date(options.filter(x => x.value === value)[0].dateOfBirth.seconds * 1000);
            setDdStudentName(value);
            setStudentName(studentName);
            setEmail(email);
            setParentName(parentName);
            setDateOfBirth(dob);
            setIsOtherSelected(false);
            setDisableParentNameAndEmail(true);
            setDisableStudentDetails(true);
        }

    };

    const changeSkill = (event, { value }) => {
        setSkill(value);
    };

    const changeSource = (event, { value }) => {
        setSource(value);
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Mobile Number</label>
                        <input
                            style={{ marginRight: "6px" }}
                            disabled={disableStudentDetails}
                            type='number'
                            value={mobile}
                            pattern='[0-9]{10}$*'
                            placeholder='9911XXXXXX'
                            // onFocus={}
                            onChange={(evt) => fetchParentData(evt.target.value)}

                        />
                    </Form.Field>
                    {showMultipleStudents ?
                        <Form.Field  >

                            <label>Select Students</label>
                            <Dropdown
                                onChange={changeStudent}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={ddstudentName}
                                options={listOfStudents}
                                style={{ marginRight: "30px" }}
                            />
                        </Form.Field>
                        :
                        <Form.Field  >
                            <label>Student Name</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='text'
                                value={studentName}
                                disabled={disableStudentDetails}
                                placeholder='Shaurya Jain'
                                onChange={(evt) => setStudentName(evt.target.value)}

                            />
                        </Form.Field>

                    }
                    {isOtherSelected ? <Form.Field  >
                        <label>Student Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={studentName}
                            placeholder='Shaurya Jain'
                            onChange={(evt) => setStudentName(evt.target.value)}

                        />
                    </Form.Field> : null}


                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Parent Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={parentName}
                            placeholder='Vishal Jain'
                            onChange={(evt) => setParentName(evt.target.value)}
                            disabled={disableParentNameAndEmail}

                        />
                    </Form.Field>

                    <Form.Field  >
                        <label>Email</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='email'
                            value={email}
                            placeholder='shaurya@gmail.com'
                            onChange={(evt) => setEmail(evt.target.value)}

                            disabled={disableParentNameAndEmail}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Date of Birth</label>
                        <DatePicker
                            autoOk
                            disableFuture
                            disabled={disableStudentDetails}
                            variant='inline'
                            value={dateOfBirth}
                            placeholder='Select Date...'
                            onChange={setDateOfBirth}
                            format='DD MMM yyyy'
                        />
                    </Form.Field>


                </Form.Group>
                {showTrialData ?
                    <>
                        <Divider horizontal>
                            <span style={{ color: colors.blue["400"] }}>Trial Details</span>
                        </Divider>
                        <Form.Group widths='equal'>
                            <Form.Field  >
                                <label>Trial Date & Time</label>
                                <DateTimePicker
                                    autoOk
                                    disablePast
                                    variant='inline'
                                    value={trialDate}
                                    placeholder='Select Date...'
                                    onChange={setTrialDate}
                                    format='DD MMM yyyy h:mm'
                                />
                            </Form.Field>
                            <Form.Field  >

<label>Skills</label>
<Dropdown
    onChange={changeSkill}
    placeholder='Select...'
    openOnFocus
    selection
    value={skill}
    options={SKILLS}
    style={{ marginRight: "30px" }}
/>
</Form.Field>
<Form.Field  >

<label>Source</label>
<Dropdown
    onChange={changeSource}
    placeholder='Select...'
    openOnFocus
    selection
    value={source}
    options={LEAD_SOURCES}
    style={{ marginRight: "30px" }}
/>
</Form.Field>
                        </Form.Group>

                        <Form.Group widths='equal'>
                        <Form.Field  >
                        <label>Remarks</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={remarks}
                            placeholder='More detail about the lead'
                            onChange={(evt) => setRemarks(evt.target.value)}

                        />
                    </Form.Field>
                        </Form.Group>
                    </>
                    : null}

            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={addATrial}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addATrial = () => {
        const createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
        showModal(false);
        isLoading(true);
        const postBody = {
            facilityId, arenaId, sportId, mobile, parentName, studentName, email, dateOfBirth, trialDate, remarks,
            arenaName :  context.selectedArena.arenaName, sportName : selectedSport.text,skill,
            createdBy: {
                userName: context.user.userName,
                userId: context.user.userId,
            },newCustomer : newParent, source
        };
        console.log('post body is ', postBody);

        addTrial(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Trial Added",
                    message: "Trial has been added successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });
    }
    return (
        <>
            <ModalPortal
                title='Add A Trial'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddTrial;