import { colors } from "@material-ui/core";
import React, { useEffect, useContext, useState } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { getBulkBookingDetail } from "../../apiclients/BulkBookingApiClient";
import { AppContext } from "../../AppContext";

import "../dashboard/style.scss";
import GenericLoader from "../generic/GenericLoader";
import { convertFirstoreDate, getKeyValue } from "../../utils/helperFunctions";
import moment from "moment";

const COURTS = {
  c1: "COURT 1",
  c2: "COURT 2",
  c3: "COURT 3",
  c4: "COURT 4",
  c5: "COURT 5",
  c6: "COURT 6",
  c7: "COURT 7",
};

const TIMINGS = {
  t000: "6:00 - 7:00",
  t001: "7:00 - 8:00",
  t002: "8:00 - 9:00",
  t003: "9:00 - 10:00",
  t004: "10:00 - 11:00",
  t005: "11:00 - 12:00",
  t006: "12:00 - 13:00",
  t007: "13:00 - 14:00",
  t008: "14:00 - 15:00",
  t009: "15:00 - 16:00",
  t010: "16:00 - 17:00",
  t011: "17:00 - 18:00",
  t012: "18:00 - 19:00",
  t013: "19:00 - 20:00",
  t014: "20:00 - 21:00",
  t015: "21:00 - 22:00",
  t016: "22:00 - 23:00",
};

const BOOKED_SLOT_SHOW_LIMIT = 3;

const BulkBookingDetail = (props) => {
  const { history } = props;
  const context = useContext(AppContext);

  const [data, setData] = useState({
    bulkBookingDetails: {},
    bookingPeriodDetails: {},
  });
  const [bookedSlots, setBookedSlots] = useState([]);
  const [loader, setLoader] = useState(false);
  const updateBookedSlots = () => {
    const slots = getKeyValue(data, "bookingPeriodDetails.bookingList");
    const move = bookedSlots.length < allUnavailableSlots?.length;
    setBookedSlots(
      [...slots].splice(
        0,
        move
          ? bookedSlots.length + BOOKED_SLOT_SHOW_LIMIT
          : BOOKED_SLOT_SHOW_LIMIT
      )
    );
  };
  useEffect(() => {
    setLoader(true);
    getBulkBookingDetail(
      context.selectedFacility,
      context.selectedArena.arenaId,
      props.match.params?.id
    ).then((response) => {
      const slots = getKeyValue(
        response.data,
        "bookingPeriodDetails.bookingList"
      );
      setBookedSlots([...slots].splice(0, BOOKED_SLOT_SHOW_LIMIT));
      setData(response.data);
      setLoader(false);
    });
  }, [context.selectedFacility, context.selectedArena]);

  const allUnavailableSlots = getKeyValue(
    data,
    "bookingPeriodDetails.bookingList"
  );
  const currentSport =
    context.facilitySports &&
    context.facilitySports.find(
      (x) => x.sportId === data.bulkBookingDetails?.sportId
    );

  const members = getKeyValue(data, "bulkBookingDetails.members") || [];
  const selectedCourts = getKeyValue(data, "bulkBookingDetails.courts") || [];

  return (
    <div style={{ padding: "16px" }}>
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>
              Bulk Booking Detail:{" "}
              <span style={{ fontWeight: "normal" }}>
                {props.match.params?.id}
              </span>
            </span>
          </div>
        </div>
      </div>
      {loader ? (
        <GenericLoader />
      ) : (
        <>
          <div
            style={{
              textDecoration: "underline",
              color: colors.blue[500],
              cursor: "pointer",
            }}
            onClick={() => history.goBack()}
          >
            {"< Back"}
          </div>
          <Segment.Group style={{ padding: "12px" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>SportId:</span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{currentSport?.sportName}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Dates: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {data.bulkBookingDetails.startDate +
                      "  to  " +
                      data.bulkBookingDetails.endDate}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Courts Selected: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {selectedCourts.map((c) => c.courtName).join(", ")}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Timings: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{data.bulkBookingDetails.slotDuration}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Days: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{data.bulkBookingDetails.days}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Total Amount: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>Rs.{data.bulkBookingDetails.totalAmount}/-</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Discount: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{data.bulkBookingDetails.discount}%</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Amount Paid: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>Rs.{data.bulkBookingDetails.amountPaid}/-</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Amount Due: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span
                    style={
                      data.bulkBookingDetails.amountDue > 0
                        ? { color: colors.red["500"] }
                        : {}
                    }
                  >
                    Rs.{data.bulkBookingDetails.amountDue}/-
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Members: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {members.map((x) => `${x.name} (${x.number})`).join(", ")}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Booked Slots: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <div>
                    {bookedSlots
                      ? bookedSlots.map((x) => {
                          return (
                            <>
                              <div>
                                Date: <b>{x.bookingDate}</b> booked for:{" "}
                              </div>
                              <div>
                                {x.details.map((y) => {
                                  return (
                                    <div>
                                      <b>Time:</b> {TIMINGS[y.slotNo]}
                                      {"    "}
                                      <b>Courts:</b>{" "}
                                      {y?.court
                                        .map((c) => COURTS[c.courtNo])
                                        .join(", ")}
                                    </div>
                                  );
                                })}
                              </div>
                            </>
                          );
                        })
                      : null}
                    <div
                      style={{
                        color: colors.blue["500"],
                        textDecoration: "underline",
                      }}
                      onClick={updateBookedSlots}
                    >
                      {bookedSlots.length < allUnavailableSlots?.length
                        ? "+ More"
                        : "- Less"}
                    </div>
                  </div>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Payment History: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {data?.bulkBookingDetails?.history
                      ? data?.bulkBookingDetails?.history.map((item, indx) => {
                          return (
                            <div>
                              <div>
                                <b>Payment Entry: {indx + 1}</b>
                              </div>
                              <div>
                                Transaction:{" "}
                                {item.transactionId +
                                  " ( " +
                                  //convertFirstoreDate(item.creationDate) +
                                  moment(
                                    item.creationDate,
                                    "YYYY-MM-DD"
                                  ).format("MM-DD-YYYY") +
                                  " )"}
                              </div>
                              <div>Amount Paid: {item.amountPaid}</div>
                            </div>
                          );
                        })
                      : null}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment.Group>
        </>
      )}
    </div>
  );
};

export default BulkBookingDetail;
