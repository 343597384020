import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";
import { BOOKING_SOURCES } from "./../utils/constants";

export const getPackages = (facilityId, arenaId, sport, isFlexiPackage) => {
  const url = getURLWithQueryParams(
    API_URLS.GET_PACKAGES,
    { sport,isFlexiPackage },
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};

export const getCancelAmountForMembership = (
  facilityId,
  arenaId,
  membershipId
) => {
  const url = getURLWithQueryParams(API_URLS.CANCEL_AMOUNT_MEMBERSHIP, null, {
    facilityId,
    arenaId,
    membershipId,
  });
  return tokenAxiosInstance.get(url);
};

export const getMemberships = (facilityId, arenaId, sport, isIndividual) => {
  console.log('the data in url is ', isIndividual);
  const url = getURLWithQueryParams(
    API_URLS.GET_MEMBERSHIPS,
    { sport, isIndividual },
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};

export const getFlexiMembersData = (facilityId, arenaId, sport, isActive) => {
 
  const url = getURLWithQueryParams(
    API_URLS.GET_FLEXI_MEMBERSHIPS,
    { sport, isActive },
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};

export const createNewPackage = (facilityId, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.CREATE_PACKAGE, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const addFlexiMembershipPackage = (facilityId, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.CREATE_FLEXI_PACKAGE, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, postBody);
};


export const deactivatePackage = (facilityId, arenaId, sport, packageId) => {
  const url = getURLWithQueryParams(
    API_URLS.DELETE_PACKAGE,
    { packageId, sport },
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.delete(url);
};

export const deactivateFlexiPackage = (facilityId, arenaId, sport, packageId) => {
  const url = getURLWithQueryParams(
    API_URLS.DELETE_FLEXI_PACKAGE,
    { packageId, sport },
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.delete(url);
};

export const updateMembershipPayment = (
  facilityId,
  arenaId,
  membershipId,
  paymentArray,
  arenaData
) => {
  const url = getURLWithQueryParams(API_URLS.UPDATE_PAYMENT_MEMBERSHIP, null, {
    facilityId,
    arenaId,
    membershipId
  });
  return tokenAxiosInstance.post(url, {
    paymentInfo: JSON.stringify(paymentArray),
    arenaData
  });
};

export const cancelMembership = (
  facilityId,
  arenaId,
  id,
  sportId,
  cancellationAmount
) => {
  const url = getURLWithQueryParams(API_URLS.CANCEL_MEMBERSHIP, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, {
    membershipId: id,
    sportId,
    cancellationAmount,
  });
};

export const deactivateFlexiMembership = (facilityId, arenaId, sport, memberId) => {
  const url = getURLWithQueryParams(API_URLS.DEACTIVATE_FLEXI_MEMBERSHIP, null, {
      facilityId,
      arenaId,
    } );
  return tokenAxiosInstance.post(url, 
    { memberId, sport });
};

export const checkAvailabilityForPackage = (
  facilityId,
  arenaId,
  queryParams
) => {
  /*
packageId: WeekPlan
slotNo: t001
courtNo: c1
startDate: 01-01-2021
sport: SP001
*/
  const url = getURLWithQueryParams(
    API_URLS.CHECK_AVAILABILITY_MEMBERSHIP,
    queryParams,
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};

export const createMembership = (facilityId, arenaId, postBody) => {
  const body = { ...postBody, source: BOOKING_SOURCES.PLAY_ALL_WEB };
  const url = getURLWithQueryParams(API_URLS.CREATE_MEMBERSHIP, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, body);
};


export const addFlexiMembership = (facilityId, arenaId, postBody) => {
  const body = { ...postBody, source: BOOKING_SOURCES.PLAY_ALL_WEB };
  const url = getURLWithQueryParams(API_URLS.ADD_FLEXI_MEMBERSHIP, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, body);
};
