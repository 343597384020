import React, { useContext, useState } from "react";
import { Button, Form } from "semantic-ui-react";
import { addFlexiMembershipPackage } from "../../../apiclients/MembershipApiClient";
import { AppContext } from "../../../AppContext";
import ModalPortal from '../../generic/ModalPortal';

const AddFlexiPackage = (props) => {
    const { showModal, setReload, setMessage, isLoading, sport } = props;
    
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const user = context.user;
    const [numberOfMonth, setNumberOfMonth] = useState();
    const [fees, setFees] = useState();
    const [usagePerDay, setUsagePerDay] = useState(1);
    const [hoursBeforeBooking, setHoursBeforeBooking] = useState();

    const isSubmitAllowed = numberOfMonth && fees > 0 && usagePerDay && hoursBeforeBooking;


    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >

                        <label>Number of Month</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={numberOfMonth}
                            placeholder='Duration of the package'
                            onChange={(evt) => setNumberOfMonth(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Fees</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={fees}
                            placeholder='In Rs. inclusive of taxes'
                            onChange={(evt) => setFees(evt.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >

                        <label>Slots Allowed Per day</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={usagePerDay}
                            placeholder='Number of slots allowed'
                            onChange={(evt) => setUsagePerDay(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>Booking Window   </label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={hoursBeforeBooking}
                            placeholder='No. of hours before booking, since booking is enabled'
                            onChange={(evt) => setHoursBeforeBooking(evt.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
                <Button disabled={!isSubmitAllowed} primary onClick={addFlexiPackage}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addFlexiPackage = () => {
        showModal(false);
        isLoading(true);
        const createdBy = {
            userName: user.userName,
            userId: user.userId,
        };
        const packageDetails = {
            numberOfMonth:  Number(numberOfMonth), 
            fees : Number(fees),
            usagePerDay : Number(usagePerDay),
            hoursBeforeBooking : Number(hoursBeforeBooking),
            createdBy
        };
        let postBody = { 
            facilityId, arenaId, sportId: sport.value,packageDetails
        };
        addFlexiMembershipPackage(facilityId, arenaId,postBody).then((response)=>{
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Package Added",
                    message: "Flexi Membership Package has been added successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }

    return (
        <>
            <ModalPortal
                title={'Add Flexi Membership Package for ' + sport.text}
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>
    )
};

export default AddFlexiPackage;