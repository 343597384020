import React from "react";
import { Button, Table } from "semantic-ui-react";
import CustomModal from "../generic/CustomModal";
import {
  convertFirstoreDate,
  getSourceLabel,
} from "../../utils/helperFunctions";
import { colors } from "@material-ui/core";
import "../../App.css";
import { NavLink } from "react-router-dom";
const Footer = ({ setClose }) => {
  return (
    <Button className={"ui button booked-footer"} onClick={setClose}>
      Close
    </Button>
  );
};
const Detail = ({ data }) => {
  return (
    <div>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <div>ID</div>
            </Table.Cell>
            <Table.Cell>
              <div>
                {data.id}
                <span
                  style={{
                    color: colors.red["500"],
                    textTransform: "uppercase",
                  }}
                >
                  {data.bookingStatus && data.bookingStatus !== "booked"
                    ? "  ( " + data.bookingStatus + " )"
                    : ""}
                </span>
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div>Arena</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.arenaName}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div>Customer Name</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.userName}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div>Customer Number</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.userMobileNumber}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div>Transaction Type</div>
            </Table.Cell>
            <Table.Cell>
              <div style={{ textTransform: "capitalize" }}>
                {data.type} {data.subtype ? ", " + data.subtype : ""}
              </div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div>Booking Date</div>
            </Table.Cell>
            <Table.Cell>
              {data.bookingDate ? (
                <div>{convertFirstoreDate(data.bookingDate)}</div>
              ) : (
                <div>{convertFirstoreDate(data.createdDate)}</div>
              )}
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div>Booked For</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.sport?.sportName}</div>
            </Table.Cell>
          </Table.Row>
          {data.court ? (
            <Table.Row>
              <Table.Cell>
                <div>Slot Booked</div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  {data.court.courtName},{" "}
                  {data.slot
                    ? data.slot.interval?.start +
                      " - " +
                      data.slot.interval?.end
                    : ""}
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          <Table.Row>
            <Table.Cell>
              <div>Booking Source</div>
            </Table.Cell>
            <Table.Cell>
              <div>{getSourceLabel(data.source)}</div>
            </Table.Cell>
          </Table.Row>
          {data.bulkBookingID ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Bulk Booking ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>
                  <NavLink
                    style={{
                      textDecoration: "underline",
                      color: colors.blue[500],
                    }}
                    to={`/bulkBooking/${data.bulkBookingID}`}
                  >
                    {data.bulkBookingID}
                  </NavLink>
                </div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.cancelled ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Reference ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>{data.referenceID}</div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.rescheduledFrom ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Reference ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>{data.rescheduledFrom.id}</div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.rescheduleTo ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Reference ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>{data.rescheduleTo.id}</div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          {data.extendFrom ? (
            <Table.Row>
              <Table.Cell>
                <div class='bold'>Reference ID</div>
              </Table.Cell>
              <Table.Cell>
                <div>{data.extendFrom.id}</div>
              </Table.Cell>
            </Table.Row>
          ) : null}
          <Table.Row>
            <Table.Cell>
              <div>Slot Price</div>
            </Table.Cell>
            <Table.Cell>
              <div>Rs. {data.courtBookingAmount}/-</div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
    </div>
  );
};
const BookingDetail = ({ setShowDetails, selectedData }) => {
  const setClose = () => {
    setShowDetails(false);
  };
  return (
    <CustomModal
      header={"Booking Detail"}
      content={<Detail data={selectedData} />}
      footer={<Footer setClose={setClose} />}
      setOpen={setClose}
      open={!!selectedData}
    />
  );
};

export default BookingDetail;
