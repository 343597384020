import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";

export const getBlockings = (facilityId, arenaId, type) => {
  const url = getURLWithQueryParams(
    API_URLS.GET_BLOCKING,
    { type },
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};


export const createBlocking = (facilityId, arenaId, postBody) => {
  const body = { ...postBody };
  const url = getURLWithQueryParams(API_URLS.CREATE_BLOCKING, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, body);
};

export const releaseBlocking = (facilityId, arenaId, postBody) => {
  const body = { ...postBody };
  const url = getURLWithQueryParams(API_URLS.RELEASE_BLOCKING, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, body);
};


export const cancelBlocking = (facilityId, arenaId, id, sportId) => {
  const url = getURLWithQueryParams(API_URLS.CANCEL_BLOCKING, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, {
    blockingId: id,
    sportId,
  });
};

export const getBlockingDetail = (facilityId, arenaId, blockingId) => {
  const url = getURLWithQueryParams(API_URLS.GET_BLOCKING_DETAIL, null, {
    facilityId,
    arenaId,
    id: blockingId,
  });
  return tokenAxiosInstance.get(url);
};

