import React, { useEffect, useContext, useState, useRef } from "react";

import { colors } from "@material-ui/core";
import { Button, Dropdown, Icon, Tab, Table } from "semantic-ui-react";
import "../dashboard/style.scss";

import {
  deactivatePackage,
  getPackages,
} from "../../apiclients/MembershipApiClient";
import { AppContext } from "../../AppContext";
import GenericLoader from "../generic/GenericLoader";
import { getPnPSportsOfArena } from "../../apiclients/BookingApiClient";

const BookingDataTable = ({
  packages,
  setReload,
  sport,
  history,
  isIndividualBooking,
}) => {
  const context = useContext(AppContext);

  const deletePackage = (packageId) => {
    deactivatePackage(
      context.selectedFacility,
      context.selectedArena.arenaId,
      sport.key,
      packageId
    ).then(() => {
      setReload(true);
    });
  };
  return (
    <>
      <div style={{ flex: 1, display: "flex", justifyContent: "flex-end" }}>
        <Button
          primary
          style={{
            padding: "8px",
            fontSize: "12px",
          }}
          onClick={() =>
            history.push({
              pathname: `/extranet/enrolledMembership/${sport.key}`,
              state: { isIndividualBooking: isIndividualBooking },
            })
          }
        >
          Show Enrolled User Details
        </Button>
      </div>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Package Name</Table.HeaderCell>
            <Table.HeaderCell>Number of Days</Table.HeaderCell>
            <Table.HeaderCell>Price</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {packages.map((x) => {
            return (
              <Table.Row>
                <Table.Cell>{x.name}</Table.Cell>
                <Table.Cell>{x.numberOfDays}</Table.Cell>
                <Table.Cell>Rs.{x.packagePrice}/-</Table.Cell>
                <Table.Cell
                  style={{
                    flex: 1,
                    justifyContent: "center",
                    flexDirection: "column",
                    display: "flex",
                  }}
                >
                  <Button
                    style={{
                      padding: "8px",
                      fontSize: "12px",
                      marginBottom: "8px",
                      backgroundColor: colors.orange["800"],
                      color: "#fff",
                    }}
                    onClick={() =>
                      history.push({
                        pathname: "/extranet/addmembership",
                        state: { package: x, sport: sport },
                      })
                    }
                  >
                    Enroll
                  </Button>

                  <Button
                    style={{
                      padding: "8px",
                      fontSize: "12px",
                      marginBottom: "8px",
                    }}
                    onClick={() => deletePackage(x.id)}
                  >
                    Delete
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

const MembershipMain = (props) => {
  const { history } = props;
  const context = useContext(AppContext);
  const [sport, setSport] = useState();
  const [reload, setReload] = useState(true);
  const [packages, setPackages] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const sports = useRef();

  useEffect(() => {
    if (!reload) {
      setReload(true);
    }
  }, [context.selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getPnPSportsOfArena(
      context.selectedFacility,
      context.selectedArena.arenaId
    ).then((response) => {
      const sportsResponse = response.data;
      const initialSport = sportsResponse[0];
      setSport({
        key: initialSport.sportId,
        value: initialSport.sportId,
        text: initialSport.sportName,
      });
      sports.current = sportsResponse.map((x, index) => ({
        key: x.sportId,
        value: x.sportId,
        text: x.sportName,
      }));
    });
  }, [context.selectedArena.arenaId, context.selectedFacility]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sport && reload) {
      getPackages(
        context.selectedFacility,
        context.selectedArena.arenaId,
        sport.key
      ).then((res) => {
        const keys = Object.keys(res.data.packageDetails);
        const p = keys.map((x) => res.data.packageDetails[x]);
        setPackages(p);
        setReload(false);
      });
    }
  }, [sport, reload]);// eslint-disable-line react-hooks/exhaustive-deps

  const individualPackages = packages.filter((x) => x.individualPackage);
  const groupPackages = packages.filter((x) => !x.individualPackage);
  const changeSport = (event, { value }) => {
    const data = sports.current.find((facility) => facility.value === value);
    setSport(data);
    setReload(true);
  };
  const panes = [
    {
      menuItem: "Individual Packages",
      render: () => (
        <Tab.Pane className={"tabBox"}>
          <BookingDataTable
            packages={individualPackages}
            setReload={setReload}
            sport={sport}
            history={history}
            isIndividualBooking={true}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Group Packages",
      render: () => (
        <Tab.Pane className={"tabBox"}>
          <BookingDataTable
            sport={sport}
            packages={groupPackages}
            setReload={setReload}
            history={history}
            isIndividualBooking={false}
          />
        </Tab.Pane>
      ),
    },
  ];
  return (
    <div style={{ padding: "16px" }}>
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>Fixed Membership</span>
          </div>
          <div style={{ alignItems: "center" }}>
            <div style={{ fontSize: "14px" }}>
              <Dropdown
                onChange={changeSport}
                placeholder='Select...'
                openOnFocus
                selection
                value={sport && sport.value}
                options={sports.current}
              />
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => history.push("/extranet/addpackage")}
                name={"Add Package"}
              >
                <Icon name={"add"} />
                Add Package
              </Button>
            </div>
          </div>
        </div>
      </div>
      {reload ? (
        <GenericLoader />
      ) : (
        <div style={{ paddingTop: "16px" }}>
          <Tab
            activeIndex={activeIndex}
            onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
            renderActiveOnly={true}
            menu={{
              color: "orange",
              pointing: true,
            }}
            panes={panes}
          />
        </div>
      )}
    </div>
  );
};

export default MembershipMain;
