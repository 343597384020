import React from 'react';
import { Button, Table, Divider, TableCell } from "semantic-ui-react";
import ModalPortal from '../../generic/ModalPortal';
import { colors } from '@material-ui/core';
import { PRODUCT_SUBTYPES } from '../../../utils/constants';
import CustomModal from '../../generic/CustomModal';
const StockHistory = (props) => {
    const { showModal, selectedProduct, stockData } = props;
    let closingStock = selectedProduct.productQuantity;
    const modalContent = (
        <div>
            <Table celled padded>
                <Table.Body>
                    <Table.Row>
                        <Table.Cell>
                            <div><b>Product Name</b></div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>{selectedProduct.productName}</div>
                        </Table.Cell>
                        <Table.Cell>
                            <div><b>Company Name</b></div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>
                                {selectedProduct.companyName}
                            </div>
                        </Table.Cell>
                        <Table.Cell>
                            <div><b>Closing Stock</b></div>
                        </Table.Cell>
                        <Table.Cell>
                            <div>
                                {closingStock}
                            </div>
                        </Table.Cell>

                    </Table.Row>
                </Table.Body>
            </Table>
            <Divider horizontal>
                <span style={{ color: colors.blue["400"] }}>Stock History</span>
            </Divider>

            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>
                            Date
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Time
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Type
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Quantity
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Opening Stock
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Remarks
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Created By
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {stockData.history.map((record, i) => {
                        if (record.type === PRODUCT_SUBTYPES.add) {
                            closingStock = closingStock - record.quantity
                        } else if (
                            record.type === PRODUCT_SUBTYPES.assingToAcademy ||
                            record.type === PRODUCT_SUBTYPES.discard ||
                            record.type === PRODUCT_SUBTYPES.sell ||
                            record.type === PRODUCT_SUBTYPES.complimentary
                        ) {
                            closingStock = closingStock + record.quantity
                        }

                        return (<Table.Row key={i}>
                            <Table.Cell>
                                {new Date(record.createdAt).toLocaleDateString()}
                            </Table.Cell>
                            <Table.Cell>
                                {new Date(record.createdAt).toLocaleTimeString()}
                            </Table.Cell>
                            <Table.Cell>
                                {record.type}
                            </Table.Cell>
                            <Table.Cell>
                                {record.quantity}
                            </Table.Cell>
                            <Table.Cell>
                                {closingStock}
                            </Table.Cell>
                            <Table.Cell>
                                {record.remarks}
                            </Table.Cell>
                            <Table.Cell>
                                {record.createdBy ? record.createdBy.userName : null}
                            </Table.Cell>
                        </Table.Row>);
                    })}
                </Table.Body>
            </Table>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            </React.Fragment>
        );
    };
    return (
        <>
        
            <CustomModal
                header={'Stock History'}
                content={modalContent}
                footer={renderAction()}
                setOpen={showModal(true)}
                open={!!modalContent}
            />
        </>
    )
}

export default StockHistory;