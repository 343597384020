import React from "react";
import { Accordion, Icon } from "semantic-ui-react";
import { useState } from "react";
import UpdateSlotPricing from "./UpdateSlotPricing";

const UpdatePricing = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (e, titleProps) => {
    const { index } = titleProps;
    const newIndex = activeIndex === index ? -1 : index;

    setActiveIndex(newIndex);
  };
  return (
    <div style={{ padding: "16px" }}>
      <div className='dashboard' style={{ marginBottom: "16px" }}>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>Update pricing</span>
          </div>
        </div>
      </div>

      <Accordion fluid styled>
        <Accordion.Title
          active={activeIndex === 0}
          index={0}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          Booking Slot Price
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 0}>
          <UpdateSlotPricing />
        </Accordion.Content>

        <Accordion.Title
          active={activeIndex === 1}
          index={1}
          onClick={handleClick}
        >
          <Icon name='dropdown' />
          Holiday Pricing
        </Accordion.Title>
        <Accordion.Content active={activeIndex === 1}>
          <p>
            There are many breeds of dogs. Each breed varies in size and
            temperament. Owners often select a breed of dog that they find to be
            compatible with their own lifestyle and desires from a companion.
          </p>
        </Accordion.Content>
      </Accordion>
    </div>
  );
};

export default UpdatePricing;
