import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import { Helmet } from "react-helmet";
import { Fade, Zoom, } from 'react-reveal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>Play Pickle-Ball in your Favorite arena</h4>
        <h1 data-aos='fade-up'>
          We Are Redefining Sports. Experience The Difference
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> Online Booking available</h5>
        </div>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> Best in Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#football'></use>
          </svg>
          <h5> All equipments complimentary </h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>



      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 >Noida 73</h2>
                <p>With 4 Best-in-Class <b>Pickle Ball Courts</b>, PlayAll is one of the best <b>Pickle Ball Arena</b> in Noida. With Amenities like Ample Parking, Shower, and Sports Shop, we ensure that your Pickle Ball experience is hassel free and awesome. </p>

              </Zoom>

              <span class='address'>
                Near Noida Pet Clinic, Sector 73, Noida, Uttar Pradesh 201301 | Contact - 01140849901
              </span>
              <div class='btarea mb-3'>


                <a href='https://goo.gl/maps/MHyikEmmcw22' class='btwhite1'
                  target="_blank">

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>


              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/noida_73_pickle_ball.png' alt='pickleBall near me' />

                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec '>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>

                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>
                    <img src='images/gallery/coming_soon_box_cricket.png' alt='pickleball in jaipur' />
                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll Sports Arena</h4>
                <h2 >Orbit Mall, Civil Lines, Jaipur</h2>
                <p>With 3 Best-in-Class <b>Pickle Ball Courts</b>, PlayAll is one of the best <b>Pickle Ball Arena</b> in Jaipur. With Amenities like Ample Parking, Shower, and Sports Shop, we ensure that your Pickle Ball experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - Civil Lines Metro Station, Orbit Mall, Ajmer Rd, Madrampur, Civil Lines, Jaipur, Rajasthan 302006 | Contact - 	011-411-86654
              </span>
              <div class='btarea mb-3'>


                <a href='https://goo.gl/maps/4VAo7j8ZzX9rBojQ9' class='btwhite1'
                  target="_blank">

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
          </div>
        </div>
      </section>
      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 >Faridabad</h2>
                <p>With 2 Best-in-Class <b>Pickle Ball Courts</b>, PlayAll is one of the best <b>Pickle Ball Arena</b> in Faridabad. With Amenities like Ample Parking, Shower, and Sports Shop, we ensure that your Pickle Ball experience is hassel free and awesome. </p>

              </Zoom>
              <span class='address'>
                Address - Near Sai Mandir, Sector 86, Faridabad, Haryana 121002| Contact - 01141187780
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://g.page/play-all-sports-faridabad?share'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/faridabad_86_pickle_ball.png' alt='pickleball court in faridabad' />

                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec '>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>

                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>
                    <img src='images/gallery/NGF_box_cricket_2.png' alt='cricket turf' />
                    <img src='images/gallery/NGF_box_cricket_1.png' alt='box-cricket turf' />
                    <img src='images/gallery/NGF_box_cricket_3.png' alt='cricket ground near me' />
                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll PickleBall Arena</h4>
                <h2 >New Green Field School, Alaknanda Delhi</h2>
                <p>With 3 Best-in-Class <b>Pickle Ball Courts</b>, PlayAll is one of the best <b>Pickle Ball Arena</b> in South Delhi. With Amenities like Ample Parking, Shower, and Sports Shop, we ensure that your Pickle Ball experience is hassel free and awesome. </p>
              </Zoom>
              <span class='address'>
                Address - New Green Field School, Alaknanda, New Delhi, Delhi| Contact - 011-411-87496
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://maps.app.goo.gl/a9iKRNwGpwH3xgreA'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>


            </div>
          </div>
        </div>
      </section>


    </main>
  </>
);

const PickleBall = () => {
  return (
    <>

      <Helmet>
        <title>Pickle Ball Courts</title>
        <meta name="description" content="Play all offers best Pickle Ball courts in Faridabad" />
      </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner pickleBallBg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"pickleBall"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default PickleBall;
