import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";
import { BOOKING_SOURCES } from "./../utils/constants";

export const getBulkBookings = (facilityId, arenaId, type) => {
  const url = getURLWithQueryParams(
    API_URLS.GET_BULK_BOOKING,
    { type },
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};

export const checkAvailabilityForBulkBookings = (
  facilityId,
  arenaId,
  queryParams
) => {

  const url = getURLWithQueryParams(
    API_URLS.CHECK_AVAILABILITY_BULK_BOOKING,
    queryParams,
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};

export const getBulkBookingDetail = (facilityId, arenaId, bulkBookingId) => {
  const url = getURLWithQueryParams(API_URLS.GET_BULK_BOOKING_DETAIL, null, {
    facilityId,
    arenaId,
    id: bulkBookingId,
  });
  return tokenAxiosInstance.get(url);
};

export const createBulkBooking = (facilityId, arenaId, postBody) => {
  const body = { ...postBody, source: BOOKING_SOURCES.PLAY_ALL_WEB };
  const url = getURLWithQueryParams(API_URLS.CREATE_BULK_BOOKING, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, body);
};

export const updateBulkBookingPayment = (
  facilityId,
  arenaId,
  id,
  paymentArray,
  createdBy,arenaData
) => {
  const url = getURLWithQueryParams(API_URLS.UPDATE_BULK_PAYMENT, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, {
    bulkBookingId: id,
    paymentInfo: JSON.stringify(paymentArray),
    createdBy,arenaData
  });
};

export const cancelBulkBooking = (facilityId, arenaId, id, sportId,cancellationAmount) => {
  const url = getURLWithQueryParams(API_URLS.CANCEL_BULK_BOOKING, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.post(url, {
    bulkBookingId: id,
    sportId,
    cancellationAmount,
  });
};

export const cancelBulkBookingAmount = (facilityId, arenaId, id) => {
  const url = getURLWithQueryParams(
    API_URLS.CANCEL_BULK_BOOKING_AMOUNT,
    { bulkBookingId: id },
    {
      facilityId,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};
