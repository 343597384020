import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import {Helmet} from "react-helmet";

import { Fade, Zoom } from 'react-reveal';
const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>If you are a School Owner/Decision Maker</h4>
        <h1 data-aos='fade-up'>
          And want to upgrade your sports infrastructure for free, Fill in this form{" "}
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>
    
  </>
);

const mainSection = (
  <>
    <main>
    <div className="form-container">
      <h2>Fill out our survey!</h2>
      <div className="iframe-container">
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSfLHaIM2lWtyGxoxKat_Z2mG3PKDoZfg4PNZ1WnqTvhIr0fiQ/viewform?embedded=true"
          frameBorder="0"
          marginHeight="0"
          marginWidth="0"
        >
          Loading…
        </iframe>
      </div>
    </div>
    </main>{" "}
  </>
);

const SchoolPage = () => {
  return (
    <>

<Helmet>
            <title>Sports Infrastructure in school</title>
            <meta name="description" content="Play all develop turf in school, be it astro turf or articial turf playAll install it at zero cost to school." />
        </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner schoolbg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"consultancy"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default SchoolPage;
