import React, { useContext, useState } from 'react';
import {
    Button,
    Form,
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { updatePriceOfProducts } from '../../../apiclients/ProductApiClient';
const UpdatePrice = (props) => {
    const { showModal, setReload, setMessage, isLoading, product, isCafe } = props;
    const { id, productName } = product;
    const existingPrice = product.sellingPrice;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const [price, setPrice] = useState();

    const isSubmitAllowed = price > 0 && price !== existingPrice;

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>New Price</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={price}
                            placeholder={'Existing Price is - Rs. ' + existingPrice}
                            onChange={(evt) => setPrice(evt.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={discardProduct}>  Submit</Button>
            </React.Fragment>
        );
    };

    const discardProduct = () => {
        showModal(false);
        isLoading(true);
        let postBody = {
            id, price: Number(price), 
            createdBy: {
                userName: context.user.userName,
                userId: context.user.userId,
            },
            isCafe : isCafe,
        };

        updatePriceOfProducts(
            facilityId,
            arenaId,
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Price updated for the product",
                    message: "Product information has been updated successfully.",
                    color: "yellow",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }
    return (
        <>
            <ModalPortal
                title={'Update price for ' + productName }
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default UpdatePrice;