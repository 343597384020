import React, { useContext, useEffect, useState } from 'react';
import {
    Button,
    Form,
} from "semantic-ui-react";

import ModalPortal from '../generic/ModalPortal';
import { AppContext } from '../../AppContext';
import { editReceiptNumber, getLastReceipt } from "../../apiclients/ReportsApiClient";

const EditReceiptNumber = (props) => {
    const {  showModal, setReload, setMessage, isLoading } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const [oldReceiptNumber, setOldReceiptNumber] = useState();
    const [receiptNumber, setReceiptNumber] = useState();

    useEffect(()=>{
        isLoading(true);
        getLastReceipt(
            facilityId,arenaId,
        ).then((res) => {
            setOldReceiptNumber(res.data.data);
            isLoading(false);
          });
    },[])


    const isSubmitAllowed =  receiptNumber;

    const modalContent = (
        <div>
           {oldReceiptNumber ?<div> Next Receipt Number is <b>{oldReceiptNumber} </b></div> : null} 
           <br/>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Last Receipt</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={receiptNumber}
                            placeholder='Update the last receipt Number, e.g. 25'
                            onChange={(evt) => setReceiptNumber(evt.target.value)}
                        />
                    </Form.Field>
                  </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            
                <Button disabled={!isSubmitAllowed} primary onClick={editReceipt}>  Submit</Button>
                </React.Fragment>
        );
    };

    const editReceipt = () => {
        
        showModal(false);
        isLoading(true);

        const postBody = {
            facilityId, arenaId, receiptNumber, createdBy :{
                userName: context.user.userName,
                userId: context.user.userId,
            }
        };

        editReceiptNumber(
            postBody
        ).then((response) => {
            if( response.status === 200 || response.status === 201){
            
             setMessage({
                header: "Receipt No. Edited",
                message: "Receipt Number has been edited successfully.",
                color: "green",
              });
            }else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
              });
          });
    }
    return (
        <>
            <ModalPortal
                title='Edit Receipt Number'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default EditReceiptNumber;