import React from 'react';
import { Dropdown } from 'semantic-ui-react';
import { colors } from '@material-ui/core';

const SlotSelection = ({ sportTimings, startSlot, endSlot, changeSlot }) => {
  const timings = {};
  sportTimings.forEach((x, index) => (timings[x.timeId] = index));

  const startSlots = sportTimings.map((x, index) => ({
    key: index,
    value: x.timeId,
    text: x.interval.start,
  }));
  let counter = 0;
  const endSlots = sportTimings
    .filter(function (x) {
      if (startSlot.value <= x.timeId) {
        return true;
      }
      return false;
    })
    .map(function (x) {
      return {
        key: counter++,
        value: x.timeId,
        text: x.interval.end,
      };
    });

    const changeSelectedRange = isStart => (event, val) => {
         const slots = isStart ? startSlots : endSlots;
         const data = slots.find((s) => s.value === val.value);
         let isValidRange = false;
         if(data) {
            if(isStart) {
                 isValidRange = timings[data.value] <= timings[endSlot.value];
            } else {
                isValidRange = timings[startSlot.value] <= timings[data.value];
            }
        }

        changeSlot(data, isStart, isValidRange);
    }
  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      <div
        style={{
          marginBottom: "10px",
          marginTop: "10px",
          fontWeight: "bold",
        }}
      >
        Slot timings
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Dropdown
          onChange={changeSelectedRange(true)}
          placeholder='Select...'
          openOnFocus
          selection
          value={startSlot && startSlot.value}
          options={startSlots}
          style={{ marginRight: "30px" }}
        />
        <span style={{ marginRight: "30px" }}>to</span>
        <Dropdown
          onChange={changeSelectedRange(false)}
          placeholder='Select...'
          openOnFocus
          selection
          value={endSlot && endSlot.value}
          options={endSlots}
        />
      </div>
      {timings[startSlot.value] > timings[endSlot.value] ? (
        <div style={{ color: colors.red["500"] }}>
          *Slot start time cannot be greater or equal to end slot time
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default SlotSelection;
