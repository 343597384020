import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import { Fade, Zoom,  } from 'react-reveal';
import {Helmet} from "react-helmet";
const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>COACHING THAT</h4>
        <h1 data-aos='fade-up'>Creates The Players Of Tomorrow </h1>
        <div class='btarea' data-aos='zoom-in'>
          <button class='btwhite'>Call us @ 9310096119</button>
        </div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> World-Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#price'></use>
          </svg>
          <h5> Affordable Fee</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#support'></use>
          </svg>
          <h5> 100+ Fully-Trained Students</h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>
      <section class='partnersec badmintondeco '>
        <div class='container'>
          <div class='innerbx'>
            <div class='contentarea '>
              <h4 >Badminton </h4>
              <h2 >
                Begin Your Badminton Journey The Right Way
              </h2>

              <span>
                <Zoom>
                With trained professional coaches and a court-to-student ratio
                of 1:6, we are all set to help you reach your maximum potential
                in the sport. From wooden flooring for better shock absorption
                to comfortable seating areas for the audience – we have it all.{" "}</Zoom>
              </span>
              <div class='btarea'>
                <a href='#footer' class='btorng'>
                  Call us @ 9310096119
                </a>
              </div>
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade right><img class='techimgs' src='images/badmintonimg.png' /></Fade>
                <h5 class='pr-4 pl-4'>
                  <Zoom>
                  We operate with a strict ‘No Touch’ policy, and our
                  scientifically-defined curriculum is all you need for
                  systematic coaching that helps you bring out your best in
                  every badminton game you play.</Zoom>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class='partnersec cricketdeco'>
        <div class='container'>
          <div class='innerbx'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
               <Fade left> <img class='techimgs' src='images/footballimg.png' /></Fade>
                <h5 class='pr-4 pl-4'><Zoom>
                  Our training methodology helps us recognize every
                  student-athlete’s full potential as we groom and prepare
                  future footballers of the world.{" "}</Zoom>
                </h5>
              </div>
            </div>

            <div class='contentarea '>
              <h4 >Football</h4>
              <h2 >
                Take Your Football Game To The Next Level{" "}
              </h2>

              <span>
                <Zoom>
                We transform aspiring footballers into professional players with
                first-class facilities and expert guidance from our trained
                football coaches. Our sessions focus on individual technique,
                teamwork, strength, and complementing conditioning to prepare
                you for the modern game.</Zoom>
              </span>
              <div class='btarea'>
                <a href='#footer' class='btorng'>
                  Call us @ 9310096119
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class=' techsection footballdeco'>
        <div class='container'>
          <div class='headarea'>
            <h4  class=' sectiontitle '>
              HOW DO WE{" "}
            </h4>
            <h2 class=' sectionhead fadeInDown wow'>
              Excel At Doing What We DO?
            </h2>
            <span>
            <span class='playallst'>Play All</span><Fade  cascade> works with the passion for
              creating once-in-a-lifetime experiences for aspiring athletes.</Fade>
            </span>
          </div>
          <div class='techarea sportsarea'>
            <div class='sportsbox box'>
              <div class='iconarea'>
                <svg>
                  <use href='#whistle'></use>
                </svg>
              </div>
             <Zoom> <h4 >Qualified Coaches</h4></Zoom>
              <span>
               
            <Fade botton > We give you the chance to train at best-in-class facilities
                under qualified coaches
            </Fade>
              </span>
            </div>
            <div class='sportsbox box'>
              <div class='iconarea'>
                <svg>
                  <use href='#growth'></use>
                </svg>
              </div>
             <Zoom> <h4 >Beginner to Expert level</h4></Zoom>
              <span>
              <Fade botton >Our training sessions are designed for beginner to expert level
                athletes with no requirement for a minimum playing experience
            </Fade>
              </span>
            </div>
            <div class='sportsbox box'>
              <div class='iconarea'>
                <svg>
                  <use href='#fitness'></use>
                </svg>
              </div>
              <Zoom><h4 >All-Round Development</h4></Zoom>
              <span>
              <Fade botton > We focus on our players’ all-round development with teams
                specializing in child psychology, nutrition, and physio
            </Fade>
              </span>
            </div>
          </div>
        </div>
      </section>
    </main>{" "}
  </>
);

const AcademyPage = () => {
  return (
    <>
    <Helmet>
            <title>Sports Academy - Badminton Academy, Football Academy, and Skating Academy</title>
            <meta name="description" content="Play all offers best badminton academy, football academy, and skating academy with professional coaching and best in class infrastructure in Noida, Gurgaon and Faridabad." />
        </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner academybg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"academy"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default AcademyPage;
