import React, { useContext, useEffect, useRef, useState } from "react";

import { colors } from "@material-ui/core";
import { Button, Dropdown, Icon, Tab, Table } from "semantic-ui-react";
import { getPnPSportsOfArena } from "../../../apiclients/BookingApiClient";
import { deactivateFlexiMembership, deactivateFlexiPackage, getFlexiMembersData, getPackages } from "../../../apiclients/MembershipApiClient";
import { AppContext } from "../../../AppContext";
import { ROLES } from "../../../utils/constants";
import GenericLoader from "../../generic/GenericLoader";
import Toast from "../../generic/Toast";
import AddFlexiPackage from "./AddFlexiPackage";
import AddFlexiMember from "./AddFlexiMember";
import { convertFirstoreDate } from "../../../utils/helperFunctions";
import TransactionHistory from "./TransactionHistory";

const PacakagesDataTable = ({
  packages,
  setReload,
  sport,
  setMessage,
  setShowAddMembers,
  setSelectedPackage
}) => {
  const context = useContext(AppContext);

  const deletePackage = (packageId) => {
    deactivateFlexiPackage(
      context.selectedFacility,
      context.selectedArena.arenaId,
      sport.key,
      packageId
    ).then((response) => {
      if (response.status === 200 || response.status === 201) {
        setMessage({
          header: "Package Deleted",
          message: "Flexi Membership Package has been Deleted successfully.",
          color: "green",
        });
      } else {
        setMessage({
          header: "Error!",
          message: response.data.error.message,
          color: "red",
        });
      }
      setReload(true);
    }).catch((error) => {
      setMessage({
        header: "Error!",
        message: error.message,
        color: "red",
      });
    });
  };

  const enrollMember = (packages) => {
    setSelectedPackage(packages);
    setShowAddMembers(true);
  }
  return (
    <>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Number of Months</Table.HeaderCell>
            <Table.HeaderCell>Fees</Table.HeaderCell>
            <Table.HeaderCell>Slots Allowed Per day</Table.HeaderCell>
            <Table.HeaderCell>Booking Window </Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {packages.map((x) => {
            return (
              <Table.Row key={x.id}>
                <Table.Cell>{x.numberOfMonth}</Table.Cell>
                <Table.Cell>Rs. {x.fees}/-</Table.Cell>
                <Table.Cell>{x.usagePerDay}</Table.Cell>
                <Table.Cell>{x.hoursBeforeBooking} {x.hoursBeforeBooking <= 1 ? " Hour" : "Hours"} </Table.Cell>
                <Table.Cell
                  style={{ flex: 1, justifyContent: "center", flexDirection: "column", display: "flex", }} >
                  <Button
                    style={{
                      padding: "8px",
                      fontSize: "12px",
                      marginBottom: "8px",
                      backgroundColor: colors.orange["800"],
                      color: "#fff",
                    }}

                    onClick={() => enrollMember(x)}
                  >
                    Enroll
                  </Button>

                  <Button
                    style={{
                      padding: "8px",
                      fontSize: "12px",
                      marginBottom: "8px",
                    }}
                    onClick={() => deletePackage(x.id)}
                  >
                    Delete
                  </Button>
                </Table.Cell>
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

const MembersDataTable = ({
  members,
  setReload,
  sport,
  setMessage,
  currentMembership,
  setisLoading
}) => {
  const context = useContext(AppContext);
  const [transactionsData, setTransactionData] = useState();
  const [showTransactionModal, setShowTransactionModal] = useState(false);

  const deactivateMembership = (memberId) => {
    setisLoading(true);
    deactivateFlexiMembership(
      context.selectedFacility,
      context.selectedArena.arenaId,
      sport.key,
      memberId
    ).then((response) => {
      if (response.status === 200 || response.status === 201) {
        setMessage({
          header: "Membership Deactivated",
          message: "Flexi Membership has been Deactivated successfully.",
          color: "green",
        });
      } else {
        setMessage({
          header: "Error!",
          message: response.data.error.message,
          color: "red",
        });
      }

      setReload(true);
      setisLoading(false);
    }).catch((error) => {
      setMessage({
        header: "Error!",
        message: error.message,
        color: "red",
      });
    });
  };

  const showTransactions = (data, userName) => {
    const tData = { data, userName }
    setTransactionData(tData);
    setShowTransactionModal(true);
  }


  return (
    <>
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Mobile</Table.HeaderCell>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            <Table.HeaderCell>End Date </Table.HeaderCell>
            <Table.HeaderCell>Slots Allowed / Day</Table.HeaderCell>
            <Table.HeaderCell>Booking Window</Table.HeaderCell>
            <Table.HeaderCell>Fees Paid</Table.HeaderCell>
            <Table.HeaderCell>Booking History</Table.HeaderCell>
            {currentMembership && <Table.HeaderCell>Deactivate</Table.HeaderCell>}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {members.map((x) => {
            return (
              <Table.Row key={x.id}>
                <Table.Cell>{x.userName}</Table.Cell>
                <Table.Cell>{x.userMobileNumber}</Table.Cell>
                <Table.Cell>{new Date(x.startDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
                <Table.Cell>{new Date(x.endDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
                <Table.Cell>{x.usagePerDay}</Table.Cell>
                <Table.Cell>Within {x.hoursBeforeBooking} {x.hoursBeforeBooking <= 1 ? " Hour" : "Hours"} of the booking </Table.Cell>
                <Table.Cell>Rs. {x.fees} /- </Table.Cell>
                <Table.Cell>
                  <Button
                    style={{
                      padding: "8px",
                      fontSize: "12px",
                      marginBottom: "8px",
                    }}
                    onClick={() => showTransactions(x.transactions, x.userName)}
                    disabled={!(x.transactions && x.transactions.length > 0)}
                  >
                    History
                  </Button>
                </Table.Cell>
                {currentMembership && <Table.Cell
                  style={{ flex: 1, justifyContent: "center", flexDirection: "column", display: "flex", }} >
                  {x.isActive ? <Button
                    style={{
                      padding: "8px",
                      fontSize: "12px",
                      marginBottom: "8px",
                    }}
                    onClick={() => deactivateMembership(x.id)}
                  >
                    Deactivate
                  </Button> : "Membership Deactivated"}

                </Table.Cell>}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>

      {showTransactionModal ? (
        <TransactionHistory
          data={transactionsData}
          showModal={setShowTransactionModal}
        />
      ) : null}

    </>
  );
};

const FlexiMembership = (props) => {
  const context = useContext(AppContext);
  const { selectedSportDetails, facilitySports, user } = context;
  const [allSports, setAllSports] = useState([]);
  const [selectedSport, setSelectedSport] = useState();
  const [allMembers, setAllMembers] = useState([]);
  const [packages, setPackages] = useState([]);
  const [reload, setReload] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [isLoading, setisLoading] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});

  const [showAddPackages, setShowAddPackages] = useState(false);
  const [showAddMembers, setShowAddMembers] = useState(false);



  useEffect(() => {
    const sportsDropdown = facilitySports.map(x => ({ key: x.sportId, value: x.sportId, text: x.sportName, }));
    const firstSport = selectedSportDetails.detail;
    const firstSportObject = { key: firstSport.sportId, value: firstSport.sportId, text: firstSport.sportName };
    setAllSports(sportsDropdown);
    setSelectedSport(firstSportObject);
    loadData(firstSportObject);
  }, [context.selectedArena.arenaId, context.selectedFacility]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reload) {
      loadData(selectedSport).then(() => {
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setisLoading(true);
    loadData(selectedSport);
  }, [activeIndex]);

  const loadData = async (sport) => {
    if (sport) {
      if (activeIndex === 0) {//download packages

        return await getPackages(
          context.selectedFacility,
          context.selectedArena.arenaId,
          sport.key,
          true
        ).then((res) => {
          const keys = Object.keys(res.data.packageDetails);
          const p = keys.map((x) => res.data.packageDetails[x]);
          setPackages(p);
          setisLoading(false);
          setReload(false);
          return res;
        });
      } else if (activeIndex === 1) {//download active membership
        const activeMemberships = true;
        return await getFlexiMembersData(
          context.selectedFacility,
          context.selectedArena.arenaId,
          sport.key,
          activeMemberships
        ).then((res) => {
          const members = res.data.data;
          setAllMembers(members);
          setisLoading(false);
          setReload(false);
          return res;
        });

      } else if (activeIndex === 2) {//download inactive membership
        const activeMemberships = false;
        return await getFlexiMembersData(
          context.selectedFacility,
          context.selectedArena.arenaId,
          sport.key,
          activeMemberships
        ).then((res) => {
          const members = res.data.data;
          setAllMembers(members);
          setisLoading(false);
          setReload(false);
          return res;
        });

      }
    }

  };

  const changeSport = (event, { value }) => {
    setisLoading(true);
    const data = allSports.find((facility) => facility.value === value);
    setSelectedSport(data);
    loadData(data);
  };

  const panes = [
    {
      menuItem: "Memberships Packages",
      render: () => (
        <Tab.Pane className={"tabBox"}>
          <PacakagesDataTable
            packages={packages}
            setReload={setReload}
            sport={selectedSport}
            setMessage={setMessageContent}
            setShowAddMembers={setShowAddMembers}
            setSelectedPackage={setSelectedPackage}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Current Memberships",
      render: () => (
        <Tab.Pane className={"tabBox"}>
          <MembersDataTable
            members={allMembers}
            setReload={setReload}
            sport={selectedSport}
            setMessage={setMessageContent}
            currentMembership={true}
            setisLoading={setisLoading}
          />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Past Memberships",
      render: () => (
        <Tab.Pane className={"tabBox"}>
          <MembersDataTable
            members={allMembers}
            setReload={setReload}
            sport={selectedSport}
            setMessage={setMessageContent}
            currentMembership={false}
            setisLoading={setisLoading}
          />
        </Tab.Pane>
      ),
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <Toast
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        message={messageContent.message}
        messageHeader={messageContent.header}
        color={messageContent.color}
      />
      <div className='dashboard'>
        <div style={{ flex: 1, display: "flex", justifyContent: "space-between", alignItems: "flex-end", }} >
          <div>
            <span>Flexi Membership</span>
          </div>
          <div style={{ alignItems: "center" }}>
            <div style={{ fontSize: "14px" }}>
              <Dropdown
                onChange={changeSport}
                placeholder='Select...'
                openOnFocus
                selection
                value={selectedSport && selectedSport.value}
                options={allSports}
              />
              <Button
                style={{ marginLeft: "20px" }}
                onClick={() => setShowAddPackages(true)}
                name={"Add Package"}
                disabled={!(user.role === ROLES.ADMIN || user.role === ROLES.REGIONAL_MANAGER)}
              >
                <Icon name={"add"} />
                Add Package
              </Button>
            </div>
          </div>
        </div>
      </div>
      {isLoading ? (
        <GenericLoader />
      ) : (
        <div style={{ paddingTop: "16px" }}>
          <Tab
            activeIndex={activeIndex}
            onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
            renderActiveOnly={true}
            menu={{
              color: "orange",
              pointing: true,
            }}
            panes={panes}
          />
          <br />
          Flexi Memberships - Package and enrollment ?
          <div className="player">
            <iframe width="300" height="150" src="https://www.youtube.com/embed/UTz0ocyRMZE" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
          </div>
        </div>
      )}

      {showAddMembers ? (
        <AddFlexiMember
          sport={selectedSport}
          showModal={setShowAddMembers}
          setReload={setReload}
          setMessage={setMessageContent}
          isLoading={setisLoading}
          selectedPackage={selectedPackage}
        />
      ) : null}

      {showAddPackages ? (
        <AddFlexiPackage
          sport={selectedSport}
          showModal={setShowAddPackages}
          setReload={setReload}
          setMessage={setMessageContent}
          isLoading={setisLoading}
        />
      ) : null}
    </div>
  )
};


export default FlexiMembership;