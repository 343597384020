import React, { useEffect, useContext, useState, useReducer } from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";
import moment from "moment";

import "../dashboard/style.scss";
import { AppContext } from "../../AppContext";
import { loadBookingTransactions } from "../../apiclients/ReportsApiClient";
import {
  convertFirstoreDate,convertFirstoreDateForExcel,
  getKeyValue,
  getSubtypeLabel,
} from "../../utils/helperFunctions";
import CustomModal from "../generic/CustomModal";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import {
  filterReducer,
  FILTERS_ACTION_TYPE,
  initialFilterState,
} from "./bookingReportReducer";
import GenericLoader from "../generic/GenericLoader";
import ExportToExcel from "../generic/ExportToExcel";
import BookingDetail from "./BookingDetail";
import { BOOKING_SOURCES, ROLES } from "./../../utils/constants";

const paymentOptions = [
  { label: "Paytm", value: "paytm" },
  { label: "Credit/Debit Card", value: "card" },
  { label: "Cash", value: "cash" },
  { label: "Other", value: "other" },
  { label: "Bank Transfer", value: "bankTransfer" },
  { label: "Online", value: "Online" },
];

const bookingOptions = [
  { label: "Play.all", value: BOOKING_SOURCES.PLAY_ALL_WEB },
  { label: "Hudle", value: "Hudle" },
  { label: "Sportido", value: "Sportido" },
  { label: "Playo", value: "Playo" },
  { label: "Others", value: "Others" },
];



const FilterFooter = ({ setShowFilter, onApply }) => {
  return (
    <Modal.Actions>
      <Button className={"ui button booked-footer"} onClick={onApply}>
        Apply
      </Button>
      <Button onClick={() => setShowFilter(false)}>Cancel</Button>
    </Modal.Actions>
  );
};

const FilterContent = ({ onApply, setShowFilter, selectedFilters, role }) => {
  const [filters, dispatch] = useReducer(filterReducer, {
    ...initialFilterState,
    ...selectedFilters,
  });
  const { startDate, endDate, paymentMode, bookingModes } = filters;

  const onReset = () => {
    dispatch({ type: FILTERS_ACTION_TYPE.RESET });
  };
  const onFilterApplyClicked = () => {
    onApply(filters);
  };
  const updateSelectedModes = (mode) => () => {
    dispatch({
      type: FILTERS_ACTION_TYPE.ADD_PAYMENT_MODES,
      payload: paymentMode.value === mode.value ? {} : mode,
    });
  };

  const updateSelectedBookingModes = (mode) => () => {
    const isAdded = bookingModes.some((x) => x.value === mode.value);
    if (isAdded) {
      dispatch({
        type: FILTERS_ACTION_TYPE.ADD_BOOKING_MODES,
        payload: bookingModes.filter((x) => x.value !== mode.value),
      });
    } else {
      dispatch({
        type: FILTERS_ACTION_TYPE.ADD_BOOKING_MODES,
        payload: [...bookingModes, mode],
      });
    }
  };

  return (
    <>
      <Modal.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Filters</span>
          <span
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              color: colors.orange[500],
            }}
            onClick={onReset}
          >
            Clear all
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Booking Date
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Start Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={startDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_START_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}
              minDate={ !(role === ROLES.ADMIN || role === ROLES.PARTNER || role === ROLES.ACCOUNTANT)? moment().subtract(7, 'days') : undefined}
              
            />
            <div style={{ fontWeight: "bold" }}>End Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={endDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_END_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}
              minDate={ !(role === ROLES.ADMIN || role === ROLES.PARTNER || role === ROLES.ACCOUNTANT)? moment().subtract(7, 'days') : undefined}
              
            />
          </div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Payment Mode
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {paymentOptions.map((x) => {
              const isActive = paymentMode && paymentMode.value === x.value;
              return (
                <Button
                  onClick={updateSelectedModes(x)}
                  style={{
                    marginRight: "10px",
                    backgroundColor: isActive
                      ? colors.orange[700]
                      : colors.grey[50],
                    color: isActive ? colors.blue[50] : colors.black,
                  }}
                >
                  {x.label}
                </Button>
              );
            })}
          </div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Booking Mode
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            {bookingOptions.map((x) => {
              const isActive = bookingModes.some(
                (mode) => mode.value === x.value
              );
              return (
                <Button
                  onClick={updateSelectedBookingModes(x)}
                  style={{
                    marginRight: "10px",
                    backgroundColor: isActive
                      ? colors.orange[700]
                      : colors.grey[50],
                    color: isActive ? colors.blue[50] : colors.black,
                  }}
                >
                  {x.label}
                </Button>
              );
            })}
          </div>
        </div>
      </Modal.Content>
      <FilterFooter
        onApply={onFilterApplyClicked}
        setShowFilter={setShowFilter}
      />
    </>
  );
};

const BookingFilters = ({
  setShowFilter,
  showFilter,
  selectedFilters,
  onApply,
  role
}) => {
  return (
    <CustomModal
      comp={true}
      content={
        <FilterContent
          onApply={onApply}
          selectedFilters={selectedFilters}
          setShowFilter={setShowFilter}
          role={role}
        />
      }
      setOpen={setShowFilter}
      open={showFilter}
    />
  );
};
const BookingReports = () => {
  const { selectedFacility, selectedArena, user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [showFilter, setShowFilter] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [loadData, setLoadData] = useState(true);

  useEffect(() => {
    if (!loadData) {
      setLoadData(true);
    }
  }, [selectedArena]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadData) {
      let params = {
        startDate: selectedFilters.startDate,
        endDate: selectedFilters.endDate,
        type: "booking",
      };
      if (selectedFilters.bookingModes) {
        params.bookingModes = selectedFilters.bookingModes.join(",");
      }
      if (getKeyValue(selectedFilters, "paymentMode.value")) {
        params.paymentMode = selectedFilters.paymentMode.value;
      }
      if (
        selectedFilters.bookingModes &&
        selectedFilters.bookingModes.length > 0
      ) {
        params.bookingModes = selectedFilters.bookingModes.map((x) => x.value);
      }

      loadBookingTransactions(
        selectedFacility,
        selectedArena.arenaId,
        params
      ).then((res) => {
        setData(res.data.data);
        setLoadData(false);
      });
    }
  }, [loadData]); // eslint-disable-line react-hooks/exhaustive-deps

  const onApply = (filters) => {
    setLoadData(true);
    setSelectedFilters(filters);
    setShowFilter(false);
  };

  const openFilter = () => {
    setShowFilter(true);
  };

  const openTransactionDetail = (data) => () => {
    setShowDetails(data);
  };

  const excelFirstHalf = [
  { label: "Booking Type", value: "type" },
  { label: "Sub Category", value: (col) => getSubtypeLabel(col.subtype) },
  { label: "Arena", value: "arenaName" },
  { label: "Sport", value: (col) => (col.alternativeSport ? col.alternativeSport.text : col.sport ? col.sport.sportName : "") },
  {
    label: "Transaction Date",
    value: (col) => convertFirstoreDateForExcel(col.createdDate),
  },
  {
    label: "Transaction Time",
    value: (col) => col.createdTime,
  }, {
    label: "Booking Date",
    value: (col) => (col.bookingDate ? convertFirstoreDateForExcel(col.bookingDate) : ""),
  },
  {
    label: "Slot Name",
    value: (col) => (col.slot ? col.slot.interval ? col.slot.interval.start + '-' + col.slot.interval.end : "" : ""),
  },
  {
    label: "Court Name",
    value: (col) => (col.court ? col.court.courtName : ""),
  },];
  const excelSecondHalf = [
  { label: "Customer Name", value: "userName" },
  { label: "Transaction ID", value: "id" },
  { label: "Total Amount (in Rs.)", value: "totalAmount" },
  { label: "Discount (in %)", value: "discount" },
  { label: "Amount Paid (in Rs.)", value: "amountPaid" },
  { label: "Amount Due (in Rs.)", value: "amountDue" },
  {
    label: "UPI (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "upi")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Card Payment (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "card")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Cash (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "cash")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Bank Transfer (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "banktransfer")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Others (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "others")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },

  {
    label: "Online (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "online")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Paytm (in Rs.)",
    value: (col) =>
      col.payment
        ? col.payment
          .filter((x) => x.mode.toLowerCase() === "paytm")
          .map((x) => x.amount)
          .reduce((x, sum) => Number(sum) + Number(x), 0)
        : "",
  },
  {
    label: "Third Party Commission",
    value: (col) =>
      col.thirdPartyCommission
        ? col.thirdPartyCommission * col.totalAmount
        : "",
  },
  {
    label: "Hudle Pass Discount",
    value: (col) =>
      col.hudlePassDiscount
        ? col.hudlePassDiscount * col.totalAmount
        : "",
  },
  {label: "CGST", value: (col) => col.cgst >= 0 ? col.cgst : "", },
  {label: "IGST", value: (col) => col.igst >= 0? col.igst : "", },
  {label: "SGST", value: (col) => col.sgst >= 0? col.sgst : "", },
  {label: "Cess", value: (col) => col.cess >= 0 ? col.cess : "", },
  {label: "Taxable Amount", value: (col) => col.taxableAmount >= 0 ? col.taxableAmount : "", },
  {label: "Net Receipt", value: (col) => col.taxableAmount && col.thirdPartyCommission ? col.taxableAmount - (col.thirdPartyCommission* col.totalAmount) : col.taxableAmount>= 0 ?  col.taxableAmount : "", },
  
  {
    label: "Booking Date",
    value: (col) => convertFirstoreDate(col.bookingDate),
  },
  { label: "Booking Source", value: "source" },
  { label: "Booking Sub-Source", value: "bookingSource" },
  { label: "Created By", value: (col) => col.createdBy ? col.createdBy.userName : "" },

  {
    label: "Partner Booking ID",
    value: (col) => (col.partnerBookingId ? col.partnerBookingId : ""),
  },]
  let tableHeader;
  if(user.role !== ROLES.PARTNER ){
    tableHeader = [...excelFirstHalf,{
      label: "Customer Number",
      value: (col) => (col.userMobileNumber ? col.userMobileNumber : ""),
    }, ...excelSecondHalf]
  } else {
    tableHeader = [...excelFirstHalf, ...excelSecondHalf];
  }

  const startDate = convertFirstoreDate(
    selectedFilters.startDate,
    "DD MMM YYYY"
  );
  const endDate = convertFirstoreDate(selectedFilters.endDate, "DD MMM YYYY");
  const fileName = 'Bookings '+startDate+" - "+endDate;
  const sheetName = moment().format('MMMM Do YYYY, h_mm a');
  let updatedData = data.filter(x => (x.cancelled !== true ));
   updatedData = updatedData.filter(x => (x.bookingStatus !== "cancelled"));
  const sheets = [
    {
      name: sheetName,
      columns: tableHeader,
      dataSet: updatedData,
    },
  ];

  return (
    <div style={{ padding: "16px" }}>
      <div style={{ paddingBottom: "16px" }}>
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span>Booking Reports </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingRight: "10px",
                }}
              >
                ( Range:{" "}
                {convertFirstoreDate(selectedFilters.startDate, "DD MMM YYYY")}{" "}
                - {convertFirstoreDate(selectedFilters.endDate, "DD MMM YYYY")})
              </span>
            </div>
            <div style={{ alignItems: "center" }}>
              <span>
              <ExportToExcel disabled={data.length === 0 || !(user.role === ROLES.ADMIN || user.role === ROLES.PARTNER || user.role === ROLES.ACCOUNTANT)} sheets={sheets} 
                  fileName={fileName}
                  buttonName="Download Report"/>
                <Button onClick={openFilter} name={"filter"}>
                  <Icon
                    color={selectedFilters.isFilterApplied ? "orange" : "grey"}
                    name={"filter"}
                  />
                  Filter
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>

      {showFilter ? (
        <BookingFilters
          onApply={onApply}
          selectedFilters={selectedFilters}
          setLoadData={setLoadData}
          setShowFilter={setShowFilter}
          showFilter
          role={user.role}
        />
      ) : null}
      {!!showDetails ? (
        <BookingDetail
          selectedData={showDetails}
          setShowDetails={setShowDetails}
        />
      ) : null}
      {loadData ? (
        <GenericLoader />
      ) : data.length === 0 ? (
        "No data available"
      ) : (
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Sport</Table.HeaderCell>
              <Table.HeaderCell>Slot</Table.HeaderCell>
              <Table.HeaderCell>Court</Table.HeaderCell>
              <Table.HeaderCell>Customer Detail</Table.HeaderCell>
              <Table.HeaderCell>Slot Price Paid</Table.HeaderCell>
              <Table.HeaderCell>Booking Type</Table.HeaderCell>
              <Table.HeaderCell>Source</Table.HeaderCell>
              <Table.HeaderCell>Date of Transaction</Table.HeaderCell>
              <Table.HeaderCell>Created By</Table.HeaderCell>
              <Table.HeaderCell>Transaction ID</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {data.map((x) => {
              return (
                <Table.Row>
                  <Table.Cell>{x.alternativeSport ? x.alternativeSport.text : x.sport?.sportName}</Table.Cell>
                  <Table.Cell>
                    <div>
                      {convertFirstoreDate(x.bookingDate)},{" "}
                      {x.slot?.interval?.start} - {x.slot?.interval?.end}
                    </div>
                  </Table.Cell>
                  <Table.Cell>
                    <div>{x.court?.courtName}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div>{x.userName}</div>
                    <div>{x.userMobileNumber}</div>
                  </Table.Cell>
                  <Table.Cell>
                    <div
                      style={{
                        color:
                          x.courtBookingAmount < 0 ? colors.red["500"] : "#000",
                      }}
                    >
                      Rs.{x.courtBookingAmount}/-{" "}
                    </div>
                    <div style={{ fontSize: "10px", color: colors.red["300"] }}>
                      {x.rescheduledFrom ? "Adjusted from last booking" : ""}
                    </div>
                  </Table.Cell>

                  <Table.Cell>{getSubtypeLabel(x.subtype)}</Table.Cell>
                  <Table.Cell>{getSubtypeLabel(x.source)}</Table.Cell>
                  <Table.Cell>
                    <div>
                      {convertFirstoreDate(x.createdDate)}, {x.createdTime}
                    </div>
                  </Table.Cell>
                  <Table.Cell>{x.createdBy?.userName || "-"}</Table.Cell>
                  <Table.Cell onClick={openTransactionDetail(x)}>
                    <div
                      style={{
                        textDecoration: "underline",
                        color: colors.blue[500],
                      }}
                    >
                      {x.id}
                    </div>
                    <div
                      style={{
                        backgroundColor: colors.grey["500"],
                        textTransform: "uppercase",
                        borderRadius: "12px",
                        color: "#fff",
                        textAlign: "center",
                        fontWeight: "900",
                        fontSize: "10px",
                      }}
                    >
                      {x.cancelled
                        ? "Cancelled"
                        : ""}

                    </div>
                      <div
                        style={{
                          backgroundColor: colors.green["500"],
                          textTransform: "uppercase",
                          borderRadius: "12px",
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "900",
                          fontSize: "10px",
                        }}
                      >
                        {x.rescheduledFrom ? "NEW SLOT" : ""}
                      </div>
                      <div
                        style={{
                          backgroundColor: colors.blue["500"],
                          textTransform: "uppercase",
                          borderRadius: "12px",
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "900",
                          fontSize: "10px",
                        }}
                      >
                        {x.rescheduleTo ? "RESCHEDULED" : ""}
                      </div>
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      )}
    </div>
  );
};

export default BookingReports;
