import React, { useState } from 'react';

function SuccessPopUp(props) {

    return (
        <>

            {props.isOpen && (
                <div className="popup-overlay"  >
                    <div className="popup-content" >
                        <div style={{ padding: "2vh" }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <img
                                    alt=''
                                    // class='featureLogo'
                                    style={{
                                        width: "20%",
                                        height: "20%"
                                    }}
                                    src={props.popName === "success" ? 'images/check.png' : 'images/cancel.png'}
                                />

                            </div>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "1vh" }}>
                                <h2> {props.popName === "success" ? 'Success' : "OPSS!!"}  </h2>
                            </div>
                            {props.popName === "success" ?
                                <div>
                                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                        <p style={{ fontSize: "2vh", marginBottom: 0, width: "15vw", textAlign: "center" }}>Thank you for your enquiry! We will get back to you shorty. Meanwhile, feel free to reach out to us directly at <span style={{ fontSize: "2vh", fontWeight: "bold" }}>9911371140</span></p>


                                    </div>

                                </div>
                                : <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                    <p style={{ fontSize: "2vh", marginTop: "1vh", width: "16vw", textAlign: "center" }}> Somthing Went Wrong! Please try again after some time</p>


                                </div>}
                        </div>
                        <div style={{
                            display: "flex", justifyContent: "center", alignItems: "center", background: "#DDE4F3", borderBottomRightRadius: '2vh',
                            borderBottomLeftRadius: '2vh', paddingTop: "1vh", paddingBottom: "1vh"
                        }}
                            onClick={() => { props.onClose() }}
                        >
                            <p style={{ fontSize: "2vh", marginBottom: 0 }}>Dismiss</p>
                        </div>
                    </div>

                </div>
            )}

        </>
    );
}

export default SuccessPopUp;
