import React, { useEffect, useContext, useState, useReducer } from "react";

import "../dashboard/style.scss";
import {
  Button,
  Divider,
  Dropdown,
  Form,
  Grid,
  Segment,
  Icon,
} from "semantic-ui-react";
import { AppContext } from "../../AppContext";
import { DatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import { getPnPSportsOfArena } from "../../apiclients/BookingApiClient";
import moment from "moment";
import Payment from "../dashboard/Payment";
// import AddUser from "./AddUser";
import GenericLoader from "../generic/GenericLoader";
import {
  checkAvailabilityForPackage,
  createMembership,
} from "../../apiclients/MembershipApiClient";
import AddUser from "../bulk-booking/AddUser";


const initialState = {
  sport: "",
  startDate: null,
  endDate: null,
  days: [],
  courts: null,
  startSlot: "",
  endSlot: "",
  paymentArray: [],
  users: [],
  discount: 0,
};

const ACTION_TYPE = {
  ADD_SPORT: "addSport",
  ADD_COURTS: "addCourts",
  ADD_START_DATE: "addStartDate",
  ADD_END_DATE: "addEndDate",
  ADD_DAYS: "addDays",
  ADD_START_SLOT: "addStartSlot",
  ADD_END_SLOT: "addEndSlot",
  ADD_NUMBER: "addNumber",
  ADD_Name: "addName",
  ADD_PAYMENT_ARRAY: "addPaymentArray",
  ADD_USERS: "addUsers",
  ADD_DISCOUNT: "addDiscount",
};

const reducer = (state, action) => {
  if (action.type === ACTION_TYPE.ADD_SPORT) {
    return { ...state, sport: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_COURTS) {
    return { ...state, courts: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_START_DATE) {
    return { ...state, startDate: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_END_DATE) {
    return { ...state, endDate: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_DAYS) {
    return { ...state, days: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_START_SLOT) {
    return { ...state, startSlot: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_END_SLOT) {
    return { ...state, endSlot: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_DISCOUNT) {
    return { ...state, discount: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_PAYMENT_ARRAY) {
    return {
      ...state,
      ...action.payload,
    };
  }
  if (action.type === ACTION_TYPE.ADD_USERS) {
    return {
      ...state,
      users: action.payload,
    };
  }
  return state;
};

const getTotalAmount = (paymentArray) => {
  return (
    paymentArray &&
    paymentArray.length > 0 &&
    paymentArray.map((x) => +x.amount).reduce((x, sum) => sum + x)
  );
};

const AddMembership = (props) => {
  const { history } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const selectedPackage = props.location.state.package;
  const sport = props.location.state.sport;

  const context = useContext(AppContext);
  const arenaData = context.arenaData[context.selectedArena.arenaId];
  const [sportDetail, setSportDetail] = useState([]);
  const [showBookingWindow, setShowBookingWindow] = useState(false);
  const [availableSlots, setAvailableSlots] = useState();
  const [loader, setLoader] = useState(false);



  const {startDate,courts,startSlot,endSlot,paymentArray,users,discount,} = state;
  const isMandatoryFieldsSelected = startDate && courts && startSlot;
  useEffect(() => {
    getPnPSportsOfArena(
      context.selectedFacility,
      context.selectedArena.arenaId
    ).then((response) => {
      const sports = response.data;
      const initialSport = sports[0];
      dispatch({
        type: ACTION_TYPE.ADD_SPORT,
        payload: {
          key: initialSport.sportId,
          value: initialSport.sportId,
          text: initialSport.sportName,
        },
      });
      setSportDetail(sports);
    });
  }, [context.selectedArena.arenaId, context.selectedFacility]);

  const checkAvailability = () => {
    setLoader(true);
    let slotPeriods = [];
    slotPeriods.push(startSlot.value);

    const queryParams = {
      sport: sport.key,
      packageId: selectedPackage.id,
      slotNo: startSlot.value,
      courtNo: state.courts.courtId,
      startDate: moment(state.startDate).format("DD-MM-YYYY"),
    };
    checkAvailabilityForPackage(
      context.selectedFacility,
      context.selectedArena.arenaId,
      queryParams
    ).then((response) => {
      setAvailableSlots(response.data);
      setShowBookingWindow(true);
      setLoader(false);
      console.log("List", response.data);
    });
  };

  const changeSlot =
    (type) =>
    (event, { value }) => {
      const slots = startSlots;
      const data = slots.find((s) => s.value === value);
      dispatch({ type, payload: data });
    };

  const updateSelectedCourt = (mode, checked) => {
    const isAdded = state.courts && state.courts.courtId === mode.courtId;
    console.log("isAddeed", isAdded);
    if (isAdded) {
      dispatch({
        type: ACTION_TYPE.ADD_COURTS,
        payload: null,
      });
    } else {
      dispatch({
        type: ACTION_TYPE.ADD_COURTS,
        payload: mode,
      });
    }
    // }
  };

  const updatePaymentArray = (updatedArray) => {
    dispatch({
      type: ACTION_TYPE.ADD_PAYMENT_ARRAY,
      payload: {
        paymentArray: updatedArray,
      },
    });
  };

  const createBooking = () => {
    setLoader(true);

    if (startSlot.text > endSlot.text) {
      return;
    }
    let bookingList = availableSlots.availableSlot.map((x) => {
      return x.bookingDate;
    });

    const postBody = {
      memberDetails: JSON.stringify(users),
      paymentInfo: JSON.stringify(paymentArray),
      sport: sport.key,
      packageId: selectedPackage.id,
      packageAmount: selectedPackage.packagePrice,
      slotNo: startSlot.value,
      courtNo: state.courts.courtId,
      bookingList: JSON.stringify(bookingList),
      individualBooking: selectedPackage.individualPackage,
      createdBy : {
        userName: context.user.userName,
        userId: context.user.userId,
    },arenaData
    };
    createMembership(
      context.selectedFacility,
      context.selectedArena.arenaId,
      postBody
    ).then((response) => {
      history.push("/extranet/membership");
      setLoader(false);
      console.log("Booking created successfully!");
    });
  };

  const selectedSport =
    state.sport && sportDetail
      ? sportDetail.find((x) => x.sportId === state.sport.value)
      : sportDetail && sportDetail[0];
  const startSlots =
    selectedSport &&
    selectedSport.timings.map((x, index) => ({
      key: index,
      value: x.timeId,
      text: `${x.interval.start} - ${x.interval.end}`,
    }));

  const courtsOption =
    selectedSport && selectedSport.inventoryCourt
      ? selectedSport.inventoryCourt.filter((x) =>
          selectedPackage.courtDetails.includes(x.courtId)
        )
      : [];

  const addUsers = (u) => {
    dispatch({ type: ACTION_TYPE.ADD_USERS, payload: u });
  };

  const addDiscount = (evt) => {
    const numericValue =
      evt.target.validity.valid && evt.target.value < 100
        ? evt.target.value
        : discount;
    dispatch({ type: ACTION_TYPE.ADD_DISCOUNT, payload: numericValue });
  };
  const totalPayment = getTotalAmount(paymentArray);

  const totalAmount = selectedPackage.packagePrice
    ? (selectedPackage.packagePrice * (100 - discount)) / 100
    : 0;
  const isSubmitAllowed = users.length > 0 && paymentArray.length > 0;
  return (
    <>
      {loader ? <GenericLoader /> : null}
      <div style={{ padding: "16px" }}>
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span>Enroll new membrship</span>
            </div>
          </div>
        </div>
      </div>
      {!showBookingWindow ? (
        <div
          style={{
            padding: "16px",
            alignItems: "center",
            display: "flex",
          }}
        >
          <div>
            <div
              style={{
                flex: 1,
                display: "flex",
                flexDirection: "row",
                marginTop: "20px",
              }}
            >
              <div style={{ marginRight: "50px" }}>
                <div
                  style={{
                    marginBottom: "10px",

                    fontWeight: "bold",
                  }}
                >
                  Start Date
                </div>
                <DatePicker
                  autoOk
                  
                  id='startDate'
                  name='startDate'
                  placeholder='Select Date...'
                  format='DD MMM yyyy'
                  onChange={(v) => {
                    dispatch({ type: ACTION_TYPE.ADD_START_DATE, payload: v });
                  }}
                  leftArrowIcon={() => <Icon name={"calendar"} />}
                  value={state.startDate}
                />
              </div>
            </div>

            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div>
                <div
                  style={{
                    marginBottom: "10px",
                    marginTop: "20px",
                    fontWeight: "bold",
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Courts
                  {/* <Checkbox
                    checked={courts.length === courtsOption.length}
                    style={{ marginRight: "7px", marginLeft: "7px" }}
                    onClick={(e, { checked }) => {
                      updateSelectedCourt("all", checked);
                    }}
                  />
                  <span style={{ fontWeight: "lighter" }}> All Courts </span> */}
                </div>
                {courtsOption.map((x) => {
                  const isActive =
                    state.courts && state.courts.courtId === x.courtId;
                  return (
                    <Button
                      onClick={() => updateSelectedCourt(x)}
                      style={{
                        marginRight: "10px",
                        backgroundColor: isActive
                          ? colors.orange[700]
                          : colors.grey[200],
                        color: isActive ? colors.blue[50] : colors.black,
                      }}
                    >
                      {x.courtName}
                    </Button>
                  );
                })}
              </div>
            </div>

            <div style={{ marginTop: "20px", marginBottom: "20px" }}>
              <div
                style={{
                  marginBottom: "10px",
                  marginTop: "10px",
                  fontWeight: "bold",
                }}
              >
                Slot timing
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Dropdown
                  onChange={changeSlot(ACTION_TYPE.ADD_START_SLOT)}
                  placeholder='Select...'
                  openOnFocus
                  selection
                  value={state.startSlot && state.startSlot.value}
                  options={startSlots}
                  style={{ marginRight: "30px" }}
                />
              </div>
            </div>

            <Button
              primary
              disabled={!isMandatoryFieldsSelected}
              onClick={checkAvailability}
            >
              Check Availability
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ padding: "16px" }}>
          <div
            style={{
              textDecoration: "underline",
              color: colors.blue[500],
              cursor: "pointer",
            }}
            onClick={() => setShowBookingWindow(false)}
          >
            {"< Back"}
          </div>
          <Segment.Group style={{ padding: "12px" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Sport:</span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{sport.text}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Dates: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {availableSlots.availableSlot[0].bookingDate +
                      " to " +
                      availableSlots.availableSlot[availableSlots.availableSlot.length-1].bookingDate}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Days: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {selectedPackage.daySelected.map((x) => x).join(", ")}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Courts: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{courts.courtName}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Timings: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{startSlot.text}</span>
                </Grid.Column>
              </Grid.Row>
              {/* <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Available Slots: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{availableSlots.availableSlot.map((x) => <div>{"BookingDate: " + x.bookingDate + " SlotNo: " + x.slotInterval.interval.start + "-" + x.slotInterval.interval.end + " CourtNo: " + x.court[0].courtId}</div>)}</span>
                </Grid.Column>
              </Grid.Row> */}
              {availableSlots.alreadyBookedSlot.length !== 0 ? (
                <Grid.Row>
                  <Grid.Column width={4}>
                    <span style={{ fontWeight: "bold" }}>
                      Unavailable Slots:{" "}
                    </span>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <span>
                      {availableSlots.alreadyBookedSlot.map((x) => (
                        <div>
                          BookingDate <b>{x.bookingDate}</b>
                          {"   SlotNo:  "}
                          <b>{x.slotInterval.interval.start}</b> <b>-</b>
                          {"  "}
                          <b>{x.slotInterval.interval.end}</b>
                          {"   CourtNo:  "}
                          <b>{courtsOption.find((c) => c.courtId === x.court.courtId)?.courtName} </b>
                        </div>
                      ))}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              ) : null}
            </Grid>
          </Segment.Group>
          {/* <Segment.Group style={{ padding: "12px", marginTop: "12px" }}>
            <div></div>
          </Segment.Group> */}
          <Segment.Group style={{ padding: "12px", marginTop: "12px" }}>
            <Form>
              <div style={{ marginBottom: "7px", fontWeight: "bold" }}>
                Customer(s) detail
              </div>
              <AddUser
                single={selectedPackage.individualPackage}
                userArray={state.users}
                setUserArray={addUsers}
              />
              <>
                <Divider horizontal>
                  <span style={{ color: colors.blue["400"] }}>Payment</span>
                </Divider>
                <Grid>
                  <Grid.Column width={4}>
                    <div style={{ marginTop: "10px", marginBottom: "10px" }}>
                      <div style={{ marginBottom: "7px", fontWeight: "bold" }}>
                        Discount
                      </div>
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <input
                          type='text'
                          pattern='^\d*\.?\d{0,4}$'
                          onInput={addDiscount}
                          value={discount}
                          placeholder='Discount'
                        />
                        <span style={{ padding: "7px", fontWeight: "bolds" }}>
                          %
                        </span>
                      </span>
                    </div>
                  </Grid.Column>
                </Grid>
                <div style={{ paddingBottom: "10px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Total Amount to be paid: Rs.
                  </span>
                  <span>{totalAmount}/-</span>
                </div>
                <div style={{ marginBottom: "7px", fontWeight: "bold" }}>
                  Payment Breakup
                </div>
                <Payment
                  paymentArray={paymentArray}
                  setPaymentArray={updatePaymentArray}
                />
                <div style={{ paddingTop: "10px" }}>
                  <span style={{ fontWeight: "bold" }}>Total Payment: Rs.</span>
                  <span>{totalPayment}/-</span>
                </div>
              </>
            </Form>
          </Segment.Group>
          <div style={{ justifyContent: "center", display: "flex", flex: 1 }}>
            <Button disabled={!isSubmitAllowed} primary onClick={createBooking}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddMembership;
