import React from "react";
import { Modal } from "semantic-ui-react";

const CustomModal = ({
  header,
  footer,
  content,
  open,
  setOpen,
  comp,
  closeOnDimmerClick,
}) => {
  return (
    <Modal
      closeOnDimmerClick={!!closeOnDimmerClick}
      dimmer={"inverted"}
      open={open}
      onClose={() => setOpen(false)}
    >
      {header ? <Modal.Header>{header}</Modal.Header> : null}
      {comp ? (
        content
      ) : (
        <>
          <Modal.Content>{content}</Modal.Content>
          <Modal.Actions>{footer}</Modal.Actions>
        </>
      )}
    </Modal>
  );
};
export default CustomModal;
