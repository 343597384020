import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../../AppContext";
import moment from "moment";
import { ROLES } from "../../../../utils/constants";
import { Button, Dropdown, Form, Divider, Modal, Loader } from "semantic-ui-react";
import { getRescheduleAvailableSlots, rescheduleBooking } from "../../../../apiclients/BookingApiClient";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import Payment from "../../Payment";

const RescheduleCapacityBooking = (props) => {
  const { selectedSportDetails, user } = useContext(AppContext);
  const context = useContext(AppContext);
  const { userData, bookingDetails, setOpen, reload, setMessage, setIsLoading, setShowRescheduleModal } = props;
  const [policyRule, setPolicyRule] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [slots, setSlots] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [selectedCourt, setSelectedCourt] = useState();
  const [rescheduleButtonDisabled, setRescheduleButtonDisabled] = useState(false);
  const [paymentArray, setPaymentArray] = useState([]);

  useEffect(() => {
    const data = selectedSportDetails.detail.policies.reschedule;
    const today = moment();
    const ms = moment(
      moment(bookingDetails.bookingDate, "MM-DD-YYYY").format("DD-MM-YYYY") +
      " " + bookingDetails.interval.start.toString() + ":00", "DD/MM/YYYY HH:mm:ss"
    ).diff(moment(today, "DD/MM/YYYY HH:mm:ss"));
    const d = moment.duration(ms);
    if (
      (data.isAllowed && data.hourRemaining < Math.floor(d.asHours())) ||
      user.role === ROLES.ADMIN || user.role === ROLES.REGIONAL_MANAGER
    ) {
      setPolicyRule({ isAllowed: true, data });
    } else {
      setPolicyRule({ isAllowed: false });
    };
  }, []);

  useEffect(() => {
    getRescheduleAvailableSlots(
      context.selectedFacility,
      context.selectedArena.arenaId,
      context.selectedSportDetails.sportId,
      selectedDate.format("DD-MM-YYYY")
    ).then((response) => {
      setIsLoading(false);
      const data = response.data;
      if (Object.keys(data).length > 0) {
        const courtInfo = {};
        const slotTime = data.result.map((x) => {
          courtInfo[x.slot.timeId] = x.availableCourts;
          return {
            key: x.slot.timeId,
            value: x.slot.timeId,
            text: x.slot.interval.start + " - " + x.slot.interval.end,
          };
        });
        setSelectedTime(slotTime[0].key);
        setSlots({ slotTime, courtInfo });
      }
    });
  }, [selectedDate]);

  const changeTime = (event, data) => {
    setSelectedTime(data.value);
  };

  const changeCourt = (event, { value }) => {
    const selected = slots.courtInfo[selectedTime].find(
      (x) => x.courtId === value
    );
    setSelectedCourt(selected);
  };

  const onRescheduleClick = () => {

    setIsLoading(true);
    setShowRescheduleModal(false);
    const arenaData = context.arenaData[context.selectedArena.arenaId];

    const postBody = {
      bookingId: userData.id,
      userName: userData.userName,
      userMobileNumber: userData.userMobileNumber || userData.mobileNumber,
      rescheduledDate: selectedDate.format("DD-MM-YYYY"),
      rescheduledTime: selectedTime,
      rescheduledCourt: selectedCourt.courtId,
      paymentInfo: JSON.stringify(paymentArray),
      sport: context.selectedSportDetails.sportId,
      role: ROLES.ADMIN,
      createdBy: {
        userName: context.user.userName,
        userId: context.user.userId,
      },
      arenaData,
      rescheduleAllowed: true
    };

    rescheduleBooking(
      context.selectedFacility,
      context.selectedArena.arenaId,
      postBody
    ).then(() => {
      setIsLoading(false);
      setOpen(false);
      reload(true);
      setMessage({
        header: "Booking Rescheduled!",
        message: "You booking has been rescheuled.",
        color: "blue",
      });
    })
      .catch((err) => {
        setOpen(false);
        setMessage({
          header: "Error : Booking not Rescheduled!",
          message: `${err.response.data}`,
          color: "red",
        });
        reload(true);
      });
  };


  const availableCourts = slots && slots.courtInfo &&
    slots.courtInfo[selectedTime].map((x) => ({
      key: x.courtId,
      value: x.courtId,
      text: x.courtName,
      bookingPrice: x.bookingPrice,
    }));
  const payableAmount = selectedCourt ? (selectedCourt.bookingPrice) * userData.numberOfCustomer - userData.amountPaid : 0;
  const totalAmount = payableAmount > 0 ? payableAmount : 0;
  const totalPayment = paymentArray.map((x) => +x.amount).reduce((x, sum) => sum + x, 0);

  return (
    <>
      <Modal.Content>
        {showLoader ? (
          <Loader size={"small"} style={{ padding: "10px" }} active inline />
        ) : (
          <div style={{ padding: "10px" }}>
            {policyRule.isAllowed ? (
              <Form>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>Date</label>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      variant='inline'
                      value={selectedDate}
                      placeholder='Select Date...'
                      onChange={setSelectedDate}
                      format='DD MMM yyyy'
                      style={{ padding: "10px", borderColor: "transparent" }}
                    />
                  </Form.Field>
                  {slots ? (
                    <>
                      <Form.Field>
                        <label style={{ marginBottom: "14px" }}>
                          Slot timing
                        </label>
                        <Dropdown
                          onChange={changeTime}
                          placeholder='Select...'
                          openOnFocus
                          selection
                          value={selectedTime}
                          options={slots.slotTime}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label style={{ marginBottom: "14px" }}>Court</label>
                        <Dropdown
                          onChange={changeCourt}
                          placeholder={availableCourts.length > 0 ? 'Select...' : 'Not available for this slot'}
                          openOnFocus
                          selection
                          value={selectedCourt && selectedCourt.courtId}
                          options={availableCourts}
                          disabled={availableCourts.length <= 0}
                        />
                      </Form.Field>

                    </>
                  ) : null}
                </Form.Group>
                {totalAmount > 0 ? (
                  <>
                    <Divider horizontal>
                      <span style={{ color: colors.blue["400"] }}>Payment</span>
                    </Divider>
                    <div style={{ paddingBottom: "10px" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Total Amount to be paid:{" "}
                      </span>
                      <span>Rs.{totalAmount}/-</span>
                    </div>
                    <Payment
                      paymentArray={paymentArray}
                      setPaymentArray={setPaymentArray}
                    />
                    <div style={{ paddingTop: "10px" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Total Payment:{" "}
                      </span>
                      <span>Rs.{totalPayment}/-</span>
                    </div>
                  </>
                ) : null}
              </Form>
            ) : (
              "Reschedule not allowed."
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>

        {((totalAmount === totalPayment) || (totalAmount === 0)) && selectedCourt ? (
          <Button
            primary
            onClick={onRescheduleClick}
            disabled={rescheduleButtonDisabled}
          >
            Reschedule
          </Button>
        ) : null}
      </Modal.Actions>
    </>
  )
};

export default RescheduleCapacityBooking;