import React, { useEffect, useContext, useState } from "react";
import { Form, Table, Button } from "semantic-ui-react";
import { AppContext } from "../../AppContext";
import GenericLoader from "../generic/GenericLoader";
import { updateGST, updateBookingCommission } from "../../apiclients/AccountsClient";


const GSTAndCommission = (props) => {
  const { selectedFacility, selectedArena, arenaData, user } = useContext(AppContext);
  const context = useContext(AppContext);
  const [gstData, setGstData] = useState();
  const [commissionData, setCommissionData] = useState();
  const [showLoader, setShowLoader] = useState();

  useEffect(() => {
    console.log('arenaData[selectedArena.arenaId]',arenaData[selectedArena.arenaId].gstDetails);
    setGstData(arenaData[selectedArena.arenaId].gstDetails);
    setCommissionData(arenaData[selectedArena.arenaId].bookingCommission);
  }, [arenaData]);// eslint-disable-line react-hooks/exhaustive-deps



  const updateGstInfo = () => {
    setShowLoader(true);
    const postBody = {
      facilityId: selectedFacility,
      arenaId: selectedArena.arenaId,
      gstData
    };
    let arenaInfo = arenaData[selectedArena.arenaId];
    arenaInfo.gstDetails = gstData;
    updateGST(postBody).then((res) => {
      context.dispatch({
        type: "upsertArenaData",
        payload: arenaInfo,
      });
      setShowLoader(false);
    });
  };

  const updateCommissionInfo = () => {
    setShowLoader(true);
    const postBody = {
      facilityId: selectedFacility,
      arenaId: selectedArena.arenaId,
      commissionData
    };
    let arenaInfo = arenaData[selectedArena.arenaId];
    arenaInfo.bookingCommission = commissionData;
    updateBookingCommission(postBody).then((res) => {
      context.dispatch({
        type: "upsertArenaData",
        payload: arenaInfo,
      });
      setShowLoader(false);
    });
  };
console.log('arenaData',arenaData);
  return (
    <div style={{ padding: "16px" }}>
      {showLoader ? <GenericLoader /> : <>
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span>GST and Commissions</span>
            </div>
          </div>
        </div>

        <div>
          <div
            style={{
              marginTop: "12px",
              marginBottom: "10px",
              fontSize: "15px",
              fontWeight: "600",
            }}
          >
            GST Details
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Heading</Table.HeaderCell>
                <Table.HeaderCell>Sub-Head</Table.HeaderCell>
                <Table.HeaderCell>CGST %</Table.HeaderCell>
                <Table.HeaderCell>SGST %</Table.HeaderCell>
                <Table.HeaderCell>IGST %</Table.HeaderCell>
                <Table.HeaderCell>HSN Code</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>

              {gstData ? <>
                <Table.Row>
                  <Table.Cell>Academy</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            academy: { ...gstData.academy, cgst: evt.target.value / 100, }
                          });
                        }}
                        value={gstData.academy.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            academy: { ...gstData.academy, sgst: evt.target.value / 100, }
                          });
                        }}
                        value={gstData.academy.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            academy: { ...gstData.academy, igst: evt.target.value / 100, }
                          });
                        }}
                        value={gstData.academy.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            academy: { ...gstData.academy, hsn: evt.target.value, }
                          });
                        }}
                        value={gstData.academy.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Booking</Table.Cell>
                  <Table.Cell>Regular </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, regularBooking: { ...gstData.booking.regularBooking, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.regularBooking.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, regularBooking: { ...gstData.booking.regularBooking, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.regularBooking.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, regularBooking: { ...gstData.booking.regularBooking, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.regularBooking.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, regularBooking: { ...gstData.booking.regularBooking, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.booking.regularBooking.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Booking</Table.Cell>
                  <Table.Cell>Third Party </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, thirdPartyBooking: { ...gstData.booking.thirdPartyBooking, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.thirdPartyBooking.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, thirdPartyBooking: { ...gstData.booking.thirdPartyBooking, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.thirdPartyBooking.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, thirdPartyBooking: { ...gstData.booking.thirdPartyBooking, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.thirdPartyBooking.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, thirdPartyBooking: { ...gstData.booking.thirdPartyBooking, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.booking.thirdPartyBooking.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Booking</Table.Cell>
                  <Table.Cell>Hudle Pass</Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, hudlePassBooking: { ...gstData.booking.hudlePassBooking, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.hudlePassBooking.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, hudlePassBooking: { ...gstData.booking.hudlePassBooking, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.hudlePassBooking.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, hudlePassBooking: { ...gstData.booking.hudlePassBooking, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.hudlePassBooking.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, hudlePassBooking: { ...gstData.booking.hudlePassBooking, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.booking.hudlePassBooking.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Booking</Table.Cell>
                  <Table.Cell>Bulk Booking </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, bulkBooking: { ...gstData.booking.bulkBooking, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.bulkBooking.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, bulkBooking: { ...gstData.booking.bulkBooking, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.bulkBooking.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, bulkBooking: { ...gstData.booking.bulkBooking, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.bulkBooking.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, bulkBooking: { ...gstData.booking.bulkBooking, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.booking.bulkBooking.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Booking</Table.Cell>
                  <Table.Cell>Group Booking </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, group: { ...gstData.booking.group, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.group.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, group: { ...gstData.booking.group, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.group.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, group: { ...gstData.booking.group, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.group.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, group: { ...gstData.booking.group, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.booking.group.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Booking</Table.Cell>
                  <Table.Cell>Individual Booking </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, individual: { ...gstData.booking.individual, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.individual.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, individual: { ...gstData.booking.individual, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.individual.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, individual: { ...gstData.booking.individual, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.individual.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, individual: { ...gstData.booking.individual, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.booking.individual.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Booking</Table.Cell>
                  <Table.Cell>Part Payment </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, partPayment: { ...gstData.booking.partPayment, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.partPayment.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, partPayment: { ...gstData.booking.partPayment, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.partPayment.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, partPayment: { ...gstData.booking.partPayment, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.booking.partPayment.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            booking: { ...gstData.booking, partPayment: { ...gstData.booking.partPayment, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.booking.partPayment.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Receipt</Table.Cell>
                  <Table.Cell>Offline Credits </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, offlineCredits: { ...gstData.receipt.offlineCredits, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.receipt.offlineCredits.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, offlineCredits: { ...gstData.receipt.offlineCredits, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.receipt.offlineCredits.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, offlineCredits: { ...gstData.receipt.offlineCredits, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.receipt.offlineCredits.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, offlineCredits: { ...gstData.receipt.offlineCredits, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.receipt.offlineCredits.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Receipt</Table.Cell>
                  <Table.Cell>Play All Tournament </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, playAllTournament: { ...gstData.receipt.playAllTournament, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.receipt.playAllTournament.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, playAllTournament: { ...gstData.receipt.playAllTournament, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.receipt.playAllTournament.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, playAllTournament: { ...gstData.receipt.playAllTournament, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.receipt.playAllTournament.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, playAllTournament: { ...gstData.receipt.playAllTournament, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.receipt.playAllTournament.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Receipt</Table.Cell>
                  <Table.Cell>Misc </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, misc: { ...gstData.receipt.misc, cgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.receipt.misc.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, misc: { ...gstData.receipt.misc, sgst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.receipt.misc.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, misc: { ...gstData.receipt.misc, igst: evt.target.value / 100, } }
                          });
                        }}
                        value={gstData.receipt.misc.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            receipt: { ...gstData.receipt, misc: { ...gstData.receipt.misc, hsn: evt.target.value, } }
                          });
                        }}
                        value={gstData.receipt.misc.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell>Wallet Recharge</Table.Cell>
                  <Table.Cell></Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            walletRecharge: { ...gstData.walletRecharge, cgst: evt.target.value / 100, }
                          });
                        }}
                        value={gstData.walletRecharge?.cgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            walletRecharge: { ...gstData.walletRecharge, sgst: evt.target.value / 100, }
                          });
                        }}
                        value={gstData.walletRecharge?.sgst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            walletRecharge: { ...gstData.walletRecharge, igst: evt.target.value / 100, }
                          });
                        }}
                        value={gstData.walletRecharge?.igst * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setGstData({
                            ...gstData,
                            walletRecharge: { ...gstData.walletRecharge, hsn: evt.target.value, }
                          });
                        }}
                        value={gstData.walletRecharge?.hsn}
                        placeholder='970899'
                      />{" "} %
                    </Form>
                  </Table.Cell>
                </Table.Row>
              </>
                : null}
            </Table.Body>
          </Table>
          <div style={{ flex: 1, display: "flex", justifyContent: "flex-end", alignItems: "flex-end", }} >
            <Button onClick={updateGstInfo} style={{ backgroundColor: "#e8632b", color: "#fff", }} >
              Update
            </Button>
          </div>
          <hr />
          <div style={{ marginTop: "22px", marginBottom: "10px", fontSize: "15px", fontWeight: "600", }} >
            Commissions
          </div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Partner</Table.HeaderCell>
                <Table.HeaderCell>Type of Transaction</Table.HeaderCell>
                <Table.HeaderCell>Commission %</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>

              {commissionData ? <> <Table.Row>
                <Table.Cell>Hudle</Table.Cell>
                <Table.Cell>General </Table.Cell>
                <Table.Cell>
                  <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                    <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                      onInput={(evt) => {
                        setCommissionData({
                          ...commissionData,
                          Hudle: { ...commissionData.Hudle, general: evt.target.value / 100, }
                        });
                      }}
                      value={commissionData.Hudle.general * 100}
                      placeholder='9'
                    />{" "} %
                  </Form>
                </Table.Cell>

              </Table.Row>
                <Table.Row>
                  <Table.Cell>Hudle</Table.Cell>
                  <Table.Cell>Pass </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setCommissionData({
                            ...commissionData,
                            Hudle: { ...commissionData.Hudle, pass: evt.target.value / 100, }
                          });
                        }}
                        value={commissionData.Hudle.pass * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>

                </Table.Row>
                <Table.Row>
                  <Table.Cell>Playo</Table.Cell>
                  <Table.Cell>General </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setCommissionData({
                            ...commissionData,
                            Playo: { ...commissionData.Playo, general: evt.target.value / 100, }
                          });
                        }}
                        value={commissionData.Playo.general * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>

                </Table.Row>

                <Table.Row>
                  <Table.Cell>KheloMore</Table.Cell>
                  <Table.Cell>General </Table.Cell>
                  <Table.Cell>
                    <Form style={{ display: "flex", justifyContent: "center", alignItems: "center", }} >
                      <input pattern='[0-9]*' style={{ marginRight: "12px", marginLeft: "12px" }} type='text'
                        onInput={(evt) => {
                          setCommissionData({
                            ...commissionData,
                            KheloMore: { ...commissionData.KheloMore, general: evt.target.value / 100, }
                          });
                        }}
                        value={commissionData.KheloMore.general * 100}
                        placeholder='9'
                      />{" "} %
                    </Form>
                  </Table.Cell>

                </Table.Row>
           
                </> : null}
            </Table.Body>
          </Table>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Button
              onClick={updateCommissionInfo}
              style={{
                backgroundColor: "#e8632b",
                color: "#fff",
              }}
            >
              Update
            </Button>
          </div>

        </div>
      </>
      }
    </div>
  );
};

export default GSTAndCommission;
