import React, { useContext, useState } from 'react';
import { colors } from "@material-ui/core";
import {
    Button,
    Dropdown,
    Form,
    Checkbox,
} from "semantic-ui-react";

import ModalPortal from '../generic/ModalPortal';
import { AppContext } from '../../AppContext';
import { editUser } from "../../apiclients/UserManagementApiClient";

const EditUser = (props) => {
    const {  showModal, setReload, userData, setMessage, isLoading } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaList = context?.arenas;

    const [userName, setUserName] = useState(userData.userName);
    const [userEmail, setUserEmail] = useState(userData.userEmail);
    const [role, setRole] = useState(userData.role);
    const [arenas, setArenas] = useState(userData.facilityIncharge);

    const roles = [
        {key: 0, value: 'Admin',text: 'Admin',}, 
        {key: 1,value: 'Partner',text: 'Partner',},
        {key: 2,value: 'Coach',text: 'Coach',},
        {key: 3,value: 'Regional Manager',text: 'Regional Manager',},
        {key: 4,value: 'Manager',text: 'Manager',},
        {key: 5,value: 'HR',text: 'HR',},
        {key: 6,value: 'Accountant',text: 'Accountant',},
        {key: 7,value: 'Sales Manager',text: 'Sales Manager',},
        {key: 8,value: 'Marketing Manager',text: 'Marketing Manager',},
        {key: 9,value: 'View Only',text: 'View Only',},
        {key: 10,value: 'Auditor',text: 'Auditor',},
        {key: 11,value: 'QC Manager',text: 'QC Manager',},
    ];
    // managers can book courts in app
    // employee can only view bookings in app
    const isSubmitAllowed =  userName && userEmail && role && arenas.length > 0;
  

    const updateAllArena = (checked) => {
        if (checked) {
            setArenas(arenaList);
        } else {
            setArenas([]);
        }
    };

    const updateArenas = (obj, arenaId) => {

        if (arenas.some(o => o.arenaId === arenaId)) {
            setArenas(arenas.filter(o => o.arenaId !== arenaId));
        } else {
            setArenas([...arenas, obj]);
        }

    };

    const changeRole = (event, { value }) => {
        setRole(value);
           };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={userName}
                            placeholder='Name'
                            onChange={(evt) => setUserName(evt.target.value)}
                            disabled

                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Email</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='email'
                            value={userEmail}
                            placeholder='Email'
                            onChange={(evt) => setUserEmail(evt.target.value)}
                            disabled

                        />
                    </Form.Field>
                   
                  </Form.Group>
                <Form.Group widths='equal'>
                <Form.Field  >

<label>Role</label>
<Dropdown
    onChange={changeRole}
    placeholder='Select...'
    openOnFocus
    selection
    value={role}
    options={roles}
    style={{ marginRight: "30px" }}
/>
</Form.Field>
</Form.Group>
                <Form.Group widths='equal'>

                    <Form.Field  >

                        <label>
                            Arenas
                            <Checkbox
                                checked={
                                    arenaList.length === arenas.length
                                }
                                style={{ marginRight: "7px", marginLeft: "7px" }}
                                onClick={(e, { checked }) => {
                                    updateAllArena(checked);
                                }}
                            />


                            <span style={{ fontWeight: "lighter" }}> All Arenas </span>
                        </label>
                        {arenaList.map((x) => {
                            const isActive = arenas.some(
                                (mode) => mode.arenaId === x.arenaId
                            );
                            return (
                                <Button
                                    key={x.arenaId}
                                    onClick={() => { updateArenas(x, x.arenaId) }}
                                    style={{
                                        marginRight: "10px",
                                        backgroundColor: isActive
                                            ? colors.orange[700]
                                            : colors.grey[200],
                                        color: isActive ? colors.blue[50] : colors.black,
                                    }}
                                >
                                    {x.arenaName}
                                </Button>
                            );
                        })}

                    </Form.Field>
                </Form.Group>

            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            
                <Button disabled={!isSubmitAllowed} primary onClick={editUserData}>  Submit</Button>
                </React.Fragment>
        );
    };

    const editUserData = () => {
        
        showModal(false);
        isLoading(true);

        const postBody = {
            facilityId, uid: userData.userId, role, arenas
        };

        editUser(
            context.selectedFacility,
            postBody
        ).then((response) => {
            if( response.status === 200 || response.status === 201){
            
             setMessage({
                header: "User Edited",
                message: "User has been edited successfully.",
                color: "green",
              });
            }else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
              });
            // ..
          });
    }
    return (
        <>
            <ModalPortal
                title='Edit User'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default EditUser;