import React, { useContext } from 'react';
import {
    Button,
} from "semantic-ui-react";

import { colors } from "@material-ui/core";
import ModalPortal from '../generic/ModalPortal';
import { AppContext } from '../../AppContext';
import { deleteExpenseOrReceipt } from '../../apiclients/AccountsClient';
const DeleteExpense = (props) => {
    const { showModal, setReload, selectedTransaction, setMessage, isLoading } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    console.log('selected Transaction is ', selectedTransaction);
    const modalContent = (
        <div>
            Are you sure you  want to delete the {selectedTransaction.subtype} Expense of Rs. {selectedTransaction.amountPaid*-1} ?
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button style={{
                    backgroundColor: colors.orange["800"],
                    color: "#fff",
                }}
                    onClick={deleteExpense}>  Delete</Button>
            </React.Fragment>
        );
    };

    const deleteExpense = () => {

        showModal(false);
        isLoading(true);

        const postBody = {
            facilityId, arenaId, transactionId : selectedTransaction.id, payment : selectedTransaction.payment,transactionDate:selectedTransaction.createdDate
        };

        deleteExpenseOrReceipt(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Expense Deleted",
                    message: "Expense has been successfully deleted.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }
    return (
        <>
            <ModalPortal
                title='Delete Expense'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default DeleteExpense;