import React, { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import history from "./services/history";
import Routes from "./routes";
import ReactGA from 'react-ga';
import RouteChangeTracker from './routes/RouteChangeTracker';
import ReactPixel from 'react-facebook-pixel';

import AppContextProvider from "./AppContext";
 

function App() {
  const TRACKING_ID = "UA-136504705-1"; // YOUR_OWN_TRACKING_ID
  ReactGA.initialize(TRACKING_ID);

  const options = {
    autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
    debug: false, // enable logs
  };
  ReactPixel.init('726202134393185', options);


  
  return (
    <AppContextProvider>
       {/* <ThemeProvider theme={theme}> */}
      <div style={{ flex: 1 }}>

        <Router history={history}>
          <RouteChangeTracker />
          <Suspense fallback={<div>Loading...</div>}>
            <Routes />
          </Suspense>
        </Router>
      </div>
      {/* </ThemeProvider> */}
    </AppContextProvider>
  );
}

export default App;
