import React, { useContext, useState } from 'react';
import {
    Button,
    Form,
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { discardProductsFromRegister } from '../../../apiclients/ProductApiClient';
const DiscardProduct = (props) => {
    const { showModal, setReload, setMessage, isLoading, product } = props;
    const { id, productName } = product;
    const availableQuantity = product.productQuantity;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const [quantity, setQuantity] = useState();
    const [remarks, setRemarks] = useState();

    const isSubmitAllowed = quantity > 0 && quantity <= availableQuantity && remarks;

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Discard Quantity</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={quantity}
                            placeholder={'Less than or equal to available quantity - ' + availableQuantity}
                            onChange={(evt) => setQuantity(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Remarks</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={remarks}
                            placeholder='Reason for discard'
                            onChange={(evt) => setRemarks(evt.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={discardProduct}>  Submit</Button>
            </React.Fragment>
        );
    };

    const discardProduct = () => {
        showModal(false);
        isLoading(true);
        const x = product;
        const price = 0; // because it is discarded
        const discount = 0;
        const netAmount = Math.round(Number(quantity)*price*(1-discount)*100)/100;
        const totalGST = Math.round((x.cgst+x.sgst+x.igst)*100)/100;
        const totalTax = Math.round((x.cgst+x.sgst+x.igst+x.cess)*100)/100;
        const totalAmount =  Number(quantity)*price;
        const cgst = x.cgst ? Math.round(x.cgst*(netAmount/(1+totalTax))*100)/100 : 0;
        const sgst = x.sgst ? Math.round(x.sgst*(netAmount/(1+totalTax))*100)/100 : 0;
        const igst = x.igst ? Math.round(x.igst*(netAmount/(1+totalTax))*100)/100 : 0;
        const cess = x.cess ? Math.round(x.cess*(netAmount/(1+totalTax))*100)/100 : 0;
        const taxableAmount = Math.round((netAmount - cgst - sgst - igst - cess)*100)/100;
        const products = [{id: x.id,name: x.productName,
            quantity: Number(quantity),discount,isRental: false,totalAmount,netAmount,
            price,cgst,sgst,igst,cess,taxableAmount,hsn : x.hsn ? x.hsn : '9996',gst:totalGST ? totalGST : 0,
          }];
          const productDetails = {productName : product.productName,
          id, quantity: Number(quantity), remarks, 
          createdBy: {
              userName: context.user.userName,
              userId: context.user.userId,
          }} ;

        let postBody = {
            products,
            productDetails,
        };

        discardProductsFromRegister(
            facilityId,
            arenaId,
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Product Discarded from the register",
                    message: "Product has been discarded successfully.",
                    color: "yellow",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }
    return (
        <>
            <ModalPortal
                title={'Discard ' + productName + ' from the Register'}
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default DiscardProduct;