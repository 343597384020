import React, { useState, useContext } from "react";

import BookinInfo from "../BookingInfo";
import { Button, Divider, Form, Modal } from "semantic-ui-react";
import { AppContext } from "../../../AppContext";
import Payment from "../Payment";
import { postExtendBookingDues } from "../../../apiclients/BookingApiClient";
import { colors } from "@material-ui/core";
import GenericLoader from "../../generic/GenericLoader";

const PayNow = ({ booking, facility, setOpen, setType, reload,setMessage, isLoading}) => {
  const context = useContext(AppContext);
  const arenaData = context.arenaData[context.selectedArena.arenaId];
  
  const [paymentArray, setPaymentArray] = useState([]);
  const [loader, setLoader] = useState(false);

  const onSubmitClicked = () => {

    isLoading(true);
    const postBody = {
      paymentInfo: JSON.stringify(paymentArray),
      sportId: context.selectedSportDetails.sportId,
      transactionId: booking.id,
      createdBy : {
        userName: context.user.userName,
        userId: context.user.userId,
      },
      arenaData
    };

    postExtendBookingDues(
      context.selectedFacility,
      context.selectedArena.arenaId,
      postBody
    ).then(() => {
      setOpen(false);
      isLoading(false);
      setMessage({
        header: "Success",
        message: 'Amount Paid successfully',
        color: "green",
      });
      reload(true);
    }).catch(err => {
      setLoader(false);
      setMessage({
        header: "Error : In Getting available courts.",
        message: `${err.response.data}`,
        color: "red",
      });
      reload(true);
    });
  };
  const totalAmount = booking.amountDue;
  const totalPayment = paymentArray
    .map((x) => +x.amount)
    .reduce((x, sum) => sum + x, 0);
  return  (
    <>
      <Modal.Content>
        <BookinInfo booking={booking} facility={facility} />
        <Form>
          {totalAmount > 0 ? (
            <>
              <Divider horizontal>
                <span style={{ color: colors.blue["400"] }}>Payment</span>
              </Divider>
              <div style={{ paddingBottom: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Total Amount to be paid:{" "}
                </span>
                <span>Rs.{totalAmount}/-</span>
              </div>
              <Payment
                paymentArray={paymentArray}
                setPaymentArray={setPaymentArray}
              />
              <div style={{ paddingTop: "10px" }}>
                <span style={{ fontWeight: "bold" }}>Total Payment: </span>
                <span>Rs.{totalPayment}/-</span>
              </div>
            </>
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setType("request")}>Go Back</Button>
        {( booking.bookingStatus === "extended" &&  booking.amountDue === totalPayment ) ||  ( booking.bookingStatus !== "extended" &&   booking.amountDue >= totalPayment && totalPayment > 0  )? 
        <Button
          style={{
            backgroundColor: "orange",
            color: "white",
            marginLeft: "10px",
          }}
          onClick={onSubmitClicked}
        >
          Submit
        </Button>
        : null }
      </Modal.Actions>
    </>
  );
};

export default PayNow;
