import React, { useContext, useState } from "react";
import { Form, Message } from "semantic-ui-react";

import { AppContext } from "../../AppContext";
import { getUserName } from "../../apiclients/DashboardApiClient";

const CustomerDetail = ({ number, name, setName, setNumber }) => {
  const context = useContext(AppContext);
  const [showMessage, setShowMessage] = useState(false);

  const fetchUserName = () => {
    if (number && number.length !== 10) {
      setShowMessage(true);
      setName("");
      return;
    }
    if (number ) {
      setShowMessage(false);
      getUserName(context.selectedFacility, number).then((response) => {
        console.log(response.data);
        setName(response.data.name);
      });
    }
  };

  return (
    <>
      <Form.Group widths='equal'>
        <Form.Field>
          <label>Mobile Number</label>
          <input
            value={number}
            type='text'
            pattern='[0-9]{10}$*'
            placeholder='e.g., 9999XXXXXX'
            onInput={setNumber}
          />
        </Form.Field>
        <Form.Field>
          <label>Customer Name</label>
          <input
            onFocus={fetchUserName}
            value={name}
            placeholder='Customer Name'
            onChange={(data) => setName(data.target.value)}
          />
        </Form.Field>
      </Form.Group>
      {showMessage && (
        <Message
          onDismiss={() => setShowMessage(false)}
          color={"red"}
          content='Add correct 10-digit phone number'
        />
      )}
    </>
  );
};

export default CustomerDetail;
