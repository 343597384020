import React, { useContext, useState, useEffect, useRef } from 'react';
import { colors } from "@material-ui/core";
import {
    Button,
    Dropdown,
    Form,
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { getAllCategories, addProductsToMaster } from '../../../apiclients/ProductApiClient';
import { CATEGORY_TYPE } from '../../../utils/constants';
const AddProductToMaster = (props) => {
    const { showModal, setReload, setMessage, isLoading } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const rawCategories = useRef([]);
    const [productName, setProductName] = useState();
    const [companyName, setCompanyName] = useState();
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [reorderLevel, setReorderLevel] = useState();
    const [availableForRent, setAvailableForRent] = useState(false);
    const [availableForAcademy, setAvailableForAcademy] = useState(false);
    const [availableForSale, setAvailableForSale] = useState(false);
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isSubCategoryAvailable, setIsSubCategoryAvailable] = useState(false);
    const [cgst, setCgst] = useState(0);
    const [igst, setIgst] = useState(0);
    const [sgst, setSgst] = useState(0);
    const [hsn, setHsn] = useState();
    const [cess, setCess] = useState(0);
    const [imageUrl, setImageUrl] = useState();
    const [productId, setProductId] = useState();

    // const isSubmitAllowed = isSubCategoryAvailable ? subCategory : true && productName && companyName && category && reorderLevel && ((cgst && sgst) || igst) && hsn && imageUrl && productId;
    const isSubmitAllowed =   productName && companyName && category  && (availableForRent || availableForAcademy || availableForSale) && ((cgst && sgst) || igst) && hsn && imageUrl && productId && (isSubCategoryAvailable ?  subCategory ? true: false   :true)  ? true :false

    useEffect(() => {
        getAllCategories(CATEGORY_TYPE.product).then((response) => {
            const availableCategories = response.data.data.categories;
            rawCategories.current = availableCategories;
            const cate = availableCategories.map((x) => {
                return { key: x.id, value: x.name, text: x.name }
            })
            setCategories(cate);
        });

    }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const changeCategory = (event, { value }) => {
        const data = rawCategories.current.find((category) => category.name === value);
        if (data.subCategories) {
            setIsSubCategoryAvailable(true);
            const subCate = data.subCategories.map((x) => {
                return { key: x.id, value: x.name, text: x.name }
            });
            setSubCategories(subCate);
        } else {
            setIsSubCategoryAvailable(false);
            setSubCategories([]);
        }
        setCategory(value);
    };
    const changeSubCategory = (event, { value }) => {
        setSubCategory(value);
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Product Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={productName}
                            placeholder='Product Name'
                            onChange={(evt) => setProductName(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Company Name</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={companyName}
                            placeholder='Company Name'
                            onChange={(evt) => setCompanyName(evt.target.value)}
                        />
                    </Form.Field>

                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >

                        <label>Select Category</label>
                        <Dropdown
                            onChange={changeCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={category}
                            options={categories}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>

                    {isSubCategoryAvailable ? <Form.Field  >

                        <label>Select Sub-Category</label>
                        <Dropdown
                            onChange={changeSubCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={subCategory}
                            options={subCategories}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field> : null}
                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Reorder Level</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={reorderLevel}
                            placeholder='Reorder Level'
                            onChange={(evt) => setReorderLevel(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>
                            Avaiable For
                        </label>
                        <Button
                            onClick={() => { setAvailableForSale(!availableForSale) }}
                            style={{
                                marginRight: "10px",
                                backgroundColor: availableForSale
                                    ? colors.orange[700]
                                    : colors.grey[200],
                                color: availableForSale ? colors.blue[50] : colors.black,
                            }}
                        >
                            Sale
                        </Button>
                        <Button
                            onClick={() => { setAvailableForRent(!availableForRent) }}
                            style={{
                                marginRight: "10px",
                                backgroundColor: availableForRent
                                    ? colors.orange[700]
                                    : colors.grey[200],
                                color: availableForRent ? colors.blue[50] : colors.black,
                            }}
                        >
                            Rental
                        </Button>
                        <Button
                            onClick={() => { setAvailableForAcademy(!availableForAcademy) }}
                            style={{
                                marginRight: "10px",
                                backgroundColor: availableForAcademy
                                    ? colors.orange[700]
                                    : colors.grey[200],
                                color: availableForAcademy ? colors.blue[50] : colors.black,
                            }}
                        >
                            Academy
                        </Button>
                    </Form.Field>


                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>CGST %</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={cgst * 100}
                            placeholder='9'
                            onChange={(evt) => setCgst(evt.target.value / 100)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>SGST %</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={sgst * 100}
                            placeholder='9'
                            onChange={(evt) => setSgst(evt.target.value / 100)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>IGST %</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={igst * 100}
                            placeholder='0'
                            onChange={(evt) => setIgst(evt.target.value / 100)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>HSN</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={hsn}
                            placeholder='98963'
                            onChange={(evt) => setHsn(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Cess %</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={cess * 100}
                            placeholder='2'
                            onChange={(evt) => setCess(evt.target.value / 100)}
                        />
                    </Form.Field>

                </Form.Group>
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Image Url</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={imageUrl}
                            // placeholder='In Rupees, inclusive of taxes'
                            onChange={(evt) => setImageUrl(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field  >
                        <label>Product Id</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={productId}
                            // placeholder='In Rupees, inclusive of taxes'
                            onChange={(evt) => setProductId(evt.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={addProductToMaster}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addProductToMaster = () => {

        showModal(false);
        isLoading(true);

        const postBody = {
            productName, companyName, category, subCategory, availableForRent, availableForSale, availableForAcademy, reorderLevel: Number(reorderLevel), cgst, sgst, igst, hsn, cess,imageUrl,productId
        };
        
        addProductsToMaster(
            facilityId,
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Product Added to the master",
                    message: "Product has been added successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }
    return (
        <>
            <ModalPortal
                title='Add Product in Master'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AddProductToMaster;