import React, { useContext, useState, useEffect, useRef } from 'react';
import {
    Button,
    Form,
    Dropdown
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { assignProductToAcademy } from '../../../apiclients/ProductApiClient';

import { getAllAcademySportsOfArena } from "../../../apiclients/AcademyClient";


const AssignToAcademy = (props) => {
    const { history, showModal, setReload, setMessage, isLoading, product,  } = props;
    const { id, productName } = product;
    const availableQuantity = product.productQuantity;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const [quantity, setQuantity] = useState();
    const [remarks, setRemarks] = useState();
    const sports = useRef([]);
    const [selectedSport, setSelectedSport] = useState({});

    const isSubmitAllowed = quantity > 0 && quantity <= availableQuantity && remarks;

    useEffect(() => {
        if (facilityId && context.selectedArena) {
            getAllAcademySportsOfArena(facilityId, arenaId).then(
                (response) => {
                    if (response.status === 200) {
                        const sport = response.data;
                        const dropdownSportsData = sport.map(
                            (x, index) => ({
                                key: x.sportId,
                                value: x.sportId,
                                text: x.sportName,
                            })
                        );
                        sports.current = dropdownSportsData;
                        setSelectedSport(dropdownSportsData[0]);
                    } else {
                        history.push("/extranet/dashboard");
                    }

                }
            ).catch((err) => {
                history.push("/extranet/dashboard");
            });

        }
    }, [facilityId, context.selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

    const changeSport = (event, { value }) => {
        const data = sports.current.find((facility) => facility.value === value);
        setSelectedSport(data);
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field>
                        <label>Select Sports</label>
                            <Dropdown
                                onChange={changeSport}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={selectedSport && selectedSport.value}
                                options={sports.current}
                            />
                            </Form.Field>
                    <Form.Field>
                        <label>Assign Quantity</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='number'
                            value={quantity}
                            placeholder={'Available quantity - ' + availableQuantity}
                            onChange={(evt) => setQuantity(evt.target.value)}
                        />
                    </Form.Field>
                    <Form.Field>
                        <label>Remarks</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={remarks}
                            placeholder='Details'
                            onChange={(evt) => setRemarks(evt.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={assignProduct}>  Submit</Button>
            </React.Fragment>
        );
    };

    const assignProduct = () => {
        showModal(false);
        isLoading(true);
        let postBody = {
            productName : product.productName,
            companyName : product.companyName,
            sellingPrice : product.sellingPrice,
            category: product.category,
            id, quantity: Number(quantity), remarks, 
            selectedSport : selectedSport,
            createdBy: {
                userName: context.user.userName,
                userId: context.user.userId,
            }
        };
        if(product.subCategory){
            postBody.subCategory = product.subCategory;
        }

        assignProductToAcademy(
            facilityId,
            arenaId,
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Product Assigned to Academy",
                    message: "Product has been assigned to academy successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }
    return (
        <>
            <ModalPortal
                title={'Assign ' + productName + ' to academy'}
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AssignToAcademy;