import React, { useReducer } from "react";
import { Icon, Select } from "semantic-ui-react";

import "./style.scss";
import { colors } from "@material-ui/core";

const options = [
  { key: "upi", value: "upi", text: "UPI" },
  { key: "cash", value: "cash", text: "Cash" },
  { key: "card", value: "card", text: "Card" },
  { key: "bankTransfer", value: "bankTransfer", text: "Bank Transfer" },
  { key: "others", value: "others", text: "Others" },
];

const initialState = {
  amount: 0,
  mode: "",
  remark: "",
};
const ACTION_TYPE = {
  ADD_AMOUNT: "addAmount",
  ADD_MODE: "addMode",
  ADD_REMARK: "addRemark",
  ADD_PAYMENT_ARRAY: "addPaymentArray",
};

const reducer = (state, action) => {
  if (action.type === ACTION_TYPE.ADD_MODE) {
    return { ...state, mode: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_AMOUNT) {
    return { ...state, amount: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_REMARK) {
    return { ...state, remark: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_PAYMENT_ARRAY) {
    return { ...state, ...action.payload };
  }
  return state;
};

const Payment = ({ paymentArray, setPaymentArray }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { mode, remark, amount } = state;

  const onChange = (type, data) => {
    if (type === ACTION_TYPE.ADD_REMARK) {
      dispatch({
        type,
        payload: data.target.value,
      });
    }
    if (type === ACTION_TYPE.ADD_AMOUNT) {
      dispatch({ type, payload: data });
    }
    if (type === ACTION_TYPE.ADD_MODE) {
      dispatch({ type, payload: data.value });
    }
  };

  const handleChange = (evt) => {
    const numericValue = evt.target.validity.valid ? Number(evt.target.value) : amount;
    onChange(ACTION_TYPE.ADD_AMOUNT, numericValue);
  };

  const onKeyPress = (event) => {
    console.log("Keycode", event.keyCode);
    if (amount && mode && event.key === "Enter") {
      addPaymentOption();
    }
  };
  const addPaymentOption = () => {
    const val = {
      amount,
      mode,
      remark,
    };
    const updatedArray = [...paymentArray, val];
    setPaymentArray(updatedArray);
    dispatch({
      type: ACTION_TYPE.ADD_PAYMENT_ARRAY,
      payload: {
        amount: 0,
        mode: "",
        remark: "",
      },
    });
  };

  const removePaymentOption = (deleteIndex) => () => {
    const updatedArray = paymentArray.filter(
      (x, index) => deleteIndex !== index
    );
    setPaymentArray(updatedArray);
  };

  return (
    <div>
      <div style={{ flexDirection: "row", display: "flex", flexWrap: "wrap" }}>
        {paymentArray.map((x, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "7px",
                padding: "2px",
                borderRadius: "4pxs",
              }}
            >
              <div
                style={{
                  backgroundColor: colors.blueGrey["50"],
                  padding: "3px",
                }}
                key={index}
              >
                <b>+</b> Rs.
                {x.amount + "   "}
                {x.mode && "  -  " + x.mode + "   "}
                {x.remark && "  -  " + x.remark}
                <Icon
                  onClick={removePaymentOption(index)}
                  name={"minus square"}
                />
              </div>
            </div>
          );
        })}
      </div>
      <div className={"row-center"}>
        <input
          type='Number'
          step=".01"
          onInput={handleChange}
          onKeyPress={onKeyPress}
          value={amount}
          placeholder='Amount'
        />
        <Select
          placeholder='Mode'
          options={options}
          value={mode}
          onChange={(event, d) => onChange(ACTION_TYPE.ADD_MODE, d)}
        />
        <input
          onKeyPress={onKeyPress}
          value={remark}
          placeholder='Remark'
          onChange={(d) => onChange(ACTION_TYPE.ADD_REMARK, d)}
        />
        <div>
          <Icon
            onClick={addPaymentOption}
            name={"add square"}
            className={"add-minus-button"}
            disabled = {!(amount && mode)}
          />
        </div>
      </div>
    </div>
  );
};

export default Payment;
