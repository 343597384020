import React, { useState } from "react";
import { Button, Table, Divider } from "semantic-ui-react";
import CustomModal from "../../generic/CustomModal";
import { colors } from "@material-ui/core";
import "../../../App.css";
import DeleteStudent from "./DeleteStudent";
import AddPayment from "./AddPayment";
import EnrollStudent from "./EnrollStudent";
import StudentAttendanceHistory from "./StudentAttendanceHistory";
import AllotProduct from './AllotProduct';

const Footer = ({ setClose, type, studentData, setDeleteModal, setPaymentModal, setRenewalModal, setAttendanceModal , setAllotmentModal}) => {
  const deleteStudent = (x) => {
    setDeleteModal(true);
  };

  const allotProductsToStudent = (x) => {
    setAllotmentModal(true);
  };

  return (

    <>
      {studentData.amountDue > 0 ?

        <Button className={"ui button booked-footer"} onClick={()=>{setPaymentModal(true)}}>
          Add Payment
        </Button> : null}
        {type !== 'deleted' ? 
        <Button className={"ui button booked-footer"} onClick={()=>{setRenewalModal(true)}}>
        Renew
      </Button>
         : null}
         <Button className={"ui button"} onClick={()=>{setAttendanceModal(true)}}>
        Attendance History
      </Button>
      {type === 'active' ?
      <>
        <Button

          onClick={() => { allotProductsToStudent(studentData) }}
        >
          Allot Product
        </Button>

        <Button

          onClick={() => { deleteStudent(studentData) }}
        >
          Delete
        </Button>
        </>
        : null}
      <Button onClick={setClose}>
        Close
      </Button>

    </>
  );
};
const Detail = ({ data }) => {

  return (
    <div>
      <Table celled padded>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <div><b>Student ID</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>
                {data.studentId}
              </div>
            </Table.Cell>

            <Table.Cell>
              <div><b>Student Name</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.studentName}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div><b>Parent Name</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.parentName}</div>
            </Table.Cell>
            <Table.Cell>
              <div><b>Customer Number</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.mobile}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div><b>Email</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div style={{ paddingRight: "7px" }}>
                    <span>{data.email}</span>
                  </div>
                </div>
              </div>
            </Table.Cell>
            <Table.Cell>
              <div><b>Date of Birth</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{new Date(data.dateOfBirth.seconds * 1000).toLocaleDateString()}</div>
            </Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>

      <Divider horizontal>
        <span style={{ color: colors.blue["400"] }}>Current Batch Details</span>
      </Divider>
      <Table celled padded>
        <Table.Body >
          <Table.Row>
            <Table.Cell>
              <div><b>Enrollment ID</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>
                {data.enrollmentId}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div><b>Duration</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{new Date(data.startDate.seconds * 1000).toLocaleDateString()} - {new Date(data.endDate.seconds * 1000).toLocaleDateString()}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div><b>Enrollment Date</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{new Date(data.createdDate.seconds * 1000).toLocaleDateString()}</div>
            </Table.Cell>
            <Table.Cell>
              <div><b>Enrolled By</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.createdBy.userName}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div><b>Total Classes</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.totalNoOfClasses}</div>
            </Table.Cell>
            <Table.Cell>
              <div><b>Available Classes</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.availableNoOfClasses}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div><b>Batch Name</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>
                {data.batchName}
              </div>
            </Table.Cell>
            <Table.Cell>
              <div><b>Batch Fees</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>Rs. {data.fees}/-</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div><b>Registration Fees</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>Rs. {data.registrationFees}/-</div>
            </Table.Cell>
            <Table.Cell>
              <div><b>Total Amount</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>Rs. {data.totalAmount}/-</div>
            </Table.Cell>
          </Table.Row>


          <Table.Row>
            <Table.Cell>
              <div><b>Discount</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.discount}%</div>
            </Table.Cell>
            <Table.Cell>
              <div><b>Net Amount</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>Rs. {data.netAmount}/-</div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <div><b>Amount Paid</b></div>
            </Table.Cell>
            <Table.Cell>
              <div>Rs. {data.amountPaid}/-</div>
            </Table.Cell>
            <Table.Cell>
              <div><b>Amount Due</b></div>
            </Table.Cell>
            <Table.Cell>
              {data.amountDue > 0 ?
                <div><span style={{ color: colors.red[500] }}> Rs. {data.amountDue}/-</span></div>
                :
                <div> Rs. {data.amountDue}/-</div>}

            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <div><b>Payment History</b></div>
            </Table.Cell>
            <Table.Cell>
              {data?.history
                ? data?.history.map((item, indx) => {
                  return (
                    <div key={indx}>
                      <div>
                        <b>Payment Entry: {indx + 1}</b>
                      </div>
                      <div>
                        Transaction:{" "}
                        {item.transactionId +
                          " ( " +
                          new Date(item.creationDate.seconds * 1000).toLocaleDateString() +
                          " )"}
                      </div>
                      <div>Amount Paid: {item.amountPaid}</div>
                    </div>
                  );
                })
                : null}

            </Table.Cell>
            {data.remarks === "" ?
              <>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell></> :
              <>
                <Table.Cell>
                  <div><b>Remarks</b></div>
                </Table.Cell>
                <Table.Cell>
                  <div>{data.remarks}</div>
                </Table.Cell>
              </>
            }
          </Table.Row>


        </Table.Body>
      </Table>
    </div>
  );
};
const StudentDetail = ({ setShowDetails, selectedData, history, setReload, setMessage, isLoading, selectedSport, type }) => {

  const [deleteModal, setDeleteModal] = useState(false); // to show delete modal
  const [allotmentModal, setAllotmentModal] = useState(false); // to show allotment modal
  const [paymentModal, setPaymentModal] = useState(false); // to show Payment modal
  const [renewalModal, setRenewalModal] = useState(false); // to show Renewal modal
  const [attendanceModal, setAttendanceModal] = useState(false); // to show attendance history modal
  const setClose = () => {
    setShowDetails(false);
  };
  return (
    <>
      {deleteModal ? (
        <DeleteStudent
          history={history}
          showModal={setDeleteModal}
          setReload={setReload}
          studentData={selectedData}
          setMessage={setMessage}
          isLoading={isLoading}
          selectedSport={selectedSport}
        />
      ) : paymentModal ?
        <AddPayment 
          history={history}
          showModal={setPaymentModal}
          setReload={setReload}
          studentData={selectedData}
          setMessage={setMessage}
          isLoading={isLoading}
          selectedSport={selectedSport}
        />
        : renewalModal ? 
        <EnrollStudent
        history={history}
        showModal={setRenewalModal}
        setReload={setReload}
        setMessage={setMessage}
        isLoading={isLoading}
        selectedSport={selectedSport}
        isRenewal = {true}
        renewalDetails = {selectedData}
    />
        : attendanceModal ? 
        <StudentAttendanceHistory 
        studentData={selectedData}
        showModal={setAttendanceModal}
        />
        : allotmentModal ? 
          <AllotProduct
            history={history}
            showModal={setAllotmentModal}
            setReload={setReload}
            studentData={selectedData}
            setMessage={setMessage}
            isLoading={isLoading}
            selectedSport={selectedSport}
          />
        :
        <CustomModal
          header={"Student Detail"}
          content={<Detail data={selectedData} />}
          footer={<Footer setClose={setClose} type={type} studentData={selectedData} setDeleteModal={setDeleteModal}  setPaymentModal={setPaymentModal} setRenewalModal={setRenewalModal} setAttendanceModal={setAttendanceModal} setAllotmentModal={setAllotmentModal}/>}
          setOpen={setClose}
          open={!!selectedData}
        />}

    </>
  );
};

export default StudentDetail;
