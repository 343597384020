import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import SideNavbar from "../components/side-navbar/SideNavbar";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import Header from "../components/Header";
import { AppContext } from "../AppContext";

const RouteWrapper = ({ component: Component, ...rest }) => {
  const { user } = useContext(AppContext);

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const props = { ...routeProps };

        return !!user ? (
          <>
            <Header />
            <div>
              <SideNavbar />
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <div
                  style={{
                    paddingLeft: "80px",
                    position: "absolute",
                    top: "50px",
                    left: "0px",
                    right: "0px",
                    bottom: "0px",
                    overflowY: "scroll",
                  }}
                >
                  <Component {...props} />
                </div>
              </MuiPickersUtilsProvider>
            </div>
          </>
        ) : (
          <Redirect to={"/extranet/admin"} />
        );
      }}
    />
  );
};

export default RouteWrapper;
