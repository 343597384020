import React, { useEffect, useContext, useState, useRef } from "react";

import { colors } from "@material-ui/core";
import { Button, Icon, Table, Dropdown } from "semantic-ui-react";
import "../../dashboard/style.scss";

import { AppContext } from "../../../AppContext";
import GenericLoader from "../../generic/GenericLoader";
import { getBatches, getAllAcademySportsOfArena } from "../../../apiclients/AcademyClient";
import AddBatch from "./AddBatch";
import DeleteBatch from "./DeleteBatch";
import EditBatch from "./EditBatch";
import Toast from "../../generic/Toast";


const BatchManagement = (props) => {
    const { history } = props;
    const context = useContext(AppContext);
    const { selectedFacility, selectedArena, arenaData, dispatch } = context;

    const [batches, setBatches] = useState([]);
    const [selectedBatch, setSelectedBatch] = useState({}); // for edit or deleting the user
    const sports = useRef([]);
    const [selectedSport, setSelectedSport] = useState({});
    const [allAcademySports, setAllAcademySports] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false); // to show delete modal
    const [addModal, setAddModal] = useState(false); // to show add modal
    const [editModal, setEditModal] = useState(false); // to show edit modal

    const [reload, setReload] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});

    const loadData = (sportData) => {
        return getBatches(
            context.selectedFacility,
            context.selectedArena.arenaId,
            sportData.value,
        ).then((response) => {
            const data = response.data;
            setBatches(data);
            setisLoading(false);
        });
    };

    useEffect(() => {
        if (reload) {
            loadData(selectedSport).then(() => {
                setReload(false);
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 2000);
            });
        }
    }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (context.selectedFacility && context.selectedArena) {
            getAllAcademySportsOfArena(selectedFacility, selectedArena.arenaId).then(
                (response) => {
                    if(response.status === 200){
                        const sport = response.data;
                        const dropdownSportsData = sport.map(
                            (x, index) => ({
                                key: x.sportId,
                                value: x.sportId,
                                text: x.sportName,
                            })
                        );
                        setAllAcademySports(dropdownSportsData);
                        sports.current = dropdownSportsData;
                        setSelectedSport(dropdownSportsData[0]);
                        loadData(dropdownSportsData[0]);
                    } else {
                        console.log('this was called');
                         history.push("/extranet/dashboard");
                    }
                   
                }
            ).catch((err)=>{
                history.push("/extranet/dashboard");
            });

        }
    }, [context.selectedFacility, context.selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (selectedArena && arenaData[selectedArena.arenaId]) {
            const dropdownSportsData = allAcademySports.map(
                (x, index) => ({
                    key: x.sportId,
                    value: x.sportId,
                    text: x.sportName,
                })
            );
            sports.current = dropdownSportsData;
            setSelectedSport(dropdownSportsData[0]);
            //   loadData(selectedFacility,selectedArena.arenaId, dropdownSportsData[0] );
        }
    }, [arenaData]); // eslint-disable-line react-hooks/exhaustive-deps

    const changeSport = (event, { value }) => {
        const data = sports.current.find((facility) => facility.value === value);
        setSelectedSport(data);
        loadData(data);
    };

    const openDeleteModal = (batch) => {
        console.log('selected sport is ', selectedSport);
        setSelectedBatch(batch);
        setDeleteModal(true);
    };

    const openEditModal = (batch) => {
        setSelectedBatch(batch);
        setEditModal(true);
    };


    const getDaysName = (days) => {
        return days.map(x=>x.text+' ');
        
    };




    return isLoading ? (
        <GenericLoader />
    ) : (
        <div style={{ padding: "16px" }}>

            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Academy Batches (Monthly)</span>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>
                            <Dropdown
                                onChange={changeSport}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={selectedSport && selectedSport.value}
                                options={sports.current}
                            />
                            <Button
                                style={{ marginLeft: "20px" }}
                                onClick={() => setAddModal(true)}
                                name={"Add Package"}
                            >
                                <Icon name={"add"} />
                                Add a Batch
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            <div style={{ paddingTop: "16px" }}>
                <Toast
                    showMessage={showMessage}
                    setShowMessage={setShowMessage}
                    message={messageContent.message}
                    messageHeader={messageContent.header}
                    color={messageContent.color}
                />
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>S.No</Table.HeaderCell>
                            <Table.HeaderCell>Batch Name</Table.HeaderCell>
                            <Table.HeaderCell>Skills</Table.HeaderCell>
                            <Table.HeaderCell>Days</Table.HeaderCell>
                            <Table.HeaderCell>Timings</Table.HeaderCell>
                            <Table.HeaderCell>Monthly Fees</Table.HeaderCell>
                            <Table.HeaderCell>Registration Fees</Table.HeaderCell>
                            <Table.HeaderCell>No. Of Days</Table.HeaderCell>
                            <Table.HeaderCell>Remarks</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {batches.map((x, index) => {
                            return (
                                <Table.Row key={index} >
                                    <Table.Cell>{index + 1}</Table.Cell>
                                    <Table.Cell>{x.batchName}</Table.Cell>
                                    <Table.Cell>{x.skill}</Table.Cell>
                                    <Table.Cell>{getDaysName(x.days)}</Table.Cell>
                                    <Table.Cell>{x.startTime}-{x.endTime} </Table.Cell>
                                    <Table.Cell>Rs. {x.fees} /-</Table.Cell>
                                    <Table.Cell>Rs. {x.registrationFees} /-</Table.Cell>
                                    <Table.Cell>{x.numberOfDays} </Table.Cell>
                                    <Table.Cell>{x.remarks} </Table.Cell>

                                    <Table.Cell
                                        style={{
                                            flex: 1,
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            display: "flex",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                padding: "8px",
                                                fontSize: "12px",
                                                marginBottom: "8px",
                                                backgroundColor: colors.orange["800"],
                                                color: "#fff",
                                            }}
                                            onClick={() => openEditModal(x)}
                                        >
                                            Edit
                                        </Button>

                                        <Button
                                            style={{
                                                padding: "8px",
                                                fontSize: "12px",
                                                marginBottom: "8px",
                                            }}
                                            onClick={() => openDeleteModal(x)}
                                        >
                                            Delete
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>

                            );
                        })}
                    </Table.Body>
                </Table>
            </div>

            <br/>
                                How to Add, Edit or Delete Batch ?
                                <div className="player">
                                    <iframe width="300" height="150"src="https://www.youtube.com/embed/HoG3pWuOO9g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"  allow="fullscreen;"></iframe>
                                    </div>
            {addModal ? (
                <AddBatch
                    history={history}
                    showModal={setAddModal}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                    selectedSport={selectedSport}
                />
            ) : null}
            {deleteModal ? (
                <DeleteBatch
                    history={history}
                    showModal={setDeleteModal}
                    setReload={setReload}
                    batchData={selectedBatch}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                    selectedSport={selectedSport}
                />
            ) : null}
            {editModal ? (
                <EditBatch
                    history={history}
                    showModal={setEditModal}
                    setReload={setReload}
                    batchData={selectedBatch}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                    selectedSport={selectedSport}
                />
            ) : null}
        </div>
    );


};

export default BatchManagement;