import React, { useContext, useState, useEffect, useRef } from 'react';
import { colors } from "@material-ui/core";
import {
    Button,
    Dropdown,
    Form,
} from "semantic-ui-react";

import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { getAllCategories, addProductsToCafeRegister, editProductsToCafeRegister, getProductsFromCategorySubCategory } from '../../../apiclients/ProductApiClient';
import { CATEGORY_TYPE } from '../../../utils/constants';
const EditProductToCafeRegister = (props) => {
    const { showModal, setReload, setMessage, isLoading, data } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const rawCategories = useRef([]);
    const [category, setCategory] = useState();
    const [subCategory, setSubCategory] = useState();
    const [productQuantity, setProductQuantity] = useState();
    const [sellingPrice, setSellingPrice] = useState();
    const [imageUrl, setImageUrl] = useState();
    const [productId, setProductId] = useState();

    const [rentalPrice, setRentalPrice] = useState();
    const [categories, setCategories] = useState([]);
    const [subCategories, setSubCategories] = useState([]);
    const [isSubCategoryAvailable, setIsSubCategoryAvailable] = useState(false);
    const [isProductAvailable, setIsProductAvailable] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [listOfAvailableProduct, setListOfAvailableProduct] = useState({});
    const [selectedProduct, setSelectedProduct] = useState(props.data.productName);

    const isSubmitAllowed = selectedProduct && productQuantity > 0 && sellingPrice > -1 && imageUrl && productId ?true:false ;

    useEffect(() => {
        if (props.data) {
            getAllCategories(CATEGORY_TYPE.cafe).then((response) => {
                const availableCategories = response.data.data.categories;
                rawCategories.current = availableCategories;
                const cate = availableCategories.map((x) => {
                    return { key: x.id, value: x.name, text: x.name }
                })
                setCategories(cate);
                const data = availableCategories.find((category) => category.name === props.data.category);
                // setSelectedProduct();
                // setSubCategory();
                console.log("data", data)
                if (data.subCategories) {
                    setIsSubCategoryAvailable(true);
                    setIsProductAvailable(false);
                    const subCate = data.subCategories.map((x) => {
                        return { key: x.id, value: x.name, text: x.name }
                    });
                    setSubCategories(subCate);
                    // setSelectedProduct();
                    setSubCategory(props.data.subCategory)
                    getProductData(props.data.category, props.data.subCategory);
                    //
                    setSelectedProduct(props.data.id)
                    //  console.log("props.data.productName",props.data.productName)
                } else {
                    getProductData(props.data.category);
                    setIsSubCategoryAvailable(false);
                    setSubCategories([]);
                    setSelectedProduct(props.data.id)


                }
                setCategory(props.data.category)
                setProductQuantity(props.data.productQuantity)
                setSellingPrice(props.data.sellingPrice)
                setImageUrl(props.data.imageUrl)
                setProductId(props.data.productId)
            });
            // console.log("props.data", props.data)
            // changeCategory()
            // setCategory(data.category)
            // setSubCategory(data.subCategory)

        }

    }, [props.data]);
    // console.log("selectedProduct", selectedProduct)
    // useEffect(() => {
    //     getAllCategories(CATEGORY_TYPE.cafe).then((response) => {
    //         const availableCategories = response.data.data.categories;
    //         rawCategories.current = availableCategories;
    //         const cate = availableCategories.map((x) => {
    //             return { key: x.id, value: x.name, text: x.name }
    //         })
    //         setCategories(cate);
    //     });

    // }, []);// eslint-disable-line react-hooks/exhaustive-deps

    const getProductData = (cat, subCat) => {
        getProductsFromCategorySubCategory(facilityId, cat, subCat, CATEGORY_TYPE.cafe).then((response) => {
            const products = response.data;
            if (products.length === 0) {
                setIsProductAvailable(false);
                setShowErrorMessage(true);
            } else {
                setShowErrorMessage(false);
                const pro = products.map((x) => {
                    return { key: x.id, value: x.id, text: x.productName, ...x }
                });
                setListOfAvailableProduct(pro);
                setIsProductAvailable(true);
            }
        })
    }

    const changeCategory = (event, { value }) => {
        const data = rawCategories.current.find((category) => category.name === value);
        setSelectedProduct();
        setSubCategory();
        if (data.subCategories) {
            setIsSubCategoryAvailable(true);
            setIsProductAvailable(false);
            const subCate = data.subCategories.map((x) => {
                return { key: x.id, value: x.name, text: x.name }
            });
            setSubCategories(subCate);
        } else {
            getProductData(value);
            setIsSubCategoryAvailable(false);
            setSubCategories([]);
        }
        setCategory(value);
    };
    const changeSubCategory = (event, { value }) => {
        setSelectedProduct();
        setSubCategory(value);
        getProductData(category, value);
    };
    const changeProduct = (event, { value }) => {
        console.log("value", value)
        setSelectedProduct(value);
    };

    const modalContent = (
        <div>
            <Form>
                <Form.Group widths='equal'>
                    <Form.Field  >

                        <label>Select Category</label>
                        <Dropdown
                            onChange={changeCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={category}
                            options={categories}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field>

                    {isSubCategoryAvailable ? <Form.Field  >

                        <label>Select Sub-Category</label>
                        <Dropdown
                            onChange={changeSubCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={subCategory}
                            options={subCategories}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field> : null}
                    {/* <Form.Field  >
                        <label>Select Sub-Category</label>
                        <Dropdown
                            onChange={changeSubCategory}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={subCategory}
                            options={subCategories}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field> */}

                    {isProductAvailable ? <Form.Field  >

                        <label>Select Product</label>
                        <Dropdown
                            onChange={changeProduct}
                            placeholder='Select...'
                            openOnFocus
                            selection
                            value={selectedProduct}
                            options={listOfAvailableProduct}
                            style={{ marginRight: "30px" }}
                        />
                    </Form.Field> : null}
                </Form.Group>
                {showErrorMessage ? <div>
                    <p>Stock is not avaiable for selected category.</p>
                </div> :
                    <Form.Group widths='equal'>
                        <Form.Field  >
                            <label>Quantity</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='number'
                                value={productQuantity}
                                placeholder='Product Quantity'
                                onChange={(evt) => setProductQuantity(evt.target.value)}
                            />
                        </Form.Field>
                        <Form.Field  >
                            <label>Selling Price</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='number'
                                value={sellingPrice}
                                placeholder='In Rupees, inclusive of taxes'
                                onChange={(evt) => setSellingPrice(evt.target.value)}
                            />
                        </Form.Field>
                        <Form.Field  >
                            <label>Image Url</label>
                            <input
                                style={{ marginRight: "6px" }}
                                type='text'
                                value={imageUrl}
                                placeholder='In Rupees, inclusive of taxes'
                                onChange={(evt) => setImageUrl(evt.target.value)}
                            />
                        </Form.Field>
                    </Form.Group>
                }
                <Form.Group widths='equal'>
                    <Form.Field  >
                        <label>Product Id</label>
                        <input
                            style={{ marginRight: "6px" }}
                            type='text'
                            value={productId}
                            // placeholder='In Rupees, inclusive of taxes'
                            onChange={(evt) => setProductId(evt.target.value)}
                        />
                    </Form.Field>
                </Form.Group>
            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal({ state: false, data: {} })} className='ui button'>Cancel</Button>

                <Button disabled={!isSubmitAllowed} primary onClick={addProductToRegister}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addProductToRegister = () => {

        showModal({ state: false, data: {} });
        isLoading(true);
        let postBody = listOfAvailableProduct.find((x) => x.id === selectedProduct);
        postBody.productQuantity = Number(productQuantity);
        postBody.sellingPrice = Number(sellingPrice);
        postBody.id = props.data.id
        postBody.imageUrl = imageUrl;
        postBody.productId = productId
        postBody.createdBy = {
            userName: context.user.userName,
            userId: context.user.userId,
        };
        editProductsToCafeRegister(
            facilityId,
            arenaId,
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Product Added to the register",
                    message: "Product has been added successfully.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }
    return (
        <>
            <ModalPortal
                title='Edit Product in Register'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal({ state: false, data: {} })}
            />
        </>

    );


}

export default EditProductToCafeRegister;