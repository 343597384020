import React, { useState, useContext, useEffect } from "react";

import BookinInfo from "../BookingInfo";
import { Button, Divider, Form, Modal } from "semantic-ui-react";
import { AppContext } from "../../../AppContext";
import Payment from "../Payment";
import { colors } from "@material-ui/core";
import GenericLoader from "../../generic/GenericLoader";
import {
  getBookingDetails,
  updatePayment,
} from "../../../apiclients/BookingApiClient";

const ChangePaymentMode = ({
  booking,
  facility,
  setOpen,
  setType,
  reload,
  setMessage,
}) => {
  const context = useContext(AppContext);
  const [paymentArray, setPaymentArray] = useState([]);
  const [loader, setLoader] = useState(false);
  const [detail, setDetail] = useState();

  useEffect(() => {
    setLoader(true);
    getBookingDetails(
      context.selectedFacility,
      context.selectedArena.arenaId,
      booking.id
    ).then((res) => {
      setLoader(false);
      setDetail(res?.data?.data);
    });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const onSubmitClicked = () => {
    setLoader(true);
    const postBody = {
      paymentInfo: JSON.stringify(paymentArray),
    };

    updatePayment(
      context.selectedFacility,
      context.selectedArena.arenaId,
      booking.id,
      postBody
    )
      .then(() => {
        setOpen(false);
        setLoader(false);
        setMessage({
          header: "Success",
          message: "Amount Paid successfully",
          color: "green",
        });
        reload(true);
      })
      .catch((err) => {
        setLoader(false);
        setMessage({
          header: "Error : In Getting available courts.",
          message: `${err.response.data}`,
          color: "red",
        });
        reload(true);
      });
  };

  const totalAmount = detail ? detail.totalAmount : booking.bookingPrice;
  const totalPayment = paymentArray
    .map((x) => +x.amount)
    .reduce((x, sum) => sum + x, 0);
  return loader ? (
    <GenericLoader />
  ) : (
    <>
      <Modal.Content>
        <BookinInfo booking={booking} facility={facility} />
        <Form>
          {totalAmount > 0 ? (
            <>
              <Divider horizontal>
                <span style={{ color: colors.blue["400"] }}>Payment</span>
              </Divider>
              <div style={{ paddingBottom: "10px" }}>
                <span style={{ fontWeight: "bold" }}>
                  Total Amount to be paid:{" "}
                </span>
                <span>Rs.{totalAmount}/-</span>
              </div>
              <Payment
                paymentArray={paymentArray}
                setPaymentArray={setPaymentArray}
              />
              <div style={{ paddingTop: "10px" }}>
                <span style={{ fontWeight: "bold" }}>Total Payment: </span>
                <span>Rs.{totalPayment}/-</span>
              </div>
            </>
          ) : null}
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setType("request")}>Go Back</Button>
        {totalAmount === totalPayment ? (
          <Button
            style={{
              backgroundColor: "orange",
              color: "white",
              marginLeft: "10px",
            }}
            onClick={onSubmitClicked}
          >
            Submit
          </Button>
        ) : null}
      </Modal.Actions>
    </>
  );
};

export default ChangePaymentMode;
