import React from "react";
import { Message } from "semantic-ui-react";

import "./loader.css";

const Toast = ({
  showMessage,
  setShowMessage,
  message,
  messageHeader,
  isWarning,
  color,
}) => {
  return (
    <div className={showMessage ? "messagetoast" : "messagetoast opacity0"}>
      <Message
        color={color}
        warning={isWarning}
        onDismiss={() => setShowMessage(false)}
        header={messageHeader}
        content={message}
      />
    </div>
  );
};

export default Toast;
