import React, { useContext, useEffect } from "react";
import { Dropdown, Icon } from "semantic-ui-react";

// import { playAll } from "../images/index";
import playAll from '../images/PlayAll_logo_blue.png'; 
import "./Header.css";
import { AppContext } from "../AppContext";
import { getFacilityDetail } from "../apiclients/DashboardApiClient";
import { useHistory } from "react-router-dom";
import { clearToken } from "../utils/localStorageFunction";
import { getPnPSportsOfArena } from "../apiclients/BookingApiClient";

const Header = ({ navigation }) => {
  const context = useContext(AppContext);
  let history = useHistory();

  const userOptions = [
    {
      key: "sign-out",
      text: "Sign Out",
      onClick: () => {
        clearToken();
        context.dispatch({type: 'clearContext'});
        history.push("/extranet/signIn");
      },
    },
  ];

  const facilitiesData = context?.arenas;
  const facilities = facilitiesData.map((x) => ({
    key: x.arenaId,
    value: x.arenaId,
    text: x.arenaName,
  }));

  const currentFacility = context.selectedArena
    ? facilities.find(
        (facility) => facility.value === context.selectedArena.arenaId
      )
    : null;

  useEffect(() => {
    const arenaId = context.selectedArena.arenaId;

    if (!context.arenaData[arenaId]) {
      getFacilityDetail(context.selectedFacility, arenaId).then((data) => {
        context.dispatch({
          type: "upsertArenaData",
          payload: {
            arenaId,
            ...data.data,
            selectedFacilityId: context.selectedFacility,
          },
        });
      });
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  const changeFacility = async (event, { value }) => {
    const data = facilities.find((facility) => facility.value === value);
    const selectedfacilityDetails = {
      arenaName: data.text,
      arenaId: data.value,
    };
    await getPnPSportsOfArena(context.selectedFacility, data.value).then(
      (response) => {
        const sports = response.data;
        context.dispatch({
          type: "addCurrentFacilitySports",
          payload: sports,
        });
      }
    );
    await context.dispatch({
      type: "updateSelectedFacility",
      payload: selectedfacilityDetails,
    });
    
   await getFacilityDetail(context.selectedFacility, data.value).then((data) => {
      const facilityData = data.value;
      context.dispatch({
        type: "upsertArenaData",
        payload: {
          facilityData,
          ...data.data,
          selectedFacilityId: context.selectedFacility,
        },
      });
    });
  };

  const trigger = (
    <span>
      <Icon name='user' /> {context.user.userName}
    </span>
  );

  return (
    <header>
      <div className='app-header'>
        <span
          style={{
            paddingTop: 10,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <img
          alt=''
            src={playAll}
            style={{
              width: "70px",
              height: "34px",
            }}
          />
        </span>
        <span style={{ margin: 7 }}>
          <Dropdown
            onChange={changeFacility}
            search
            selection
            wrapSelection={false}
            value={currentFacility.value}
            options={facilities}
            placeholder='Choose an option'
          />
        </span>
        <span
          style={{
            justifyContent: "flex-end",
            display: "flex",
            flex: 1,
            margin: "7px",
          }}
        >
          {/* <Button style={{ backgroundColor: "#e8632b", color: "#fff" }}>
            Add Lead
          </Button> */}
        </span>
        <span
          style={{
            display: "flex",
            margin: "7px",
            alignSelf: "center",
          }}
        >
          <Dropdown trigger={trigger} options={userOptions} />
        </span>
      </div>
    </header>
  );
};

export default Header;
