import React, { useEffect, useContext, useState, useReducer } from "react";

import "../dashboard/style.scss";
import { Button, Dropdown, Form, Checkbox, Message } from "semantic-ui-react";
import { AppContext } from "../../AppContext";

import { colors } from "@material-ui/core";
import {
  getPnPSportsOfArena,
  updateSportPrice,
} from "../../apiclients/BookingApiClient";
import SlotSelection from '../common/SlotSelection';

const daysArray = [
  { label: "Sunday", key: "sunday", value: 0 },
  { label: "Monday", key: "monday", value: 1 },
  { label: "Tuesday", key: "tuesday", value: 2 },
  { label: "Wednesday", key: "wednesday", value: 3 },
  { label: "Thursday", key: "thursday", value: 4 },
  { label: "Friday", key: "friday", value: 5 },
  { label: "Saturday", key: "saturday", value: 6 },
];
const initialState = {
  sport: "",
  days: [],
  courts: [],
  startSlot: "",
  endSlot: "",
  price: 0,
  isValidSlot: false,
};

const ACTION_TYPE = {
  ADD_SPORT: "addSport",
  ADD_COURTS: "addCourts",
  ADD_DAYS: "addDays",
  ADD_START_SLOT: "addStartSlot",
  ADD_END_SLOT: "addEndSlot",
  ADD_PRICE: "addPrice",
};

const reducer = (state, action) => {
  if (action.type === ACTION_TYPE.ADD_SPORT) {
    return { ...state, sport: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_COURTS) {
    return { ...state, courts: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_DAYS) {
    return { ...state, days: action.payload };
  }
   if (action.type === ACTION_TYPE.ADD_START_SLOT) {
     return {
       ...state,
       startSlot: action.payload.data,
       isValidSlot: action.payload.isValidRange,
     };
   }
   if (action.type === ACTION_TYPE.ADD_END_SLOT) {
     return {
       ...state,
       endSlot: action.payload.data,
       isValidSlot: action.payload.isValidRange,
     };
   }
  if (action.type === ACTION_TYPE.ADD_PRICE) {
    return { ...state, price: action.payload };
  }
  if (action.type === ACTION_TYPE.CLEAR_DATA) {
    return initialState;
  }
  return state;
};

const UpdateSlotPricing = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const context = useContext(AppContext);
  const [sportDetail, setSportDetail] = useState([]);
  const [showMessage, setShowMessage] = useState(false);

  const dropdownSportsData = context.arenaData[
    context.selectedArena.arenaId
  ]?.sports.map((x, index) => ({
    key: x.sportId,
    value: x.sportId,
    text: x.sportName,
  }));

  const { sport, days, courts, startSlot, endSlot, price, isValidSlot } = state;
    const isMandatoryFieldsSelected =
    sport &&
    days.length > 0 &&
    courts.length > 0 &&
    +price && isValidSlot;
  useEffect(() => {
    getPnPSportsOfArena(
      context.selectedFacility,
      context.selectedArena.arenaId
    ).then((response) => {
      const sports = response.data;
      const initialSport = sports[0];
      dispatch({
        type: ACTION_TYPE.ADD_SPORT,
        payload: {
          key: initialSport.sportId,
          value: initialSport.sportId,
          text: initialSport.sportName,
        },
      });
      setSportDetail(sports);
    });
  }, [context.selectedArena.arenaId, context.selectedFacility]);

  const changeSport = (event, { value }) => {
    const data = dropdownSportsData.find(
      (facility) => facility.value === value
    );
    dispatch({ type: ACTION_TYPE.ADD_SPORT, payload: data });
  };

    const changeSlot = (data, isStart, isValidRange) => {
      const actionType = isStart
        ? ACTION_TYPE.ADD_START_SLOT
        : ACTION_TYPE.ADD_END_SLOT;
      dispatch({ type: actionType, payload: { data, isValidRange } });
    };

  const updateSelectedCourt = (mode, checked) => {
    const courts = state.courts;
    if (mode === "all") {
      dispatch({
        type: ACTION_TYPE.ADD_COURTS,
        payload: !checked ? [] : selectedSport.inventoryCourt,
      });
    } else {
      const isAdded = courts.some((x) => x.courtId === mode.courtId);
      if (isAdded) {
        dispatch({
          type: ACTION_TYPE.ADD_COURTS,
          payload: courts.filter((x) => x.courtId !== mode.courtId),
        });
      } else {
        dispatch({
          type: ACTION_TYPE.ADD_COURTS,
          payload: [...courts, mode],
        });
      }
    }
  };

  const updateSelectedDays = (mode, checked) => {
    const daysSelected = state.days;
    if (mode === "all") {
      dispatch({
        type: ACTION_TYPE.ADD_DAYS,
        payload: !checked ? [] : daysArray,
      });
    } else {
      const isAdded = daysSelected.some((x) => x.value === mode.value);
      if (isAdded) {
        dispatch({
          type: ACTION_TYPE.ADD_DAYS,
          payload: daysSelected.filter((x) => x.value !== mode.value),
        });
      } else {
        dispatch({
          type: ACTION_TYPE.ADD_DAYS,
          payload: [...daysSelected, mode],
        });
      }
    }
  };

  const updatePrice = () => {
    const val = {};
    const timing = {};
    const lastIndex = selectedSport.timings.findIndex(x => x.timeId === endSlot.value);
    for (let i = startSlot.key; i <= lastIndex; i++) {
      timing[selectedSport.timings[i].timeId] = +price;
    }
    courts.forEach((x) => {
      val[x.courtId] = timing;
    });
    const postBody = {
      days: days.map((x) => x.key),
      values: val,
    };
    console.log('postbody', postBody, lastIndex, endSlot, startSlot)
    updateSportPrice(
      context.selectedFacility,
      context.selectedArena.arenaId,
      sport.key,
      postBody
    ).then(() => {
      setShowMessage(true);
      dispatch({
        type: ACTION_TYPE.CLEAR_DATA,
      });
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
    });
  };

  const selectedSport =
    state.sport && sportDetail
      ? sportDetail.find((x) => x.sportId === state.sport.value)
      : sportDetail && sportDetail[0];

  const courtsOption =
    selectedSport && selectedSport.inventoryCourt
      ? selectedSport.inventoryCourt
      : [];

  function handleNumberChange(evt) {
    const numericValue = evt.target.validity.valid ? evt.target.value : 0;
    dispatch({ type: ACTION_TYPE.ADD_PRICE, payload: numericValue });
  }

  return (
    <>
      <div
        style={{
          padding: "16px",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div>
          <div>
            <div
              style={{
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Sport
            </div>
            <Dropdown
              onChange={changeSport}
              placeholder='Select...'
              openOnFocus
              selection
              value={state.sport && state.sport.value}
              options={dropdownSportsData}
            />
          </div>

          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div>
              <div
                style={{
                  marginBottom: "10px",
                  marginTop: "20px",
                  fontWeight: "bold",
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Courts
                {courtsOption.length > 1 && (
                  <>
                    <Checkbox
                      checked={
                        courts.length === selectedSport?.inventoryCourt.length
                      }
                      style={{ marginRight: "7px", marginLeft: "7px" }}
                      onClick={(e, { checked }) => {
                        updateSelectedCourt("all", checked);
                      }}
                    />
                    <span style={{ fontWeight: "lighter" }}> All Courts </span>
                  </>
                )}
              </div>
              {courtsOption.map((x) => {
                const isActive = state.courts.some(
                  (mode) => mode.courtId === x.courtId
                );
                return (
                  <Button
                    onClick={() => updateSelectedCourt(x)}
                    style={{
                      marginRight: "10px",
                      backgroundColor: isActive
                        ? colors.orange[700]
                        : colors.grey[200],
                      color: isActive ? colors.blue[50] : colors.black,
                    }}
                  >
                    {x.courtName}
                  </Button>
                );
              })}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div>
              <div
                style={{
                  marginBottom: "10px",
                  marginTop: "20px",
                  fontWeight: "bold",
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Days
                <Checkbox
                  checked={state.days.length === 7}
                  style={{ marginRight: "7px", marginLeft: "7px" }}
                  onClick={(e, { checked }) =>
                    updateSelectedDays("all", checked)
                  }
                />
                <span style={{ fontWeight: "lighter" }}> All Days </span>
              </div>
              {daysArray.map((x) => {
                const isActive = state.days.some(
                  (mode) => mode.value === x.value
                );
                return (
                  <Button
                    key={x.value}
                    onClick={() => updateSelectedDays(x)}
                    style={{
                      marginRight: "10px",
                      backgroundColor: isActive
                        ? colors.orange[700]
                        : colors.grey[200],
                      color: isActive ? colors.blue[50] : colors.black,
                    }}
                  >
                    {x.label}
                  </Button>
                );
              })}
            </div>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <SlotSelection
              startSlot={state.startSlot}
              endSlot={state.endSlot}
              sportTimings={(selectedSport && selectedSport.timings) || []}
              changeSlot={changeSlot}
            />
          </div>
          <Form style={{ marginTop: "20px", marginBottom: "20px" }}>
            <Form.Group>
              <Form.Field>
                <label>Updated Price</label>
                <input
                  type='text'
                  value={price}
                  placeholder='Amount in Rs.'
                  onInput={handleNumberChange}
                />
              </Form.Field>
            </Form.Group>
          </Form>
          <Button
            primary
            disabled={!isMandatoryFieldsSelected}
            onClick={updatePrice}
          >
            Update
          </Button>
          {showMessage && (
            <Message
              onDismiss={() => setShowMessage(false)}
              header='Price update'
              content='The changes has been updated successfully!'
            />
          )}
        </div>
      </div>
    </>
  );
};

export default UpdateSlotPricing;
