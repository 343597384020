import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Form, Segment } from "semantic-ui-react";
import NumberInput from "../../../lib/NumberInput";
import { getAcademyProducts } from "../../../apiclients/DashboardApiClient";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { Input } from "@material-ui/core";




const AcademyProductSelections = ({
  facilityId, arenaId,
  sportId,
  selectedItems,
  setSelectedItems,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {

    if (data.length === 0) {
      getAcademyProducts(facilityId, arenaId, sportId).then((response) => {
        console.log('the  response are ', response);
        const dataWithValue = response.data.data.map((x) => ({
          ...x,
          text: x.productName,
          value: x.id,
          key: x.id,
        }));
        setData(dataWithValue);
      });
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps



  const updateProductArray = (type, id, quantityAvailable) => (value) => {
    // if (value <= quantityAvailable) {
    const updatedItems = selectedItems.map((x) => {
      if (x.key === id) {
        return { ...x, [type]: Number(value) };
      }
      return x;
    });
    console.log('updated Items', updatedItems);
    setSelectedItems(updatedItems);
    // }
  };

  const backUpText = (
    <div>
      No Products added yet. Please add product from Rates and Inventory
      section.
    </div>
  );
  return data.length > 0 ? (
    <Form style={{ marginBottom: "12px" }}>

      <Form.Group widths='equal'>
        <Form.Field>
          <label>Search a Product</label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="productName"
            multiple
            onChange={setSelectedItems}
            options={data}
            placeholder="Type a product..."
            selected={selectedItems}
          />
        </Form.Field>
      </Form.Group>
      {selectedItems &&
        selectedItems.map((x) => {
          return (
            <Segment key={x.id}>
              <div>Product Name: {x.text}</div>
              <div>
                {"Selling Price"}: Rs.
                {x.sellingPrice}/-
              </div>

              <Form.Group wproductIdths='equal'>
                <Form.Field>
                  <label>Quantity</label>
                  <NumberInput
                    placeholder={'Available stock ' + x.productQuantity}
                    onChange={updateProductArray("quantityAdded", x.id, x.productQuantity)}
                  />

                </Form.Field>
              </Form.Group>
            </Segment>
          );
        })}
    </Form>
  ) : (
    backUpText
  );
};

export default AcademyProductSelections;
