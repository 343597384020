import React, { useContext, useEffect, useState } from 'react';
import { getMonthlyAnalyticsData, getWeeklyAnalyticsData } from '../../apiclients/AnalyticsClient';
import { AppContext } from '../../AppContext';
import { Line } from 'react-chartjs-2';
import { ANALYTICS_COLORS, ARENA_ANALYTICS_COLORS } from '../../utils/constants';
import moment from 'moment';
import GenericLoader from '../generic/GenericLoader';
import { Tab, } from "semantic-ui-react";


const Dashboard = ({ data, booking }) => {
    const occupancyGraphData = data;
    return (
        <div style={{ color: "#000" }}>
            <h3>{booking ? "% of Booking User Retained" : "% of Academy User Retained"} </h3>
            <h2>Badminton </h2>
            {occupancyGraphData && <Line
                data={occupancyGraphData.SP001}
                width={100}
                height={15}
                pointRadius={1}
                showLine={false}

                options={{
                    // maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                show: true
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                show: false
                            }
                        }]
                    }
                }}
            />}
            <h2>Box-Cricket </h2>
            {occupancyGraphData &&
                <Line
                    data={occupancyGraphData.SP003}
                    width={100}
                    height={15}
                    pointRadius={1}
                    showLine={false}

                    options={{
                        // maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    show: true
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    show: false
                                }
                            }]
                        }
                    }}
                />}
            <h2>Swimming </h2>
            {occupancyGraphData &&
                <Line
                    data={occupancyGraphData.SP011}
                    width={100}
                    height={15}
                    pointRadius={1}
                    showLine={false}

                    options={{
                        // maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    show: true
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    show: false
                                }
                            }]
                        }
                    }}
                />}
            <h2>Futsal </h2>
            {occupancyGraphData && <Line
                data={occupancyGraphData.SP002}
                width={100}
                height={15}
                pointRadius={1}
                showLine={false}

                options={{
                    // maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                show: true
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                show: false
                            }
                        }]
                    }
                }}
            />}

            <h2>Table-Tennis </h2>
            {occupancyGraphData && <Line
                data={occupancyGraphData.SP004}
                width={100}
                height={15}
                pointRadius={1}
                showLine={false}

                options={{
                    // maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                show: true
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                show: false
                            }
                        }]
                    }
                }}
            />}
            <h2>Bowling Machine </h2>
            {occupancyGraphData && <Line
                data={occupancyGraphData.SP009}
                width={100}
                height={15}
                pointRadius={1}
                showLine={false}

                options={{
                    // maintainAspectRatio: false,
                    scales: {
                        xAxes: [{
                            gridLines: {
                                show: true
                            }
                        }],
                        yAxes: [{
                            gridLines: {
                                show: false
                            }
                        }]
                    }
                }}
            />}
        </div>
    )
}



const MonthlyDashboard = () => {

    const { selectedFacility, arenas } = useContext(AppContext);
    const [bookingRetentionGraphData, setBookingRetentionGraphData] = useState(); //const [occupancyGraphData, setOccupancyGraphData] = useState();
    const [academyRetentionGraphData, setAcademyRetentionGraphData] = useState();
    const [loading, setLoading] = useState(true);

    const [activeIndex, setActiveIndex] = useState(0);


    useEffect(() => {
        getMonthlyAnalyticsData(selectedFacility).then(response => {
            let bookingData = createLineItemBookingData(response.data);
            setBookingRetentionGraphData(bookingData);
            let academyData = createLineItemAcademyData(response.data);
            setAcademyRetentionGraphData(academyData);
            setLoading(false);
        })
    }, []);

    const createLineItemBookingData = (data) => {
        let lineData = {};
        // use the data to update  in a format that we get arrays of data for each arena for each sport
        const { sportsDetail } = data[data.length - 1];
        for (var i = 0; i < sportsDetail.length; i++) {
            let labels = [];
            let dataSets = [];

            const facilities = sportsDetail[i].facilityIds;
            for (var u = 0; u < facilities.length; u++) {
                const facilityName = arenas.find(x => x.arenaId === facilities[u]).arenaName;
                let dataForSlot = {
                    label: facilityName,
                    data: [],
                    borderColor: ARENA_ANALYTICS_COLORS[facilities[u]],
                    backgroundColor: ARENA_ANALYTICS_COLORS[facilities[u]],
                    tension: 0.3,
                    fill: false,
                    hidden: false,
                };
                for (var z = 0; z < data.length; z++) {

                    if (data[z][sportsDetail[i].id]) {
                        labels.length < data.length && labels.push(data[z].id);
                        const analyticsData = data[z][sportsDetail[i].id].find(x => x.arenaId === facilities[u]);
                        analyticsData && dataForSlot.data.push(analyticsData.bookingUserRetention * 100);
                    }
                }
                dataSets.push(dataForSlot);
            }
            lineData[sportsDetail[i].id] = {
                labels: labels,
                datasets: dataSets
            };
        }
        return lineData;
    }

    const createLineItemAcademyData = (data) => {
        let lineData = {};
        // use the data to update  in a format that we get arrays of data for each arena for each sport
        const { sportsDetail } = data[data.length - 1];
        for (var i = 0; i < sportsDetail.length; i++) {
            let labels = [];
            let dataSets = [];

            const facilities = sportsDetail[i].facilityIds;
            for (var u = 0; u < facilities.length; u++) {
                const facilityName = arenas.find(x => x.arenaId === facilities[u]).arenaName;
                let dataForSlot = {
                    label: facilityName,
                    data: [],
                    borderColor: ARENA_ANALYTICS_COLORS[facilities[u]],
                    backgroundColor: ARENA_ANALYTICS_COLORS[facilities[u]],
                    tension: 0.3,
                    fill: false,
                    hidden: false,
                };
                for (var z = 0; z < data.length; z++) {
                    if (data[z][sportsDetail[i].id]) {
                        labels.length < data.length && labels.push(data[z].id);
                        const analyticsData = data[z][sportsDetail[i].id].find(x => x.arenaId === facilities[u]);
                        analyticsData && dataForSlot.data.push(analyticsData.academyUserRetention * 100);
                    }
                }
                dataSets.push(dataForSlot);
            }
            lineData[sportsDetail[i].id] = {
                labels: labels,
                datasets: dataSets
            };

        }
        return lineData;
    }

    const panes = [
        {
            menuItem: "Booking Retention Data",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <Dashboard
                        data={bookingRetentionGraphData}
                        booking={true}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Academy Retention Data",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <Dashboard
                        data={academyRetentionGraphData}
                        booking={false}
                    />
                </Tab.Pane>
            ),
        },
    ];


    return loading ? (
        <GenericLoader />
    ) : (
        <div style={{ paddingTop: "16px" }}>
            <Tab
                activeIndex={activeIndex}
                onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                renderActiveOnly={true}
                menu={{
                    color: "orange",
                    pointing: true,
                }}
                panes={panes}
            />
        </div>

    );
};

export default MonthlyDashboard;