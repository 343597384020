import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";
import { BOOKING_SOURCES } from "./../utils/constants";

export const getCancellationPolicy = (
  facilityName,
  arenaId,
  sport,
  slot,
  bookingDate
) => {
  const url = getURLWithQueryParams(
    API_URLS.FACILITY_POLICY,
    { type: "cancellation", sport, slot, bookingDate },
    {
      facilityId: facilityName,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};

export const getRescheduleAvailableSlots = (
  facilityName,
  arenaId,
  sport,
  newBookingDate
) => {
  const url = getURLWithQueryParams(
    API_URLS.RESCHEDULE_AVAILABLE_SLOTS,
    { newBookingDate, sport },
    {
      facilityId: facilityName,
      arenaId,
    }
  );
  return tokenAxiosInstance.get(url);
};

export const rescheduleBooking = (facilityName, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.RESCHEDULE_BOOKING, undefined, {
    facilityId: facilityName,
    arenaId,
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const cancelRegularBooking = (facilityId, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.CANCEL_BOOKING, null, {
    facilityId,
    arenaId,
  });

  const body = { ...postBody };
  return tokenAxiosInstance.post(url, body);
};

export const releaseSlot = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.RELEASE_SLOTS, null, {});
  return tokenAxiosInstance.post(url, postBody);
};

export const getExtendAvailableCourts = (facilityId, arenaId, params) => {
  const url = getURLWithQueryParams(API_URLS.EXTEND_AVAILABLE_COURTS, params, {
    facilityId,
    arenaId,
  });

  return tokenAxiosInstance.get(url);
};

export const extendBooking = (facilityId, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.EXTEND_BOOKING, null, {
    facilityId,
    arenaId,
  });

  const body = { ...postBody, source: BOOKING_SOURCES.PLAY_ALL_WEB };
  return tokenAxiosInstance.post(url, body);
};

export const postExtendBookingDues = (facilityId, arenaId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.PAY_DUE_EXTENSION, null, {
    facilityId,
    arenaId,
  });

  const body = { ...postBody, source: BOOKING_SOURCES.PLAY_ALL_WEB };
  return tokenAxiosInstance.post(url, body);
};

export const getPnPSportsOfArena = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(API_URLS.FACILITY_ARENA_SPORTS, null, {
    facilityId,
    arenaId,
  });

  return tokenAxiosInstance.get(url);
};

export const getAllSportsOfArena = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(API_URLS.FACILITY_ARENA_ALL_SPORTS, null, {
    facilityId,
    arenaId,
  });

  return tokenAxiosInstance.get(url);
};

export const getAllPolicy = (facilityName, arenaId, sport, type) => {
  const url = getURLWithQueryParams(
    API_URLS.GET_POLICIES,
    { sport, type },
    {
      facilityId: facilityName,
      arenaId,
    }
  );

  return tokenAxiosInstance.get(url);
};

export const updatePolicies = (facilityId, arenaId, sport, type, value) => {
  const url = getURLWithQueryParams(API_URLS.UPDATE_POLICIES, null, {
    facilityId,
    arenaId,
  });

  const body = { type, sport, value };
  return tokenAxiosInstance.post(url, body);
};

export const updateSportPrice = (facilityId, arenaId, sportId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.SPORT_PRICING, null, {
    facilityId,
    arenaId,
    sportId,
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const updatePayment = (facilityId, arenaId, bookingId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.CHANGE_PAYMENT_MODE, undefined, {
    facilityId,
    arenaId,
    bookingId,
  });
  return tokenAxiosInstance.post(url, postBody);
};

export const getBookingDetails = (facilityId, arenaId, bookingId) => {
  const url = getURLWithQueryParams(API_URLS.BOOKING_DETAILS, undefined, {
    facilityId,
    arenaId,
    bookingId,
  });
  return tokenAxiosInstance.get(url);
};
