import moment from "moment";

const initialFilterState = {
  startDate: moment(),
  endDate: moment(),
  paymentMode: {},
  bookingModes: [],
  isFilterApplied: false,
};

const FILTERS_ACTION_TYPE = {
  ADD_START_DATE: "addStartDate",
  ADD_END_DATE: "addEndDate",
  ADD_PAYMENT_MODES: "addPaymentMode",
  ADD_BOOKING_MODES: "addBookingModes",
  RESET: "reset",
};

const filterReducer = (state, action) => {
  if (action.type === FILTERS_ACTION_TYPE.ADD_START_DATE) {
    let m = moment(action.payload).utcOffset(0);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    m.toISOString();
    m.format();
    return {
      ...state,
      startDate: m,
      isFilterApplied: true,
    };
  }
  if (action.type === FILTERS_ACTION_TYPE.ADD_END_DATE) {
    let m = moment(action.payload).utcOffset(0);
    m.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
    m.toISOString();
    m.format();
    return {
      ...state,
      endDate: m,
      isFilterApplied: true,
    };
  }
  if (action.type === FILTERS_ACTION_TYPE.ADD_PAYMENT_MODES) {
    return {
      ...state,
      paymentMode: action.payload,
      isFilterApplied: true,
    };
  }
  if (action.type === FILTERS_ACTION_TYPE.ADD_BOOKING_MODES) {
    return {
      ...state,
      bookingModes: action.payload,
      isFilterApplied: true,
    };
  }
  if (action.type === FILTERS_ACTION_TYPE.RESET) {
    return initialFilterState;
  }
  return state;
};

export { initialFilterState, FILTERS_ACTION_TYPE, filterReducer };
