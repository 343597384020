import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Form, Segment } from "semantic-ui-react";
import NumberInput from "../../lib/NumberInput";
import { getCafeProducts } from "../../apiclients/DashboardApiClient";

import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015
import { Input } from "@material-ui/core";


const CafeProductSelections = ({
  facility,
  selectedItems,
  setSelectedItems,
  requestFromBookingModal,
}) => {
  const [data, setData] = useState([]);
  useEffect(() => {

    if (data.length === 0) {
      getCafeProducts(facility.id, facility.arenaId).then((response) => {
        const dataWithValue = response.data.products.map((x) => ({
          ...x,
          text: x.productName,
          value: x.id,
          key: x.id,
        }));
        setData(dataWithValue);
      });
    }
  }, []);// eslint-disable-line react-hooks/exhaustive-deps



  const updateProductArray = (type, id, quantityAvailable) => (value) => {
    // if (value <= quantityAvailable) {
      const updatedItems = selectedItems.map((x) => {
        if (x.key === id) {
          return { ...x, [type]:  value };
        }
        return x;
      });
      setSelectedItems(updatedItems);
    // }
  };

  const backUpText = requestFromBookingModal ? null : (
    <div>
      No Cafe Products added yet. Please add product from Cafe Stock Register
    </div>
  );
  return data.length > 0 ? (
    <Form style={{ marginBottom: "12px" }}>

      <Form.Group widths='equal'>
        <Form.Field>
          <label>Search a Cafe Product</label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="productName"
            multiple
            onChange={setSelectedItems}
            options={data}
            placeholder="Type a product..."
            selected={selectedItems}
          />
        </Form.Field>
      </Form.Group>
      {selectedItems &&
        selectedItems.map((x) => {
          return (
            <Segment key={x.id}>
            
              <div>Product Name: {x.text}</div>
              <div>Selling Price: Rs.
                { x.sellingPrice}/-
              </div>

              <Form.Group wproductIdths='equal'>
                <Form.Field>
                  <label>Quantity</label>
                  <NumberInput
                    placeholder={'Available stock ' + x.productQuantity}
                    onChange={updateProductArray("quantityAdded", x.id, x.productQuantity)}
                  />
                 
                </Form.Field>
                <Form.Field>
                  <label>Discount</label>
                  <NumberInput
                    placeholder='Discount'
                    onChange={updateProductArray("discount", x.id)}
                  />
                </Form.Field>
              </Form.Group>
            </Segment>
          );
        })}
    </Form>
  ) : (
    backUpText
  );
};

export default CafeProductSelections;
