import React, { useState, useEffect, useContext, useReducer } from "react";
import { Button, Dropdown, Form, Message } from "semantic-ui-react";
import GenericLoader from "../generic/GenericLoader";
import NumberInput from "../../lib/NumberInput";
import { getPnPSportsOfArena } from "../../apiclients/BookingApiClient";
import { addProduct } from "../../apiclients/ProductApiClient";
import { AppContext } from "../../AppContext";

const initialState = {
  sport: "",
  productName: "",
  company: "",
  quantity: "",
  rent: 0,
  price: 0,
};

const ACTION_TYPE = {
  ADD_SPORT: "addSport",
  ADD_NAME: "addName",
  ADD_COMPANY: "addCompany",
  ADD_QUANTITY: "addQuantity",
  ADD_RENT: "addRent",
  ADD_PRICE: "addPrice",
};

const reducer = (state, action) => {
  if (action.type === ACTION_TYPE.ADD_SPORT) {
    return { ...state, sport: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_NAME) {
    return { ...state, productName: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_COMPANY) {
    return { ...state, company: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_QUANTITY) {
    return { ...state, quantity: +action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_RENT) {
    return { ...state, rent: +action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_PRICE) {
    return { ...state, price: +action.payload };
  }
  if (action.type === ACTION_TYPE.CLEAR_STATE) {
    return initialState;
  }

  return state;
};

const AddProduct = () => {
  const [loader, setLoader] = useState();
  const [sports, setSports] = useState([]);
  const [state, dispatch] = useReducer(reducer, initialState);
  const [showMessage, setShowMessage] = useState(false);

  const context = useContext(AppContext);
  const dropdownSportsData = context.arenaData[
    context.selectedArena.arenaId
  ]?.sports.map((x, index) => ({
    key: x.sportId,
    value: x.sportId,
    text: x.sportName,
  }));

  useEffect(() => {
    getPnPSportsOfArena(
      context.selectedFacility,
      context.selectedArena.arenaId
    ).then((response) => {
      const sports = response.data;
      setSports(sports);
    });
  }, [context.selectedArena.arenaId, context.selectedFacility]);
  
  const changeSport = (event, { value }) => {
    const data = dropdownSportsData.find(
      (facility) => facility.value === value
    );
    dispatch({ type: ACTION_TYPE.ADD_SPORT, payload: data });
  };

  const addNewProduct = () => {
    setLoader(true);

    addProduct(
      context.selectedFacility,
      context.selectedArena.arenaId,
      state
    ).then((x) => {
      setLoader(false);
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 2000);
      dispatch({ type: ACTION_TYPE.CLEAR_STATE });
      console.log(x.data.message);
    });
  };

  return (
    <>
      {loader ? <GenericLoader /> : null}
      <div style={{ padding: "16px" }}>
        <div className='dashboard' style={{ marginBottom: "16px" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span>Add New Product</span>
            </div>
          </div>
        </div>

        <Form widths={"equal"} style={{ marginBottom: "12px" }}>
          <Form.Field>
            <label>Product Name</label>
            <input
              value={state.productName}
              placeholder='Name'
              onChange={(data) =>
                dispatch({
                  type: ACTION_TYPE.ADD_NAME,
                  payload: data.target.value,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Company Name</label>
            <input
              //   onFocus={fetchUserName}
              value={state.company}
              placeholder='Name'
              onChange={(data) =>
                dispatch({
                  type: ACTION_TYPE.ADD_COMPANY,
                  payload: data.target.value,
                })
              }
            />
          </Form.Field>
          {/* <Form.Group wproductIdths='equal'> */}
          <Form.Field>
            <label>Sports</label>
            <Dropdown
              onChange={changeSport}
              placeholder='Select...'
              openOnFocus
              selection
              value={state.sport && state.sport.value}
              options={dropdownSportsData}
            />
          </Form.Field>
          <Form.Field>
            <label>Quantity</label>
            <NumberInput
              value={state.quantity}
              placeholder='e.g., 10'
              onChange={(data) =>
                dispatch({
                  type: ACTION_TYPE.ADD_QUANTITY,
                  payload: data,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Rental Price</label>
            <NumberInput
              value={state.rent}
              placeholder='Rental Price'
              onChange={(data) =>
                dispatch({
                  type: ACTION_TYPE.ADD_RENT,
                  payload: data,
                })
              }
            />
          </Form.Field>
          <Form.Field>
            <label>Selling Price</label>
            <NumberInput
              value={state.price}
              placeholder='Selling Price'
              onChange={(data) =>
                dispatch({
                  type: ACTION_TYPE.ADD_PRICE,
                  payload: data,
                })
              }
            />
          </Form.Field>
          {/* </Form.Group> */}
          <Button type='submit' onClick={() => addNewProduct()}>
            Submit
          </Button>
          {showMessage && (
            <Message
              onDismiss={() => setShowMessage(false)}
              header='Product Added'
              content='Product has been added successfully!'
            />
          )}
        </Form>
      </div>
    </>
  );
};

export default AddProduct;
