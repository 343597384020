import React, { useEffect, useState } from 'react';
import { getAssetLink } from '../apiclients/AssetLinkApiClient';

function Assetlink() {
  const [assetLinks, setAssetLinks] = useState(null);

  useEffect(() => {
    getAssetLink().then((response) => {
        console.log('Asset links:', response.data);
        setAssetLinks(response.data);
      })
      .catch(error => {
        console.error('Error fetching assetlinks.json:', error);
      });
  }, []);

  return (
    <div>
      {/* Render the asset links JSON here */}
      {assetLinks && (
        <pre>{JSON.stringify(assetLinks, null, 2)}</pre>
      )}
    </div>
  );
}

export default Assetlink;