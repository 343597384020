import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";

export const getUserDetails = (facilityId) => {
    const url = getURLWithQueryParams(
        API_URLS.GET_USER_DETAILS
    );
    const body = { facilityId };

    return tokenAxiosInstance.get(url,{
        params:body
    });
}

export const addUser = (facilityId, postBody) => {
    const url = getURLWithQueryParams(API_URLS.ADD_USER, null, {
        facilityId,
      });
      return tokenAxiosInstance.post(url, postBody);
}

export const editUser = (facilityId, postBody) => {
    const url = getURLWithQueryParams(API_URLS.EDIT_USER, null, {
        facilityId,
      });
      return tokenAxiosInstance.patch(url, postBody);
}

export const deleteUser = (facilityId, postBody) => {
    const url = getURLWithQueryParams(API_URLS.DELETE_USER, null, {
        facilityId,
      });
      return tokenAxiosInstance.delete(url, {data : postBody});
}

export const resetPassword = ( postBody) => {
    const url = getURLWithQueryParams(API_URLS.RESET_PASSWORD, null, {});
      return tokenAxiosInstance.post(url, postBody);
}

export const allowCheckin = ( postBody) => {
    const url = getURLWithQueryParams(API_URLS.ALLOW_CHECKIN, null, {});
      return tokenAxiosInstance.patch(url, postBody);
}