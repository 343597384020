import React, { useEffect, useContext, useState, useReducer } from "react";
import { Button, Icon, Modal, Table } from "semantic-ui-react";

import "../dashboard/style.scss";
import { AppContext } from "../../AppContext";
import {  loadEnquiryTransactions, loadReleaseReport } from "../../apiclients/ReportsApiClient";
import {
  convertFirstoreDate,
  convertFirstoreDateForExcel,
} from "../../utils/helperFunctions";
import CustomModal from "../generic/CustomModal";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import {
  filterReducer,
  FILTERS_ACTION_TYPE,
  initialFilterState,
} from "./bookingReportReducer";
import GenericLoader from "../generic/GenericLoader";
import ExportToExcel from "../generic/ExportToExcel";
import {  ROLES } from "../../utils/constants";
import moment from "moment";
import Toast from "../generic/Toast";
import EnquiryDetails from "./EnquiryDetails";





const FilterFooter = ({ setShowFilter, onApply }) => {
  return (
    <Modal.Actions>
      <Button className={"ui button booked-footer"} onClick={onApply}>
        Apply
      </Button>
      <Button onClick={() => setShowFilter(false)}>Cancel</Button>
    </Modal.Actions>
  );
};

const FilterContent = ({ onApply, setShowFilter, selectedFilters, role }) => {
  const [filters, dispatch] = useReducer(filterReducer, {
    ...initialFilterState,
    ...selectedFilters,
  });
  const { startDate, endDate,  } = filters;

  const onReset = () => {
    dispatch({ type: FILTERS_ACTION_TYPE.RESET });
  };
  const onFilterApplyClicked = () => {
    onApply(filters);
  };
  


  return (
    <>
      <Modal.Header>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Filters</span>
          <span
            style={{
              textTransform: "uppercase",
              fontSize: "14px",
              color: colors.orange[500],
            }}
            onClick={onReset}
          >
            Clear all
          </span>
        </div>
      </Modal.Header>
      <Modal.Content>
        <div>
          <div
            style={{
              paddingTop: "20px",
              paddingBottom: "10px",
              fontWeight: "bold",
            }}
          >
            Enquiry Date
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div style={{ fontWeight: "bold" }}>Start Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={startDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_START_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}

              maxDate={moment()}
            />
            <div style={{ fontWeight: "bold" }}>End Date</div>
            <KeyboardDatePicker
              autoOk
              variant='inline'
              value={endDate}
              placeholder='Select Date...'
              onChange={(date) =>
                dispatch({
                  type: FILTERS_ACTION_TYPE.ADD_END_DATE,
                  payload: date,
                })
              }
              format='DD MMM yyyy'
              style={{ padding: "10px" }}


              minDate={!(role === ROLES.ADMIN) ? 
                role === ROLES.HR ? moment().subtract(30, 'days')
                :moment().subtract(7, 'days') : undefined}
              maxDate={moment()}
            />
          </div>
          
        </div>
      </Modal.Content>
      <FilterFooter
        onApply={onFilterApplyClicked}
        setShowFilter={setShowFilter}
      />
    </>
  );
};

const BookingFilters = ({
  setShowFilter,
  showFilter,
  selectedFilters,
  onApply,
  role
}) => {
  return (
    <CustomModal
      comp={true}
      content={
        <FilterContent
          onApply={onApply}
          selectedFilters={selectedFilters}
          setShowFilter={setShowFilter}
          role={role}
        />
      }
      setOpen={setShowFilter}
      open={showFilter}
    />
  );
};
const ReleaseReport = () => {
  const { selectedFacility, selectedArena, user } = useContext(AppContext);
  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState(initialFilterState);
  const [showFilter, setShowFilter] = useState(false);
  const [loadData, setLoadData] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});
  const [totalSlotReleased, setTotalSlotReleased] = useState(0);

  useEffect(() => {
    if (!loadData) {
      setLoadData(true);
    }
  }, [selectedArena]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (loadData) {
      const params = {
        startDate: selectedFilters.startDate,
        endDate: selectedFilters.endDate,
      };
      loadReleaseReport(
        selectedFacility,
        params
      ).then((res) => {
        setData(res.data.data);
        setTotalSlotReleased(res.data.data.length);
         setLoadData(false);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    }
  }, [loadData]); // eslint-disable-line react-hooks/exhaustive-deps


 
  let tableHeader = [
      {
        label: "Release Date",
        value: (col) => new Date(col.createdAt.seconds * 1000).toLocaleDateString(),
      },
      {
        label: "Release Time",
        value: (col) => new Date(col.createdAt.seconds * 1000).toLocaleTimeString(),
      },

      {
        label: "Arena",
        value: (col) => col.arenaName,
      },

      {
        label: "Sport",
        value: (col) => col.sportName &&  col.sportName ,
      },

      
      {
        label: "Court",
        value: (col) => col.courtName &&  col.courtName ,
      },
      {
        label: "Booking Date",
        value: (col) => new Date(col?.bookingDate?.seconds * 1000).toLocaleDateString(),
      },
      {
        label: "Slot",
        value: (col) => col.slotName &&  col.slotName ,
      },

      {
        label: "Type",
        value: (col) => col.bookingType &&  col.bookingType ,
      },
   
      {
        label: "Created By",
        value: (col) => col.createdBy &&  col.createdBy.userName ,
      },
      {
        label: "Reason",
        value: (col) => col.reason &&  col.reason ,
      },
      
      {
        label: "Id",
        value: (col) => col.transactionId &&  col.transactionId ,
      },



      
    ];
  


  const onApply = (filters) => {
    setLoadData(true);
    setSelectedFilters(filters);
    setShowFilter(false);
  };

  const openFilter = () => {
    setShowFilter(true);
  };



  const startDate = convertFirstoreDate(
    selectedFilters.startDate,
    "DD MMM YYYY"
  );
  const endDate = convertFirstoreDate(selectedFilters.endDate, "DD MMM YYYY");
  const fileName = 'Release ' + startDate + " - " + endDate;
  const sheetName = moment().format('MMMM Do YYYY, h_mm a');

  const sheets = [
    {
      name: sheetName,
      columns: tableHeader,
      dataSet: data,
    },
  ];


  return (
    <div style={{ padding: "16px" }}>
      <Toast
        showMessage={showMessage}
        setShowMessage={setShowMessage}
        message={messageContent.message}
        messageHeader={messageContent.header}
        color={messageContent.color}
      />
      <div style={{ paddingBottom: "16px" }}>
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span >Slot Release Report </span>
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "500",
                  paddingRight: "10px",
                }}
              >
                ( Range: {startDate}- {endDate})
              </span>
            </div>
            <div style={{ alignItems: "center" }}>
              <span><span>
                <ExportToExcel disabled={data.length === 0 || !(user.role === ROLES.ADMIN || user.role === ROLES.REGIONAL_MANAGER )} sheets={sheets}
                  fileName={fileName}
                  buttonName="Download Report" />
              </span>
                <Button onClick={openFilter} name={"filter"}>
                  <Icon
                    color={selectedFilters.isFilterApplied ? "orange" : "grey"}
                    name={"filter"}
                  />
                  Filter
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>

      {showFilter ? (
        <BookingFilters
          onApply={onApply}
          selectedFilters={selectedFilters}
          setLoadData={setLoadData}
          setShowFilter={setShowFilter}
          showFilter
          role={user.role}
        />
      ) : null}
   
      {loadData ? (
        <GenericLoader />
      ) : data.length === 0 ? (
        "No data available"
      ) : (
        <div>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Total Slot Released</Table.HeaderCell>
                <Table.HeaderCell>{totalSlotReleased}</Table.HeaderCell>
              </Table.Row>
            </Table.Header>

            <Table.Body>
          
            </Table.Body>
          </Table>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Release Date</Table.HeaderCell>
                <Table.HeaderCell>Release Time</Table.HeaderCell>
                <Table.HeaderCell>Arena</Table.HeaderCell>
                <Table.HeaderCell>Sport</Table.HeaderCell>
                <Table.HeaderCell>Court</Table.HeaderCell>
                <Table.HeaderCell>Booking Date</Table.HeaderCell>
                <Table.HeaderCell>Slot</Table.HeaderCell>
                <Table.HeaderCell>Type</Table.HeaderCell>
                <Table.HeaderCell>Reason</Table.HeaderCell>
                <Table.HeaderCell>Released By</Table.HeaderCell>
                <Table.HeaderCell>Transaction Id</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {data.map((x) => {
                return (
                  <Table.Row>
                    <Table.Cell>
                      <div>
                        {new Date(x.createdAt.seconds * 1000).toLocaleDateString()}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <div>
                        {new Date(x.createdAt.seconds * 1000).toLocaleTimeString()}
                      </div>
                    </Table.Cell>
                    <Table.Cell>

                    {x.arenaName && x.arenaName}
                     </Table.Cell>
                    <Table.Cell>
                      {x.sportName && x.sportName}
                    </Table.Cell>
                    
                    <Table.Cell>
                      {x.courtName && x.courtName}
                    </Table.Cell>
                    
                    <Table.Cell>
                      <div>
                        {new Date(x.bookingDate.seconds * 1000).toLocaleDateString()}
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      {x.slotName && x.slotName}
                    </Table.Cell>

                    <Table.Cell>
                      {x.bookingType && x.bookingType}
                    </Table.Cell>
                    

<Table.Cell>
  {x.reason && x.reason}
</Table.Cell>
                    <Table.Cell>
                      {x.createdBy && x.createdBy.userName}
                    </Table.Cell>
                    <Table.Cell>
                      {x.transactionId && x.transactionId}
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
        </div>
      )}
    </div>
  );
};

export default ReleaseReport;
