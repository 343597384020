import React from "react";

const NumberInput = (props) => {
  const { onChange, value, placeholder } = props;
  const handleNumberChange = (evt) => {
    const numericValue = evt.target.validity.valid ? evt.target.value : value;
    onChange(numericValue);
  };
  return (
    <input
      value={value}
      type='number'
      pattern='[0-9]*'
      placeholder={placeholder}
      onInput={handleNumberChange}
    />
  );
};

export default NumberInput;
