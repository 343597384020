const getToken = () => {
  return localStorage.getItem("playAllToken");
};

const setToken = (token) => {
  localStorage.setItem("playAllToken", token);
};

const clearToken = (token) => {
  localStorage.removeItem("playAllToken");
};

export { getToken, setToken, clearToken };
