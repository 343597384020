import React, { useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { AppContext } from "../../AppContext";
import { ROLES } from "../../utils/constants";

import {
  Icon,
} from "semantic-ui-react";
import "./SideNavbar.scss";


const SideNavbar = () => {
  const context = useContext(AppContext);
  const user = context.user;
  const selectedArena = context.selectedArena;
  const arenaId = selectedArena.arenaId;
  const currentArena = context.arenaData;
  let modules = {};
  if (currentArena[arenaId] !== undefined) {
    modules = currentArena[arenaId].modules;
  }

  let allTabs = [
    {
      label: "Dashboard",
      logKey: "Dashboard",
      icon: "list alternate outline",
      gtmAction: "dashboard",
      route: "/extranet/dashboard",
      roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER,
      ROLES.MANAGER, ROLES.PARTNER, ROLES.SALES_MANAGER,
      ROLES.ACCOUNTANT, ROLES.VIEW_ONLY, ROLES.HR, ROLES.QC_MANAGER]
    },

    modules.productsSaleAndRental
      ? {
        label: "Products",
        logKey: "product",
        icon: "shop",
        gtmAction: "ri",
        route: "/extranet/rates_inventory",
        roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT, ROLES.QC_MANAGER],
        children: [
          {
            label: "Stock Register",
            logKey: "Stock Register",
            route: "/extranet/stockRegister",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT, ROLES.QC_MANAGER],
          },

          {
            label: "Academy Stock",
            logKey: "Academy Stock",
            route: "/extranet/academyStockRegister",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT, ROLES.QC_MANAGER],
          },
          {
            label: "Stock Master",
            logKey: "Stock Master",
            route: "/extranet/stockMaster",
            roles: [ROLES.ADMIN, ROLES.ACCOUNTANT, ROLES.QC_MANAGER],
          },

        ],
      }
      : null,
    modules.cafeManagement
      ? {
        label: "Cafe",
        logKey: "cafe",
        icon: "food",
        gtmAction: "ri",
        route: "/extranet/rates_inventory",
        roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT, ROLES.QC_MANAGER],
        children: [
          {
            label: "Stock Register",
            logKey: "Stock Register",
            route: "/extranet/cafeStockRegister",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT, ROLES.QC_MANAGER],
          },
          {

            label: "Stock Master",
            logKey: "Stock Master",
            route: "/extranet/cafeStockMaster",
            roles: [ROLES.ADMIN, ROLES.ACCOUNTANT, ROLES.QC_MANAGER],
          },

        ],
      }
      : null,

    modules.academy
      ? {
        label: "Academy",
        logKey: "Academy",
        icon: "trophy",
        gtmAction: "academy",
        route: "/extranet/academy",
        roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.COACH, ROLES.QC_MANAGER],
        children: [
          {
            label: "Attendance",
            logKey: "Attendance",
            route: "/extranet/attendanceManagement",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.COACH],
          },
          {
            label: "Trials",
            logKey: "Trials",
            route: "/extranet/trialManagement",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.COACH],
          },
          {
            label: "Students",
            logKey: "Students",
            route: "/extranet/studentManagement",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.COACH, ROLES.QC_MANAGER],
          },
          {
            label: "Batches",
            logKey: "Batches",
            route: "/extranet/batchManagement",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.COACH, ROLES.MANAGER],
          },
          {
            label: "Academy Analytics",
            logKey: "Academy Analytics",
            route: "/extranet/academyAnalytics",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.COACH],
          },
          {
            label: "Student Archives",
            logKey: "Student Archives",
            route: "/extranet/studentArchives",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER],
          },
        ],
      } : null,


    modules.wallet
      ? {
        label: "Wallet",
        logKey: "Wallet",
        icon: "rupee sign",
        gtmAction: "wallet",
        route: "/extranet/walletPackages",
        roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER,],
        children: [
          {
            label: "Wallet Packages",
            logKey: "Wallet Packages",
            route: "/extranet/walletPackages",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER,],
          },
          {
            label: "Wallet Usuage",
            logKey: "Wallet Usuage",
            route: "/extranet/walletUsuage",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER,],
          },

        ],
      }
      : null,
    modules.leadManagement ?
      {
        label: "Customers",
        logKey: "Customers",
        icon: "phone",
        route: "/extranet/customers",
        roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.HR],
        children: [
          {
            label: "Open Leads",
            logKey: "Open Leads",
            route: "/extranet/openLeads",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.HR],
          },
          {
            label: "Close Leads",
            logKey: "Close Leads",
            route: "/extranet/closeLeads",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.HR],
          },
        ]
      } : null,

    {
      label: "Multi-Booking",
      logKey: "Inventory",
      icon: "calendar plus outline",
      route: "/extranet/multibooking",
      roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER],
      children: [
        {
          label: "Bulk Booking",
          logKey: "Bulk Booking",
          icon: "list alternate outline",
          route: "/extranet/bulkBooking",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER],
        },
        {
          label: "Blocking",
          logKey: "Blocking",
          icon: "list alternate outline",
          route: "/extranet/blocking",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER],
        },
      ],
    },
    {
      label: "Membership",
      logKey: "Membership",
      icon: "user outline",
      roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER],
      children: [
        {
          label: "Fixed Membership",
          logKey: "Membership",
          icon: "user outline",
          route: "/extranet/membership",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER],
        },
        {
          label: "Flexi Membership",
          logKey: "Membership",
          icon: "user outline",
          route: "/extranet/flexiMembership",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER],
        },
      ],
    },

    modules.analytics
      ?
      {
        label: "Analytics",
        logKey: "Analytics",
        icon: "chart line",
        route: "/extranet/analytics",
        roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.SALES_MANAGER, ROLES.PARTNER, ROLES.HR, ROLES.MARKETING_MANAGER],
        children: [
          {
            label: "Weekly Dashboard",
            logKey: "Weekly",
            route: "/extranet/weeklyDashboard",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER],
          },
          {
            label: "Monthly Dashboard",
            logKey: "Monthly",
            route: "/extranet/monthlyDashboard",
            roles: [ROLES.ADMIN,],
          },
          {
            label: "General Occupancy",
            logKey: "General Occupancy",
            route: "/extranet/occupancyAnalytics",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.PARTNER, ROLES.SALES_MANAGER],
          },
          {

            label: "Date Wise Occupancy",
            logKey: "Date Wise Occupancy",
            route: "/extranet/slotWiseOccupancy",
            roles: [ROLES.ADMIN, ROLES.SALES_MANAGER, ROLES.REGIONAL_MANAGER, ROLES.MARKETING_MANAGER],
          },


          {
            label: "Lead Analytics",
            logKey: "Lead Analytics",
            route: "/extranet/leadAnalytics",
            roles: [ROLES.ADMIN, ROLES.HR],
          },
        ],
      } : null,
    {
      label: "Reports",
      logKey: "Reports",
      icon: "list alternate outline",
      gtmAction: "Reports",
      route: "/extranet/reports",
      roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.PARTNER, ROLES.ACCOUNTANT, ROLES.HR, ROLES.COACH,ROLES.AUDITOR, ROLES.QC_MANAGER],
      children: [
        {
          label: "Accounting Reports",
          logKey: "Accounting Reports",
          icon: "list alternate outline",
          gtmAction: "AccountingReports",
          route: "/extranet/newAccountingReports",
          roles: [ROLES.ADMIN, ROLES.ACCOUNTANT, ROLES.MANAGER, ROLES.REGIONAL_MANAGER],
        },
        {
          label: "Transaction Reports",
          logKey: "Transaction Reports",
          icon: "list alternate outline",
          gtmAction: "TransactionReports",
          route: "/extranet/transactionReports",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT,  ROLES.AUDITOR, ROLES.QC_MANAGER],
        },
        {
          label: "Investor Reports",
          logKey: "Investor Reports",
          icon: "list alternate outline",
          gtmAction: "InvestorReports",
          route: "/extranet/investorReports",
          roles: [ROLES.ADMIN,  ROLES.ACCOUNTANT, ROLES.PARTNER, ],
        },
        {
          label: "Booking Reports",
          logKey: "Booking Reports",
          icon: "list alternate outline",
          gtmAction: "BookingReports",
          route: "/extranet/bookingReports",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT],
        },
        {
          label: "Enquiry Report",
          logKey: "Enquiry Report",
          route: "/extranet/enquiryReport",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.HR],
        },


        {
          label: "Academy Transaction Reports",
          logKey: "Academy Transaction Reports",
          icon: "list alternate outline",
          gtmAction: "Academy Transaction Reports",
          route: "/extranet/academyTransactionReports",
          roles: [ROLES.ADMIN, ROLES.ACCOUNTANT, ROLES.COACH, ROLES.REGIONAL_MANAGER],
        },
        {
          label: "BD Report",
          logKey: "BD Report",
          route: "/extranet/bDReport",
          roles: [ROLES.ADMIN],
        },
        // {
        //   label: "Customer Report Data",
        //   logKey: "Customer Report Data",
        //   icon: "list alternate outline",
        //   route: "/extranet/customerReportsData",
        //   roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.HR],
        // }

      ],
    },
    {
      label: "RM Reports",
      logKey: "RM Reports",
      icon: "list alternate outline",
      gtmAction: "Reports",
      route: "/extranet/reports",
      roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER,],
      children: [
        {
          label: "Release Report",
          logKey: "Release Report",
          route: "/extranet/releaseReport",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER,],
        },
        {
          label: "Blocking Report",
          logKey: "Blocking Report",
          route: "/extranet/blockingSummaryReport",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER,],
        },
        {
          label: "Amount Due Report",
          logKey: "Amount  Due Report",
          route: "/extranet/amountDueReport",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER,],
        },

        {
          label: "Trial Report",
          logKey: "Trial Report",
          route: "/extranet/trialReport",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.HR],
        },
        {
          label: "Other Booking Report",
          logKey: "Other Booking Report",
          route: "/extranet/otherBookingReport",
          roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.HR],
        },

      ],
    },
    {
      label: " Customer Reports",
      logKey: "Customer Reports",
      icon: "list alternate outline",
      gtmAction: "Reports",
      route: "/extranet/customerReports",
      roles: [ROLES.ADMIN],
      children: [
        {
          label: "Old Report",
          logKey: "Old Report",
          route: "/extranet/oldReport",
          roles: [ROLES.ADMIN],
        },
      

      ],
    },
    modules.accounts
      ?
      {
        label: "Accounts",
        logKey: "Accounts",
        icon: "file alternate outline",
        gtmAction: "Accounts",
        route: "/extranet/accounts",
        roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT, ROLES.AUDITOR, ROLES.QC_MANAGER],
        children: [
          {
            label: "Expenses",
            logKey: "Expenses",
            icon: "list alternate outline",
            gtmAction: "Expenses",
            route: "/extranet/expenses",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.AUDITOR],
          },
          {
            label: "Receipts",
            logKey: "Receipts",
            icon: "list alternate outline",
            gtmAction: "Receipts",
            route: "/extranet/receipts",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.AUDITOR],
          },
          {
            label: "Cash Book",
            logKey: "Cash Book",
            icon: "list alternate outline",
            route: "/extranet/cashBook",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT, ROLES.AUDITOR, ROLES.QC_MANAGER],
          },
          {
            label: "Non-Cash Book",
            logKey: "Non-Cash Book",
            icon: "list alternate outline",
            route: "/extranet/nonCashBook",
            roles: [ROLES.ADMIN, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.ACCOUNTANT, ROLES.AUDITOR],
          }
        ],
      }
      : null,

    {
      label: "Settings",
      logKey: "Settings",
      icon: "setting",
      gtmAction: "Settings",
      route: "/settings",
      roles: [ROLES.ADMIN, ROLES.ACCOUNTANT, ROLES.REGIONAL_MANAGER, ROLES.HR, ROLES.MANAGER, ROLES.SALES_MANAGER, ROLES.MARKETING_MANAGER,ROLES.QC_MANAGER],
      children: [
        {
          label: "Policies",
          logKey: "Policies",
          icon: "list alternate outline",
          route: "/extranet/policies",
          roles: [ROLES.ADMIN, ROLES.ACCOUNTANT, ROLES.REGIONAL_MANAGER],
        },
        {
          label: "Booking Rates",
          logKey: "Booking Rates",
          route: "/extranet/slot_rates",
          roles: [ROLES.ADMIN,],
        }, {
          label: "User Management",
          logKey: "User Management",
          icon: "user outline",
          route: "/extranet/userManagement",
          roles: [ROLES.ADMIN, ROLES.HR],
        }, {
          label: "Category Management",
          logKey: "Category Management",
          icon: "user outline",
          route: "/extranet/categoryManagement",
          roles: [ROLES.ADMIN, ROLES.ACCOUNTANT,ROLES.QC_MANAGER],
        }, {
          label: "GST & Commission",
          logKey: "GST & Commission",
          icon: "user outline",
          route: "/extranet/gstCommission",
          roles: [ROLES.ADMIN, ROLES.ACCOUNTANT],
        }, {
          label: "Coupon Management",
          logKey: "Coupon Management",
          icon: "user outline",
          route: "/extranet/couponManagement",
          roles: [ROLES.ADMIN, ROLES.ACCOUNTANT, ROLES.REGIONAL_MANAGER, ROLES.MANAGER, ROLES.MARKETING_MANAGER],
        },
      ],
    },
  ];

  allTabs = allTabs.filter(x => x !== null);
  allTabs = allTabs.map(x => {
    if (x && x.roles && x.roles.includes(user.role)) {
      if (x.children) {
        let childs = x.children.map(y => {
          if (y.roles && y.roles.includes(user.role)) { return y } return null// Return null for elements that don't meet the condition
        }).filter(Boolean);// Use filter to remove null elements from the array
        x.children = childs;
      }
      return x;
    }
    return null // Return null for elements that don't meet the condition
  }).filter(Boolean);// Use filter to remove null elements from the array

  const [activeTab, setActiveTab] = useState("");
  const [visibleDropdown, setVisibleDropdown] = useState("");

  const updateDropDownVisible = (tab) => {
    if (visibleDropdown && activeTab?.label === tab) {
      tab = "";
    }
    setVisibleDropdown(tab);
  };
  const clickHandler = (tab) => () => {
    setVisibleDropdown("");
    setActiveTab(tab);
  };
  return (
    <nav className='header-navigation-container sr-dark-scrollbar'>
      {allTabs.map((tab) => {
        if (tab !== null && tab !== undefined) {
          if (tab.children) {
            let isActiveTab = null;
            tab.children.forEach((innerTab) => {
              if (innerTab !== null) {
                if (window.location.pathname === innerTab.route) {
                  isActiveTab = innerTab;
                }
              }
            });

            return (
              <React.Fragment key={tab.label}>
                <div
                  className={`nav-entry ${isActiveTab ? "active-navbar-item" : ""
                    }`}
                  onClick={() => updateDropDownVisible(tab.label)}
                >
                  <Icon disabled name={tab.icon} />
                  <span className='nav-label' style={{ flex: 1 }}>
                    {tab.label}
                    <span
                      style={{
                        display: "flex",
                        flex: 1,
                        justifyContent: "flex-end",
                      }}
                    >
                      {visibleDropdown === tab.label ? (
                        <Icon disabled name='angle up' />
                      ) : (
                        <Icon disabled name='angle down' />
                      )}
                    </span>
                  </span>
                </div>
                {visibleDropdown === tab.label &&
                  tab.children.map((innerTab) => {
                    if (innerTab !== null) {
                      return (
                        <NavLink
                          key={innerTab.route}
                          className={`nav-entry small ${isActiveTab?.label === innerTab.label
                            ? "active"
                            : ""
                            }`}
                          to={innerTab.route}
                          onClick={clickHandler(tab)}
                        >
                          <span className='nav-label'>{innerTab.label}</span>
                        </NavLink>
                      );
                    }
                  })}
              </React.Fragment>
            );
          } else {
            return (
              <NavLink
                key={tab.route}
                className={`nav-entry ${activeTab?.label === tab.label ? "active" : ""
                  }`}
                to={tab.route}
                onClick={clickHandler(tab)}
              >
                <Icon disabled name={tab.icon} />
                <span className='nav-label'>{tab.label}</span>
              </NavLink>
            );
          }
        }
      })}
    </nav>
  );
};

export default SideNavbar;
