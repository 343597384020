import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Loader } from "semantic-ui-react";
import moment from "moment";
import BookinInfo from "../BookingInfo";
import { AppContext } from "../../../AppContext";
import { ROLES } from "../../../utils/constants";
import {
  getAllPolicy,
  cancelRegularBooking,
} from "../../../apiclients/BookingApiClient";
import GenericLoader from "../../generic/GenericLoader";
import { getKeyValue } from "../../../utils/helperFunctions";

const CancelBooking = ({
  booking,
  facility,
  setOpen,
  setType,
  reload,
  setMessage,
}) => {
  const context = useContext(AppContext);
  const user = context.user;
  const [policyRule, setPolicyRule] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [proceedCancel, setProceedCancel] = useState(false);
  const isCancellationAllowed = booking.cancellationAllowed === 'true' ? true : false;
  useEffect(() => {
    setShowLoader(true);
    const refundArray = [];
    getAllPolicy(
      context.selectedFacility,
      context.selectedArena.arenaId,
      context.selectedSportDetails.sportId,
      "cancellation"
    ).then((response) => {
      const timings = getKeyValue(
        context,
        "selectedSportDetails.detail.timings"
      );
      const currentSlotTime = timings
        ? timings.find((t) => t.timeId === booking.slotTiming)
        : undefined;
      setShowLoader(false);
      const data = response.data.rules;
      const today = moment();
      const ms = moment(
        moment(booking.bookingDate, "MM-DD-YYYY").format("DD-MM-YYYY") +
          " " +
          currentSlotTime.interval.start.toString() +
          ":00",
        "DD/MM/YYYY HH:mm:ss"
      ).diff(moment(today, "DD/MM/YYYY HH:mm:ss"));
      const d = moment.duration(ms);
      data.forEach((doc) => {
  
        if (
          (Math.floor(d.asHours()) > doc.hoursBefore &&
            Math.floor(d.asHours()) > 0) ||
          user.role === ROLES.ADMIN || isCancellationAllowed // if admin has allowed cancellation
        ) {
          refundArray.push(doc.refund);
        }
      });

      // if (refundArray.length !== 0) {
        // if it is between 0-3 hours, when refund % is zero
        let refundPercentage = refundArray.length === 0 ? 0 : Math.max(...refundArray);
        if (refundPercentage === 0 && user.role !== ROLES.ADMIN && !isCancellationAllowed) { // if admin has allowed cancellation
          setPolicyRule({ isAllowed: false });
        } else {
          setPolicyRule({ isAllowed: true, refund: refundPercentage });
        }
        return;
      // } else if (refundArray.length == 0 && Math.floor(d.asHours()) > 0) {
      //   setPolicyRule({ isAllowed: true, refund: Number(100) });
      //   return;
      // }
      setPolicyRule({ isAllowed: false });
    });
  }, []);

  const cancelBooking = () => {
    setProceedCancel(true);

    const arenaData = context.arenaData[context.selectedArena.arenaId];
    let postBody =   {
      transactionId: booking.id,
      sport: context.selectedSportDetails.sportId,
      refundAmount: (booking.amountPaid * policyRule.refund) / 100,
      role: user.role,
      createdBy: {
        userName: context.user.userName,
        userId: context.user.userId,
      },
      cancellationAllowed :isCancellationAllowed,
      arenaData,
    };
    cancelRegularBooking(
      context.selectedFacility,
      context.selectedArena.arenaId,
      postBody,
    )
      .then(() => {
        setProceedCancel(false);
        setOpen(false);
        setMessage({
          header: "Booking Cancelled!",
          message: "You booking has been cancelled.",
          color: "green",
        });
        reload(true);
      })
      .catch((err) => {
        setProceedCancel(false);
        setOpen(false);
        setMessage({
          header: "Error : Booking not Cancelled!",
          message: `${err.response.data}`,
          color: "red",
        });
        reload(true);
      });
  };
  const amount =
    booking.bookingStatus === "rescheduled"
      ? booking.bookingPrice
      : booking.amountPaid;
  const refundAmount = (amount * policyRule.refund) / 100;
  return (
    <>
      {proceedCancel ? <GenericLoader /> : null}
      <Modal.Content>
        <BookinInfo booking={booking} facility={facility} />
        {showLoader ? (
          <Loader size={"small"} style={{ padding: "10px" }} active inline />
        ) : (
          <div style={{ padding: "10px" }}>
            {policyRule.isAllowed
              ? "Are you sure you want to cancel this booking?"
              : "Cancellation not allowed."}
            <div style={{ fontWeight: "bold" }}>
              {policyRule.isAllowed
                ? `Refund Percent :  ${policyRule.refund}%`
                : null}
            </div>
            <div style={{ fontWeight: "bold" }}>
              {policyRule.isAllowed
                ? `Refund Amount : Rs. ${refundAmount}/-`
                : null}
            </div>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setType("request")}>Go Back</Button>
        <Button
          disabled={!policyRule.isAllowed}
          negative
          onClick={cancelBooking}
        >
          Cancel Booking
        </Button>
      </Modal.Actions>
    </>
  );
};

export default CancelBooking;
