import React, { useEffect, useContext, useState, useReducer } from "react";
import { checkAvailabilityForBulkBookings } from "../../apiclients/BulkBookingApiClient";
import "../dashboard/style.scss";
import {
  Button,
  Dropdown,
  Form,
  Grid,
  Segment,
  Checkbox
} from "semantic-ui-react";
import { AppContext } from "../../AppContext";
import { createBlocking, releaseBlocking } from "../../apiclients/BlockingApiClient";
import { colors } from "@material-ui/core";
import { getPnPSportsOfArena } from "../../apiclients/BookingApiClient";
import moment from "moment";
import { convertFirstoreDate } from "../../utils/helperFunctions";
import GenericLoader from "../generic/GenericLoader";
import SlotSelection from '../common/SlotSelection';
import DatesSelection from '../common/DatesSelection';

const daysArray = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];
const initialState = {
  sport: "",
  startDate: null,
  endDate: null,
  days: [],
  courts: [],
  startSlot: "",
  endSlot: "",
  reason: "",
  isValidSlot: false,
};

const ACTION_TYPE = {
  ADD_SPORT: "addSport",
  ADD_COURTS: "addCourts",
  ADD_START_DATE: "addStartDate",
  ADD_END_DATE: "addEndDate",
  ADD_DAYS: "addDays",
  ADD_START_SLOT: "addStartSlot",
  ADD_END_SLOT: "addEndSlot",
  ADD_TYPE: "addType",
  ADD_REASON: "addReason",
};

const reducer = (state, action) => {
  if (action.type === ACTION_TYPE.ADD_SPORT) {
    return { ...state, sport: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_COURTS) {
    return { ...state, courts: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_START_DATE) {
    return { ...state, startDate: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_END_DATE) {
    return { ...state, endDate: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_DAYS) {
    return { ...state, days: action.payload };
  }
 if (action.type === ACTION_TYPE.ADD_START_SLOT) {
   return {
     ...state,
     startSlot: action.payload.data,
     isValidSlot: action.payload.isValidRange,
   };
 }
 if (action.type === ACTION_TYPE.ADD_END_SLOT) {
   return {
     ...state,
     endSlot: action.payload.data,
     isValidSlot: action.payload.isValidRange,
   };
 }
  if (action.type === ACTION_TYPE.ADD_TYPE) {
    return { ...state, type: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_REASON) {
    return { ...state, reason: action.payload };
  }
  return state;
};

const ReleaseBlocking = (props) => {
  const { history } = props;
  const [state, dispatch] = useReducer(reducer, initialState);
  const [availableSlots, setAvailableSlots] = useState();
  const context = useContext(AppContext);
  const [sportDetail, setSportDetail] = useState([]);
  const [showBookingWindow, setShowBookingWindow] = useState(false);
  const [loader, setLoader] = useState(false);

  const dropdownSportsData = context.arenaData[
    context.selectedArena.arenaId
  ]?.sports.map((x, index) => ({
    key: x.sportId,
    value: x.sportId,
    text: x.sportName,
  }));




  const checkAvailability = () => {
    setLoader(true);
    let slotPeriods = [];
    slotPeriods.push(startSlot.value);
    for (let i = 0; i <= state.endSlot.key; i++) {
      if (startSlot.value !== endSlots[i].value) {
        slotPeriods.push(endSlots[i].value);
      }
    }

    const queryParams = {
      sport: state.sport.value,
      datePeriod: JSON.stringify({
        dateFrom: moment(state.startDate).format("DD-MM-YYYY"),
        dateTo: moment(state.endDate).format("DD-MM-YYYY"),
      }),
      days: JSON.stringify(state.days.map((x) => x.value)),
      slotPeriod: JSON.stringify(slotPeriods),
      courtSelection: JSON.stringify(state.courts.map((x) => x.courtId)),
      forBlockingRelease: true,
    };
    checkAvailabilityForBulkBookings(
      context.selectedFacility,
      context.selectedArena.arenaId,
      queryParams
    ).then((response) => {
      setAvailableSlots(response.data);
      setShowBookingWindow(true);
      setLoader(false);
    });
  };

  const {
    sport,
    startDate,
    endDate,
    days,
    courts,
    startSlot,
    endSlot,
    reason,
    isValidSlot
  } = state;
  const isMandatoryFieldsSelected =
    sport &&
    startDate &&
    endDate &&
    days &&
    courts &&
    startSlot &&
    endSlot &&
    isValidSlot &&
    !startDate.isAfter(endDate);

  useEffect(() => {
    getPnPSportsOfArena(
      context.selectedFacility,
      context.selectedArena.arenaId
    ).then((response) => {
      const sports = response.data;
      const initialSport = sports[0];
      dispatch({
        type: ACTION_TYPE.ADD_SPORT,
        payload: {
          key: initialSport.sportId,
          value: initialSport.sportId,
          text: initialSport.sportName,
        },
      });
      setSportDetail(sports);
    });
  }, [context.selectedFacility, context.selectedArena]);

    const changeDate = (isStart) => (value) => {
      if (isStart) {
        dispatch({ type: ACTION_TYPE.ADD_START_DATE, payload: value });
      } else {
        dispatch({ type: ACTION_TYPE.ADD_END_DATE, payload: value });
      }
    };

  const changeSport = (event, { value }) => {
    const data = dropdownSportsData.find(
      (facility) => facility.value === value
    );
    
    dispatch({ type: ACTION_TYPE.ADD_SPORT, payload: data });
  };

  const changeSlot = (data, isStart, isValidRange) => {
    const actionType = isStart
      ? ACTION_TYPE.ADD_START_SLOT
      : ACTION_TYPE.ADD_END_SLOT;
    dispatch({ type: actionType, payload: { data, isValidRange } });
  };


  const updateSelectedCourt = (mode, checked) => {
    const courts = state.courts;
    if (mode === "all") {
      dispatch({
        type: ACTION_TYPE.ADD_COURTS,
        payload: !checked ? [] : selectedSport.inventoryCourt,
      });
    } else {
      const isAdded = courts.some((x) => x.courtId === mode.courtId);
      if (isAdded) {
        dispatch({
          type: ACTION_TYPE.ADD_COURTS,
          payload: courts.filter((x) => x.courtId !== mode.courtId),
        });
      } else {
        dispatch({
          type: ACTION_TYPE.ADD_COURTS,
          payload: [...courts, mode],
        });
      }
    }
  };

  const updateSelectedDays = (mode, checked) => {
    const daysSelected = state.days;
    if (mode === "all") {
      dispatch({
        type: ACTION_TYPE.ADD_DAYS,
        payload: !checked ? [] : daysArray,
      });
    } else {
      const isAdded = daysSelected.some((x) => x.value === mode.value);
      if (isAdded) {
        dispatch({
          type: ACTION_TYPE.ADD_DAYS,
          payload: daysSelected.filter((x) => x.value !== mode.value),
        });
      } else {
        dispatch({
          type: ACTION_TYPE.ADD_DAYS,
          payload: [...daysSelected, mode],
        });
      }
    }
  };



  const releaseBlockedSlot = () => {
    setLoader(true);
    const slotPeriods = [];
    slotPeriods.push(startSlot.value);
    for (let i = 0; i <= state.endSlot.key; i++) {
      if (startSlot.value !== endSlots[i].value) {
        slotPeriods.push(endSlots[i].value);
      }
    }
    const postBody = {
      days: JSON.stringify(days.map((x) => Number(x.value))),
      sport: sport.value,
      datePeriod: JSON.stringify({
        dateFrom: moment(startDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
        dateTo: moment(endDate, "YYYY-MM-DD").format("DD-MM-YYYY"),
      }),
      slotPeriod: JSON.stringify(slotPeriods),
      courtSelection: JSON.stringify(courts.map((x) => x.courtId)),
      reason: reason ,
      arenaName :  context.selectedArena.arenaName,
      sportName : state.sport.text,
      createdBy: {
        userName: context.user.userName,
        userId: context.user.userId,
      },
    };

    releaseBlocking(
      context.selectedFacility,
      context.selectedArena.arenaId,
      postBody
    ).then((response) => {
      history.push("/extranet/blocking");
      setLoader(false);
      console.log("Blocking Released successfully!");
    });
  };

  const selectedSport =
    state.sport && sportDetail
      ? sportDetail.find((x) => x.sportId === state.sport.value)
      : sportDetail && sportDetail[0];


  const endSlots =
    selectedSport &&
    selectedSport.timings
      .filter(function (x) {
        if (state.startSlot.value <= x.timeId) {
          return true;
        }
        return false;
      })
      .map(function (x, i) {
        return {
          key: selectedSport.timings.findIndex((t) => t.timeId === x.timeId),
          value: x.timeId,
          text: x.interval.end,
        };
      });

  const courtsOption =
    selectedSport && selectedSport.inventoryCourt
      ? selectedSport.inventoryCourt
      : [];

  return (
    <>
      {loader ? <GenericLoader /> : null}
      <div style={{ padding: "16px" }}>
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span>
                {showBookingWindow
                  ? "Release Blocking"
                  : "Check Availability for Release"}
              </span>
            </div>
          </div>
        </div>
      </div>
      {!showBookingWindow ? (
        <div
          style={{
            padding: "16px",
            alignItems: "center",
            display: "flex",
            // justifyContent: "center",
          }}
        >
          <div>
            <div>
              <div
                style={{
                  marginBottom: "10px",
                  fontWeight: "bold",
                }}
              >
                Sport
              </div>
              <Dropdown
                onChange={changeSport}
                placeholder='Select...'
                openOnFocus
                selection
                value={state.sport && state.sport.value}
                options={dropdownSportsData}
              />
            </div>
            <DatesSelection
              startDate={startDate}
              endDate={endDate}
              changeDate={changeDate}
              disablePast={true}
              disableFuture={false}
            />

            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div>
                <div
                  style={{
                    marginBottom: "10px",
                    marginTop: "20px",
                    fontWeight: "bold",
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Courts
                  <Checkbox
                    checked={
                      courts.length === selectedSport?.inventoryCourt.length
                    }
                    style={{ marginRight: "7px", marginLeft: "7px" }}
                    onClick={(e, { checked }) =>
                      updateSelectedCourt("all", checked)
                    }
                  />
                  <span style={{ fontWeight: "lighter" }}> All Courts </span>
                </div>
                {courtsOption.map((x) => {
                  const isActive = state.courts.some(
                    (mode) => mode.courtId === x.courtId
                  );
                  return (
                    <Button
                      onClick={() => updateSelectedCourt(x)}
                      style={{
                        marginRight: "10px",
                        backgroundColor: isActive
                          ? colors.orange[700]
                          : colors.grey[200],
                        color: isActive ? colors.blue[50] : colors.black,
                      }}
                    >
                      {x.courtName}
                    </Button>
                  );
                })}
              </div>
            </div>
            <div
              style={{
                flex: 1,
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <div>
                <div
                  style={{
                    marginBottom: "10px",
                    marginTop: "20px",
                    fontWeight: "bold",
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Days
                  <Checkbox
                    checked={state.days.length === 7}
                    style={{ marginRight: "7px", marginLeft: "7px" }}
                    onClick={(e, { checked }) =>
                      updateSelectedDays("all", checked)
                    }
                  />
                  <span style={{ fontWeight: "lighter" }}> All Days </span>
                </div>
                {daysArray.map((x,i) => {
                  const isActive = state.days.some(
                    (mode) => mode.value === x.value
                  );
                  return (
                    <Button
                    key={i}
                      onClick={() => updateSelectedDays(x)}
                      style={{
                        marginRight: "10px",
                        backgroundColor: isActive
                          ? colors.orange[700]
                          : colors.grey[200],
                        color: isActive ? colors.blue[50] : colors.black,
                      }}
                    >
                      {x.label}
                    </Button>
                  );
                })}
              </div>
            </div>
            <SlotSelection
              startSlot={startSlot}
              endSlot={endSlot}
              sportTimings={(selectedSport && selectedSport.timings) || []}
              changeSlot={changeSlot}
            />
            
            <Button
              primary
              disabled={!isMandatoryFieldsSelected}
              onClick={checkAvailability}
            >
              Next
            </Button>
          </div>
        </div>
      ) : (
        <div style={{ padding: "16px" }}>
          <div
            style={{
              textDecoration: "underline",
              color: colors.blue[500],
              cursor: "pointer",
            }}
            onClick={() => setShowBookingWindow(false)}
          >
            {"< Back"}
          </div>
          <Segment.Group style={{ padding: "12px" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Sport:</span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{sport.text}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Dates: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {convertFirstoreDate(startDate) +
                      " - " +
                      convertFirstoreDate(endDate)}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Days: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{days.map((x) => x.label).join(", ")}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Courts: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{courts.map((x) => x.courtName).join(", ")}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Timings: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{startSlot.text + " - " + endSlot.text}</span>
                </Grid.Column>
              </Grid.Row>
              {availableSlots.alreadyBookedSlot.length !== 0 ? (
                <Grid.Row>
                  <Grid.Column width={4}>
                    <span style={{ fontWeight: "bold" }}>
                      Blocking to be released:{" "}
                    </span>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <span>
                      {availableSlots.alreadyBookedSlot.map((x) => (
                        <div>
                          BookingDate <b>{x.bookingDate}</b>
                          {"   SlotNo:  "}
                          <b>{x.slotInterval.interval.start}</b> <b>-</b>
                          {"  "}
                          <b>{x.slotInterval.interval.end}</b>
                          {"   CourtNo:  "}
                          <b>
                            {x.court
                              .map(
                                (y) =>
                                  courtsOption.find(
                                    (c) => c.courtId === y.courtId
                                  )?.courtName
                              )
                              .join(",")}
                          </b>
                        </div>
                      ))}
                    </span>
                  </Grid.Column>
                </Grid.Row>
              ) : null}
                <Grid.Row>
                  <Grid.Column width={4}>
                    <span style={{ fontWeight: "bold" }}>Reason: </span>
                  </Grid.Column>
                  <Grid.Column width={12}>
                    <Form>
                      <input
                        type='text'
                        onInput={(data) =>
                          dispatch({
                            type: ACTION_TYPE.ADD_REASON,
                            payload: data.target.value,
                          })
                        }
                        value={reason}
                        placeholder='e.g., type reason here'
                      />
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              
            </Grid>
          </Segment.Group>
          <div style={{ justifyContent: "center", display: "flex", flex: 1 }}>
            <Button primary onClick={releaseBlockedSlot} disabled={reason ===""}>
              Submit
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default ReleaseBlocking;
