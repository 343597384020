import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../AppContext';
import GenericLoader from '../generic/GenericLoader';
import { Table, Tab } from 'semantic-ui-react';
import moment from 'moment';
import { getNonCashBook } from '../../apiclients/AccountsClient';
import { DatePicker } from '@material-ui/pickers';
import TransactionDetails from '../reports/TransactionDetails';
import { colors } from '@material-ui/core';


const NonCashTable = ({ listOfTransactions, closingBalance }) => {
    const [showDetails, setShowDetails]=useState(false);

    const openDetail = (data) => () => {
        setShowDetails(data);
    };
    return (
        <>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>

                        <Table.HeaderCell > <h5 class="ui center aligned ">Closing Balance - <span style={{ fontSize: "25px" }}>Rs. {closingBalance} /-</span></h5></Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
            </Table>

            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>S.No</Table.HeaderCell>
                        <Table.HeaderCell>Date</Table.HeaderCell>
                        <Table.HeaderCell>Time</Table.HeaderCell>
                        <Table.HeaderCell>Cash Amount</Table.HeaderCell>
                        <Table.HeaderCell>Sport</Table.HeaderCell>
                        <Table.HeaderCell>Type</Table.HeaderCell>
                        <Table.HeaderCell>Subtype</Table.HeaderCell>
                        <Table.HeaderCell>Closing Balance</Table.HeaderCell>
                        <Table.HeaderCell>Created By</Table.HeaderCell>
                        <Table.HeaderCell>Transaction ID</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {listOfTransactions.map((x, index) => {
                        return (
                            <Table.Row key={index} >
                                <Table.Cell>{index + 1}</Table.Cell>
                                <Table.Cell>{new Date(x.createdDate).toLocaleDateString()}</Table.Cell>
                                <Table.Cell>{x.createdTime}</Table.Cell>
                                <Table.Cell>Rs. {x.modeAmount} /-</Table.Cell>
                                <Table.Cell>{x.alternativeSport ? x.alternativeSport.text : x.sport?.sportName} </Table.Cell>
                                     
                                <Table.Cell>{x.type} </Table.Cell>
                                <Table.Cell>{x.subtype} </Table.Cell>
                                <Table.Cell>Rs. {x.closingBalance} /-</Table.Cell>
                                <Table.Cell>{x.createdBy.userName} </Table.Cell>
                                <Table.Cell><div
                                        style={{
                                            textDecoration: "underline",
                                            color: colors.blue[500],
                                        }}
                                        onClick={openDetail(x)}
                                    >
                                        {x.id}
                                    </div> </Table.Cell>
                            </Table.Row>

                        );
                    })}
                </Table.Body>

            </Table>
            {!!showDetails ? (
        <TransactionDetails
          selectedData={showDetails}
          setShowDetails={setShowDetails}
        /> ): null }

        </>
    )

}
const NonCashBook = (props) => {
    const today = moment();
    const { history } = props;
    const context = useContext(AppContext);
    const { selectedFacility, selectedArena } = context;
    const [isLoading, setisLoading] = useState(false);
    const [closingBalance, setClosingBalance] = useState();
    const [date, setDate] = useState(today);
    const [listOfTransactions, setListOfTransactions] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const types = [ 'others','upi', 'card','bankTransfer','paytm'];

    const loadData = () => {
        setisLoading(true);
        return getNonCashBook(
            selectedFacility,
            selectedArena.arenaId,
            date,
            types[activeIndex],
        ).then((response) => {
            const result = response.data;
            setClosingBalance(result.closingBalance);
            setListOfTransactions(result.data);
            setisLoading(false);
        }).catch((err) => {
            history.push("/extranet/dashboard");
        });
    };



    useEffect(() => {
        if (selectedFacility && selectedArena) {
            loadData();
        }
    }, [selectedFacility, selectedArena, date, activeIndex]);// eslint-disable-line react-hooks/exhaustive-deps


    const panes = [
        {
            menuItem: "Others",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <NonCashTable
                        listOfTransactions={listOfTransactions}
                        closingBalance={closingBalance}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "UPI",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <NonCashTable
                        listOfTransactions={listOfTransactions}
                        closingBalance={closingBalance}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Cards",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <NonCashTable
                        listOfTransactions={listOfTransactions}
                        closingBalance={closingBalance}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Bank Transfer",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <NonCashTable
                        listOfTransactions={listOfTransactions}
                        closingBalance={closingBalance}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Paytm",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <NonCashTable
                        listOfTransactions={listOfTransactions}
                        closingBalance={closingBalance}
                    />
                </Tab.Pane>
            ),
        },

    ];

    return isLoading ? (
        <GenericLoader />
    ) : (
        <div style={{ padding: "16px" }}>

            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Non-Cash Book</span>
                    </div>

                    <div style={{ width: "200px" }}>
                        <DatePicker
                            autoOk
                            variant='inline'
                            value={date}
                            placeholder='Select Date...'
                            onChange={setDate}
                            format='DD MMM yyyy'
                            style={{ padding: "10px" }}
                            disableFuture
                        />
                    </div>
                </div>
            </div>

            <div style={{ paddingTop: "16px" }}>
            {isLoading ? (
                <GenericLoader />
            ) : (
                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    renderActiveOnly={true}
                    menu={{
                        color: "orange",
                        pointing: true,
                    }}
                    panes={panes}
                />
            )}
            </div>

            <br />
            How to Use Non-Cash Book ?
            <div className="player">
                <iframe width="300" height="150" src="https://www.youtube.com/embed/VGFGQenXqYw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
            </div>

        </div>
    );
};

export default NonCashBook;