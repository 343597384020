import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import { Helmet } from "react-helmet";
import { Fade, Zoom, } from 'react-reveal';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>Play Table Tennis in your Favorite arena</h4>
        <h1 data-aos='fade-up'>
          We Are Redefining Sports. Experience The Difference
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> Online Booking available</h5>
        </div>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> Best in Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#football'></use>
          </svg>
          <h5> All equipments complimentary </h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>

 

      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 >Faridabad</h2>
                <p>With 2 Best-in-Class <b>Table Tennis Table</b>, Play All is one of the best <b>Table Tennis Arena</b> in Faridabad. With Amenities like Ample Parking, Shower, and Sports Shop, we ensure that your Table Tennis experience is hassel free and awesome. </p>

              </Zoom>
              <span class='address'>
                Address - Near Sai Mandir, Sector 86, Faridabad, Haryana 121002| Contact - 01141187780
              </span>

              <div class='btarea mb-3'>
                <a
                  href='https://g.page/play-all-sports-faridabad?share'
                  class='btwhite1'
                >

                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Fade left> Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>

              
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/faridabad_badminton_0.png' alt='badminton court' />
                    <img src='images/gallery/faridabad_badminton_1.png' alt='badminton school' />
                    <img src='images/gallery/faridabad_badminton_2.png' alt='badminton academy near me' />
                    <img src='images/gallery/faridabad_badminton_3.png' alt='badminton coaching' />
                    <img src='images/gallery/faridabad_badminton_4.png' alt='badminton court near me' />

                  </Carousel></Fade>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec badmintondeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade >
                  <Carousel showThumbs={false} showIndicators={false} showStatus={false} autoPlay={true} infiniteLoop={true} interval={1400} swipeable={true} emulateTouch={true}>

                    <img src='images/gallery/gaurs_badminton_0.png' alt='badminton court' />
                    <img src='images/gallery/gaurs_badminton_1.png' alt='badminton academy' />
                    <img src='images/gallery/gaurs_badminton_2.png' alt='badminton coaching' />

                  </Carousel></Fade>

              </div>
            </div>
            <div class='contentarea '>
              <Zoom>
                <h4 > PlayAll</h4>
                <h2 >Gaur City - Greater Noida</h2>
                <p>With 3 Indoor <b>Table Tennis Table</b>, Play All is one of the best <b>Table Tennis Arena</b> in Faridabad. With Amenities like Ample Parking, Shower, and Sports Shop, we ensure that your Table Tennis experience is hassel free and awesome. </p></Zoom>
                <span class='address'>
                Address - Gaur City Sports Complex, E Block, Gaur City 1, Sector 4, Greater Noida, Uttar Pradesh 201009| Contact - 011-411-87822
              </span>
              <div class='btarea mb-3'>
              <a
                  href='https://goo.gl/maps/XRjs1h6FZjYUoPtv7'
                  class='btwhite1'
                >
                  <svg width='34' height='41'>
                    <use href='#googleMap'></use>
                  </svg>
                  <Zoom >Directions</Zoom>
                </a>
              </div>


              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://play.google.com/store/apps/details?id=com.playallrn'
                  class='btorng'
                  style={{ background: "#000000" }}
                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playStore.png' style={{ height: "120px" }} />
                </a>


                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://apps.apple.com/us/app/play-all/id6480510323'
                  style={{ background: "#000000" }}

                  target="_blank"
                >
                  <img src='images/appStore.png' alt='' style={{ height: "120px" }} />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

    </main>
  </>
);

const TableTennis = () => {
  return (
    <>

      <Helmet>
        <title>Table Tennis</title>
        <meta name="description" content="Play all offers best Table Tennis Arena in Delhi NCR" />
      </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner tableTennisBg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"tableTennis"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default TableTennis;
