import React, { useRef } from "react";
import { Button, Table, Icon } from "semantic-ui-react";
import CustomModal from "../generic/CustomModal";
import {
  convertFirstoreDate,
  getSourceLabel,
} from "../../utils/helperFunctions";
import { colors } from "@material-ui/core";
import "../../App.css";
import { NavLink } from "react-router-dom";
import { ROLES } from "../../utils/constants";
import moment from "moment";
import { deleteTransaction } from "../../apiclients/ReportsApiClient";

const Footer = ({ data, setClose, role, setMessage, isLoading, setMessageContent }) => {
  return (<>
    <Button className={"ui button "} onClick={setClose}>
      Close
    </Button>
  </>
  );
};
const Detail = ({ data, role }) => {
  const parray = data.payment ? data.payment : data.paymentMode;
  return (
    <div>
      <Table>
        <Table.Body>
          <Table.Row>
            <Table.Cell>
              <div>ID</div>
            </Table.Cell>
            <Table.Cell>
              <div>
                {data.id}
              </div>
            </Table.Cell>
          </Table.Row>

          <Table.Row>
            <Table.Cell>
              <div>Created Date & Time</div>
            </Table.Cell>
            <Table.Cell>
              <div>{new Date(data.createdAt.seconds * 1000).toLocaleDateString()} | {new Date(data.createdAt.seconds * 1000).toLocaleTimeString()}</div>
            </Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>
              <div>Arena</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.arenaName}</div>
            </Table.Cell>
          </Table.Row>
          {data.customerName && <Table.Row>
            <Table.Cell>
              <div>Customer Name</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.customerName}</div>
            </Table.Cell>
          </Table.Row>}
          <Table.Row>
            <Table.Cell>
              <div>Customer Number</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.customerPhone}</div>
            </Table.Cell>
          </Table.Row>

          {data.sportName && <Table.Row>
            <Table.Cell>
              <div>Sports</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.sportName}</div>
            </Table.Cell>
          </Table.Row>}


          {data.interestArea && <Table.Row>
            <Table.Cell>
              <div>Interest Area</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.interestArea}</div>
            </Table.Cell>
          </Table.Row>}
          {data.stage && <Table.Row>
            <Table.Cell>
              <div>Sub Category</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.stage}</div>
            </Table.Cell>
          </Table.Row>}
 {data.callType && <Table.Row>
            <Table.Cell>
              <div>Call Status</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.callType}</div>
            </Table.Cell>
          </Table.Row>}
          {data.source && <Table.Row>
            <Table.Cell>
              <div>Source</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.source}</div>
            </Table.Cell>
          </Table.Row>}

           <Table.Row>
            <Table.Cell>
              <div>Lead Status</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.leadOpen ? "Open" : "Closed"}</div>
            </Table.Cell>
          </Table.Row>

          {data.newCustomer && <Table.Row>
            <Table.Cell>
              <div>New Customer</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.newCustomer ? "Yes" : "No"}</div>
            </Table.Cell>
          </Table.Row>}

          {data.createdBy && <Table.Row>
            <Table.Cell>
              <div>Created By</div>
            </Table.Cell>
            <Table.Cell>
              <div>{data.createdBy}</div>
            </Table.Cell>
          </Table.Row>}

          {data.remarks ? data.remarks.map((remark,i) => {
            
            return(
              <Table.Row key={i}>
                <Table.Cell>
                  <div>Remark {i+1}</div>
                </Table.Cell>
                <Table.Cell>
                  <div>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ paddingRight: "7px" }}>
                        <span>
                          {new Date(remark.createdAt.seconds * 1000).toLocaleDateString()} | {new Date(remark.createdAt.seconds * 1000).toLocaleTimeString()}
                           - {remark.remarks}
                        </span>
                      </div>
                    </div>
                  </div>
                </Table.Cell>
              </Table.Row>
            )
          })
          : null } 
        </Table.Body>
      </Table>
    </div>
  );
};
const EnquiryDetails = ({ setShowDetails, selectedData, role, setMessage, isLoading, setMessageContent }) => {
  const setClose = () => {
    setShowDetails(false);
  };
  return (
    <CustomModal
      header={"Enquiry Detail"}
      content={<Detail data={selectedData} role={role} />}
      footer={<Footer setClose={setClose} role={role} data={selectedData} setMessage={setMessage} setMessageContent={setMessageContent} isLoading={isLoading} />}
      setOpen={setClose}
      open={!!selectedData}
    />
  );
};

export default EnquiryDetails;
