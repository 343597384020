import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { colors } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { Button, Form, Segment, Table, Divider } from "semantic-ui-react";
import { addFlexiMembership, addFlexiMembershipPackage } from "../../../apiclients/MembershipApiClient";
import { AppContext } from "../../../AppContext";
import CustomerDetail from "../../common/CustomerDetail";
import Payment from "../../dashboard/Payment";
import ModalPortal from '../../generic/ModalPortal';

const AddFlexiMember = (props) => {
    const { showModal, setReload, setMessage, isLoading, sport, selectedPackage } = props;
    const { numberOfMonth, fees, usagePerDay, hoursBeforeBooking } = selectedPackage;
    const context = useContext(AppContext);
    const arenaData = context.arenaData[context.selectedArena.arenaId];
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const user = context.user;
    const [name, setName] = useState();
    const [mobile, setMobile] = useState();
    const [startDate, setStartDate] = useState(moment());
    const [endDate, setEndDate] = useState(moment().add(numberOfMonth, 'M').subtract(1, 'days'));
    const [paymentArray, setPaymentArray] = useState([]);

    const startDateSelected = (value) => {
        const startingDate = moment(value).startOf('day');
        setStartDate(startingDate);
        const date = moment(value).add(numberOfMonth, 'M').subtract(1, 'days').endOf('day');
        setEndDate(date);
    }

    function handleNumberChange(evt) {
        const numericValue = evt.target.validity.valid ? evt.target.value : mobile;
        setMobile(numericValue);
    }

    const getTotalAmount = (paymentArray) => {
        return (
            paymentArray &&
            paymentArray.length > 0 &&
            paymentArray.map((x) => +x.amount).reduce((x, sum) => sum + x)
        );
    };

    const totalPayment = getTotalAmount(paymentArray);
    const isSubmitAllowed = numberOfMonth && fees === totalPayment && usagePerDay && hoursBeforeBooking && startDate && endDate;


    const modalContent = (

        <div>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Number of Months</Table.HeaderCell>
                        <Table.HeaderCell>Fees</Table.HeaderCell>
                        <Table.HeaderCell>Slots Allowed Per day</Table.HeaderCell>
                        <Table.HeaderCell>Booking Window </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    <Table.Row >
                        <Table.Cell>{numberOfMonth} {Number(numberOfMonth) > 1 ? ' Month' : ' Month'}</Table.Cell>
                        <Table.Cell>Rs. {fees}/-</Table.Cell>
                        <Table.Cell>{usagePerDay} {Number(usagePerDay) > 1 ? ' Slots/Day' : ' Slot/Day'}</Table.Cell>
                        <Table.Cell> Within {hoursBeforeBooking} {hoursBeforeBooking <= 1 ? " Hour" : "Hours"} from Booking </Table.Cell>

                    </Table.Row>

                </Table.Body>
            </Table>

            <hr />
            <Form>

                <CustomerDetail
                    name={name}
                    setName={setName}
                    number={mobile}
                    setNumber={handleNumberChange}
                />
                <Form.Group widths='equal'>
                    <Form.Field  >

                        <label>Start Date</label>
                        <DatePicker
                            autoOk
                            variant='inline'
                            value={startDate}
                            placeholder='Select Date...'
                            onChange={startDateSelected}
                            format='DD MMM yyyy'
                        />
                    </Form.Field>
                    <Form.Field  >

                        <label>End Date</label>
                        <DatePicker
                            autoOk
                            variant='inline'
                            disabled
                            value={endDate}
                            placeholder='Select Date...'
                            onChange={setEndDate}
                            format='DD MMM yyyy'
                        />
                    </Form.Field>
                </Form.Group>
                {fees > 0 ? (
                    <>
                        <Divider horizontal>
                            <span style={{ color: colors.blue["400"] }}>Payment</span>
                        </Divider>
                        <div style={{ paddingBottom: "10px" }}>

                            <span style={{ fontWeight: "bold" }}>
                                Total Amount to be paid: Rs.
                            </span>
                            <span>{fees}/-</span>
                        </div>
                        <Payment
                            paymentArray={paymentArray}
                            setPaymentArray={setPaymentArray}
                        />
                        <div style={{ paddingTop: "10px" }}>
                            <span style={{ fontWeight: "bold" }}>Total Payment: Rs.</span>
                            <span>{totalPayment}/-</span>
                        </div>
                    </>
                ) : null}

            </Form>
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
                <Button disabled={!isSubmitAllowed} primary onClick={addFlexiMember}>  Submit</Button>
            </React.Fragment>
        );
    };

    const addFlexiMember = () => {
        showModal(false);
        isLoading(true);
        const createdBy = {
            userName: user.userName,
            userId: user.userId,
        };
        const postData = {
            numberOfMonth: Number(numberOfMonth),
            fees: Number(fees),
            usagePerDay: Number(usagePerDay),
            hoursBeforeBooking: Number(hoursBeforeBooking),
            name, mobile, paymentArray, totalPayment, startDate, endDate,
            createdBy,
            sportId: sport.value,
            arenaData
        };
        let postBody = {
            facilityId, arenaId, postData
        };
        addFlexiMembership(facilityId, arenaId, postBody).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Member Enrolled",
                    message: "User has been successfully added in Flexi Membership",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
        });
    }

    return (
        <>
            <ModalPortal
                title={'Membership Enrollment for ' + sport.text}
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>
    )
};

export default AddFlexiMember;