import React, { useState, useContext, useEffect } from "react";

import BookinInfo from "../BookingInfo";
import { Button, Checkbox, Divider, Form, Modal } from "semantic-ui-react";
import { AppContext } from "../../../AppContext";
import Payment from "../Payment";
import {
  extendBooking,
  getExtendAvailableCourts,
} from "../../../apiclients/BookingApiClient";
import moment from "moment";
import { colors } from "@material-ui/core";
import GenericLoader from "../../generic/GenericLoader";

const ExtendBooking = ({
  booking,
  facility,
  setOpen,
  setType,
  reload,
  setMessage,
}) => {
  const context = useContext(AppContext);
  const arenaData = context.arenaData[context.selectedArena.arenaId];
  const slots = context.selectedSportDetails.detail.timings;
  const currentSlotIndex = slots.findIndex(
    (x) => x.timeId === booking.slotTiming
  );
  const nextSlotIndex =
    currentSlotIndex >= slots.length - 1 ? null : currentSlotIndex + 1;
  const [showPayment, setShowPayment] = useState(false);
  const [allowPayment, setAllowPayment] = useState(false);
  const [paymentArray, setPaymentArray] = useState([]);
  const [availableCourts, setAvailableCourts] = useState([]);
  const [selectedCourt, setSelectedCourt] = useState();
  const [extendDiscount, setExtendDiscount] = useState(0);
  const [allowExtension, setAllowExtension] = useState();
  const [loader, setLoader] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0)

  useEffect(() => {
    if (!booking.extendedFlag) {
      let params = {
        bookingDate: moment(booking.bookingDate).format("DD-MM-YYYY"),
        timeId: booking.slotTiming,
        sport: context.selectedSportDetails.sportId,
      };
     
     
      setLoader(true);
      getExtendAvailableCourts(
        context.selectedFacility,
        context.selectedArena.arenaId,
        params
      )
        .then((response) => {
          setAvailableCourts(response.data.result.courtsArray);
          setExtendDiscount(response.data.result.discount);
          setAllowExtension(response.data.result.isAllowed);
          setLoader(false);
        })
        .catch((err) => {
          setLoader(false);
          setMessage({
            header: "Error : In Getting available courts.",
            message: `${err.response.data}`,
            color: "red",
          });
          reload(true);
        });
    }
  }, [context.selectedFacility, context.selectedArena]);

  const onExtendClicked = () => {
    setLoader(true);

    let postBody = {
      bookingId: booking.id,
      bookingDate: moment(booking.bookingDate).format("DD-MM-YYYY"),
      selectedCourtId: selectedCourt.courtId,
      extendedTimeId: slots[nextSlotIndex].timeId,
      paymentInfo: JSON.stringify(paymentArray),
      sport: context.selectedSportDetails.sportId,
      discount: extendDiscount,
      createdBy: {
        userName: context.user.userName,
        userId: context.user.userId,
      },
      arenaData
    };

    if(booking.alternativeSport){
      postBody.alternativeSport = booking.alternativeSport
    }

 
    extendBooking(
      context.selectedFacility,
      context.selectedArena.arenaId,
      postBody
    )
      .then(() => {
        setOpen(false);
        setLoader(false);
        setMessage({
          header: "Booking Extended!",
          message: "You booking has been extended.",
          color: "yellow",
        });

        reload(true);
      })
      .catch((err) => {
        setOpen(false);
        setLoader(false);
        setMessage({
          header: "Error : Courts not extended",
          message: `${err.response.data}`,
          color: "red",
        });
        reload(true);
      });
  };

  let totalPayment = paymentArray
    .map((x) => +x.amount)
    .reduce((x, sum) => sum + x, 0);

  const courtSelected = (court) => {
    setSelectedCourt(court);
    setTotalAmount((court.price / 100) * (100 - extendDiscount));
    setShowPayment(true);
  };
  return  (
    <>
      <Modal.Content>
        {loader ? (
    <GenericLoader />
  ) : allowExtension ? (
          <>
            <BookinInfo booking={booking} facility={facility} />
            {nextSlotIndex ? (
              <>
                <div style={{ paddingTop: "10px" }}>
                  Are you sure you want to book another slot{" "}
                  {slots[nextSlotIndex].interval.start +
                    " - " +
                    slots[nextSlotIndex].interval.end}{" "}
                  ?
                </div>
                <div style={{ marginTop: "7px", marginBottom: "7px" }}>
                  Select Court:
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flex: 1,
                    justifyContent: "flex-start",
                  }}
                >
                  {availableCourts.map((court) => {
                    const isActive =
                      selectedCourt && selectedCourt.courtId === court.courtId;

                    return (
                      <Button
                        key={court.courtId}
                        onClick={() => courtSelected(court)}
                        style={{
                          marginRight: "10px",
                          backgroundColor: isActive
                            ? colors.orange[700]
                            : colors.grey[50],
                          color: isActive ? colors.blue[50] : colors.black,
                        }}
                      >
                        {court.courtName}
                      </Button>
                    );
                  })}
                </div>
              </>
            ) : (
              <div style={{ padding: "12px" }}>
                "Courts are not available for Extension"
              </div>
            )}
            {showPayment ? (
              <Form>
                <Divider horizontal>
                  <span style={{ color: colors.blue["400"] }}>Payment</span>
                </Divider>
                <div style={{ paddingBottom: "10px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Extension Discount:{" "}
                  </span>
                  <span>{extendDiscount}%</span>
                </div>
                <div style={{ paddingBottom: "10px" }}>
                  <span style={{ fontWeight: "bold" }}>
                    Total Amount to be paid:{" "}
                  </span>
                  <span>Rs.{totalAmount}/-</span>
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  >
                    <div>Pay Now?</div>
                    <Checkbox
                      style={{ marginLeft: "10px" }}
                      toggle
                      onClick={() => setAllowPayment(!allowPayment)}
                    />
                  </div>
                </div>

                {allowPayment && totalAmount > 0 ? (
                  <>
                    <Payment
                      paymentArray={paymentArray}
                      setPaymentArray={setPaymentArray}
                    />
                    <div style={{ paddingTop: "10px" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Total Payment:{" "}
                      </span>
                      <span>Rs.{totalPayment}/-</span>
                    </div>
                    {totalAmount !== totalPayment && totalPayment > 0 ? (
                      <div style={{ padding: "12px" }}>Amount not same!</div>
                    ) : (
                      ""
                    )}
                  </>
                ) : null}
              </Form>
            ) : null}
          </>
        ) : (
          <div style={{ padding: "12px" }}>
            Extension not allowed as per policy!
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button onClick={() => setType("request")}>Go Back</Button>
        {(allowExtension && showPayment && totalAmount === totalPayment) ||
        (allowExtension && showPayment && !allowPayment) ? (
          <>
            <Button
              disabled={!nextSlotIndex}
              style={{
                backgroundColor: "orange",
                color: "white",
                marginLeft: "10px",
              }}
              onClick={onExtendClicked}
            >
              Extend
            </Button>
          </>
        ) : null}
      </Modal.Actions>
    </>
  );
};

export default ExtendBooking;
