import React, { useEffect, useContext, useState } from "react";

import { colors } from "@material-ui/core";
import { Button, Icon, Table } from "semantic-ui-react";
import "../dashboard/style.scss";

import { AppContext } from "../../AppContext";
import GenericLoader from "../generic/GenericLoader";
import { getUserDetails, resetPassword, allowCheckin} from "../../apiclients/UserManagementApiClient";
import AddUser from "./AddUser";
import DeleteUser from "./DeleteUser";
import EditUser from "./EditUser";
import Toast from "../generic/Toast";


const UserManagement = (props) => {
    const { history } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;

    const [users, setUser] = useState([]);
    const [selectedUser, setSelectedUser] = useState({}); // for edit or deleting the user

    const [deleteModal, setDeleteModal] = useState(false); // to show delete modal
    const [addModal, setAddModal] = useState(false); // to show add modal
    const [editModal, setEditModal] = useState(false); // to show edit modal

    const [reload, setReload] = useState(false);
    const [isLoading, setisLoading] = useState(true);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});



    const loadData = () => {
        return getUserDetails(
            context.selectedFacility,
        ).then((response) => {
            const usersDetail = response.data;
            setUser(usersDetail.data);
            setisLoading(false);
        });
    };

    useEffect(() => {
        if (reload) {
            loadData().then(() => {
                setReload(false);
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 2000);
            });
        }
    }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        loadData();
    }, [context.selectedFacility]);// eslint-disable-line react-hooks/exhaustive-deps

    const openDeleteModal = (user) => {
        setSelectedUser(user);
        setDeleteModal(true);
    };

    const openEditModal = (user) => {
        setSelectedUser(user);
        setEditModal(true);
    };

    const allowCheckinFromANewDevice = (user) => {
        setisLoading(true);
        const uid = user.userId;
        const postBody = {
            uid, facilityId
        };
        allowCheckin(postBody).then((response) => {
            if( response.status === 200 || response.status === 201){
                setMessageContent({
                header: "Device Updated",
                message: "The old associated device has been removed from the user account. Now he/she can sign in with a new device.",
                color: "green",
              });
            }  else {
                setMessageContent({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessageContent({
                header: "Error!",
                message: error.message,
                color: "red",
              });
              setReload(true);
            // ..
          });
    }

    const resetThePassword = (userEmail) => {
        setisLoading(true);
        const postBody = {
             userEmail
        };
        resetPassword(postBody).then((response) => {
            if( response.status === 200 || response.status === 201){
                setMessageContent({
                header: "Password Reset",
                message: "An Email with the reset link has been sent to the registered Email Id.",
                color: "green",
              });
            }  else {
                setMessageContent({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessageContent({
                header: "Error!",
                message: error.message,
                color: "red",
              });
            // ..
          });
    }


    function userArenaNames(facilityIncharge) {
        let arenas = '';
        for (let i = 0; i < facilityIncharge.length; i++) {
            if (i === 0) {
                arenas = facilityIncharge[i].arenaName;
            } else {
                arenas = arenas + ', ' + facilityIncharge[i].arenaName
            }
        }
        return arenas;
    }


    return isLoading ? (
        <GenericLoader />
    ) : (
        <div style={{ padding: "16px" }}>

            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Users </span>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>
                            <Button
                                style={{ marginLeft: "20px" }}
                                onClick={() => setAddModal(true)}
                                name={"Add Package"}
                            >
                                <Icon name={"add"} />
                                Add a User
                            </Button>

                        </div>
                    </div>
                </div>
            </div>

            <div style={{ paddingTop: "16px" }}>
                <Toast
                    showMessage={showMessage}
                    setShowMessage={setShowMessage}
                    message={messageContent.message}
                    messageHeader={messageContent.header}
                    color={messageContent.color}
                />
                <Table celled padded>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>S.No</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Email</Table.HeaderCell>
                            <Table.HeaderCell>Role</Table.HeaderCell>
                            <Table.HeaderCell>Arenas</Table.HeaderCell>
                            <Table.HeaderCell>Actions</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {users.map((y, index) => {
                            const x = y.data;
                            return (
                                <Table.Row key={y.id} >
                                    <Table.Cell>{index + 1}</Table.Cell>
                                    <Table.Cell>{x.userName}</Table.Cell>
                                    <Table.Cell>{x.userEmail}</Table.Cell>
                                    <Table.Cell>{x.role}</Table.Cell>
                                    <Table.Cell>{userArenaNames(x.facilityIncharge)} </Table.Cell>
                                
                                    <Table.Cell
                                        style={{
                                            flex: 1,
                                            justifyContent: "center",
                                            flexDirection: "column",
                                            display: "flex",
                                        }}
                                    >
                                        <Button
                                            style={{
                                                padding: "8px",
                                                fontSize: "12px",
                                                marginBottom: "8px",
                                                backgroundColor: colors.orange["800"],
                                                color: "#fff",
                                            }}
                                            onClick={() => openEditModal(x)}
                                        >
                                            Edit
                                        </Button>
                                        <Button
                                            style={{
                                                padding: "8px",
                                                fontSize: "12px",
                                                marginBottom: "8px",
                                            }}
                                            onClick={() => allowCheckinFromANewDevice(x)}
                                        >
                                            Allow Check-in from a new Device
                                        </Button>
                                        <Button
                                            style={{
                                                padding: "8px",
                                                fontSize: "12px",
                                                marginBottom: "8px",
                                            }}
                                            onClick={() => resetThePassword(x.userEmail)}
                                        >
                                            Reset password
                                        </Button>
                                        <Button
                                            style={{
                                                padding: "8px",
                                                fontSize: "12px",
                                                marginBottom: "8px",
                                            }}
                                            onClick={() => openDeleteModal(x)}
                                        >
                                            Delete
                                        </Button>
                                    </Table.Cell>
                                </Table.Row>
                              
                            );
                        })}
                    </Table.Body>
                </Table>
            </div>


            {addModal ? (
                <AddUser
                    history={history}
                    showModal={setAddModal}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                />
            ) : null}
            {deleteModal ? (
                <DeleteUser
                    history={history}
                    showModal={setDeleteModal}
                    setReload={setReload}
                    userData={selectedUser}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                />
            ) : null}
            {editModal ? (
                <EditUser
                    history={history}
                    showModal={setEditModal}
                    setReload={setReload}
                    userData={selectedUser}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                />
            ) : null}
        </div>
    );

    
};

export default UserManagement;