import React, { useState, useContext, useEffect, useRef } from "react";

import { Table, Popup, Dropdown } from "semantic-ui-react";
import "./style.scss";
import { DatePicker } from "@material-ui/pickers";
import { AppContext } from "../../AppContext";
import { getFacilityBookings } from "../../apiclients/DashboardApiClient";
import moment from "moment";
import {
  isIndividualBooking,
  convertFirstoreDate,
  getSourceLabel,
} from "../../utils/helperFunctions";
import RegularBooking from "./RegularBooking";
import GenericLoader from "../generic/GenericLoader";
import BookedBooking from "./booked-booking/BookedBooking";
import { colors } from "@material-ui/core";
import { getPnPSportsOfArena } from "../../apiclients/BookingApiClient";
import Toast from "../generic/Toast";
import { Helmet } from "react-helmet";
import { Button, Icon } from "semantic-ui-react";
import CustomModal from "../generic/CustomModal";
import SearchUserDetails from "./booked-booking/capacitySport/SearchUserDetails";
import RentSaleProducts from "./booked-booking/RentSaleProducts";
import SaleCafeProducts from "./booked-booking/SaleCafeProducts";
import { PAYMENT_STATUS, ROLES } from "../../utils/constants";

import ModalPortal from '../generic/ModalPortal';

const getTooltipBackgroundColor = (reqID, isIndividual, isPickupGame) => {
  if (!reqID) {
    return "#8bc34a";
  }
  if (isIndividual) {
    return colors.yellow["800"];
  }
  if (isPickupGame) {
    return colors.orange["800"];
  }
  if (reqID === "booked") {
    return "#8bc34a";
  }
  if (reqID === "extended") {
    return colors.yellow["600"];
  }
  if (reqID === "bulk") {
    return colors.blue["600"];
  }
  if (reqID === "blocked") {
    return colors.grey["600"];
  }
  if (reqID === "due") {
    return colors.red["900"];
  }
  if (reqID === "hold") {
    return colors.grey["200"];
  }

  return "#8bc34a";
};

const getBookedSlotTitle = (booking) => {
  const reqID = booking.bookingStatus;
  let title = "";
  if (!reqID) {
    title = "BOOKED";
  }

  if (booking.individualBooking) {
    title = "INDIVIDUAL";
  } else if (reqID === "bulk") {
    if (booking.isPickupGame) {
      title = "PICKUP GAME";
    } else {
      title = "BULK";
    }
  } else if (reqID === "extended") {
    title = "EXTENDED";
  } else if (reqID === "blocked") {
    title = booking.type || "BLOCKED";
  } else if (reqID === "group") {
    title = "GROUP";
  } else {
    title = booking.onHold ?  "ON HOLD" :"BOOKED"
  }

  if (booking.alternativeSport) {
    title = title + ' - ' + booking.alternativeSport.text.toUpperCase();
  }
  return title;
};

const Dashboard = () => {
  const {
    selectedFacility,
    selectedArena,
    arenaData,
    selectedSportDetails,
    dispatch,
    user
  } = useContext(AppContext);


  const arenaId = selectedArena.arenaId;
  let modules = {};
  if (arenaData[arenaId] !== undefined) {
    modules = arenaData[arenaId].modules;
  }
  const sports = useRef([]);
  const [selectedSport, setSelectedSport] = useState();
  const [selectedDate, handleDateChange] = useState(moment());
  const [isLoading, setisLoading] = useState(true);
  const [selectedSlot, setSelectedSlot] = useState(false);
  const [reload, setReload] = useState(false);

  const [showUserSearch, setShowUserSearch] = useState(false);
  const [showRentOrSell, setShowRentOrSell] = useState(false);
  const [showRentOrSellCafe, setShowRentOrSellCafe] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageContent, setMessageContent] = useState({});
  const [userDetails, setUserDetails] = useState([]);



  const arenaIndex = user.facilityIncharge.findIndex(x=>x.arenaId === selectedArena.arenaId);
  const details = selectedSportDetails && selectedSportDetails.detail;

  useEffect(() => {
    if (selectedFacility && selectedArena)
      getPnPSportsOfArena(selectedFacility, selectedArena.arenaId).then(
        (response) => {
          let sports = response.data;
          if(user.facilityIncharge[arenaIndex].sportIds){ // that means the user access is restricted to a sport
            const listOfSports = user.facilityIncharge[arenaIndex].sportIds;
              sports  = sports.filter(x=>listOfSports.includes(x.sportId))
          }
          dispatch({
            type: "addCurrentFacilitySports",
            payload: sports,
          });
        }
      );
  }, [selectedFacility, selectedArena.arenaId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedArena && arenaData[selectedArena.arenaId]) {
      let dropdownSportsData = arenaData[selectedArena.arenaId]?.sports.map(
        (x, index) => ({
          key: x.sportId,
          value: x.sportId,
          text: x.sportName,
        })
      );
      if(user.facilityIncharge[arenaIndex].sportIds){ // that means the user access is restricted to a sport
        const listOfSports = user.facilityIncharge[arenaIndex].sportIds;
        dropdownSportsData  = dropdownSportsData.filter(x=>listOfSports.includes(x.key))
      }
      sports.current = dropdownSportsData;
      setSelectedSport(dropdownSportsData[0]);
    }
  }, [arenaData]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadData = () => {
    setisLoading(true);
    return getFacilityBookings(
      selectedFacility,
      selectedArena.arenaId,
      selectedSport.value,
      selectedDate
    ).then((response) => {
      setisLoading(false);
      dispatch({
        type: "upsertSelectedSportDetails",
        payload: { ...response.data, sportId: selectedSport.value },
      });
    });
  };

  useEffect(() => {
    if (selectedSport) {
      loadData();
    }
  }, [selectedFacility, selectedArena, selectedSport, selectedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reload) {
      loadData().then(() => {
        setReload(false);
        setShowMessage(true);
        setTimeout(() => {
          setShowMessage(false);
        }, 2000);
      });
    };
  }, [reload]); // eslint-disable-line react-hooks/exhaustive-deps

  const changeSport = (event, { value }) => {

    const data = sports.current.find((facility) => facility.value === value);
    setSelectedSport(data);
  };
  const openModal = (booking) => {
    console.log('open modal', booking);
    setSelectedSlot({ ...booking, bookingDate: selectedDate });
  };

  const searchUser = (data, x,y) => {
    const userData = {
      userDetails : data.userDetails,
      interval: x.interval,
      timeId : x.timeId,
      bookingDate : selectedDate
    }
    setUserDetails(userData);
    setShowUserSearch(true);
  }

  return (
    <>
      <Helmet>
        <title>Dashboard - Play All Management System (PMS)</title>
        <meta name="description" content="This is an internal ERP system." />
      </Helmet>
      {isLoading ? (
        <GenericLoader />
      ) : (
        <div style={{ padding: "16px" }}>
          <Toast
            showMessage={showMessage}
            setShowMessage={setShowMessage}
            message={messageContent.message}
            messageHeader={messageContent.header}
            color={messageContent.color}
          />
          <div className='dashboard'>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div>
                <span>Bookings</span>
              </div>
              <div style={{ alignItems: "center" }}>
                <span>
                  {modules.productsSaleAndRental && user.role === ROLES.MANAGER ?
                    <Button
                      onClick={() => setShowRentOrSell(true)}
                      name={"Sell Product"}
                    >
                      <Icon name={"shop"} />
                      Sell/Rent Product
                    </Button>
                    : null}

                  {modules.cafeManagement && user.role === ROLES.MANAGER ?
                    <Button

                      onClick={() => setShowRentOrSellCafe(true)}
                      name={"Sell cafe items"}
                    >
                      <Icon name={"food"} />
                      Sell Cafe Items
                    </Button>
                    : null}


                </span>
              </div>
            </div>
          </div>
          <div className='selectionFilters'>
            <div>
              <Dropdown
                onChange={changeSport}
                placeholder='Select...'
                openOnFocus
                selection
                value={selectedSport && selectedSport.value}
                options={sports.current}
              />
            </div>
            <div style={{ width: "200px" }}>
              <DatePicker
                autoOk
                variant='inline'
                value={selectedDate}
                placeholder='Select Date...'
                onChange={handleDateChange}
                format='DD MMM yyyy'
                style={{ padding: "10px" }}
                minDate={!(user.role === ROLES.ADMIN ||user.role === ROLES.SALES_MANAGER || user.role === ROLES.REGIONAL_MANAGER || user.role === ROLES.PARTNER || user.role === ROLES.QC_MANAGER|| user.role === ROLES.ACCOUNTANT) ? user.role === ROLES.SALES_MANAGER ? moment().subtract(14, 'days') : moment().subtract(7, 'days') : undefined}
              />
            </div>
          </div>
          <Table celled>
            <Table.Header>
              <Table.Row style={{ textAlign: "center" }}>
                <Table.HeaderCell >Slot </Table.HeaderCell>
                {details &&
                  details.inventoryCourt.map((court) => {
                    return (
                      <Table.HeaderCell key={court.courtId}>
                        {court.courtName}
                      </Table.HeaderCell>
                    );
                  })}
                {details && details.capacityPerSlot &&
                  <>
                    <Table.HeaderCell > Search</Table.HeaderCell>
                  </>
                }
              </Table.Row>
            </Table.Header>

            <Table.Body>
              {selectedSportDetails &&
                selectedSportDetails.slotData.map((x, index) => {
                  return (
                    <Table.Row key={index} style={{ textAlign: "center" }}>
                      <Table.Cell >
                        {x.interval.start + " - " + x.interval.end}
                      </Table.Cell>
                      {x.courts.map((y, hb) => {
                        const usersString =
                          y.memberDetails && y.memberDetails.length > 0
                            ? y.memberDetails
                              .map((m) => user.role === ROLES.MANAGER ||user.role === ROLES.REGIONAL_MANAGER ||user.role === ROLES.ADMIN? m.name + " - " + m.number : m.name)
                              .join(",")
                            : user.role === ROLES.MANAGER ||user.role === ROLES.REGIONAL_MANAGER ||user.role === ROLES.ADMIN? y.userName + " - " + y.userMobileNumber : y.userName;
                        return y.booked ? (
                          <Table.Cell
                            key={hb}
                            onClick={!y.onHold ? () =>
                              openModal({
                                ...y,
                                slotTiming: x.timeId,
                                slot: {
                                  interval: x.interval,
                                  timeId: x.timeId,
                                },
                                courtNo: hb,
                                bType: "bookedSlot",
                              }):null
                            }
                            style={{
                              color: y.onHold? "#212121":(y.amountDue > 0|| y.paymentStatus === PAYMENT_STATUS.PENDING) ? "#fff" : y.bookingStatus === "extended" ? "#000" : "#fff",
                              backgroundColor: y.onHold?getTooltipBackgroundColor("hold") : (y.amountDue > 0|| y.paymentStatus === PAYMENT_STATUS.PENDING) ? getTooltipBackgroundColor("due") : getTooltipBackgroundColor(y.bookingStatus, y.individualBooking, y.isPickupGame),
                            }}
                          >
                            <Popup
                              flowing
                              hoverable
                              trigger={<div>{getBookedSlotTitle(y)}</div>}
                              content={
                                y.bookingStatus === "blocked" ? (
                                  y.reason
                                ) : (
                                  <div
                                    style={{
                                      padding: "6px",
                                      backgroundColor: isIndividualBooking(y),
                                    }}
                                  >
                                    {y.userName ? (
                                      <div>
                                        <b>Booked For:</b> {usersString}
                                      </div>
                                    ) : null}
                                    <div>
                                      <b>Booking Date: </b>
                                      {convertFirstoreDate(y.createdDate)},{" "}
                                      {y.booking_time || y.createdTime}
                                    </div>
                                    
                                    {y.amountDue ? (
                                      <div style={{ color: colors.red["600"] }}>
                                        <b>Amount Due:</b> Rs.{y.amountDue}/-

                                      </div>
                                    ) : null}
                                    {y.amountPaid > -1 ? (
                                      <div >
                                        <b>Amount Paid:</b> Rs.{y.amountPaid}/-

                                      </div>
                                    ) : null}
                                    {y.source ? (
                                      <div>
                                        <b>Source:</b> {getSourceLabel(y.source)}
                                      </div>
                                    ) : null}
                                    {y.paymentStatus === PAYMENT_STATUS.PENDING && (
                                      <div style={{ color: colors.red["600"] }}>
                                        <b>Payment Status : PENDING</b>

                                      </div>
                                    ) }
                                  </div>
                                )
                              }
                            />
                          </Table.Cell>
                        ) : (
                          <Table.Cell
                            key={hb}
                            onClick={() =>
                              openModal({
                                ...y,
                                ...x,
                                bType: "emptySlot",
                              })
                            }
                          >
                            Rs. {y.bookingPrice} /-
                          </Table.Cell>
                        );
                      })}

                      {details && details.capacityPerSlot &&
                        <>
                          <Table.Cell>
                            <Button
                              onClick={() => searchUser(x.courts[0], x)}
                              name={"Search"}
                              style={{
                                fontSize: "14px",
                                marginBottom: "8px",
                                backgroundColor: (x.courts[0].currentCapacity || x.courts[0].currentCapacity === 0) && colors.teal[
                                  (Math.ceil(((details.capacityPerSlot - x.courts[0].currentCapacity) * 900 / details.capacityPerSlot) / 100) * 100).toString()],
                                color: ((x.courts[0].currentCapacity || x.courts[0].currentCapacity === 0) && (Math.ceil(((details.capacityPerSlot - x.courts[0].currentCapacity) * 900 / details.capacityPerSlot) / 100) * 100) > 200) && "#fff",
                              }}
                              disabled={!(x.courts[0].currentCapacity || x.courts[0].currentCapacity === 0)}
                            >
                              <Icon name={"search"} />
                              {x.courts[0].currentCapacity || x.courts[0].currentCapacity === 0 ? details.capacityPerSlot - x.courts[0].currentCapacity : 0} Slots Booked
                            </Button>
                          </Table.Cell>
                         
                        </>
                      }
                    </Table.Row>
                  );
                })}
            </Table.Body>
          </Table>
          {details && details.capacityPerSlot && 
          <>
          <br />
          
          Swimming Booking and FlexiMembership
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>Swimming Booking</Table.HeaderCell>
                        <Table.HeaderCell>FlexiMembership</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                <Table.Row key={0}>
                <Table.Cell><div className="player2">
                <iframe width="200" height="100" src="https://www.youtube.com/embed/aw2ngnHg3NA" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture"  ></iframe>
                 </div></Table.Cell>
                <Table.Cell><div className="player2">
                <iframe width="200" height="100" src="https://www.youtube.com/embed/eq9g9bdXYE0" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; fullscreen; picture-in-picture" ></iframe>
                 </div></Table.Cell>
                
                </Table.Row>
                </Table.Body>
            
            </Table>
            </>}
          {selectedSlot && (user.role === ROLES.ADMIN || user.role === ROLES.MANAGER || user.role === ROLES.REGIONAL_MANAGER)? (
            <>
              {selectedSlot.bType === "emptySlot" ? (
                <RegularBooking
                  selectedDate={selectedDate}
                  sport={selectedSport}
                  selectedSlot={selectedSlot}
                  facility={{ ...selectedArena, id: selectedFacility }}
                  setSelectedSlot={setSelectedSlot}
                  setMessage={setMessageContent}
                  reload={setReload}
                />
              ) : (
                <BookedBooking
                  selectedSlot={selectedSlot}
                  sport={selectedSport}
                  setSelectedSlot={setSelectedSlot}
                  facility={{ ...selectedArena, id: selectedFacility }}
                  reload={setReload}
                  setMessage={setMessageContent}
                  isLoading={setisLoading}
                />
              )}
            </>
          ) : null}

          {/* {showUserSearch ? (
            <ModalPortal
            title="Search User"
            content={
              <SearchUserDetails
                facility={{
                  ...selectedArena,
                  id: selectedFacility,
                }}
                onSaveComplete={() => setReload(true)}
                setOpen={setShowUserSearch}
                setType={() => setShowUserSearch(false)}
                reload={() => setReload(true)}
                setMessage={setMessageContent}
                userDetails = {userDetails}
                setIsLoading={setisLoading}
              />
            }
            onDismiss={() => setShowUserSearch(false)}
          />
            
          ) : null} */}
          {showUserSearch ? 
              <SearchUserDetails
                facility={{
                  ...selectedArena,
                  id: selectedFacility,
                }}
                onSaveComplete={() => setReload(true)}
                setOpen={setShowUserSearch}
                setType={() => setShowUserSearch(false)}
                reload={() => setReload(true)}
                setMessage={setMessageContent}
                userDetails = {userDetails}
                setIsLoading={setisLoading}
              /> : null}

          {showRentOrSell ? (
            <CustomModal
              header={"Rent/Sell Products"}
              content={
                <RentSaleProducts
                  facility={{
                    ...selectedArena,
                    id: selectedFacility,
                  }}
                  onSaveComplete={() => setReload(true)}
                  setOpen={setShowRentOrSell}
                  setType={() => setShowRentOrSell(false)}
                  reload={() => setReload(true)}
                  setMessage={setMessageContent}
                />
              }
              comp={true}
              setOpen={setShowRentOrSell}
              open={showRentOrSell}
            />
          ) : null}

          {showRentOrSellCafe ? (
            <CustomModal
              header={"Sell Cafe Products"}
              content={
                <SaleCafeProducts
                  facility={{
                    ...selectedArena,
                    id: selectedFacility,
                  }}
                  onSaveComplete={() => setReload(true)}
                  setOpen={setShowRentOrSellCafe}
                  setType={() => setShowRentOrSellCafe(false)}
                  reload={() => setReload(true)}
                  setMessage={setMessageContent}
                />
              }
              comp={true}
              setOpen={setShowRentOrSellCafe}
              open={showRentOrSellCafe}
            />
          ) : null}

        </div>
      )}</>
  );
};

export default Dashboard;
