import React, { useContext } from "react";
import { Button, Table } from "semantic-ui-react";
import { AppContext } from "../../../AppContext";
import ModalPortal from "../../generic/ModalPortal";

const TransactionHistory = (props) => {
    const { data, showModal } = props;
    const {selectedSportDetails} = useContext(AppContext);
    const modalContent = (data) => {
        return <Table celled padded>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Booking Date</Table.HeaderCell>
                    <Table.HeaderCell>Slot</Table.HeaderCell>
                    <Table.HeaderCell>Court</Table.HeaderCell>
                    <Table.HeaderCell>Transaction Date & Time</Table.HeaderCell>
                    <Table.HeaderCell>Created By</Table.HeaderCell>
                    <Table.HeaderCell>Booking ID</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {data.map((x) => {
                    console.log('selectedSportDetails.detail.timings',selectedSportDetails.detail.timings);
                    console.log('selectedSportDetails.detail.timings.find(y=> y.timeId === x.slotId) ',selectedSportDetails.detail.timings.find(y=> y.timeId === x.slotId));
                    const slotTimeInterval = selectedSportDetails.detail.timings.find(y=> y.timeId === x.slotId).interval;
                    const slot = slotTimeInterval.start + '-' + slotTimeInterval.end;
                    const courtName = selectedSportDetails.detail.inventoryCourt.find(y=> y.courtId === x.courtId).courtName;
                    return (<Table.Row key={x.id}>

                        <Table.Cell>{new Date(x.bookingDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
                        <Table.Cell>{slot}</Table.Cell>
                        <Table.Cell>{courtName}</Table.Cell>
                        <Table.Cell>{new Date(x.createdDate.seconds * 1000).toLocaleDateString() + '-' + new Date(x.createdDate.seconds * 1000).toLocaleTimeString()}</Table.Cell>
                        <Table.Cell>{x.createdBy.userName}</Table.Cell>
                        <Table.Cell>{x.id}</Table.Cell>
                    </Table.Row>);
                })}
            </Table.Body>
        </Table>
    }

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            </React.Fragment>
        );
    };
    return (
        <>
            <ModalPortal
                title={'Transaction History for '+ data.userName}
                content={modalContent(data.data)}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    )
}

export default TransactionHistory;