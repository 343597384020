import moment from "moment";
import React, { useContext, useState } from "react";
import { Button, Form, Message, Table } from "semantic-ui-react";
import { getMembersDetails, postFlexiMemberBooking } from "../../../apiclients/DashboardApiClient";
import { AppContext } from "../../../AppContext";

const AddMembersBooking = ({ number, setNumber, bookingDate, bookingDetails, setShowLoader,
  setSelectedSlot,
  setMessage,
  reload, }) => {
  const { user, selectedSportDetails, selectedFacility, selectedArena } = useContext(AppContext);

  const [showMessage, setShowMessage] = useState(false);
  const [currentMembership, SetCurrentMembership] = useState([]);
  const [expiredMembership, SetExpiredMembership] = useState([]);
  const fetchMembersName = () => {
    if (number && number.length !== 10) {
      setShowMessage(true);
      return;
    }
    if (number) {
      setShowMessage(false);
      let date = moment(bookingDate).format('DD-MM-YYYY');
      getMembersDetails(selectedFacility, selectedArena.arenaId, selectedSportDetails.sportId, number, date,bookingDetails.interval.start).then((response) => {
        const data = response.data.memberDetails.membersData;
        SetCurrentMembership(data.currentMembership);
        SetExpiredMembership(data.expiredMembership);
      });
    }
  };

  const getStatusOfMembership = (data) => {
    if (!data.isUsageNotAvailed) {
      return "Membership Already Used today";
    };

    if (!data.isBookingWindowOpen) {
      return "Booking is not available now";
    };

    return true;
  }

  const makeBooking = (data) => {
    setShowLoader(true);
    const postData = {
      facilityId: selectedFacility,
      arenaId: selectedArena.arenaId,
      sportId: selectedSportDetails.sportId,
      membershipId: data.memData.id,
      bookingDate: bookingDetails.bookingDate,
      slotId: bookingDetails.timeId,
      courtId: bookingDetails.courtId,
      userName: data.memData.userName,
      userMobile: number,
      createdBy: {
        userName: user.userName,
        userId: user.userId,
      }
    };
    postFlexiMemberBooking(postData).then((response) => {
      setSelectedSlot(false);
      setShowLoader(false);
      setMessage({
        header: "Successful Booking!",
        message: "You booking has been added.",
        color: "green",
      });
      reload(true);
    })
      .catch((error) => {
        setShowLoader(false);
      });


  }
 
  return (
    <>
      <Form>
        <Form.Group widths='equal'>
          <Form.Field>
            <label>Mobile Number</label>
            <input
              value={number}
              type='text'
              pattern='[0-9]{10}$*'
              placeholder='e.g., 9999XXXXXX'
              onInput={setNumber}
              onBlur={fetchMembersName}
            />
          </Form.Field>

        </Form.Group>
      </Form>
      {showMessage && (
        <Message
          onDismiss={() => setShowMessage(false)}
          color={"red"}
          content='Add correct 10-digit phone number'
        />
      )}
      {currentMembership &&  currentMembership.length > 0 ?
        <><h3>Active Membership</h3>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Mobile</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>End Date </Table.HeaderCell>
                <Table.HeaderCell>Status</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {currentMembership.map((y) => {
                const x = y.memData;
                return (<Table.Row key={x.id}>
                  <Table.Cell>{x.userName}</Table.Cell>
                  <Table.Cell>{x.userMobileNumber}</Table.Cell>
                  <Table.Cell>{new Date(x.startDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
                  <Table.Cell>{new Date(x.endDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
                  <Table.Cell>{getStatusOfMembership(y) === true ?
                    <Button onClick={() => makeBooking(y)} primary> Book </Button>
                    : getStatusOfMembership(y)
                  }</Table.Cell>
                </Table.Row>);
              })}
            </Table.Body>
          </Table>
        </>
    :expiredMembership && expiredMembership.length > 0 ?
    <><h3>Inactive Membership</h3>
          <Table celled padded>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Mobile</Table.HeaderCell>
                <Table.HeaderCell>Start Date</Table.HeaderCell>
                <Table.HeaderCell>End Date </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {expiredMembership.map((y) => {
                console.log(y);
                const x = y.memData;
                return (<Table.Row key={x.id}>
                  <Table.Cell>{x.userName}</Table.Cell>
                  <Table.Cell>{x.userMobileNumber}</Table.Cell>
                  <Table.Cell>{new Date(x.startDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
                  <Table.Cell>{new Date(x.endDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
               
                </Table.Row>);
              })}
            </Table.Body>
          </Table>
        </>
    : "No membership associated with this membership"  
    }
    </>
  );
}

export default AddMembersBooking;