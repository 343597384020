import React, { useContext, useState } from 'react';
import {
    Button,
} from "semantic-ui-react";

import { colors } from "@material-ui/core";
import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import AcademyProductSelections from '../../rates&inventory/academyStockRegister/AcademyProductSelections';
import { allotProductToStudents } from '../../../apiclients/ProductApiClient';

const AllotProduct = (props) => {
    const { showModal, setReload, studentData, setMessage, isLoading, selectedSport } = props;

    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const sportId = selectedSport.key;
    const [selectedItems, setSelectedItems] = useState([]);
    const createdBy = {
        userName: context.user.userName,
        userId: context.user.userId,
    };

    const modalContent = (
        <AcademyProductSelections
            facilityId={facilityId}
            arenaId={arenaId}
            sportId={sportId}
            selectedItems={selectedItems}
            setSelectedItems={setSelectedItems}
        />
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>

                <Button style={{
                    backgroundColor: colors.orange["800"],
                    color: "#fff",
                }}
                    onClick={allotProducts}>  Allot Product</Button>
            </React.Fragment>
        );
    };

    const allotProducts = () => {

        showModal(false);
        isLoading(true);

        const quantityProducts = selectedItems.filter(x => x.quantityAdded > 0);
        const products = quantityProducts.map((x) => {
            const price = 0;
            const discount = +x.discount ? +x.discount / 100 : 0;
            const netAmount = Math.round(x.quantityAdded * price * (1 - discount) * 100) / 100;
            const totalGST = Math.round((x.cgst + x.sgst + x.igst) * 100) / 100;
            const totalTax = Math.round((x.cgst + x.sgst + x.igst + x.cess) * 100) / 100;
            const totalAmount = x.quantityAdded * price;
            const cgst = x.cgst ? Math.round(x.cgst * (netAmount / (1 + totalTax)) * 100) / 100 : 0;
            const sgst = x.sgst ? Math.round(x.sgst * (netAmount / (1 + totalTax)) * 100) / 100 : 0;
            const igst = x.igst ? Math.round(x.igst * (netAmount / (1 + totalTax)) * 100) / 100 : 0;
            const cess = x.cess ? Math.round(x.cess * (netAmount / (1 + totalTax)) * 100) / 100 : 0;
            const taxableAmount = Math.round((netAmount - cgst - sgst - igst - cess) * 100) / 100;
            // totalCgst = totalCgst + cgst; totalSgst = totalSgst + sgst; totalIgst = totalIgst + igst; totalCess = totalCess + cess; totalTaxableAmount = totalTaxableAmount + taxableAmount;
            return {
                id: x.id, name: x.productName,
                quantity: +x.quantityAdded, discount, isRental: !!x.isRenting, totalAmount, netAmount,
                price, cgst, sgst, igst, cess, taxableAmount, hsn: x.hsn ? x.hsn : '9996', gst: totalGST ? totalGST : 0,
            }
        });
        console.log('products are ', products);
        const postBody = {
            facilityId, arenaId, sportId, studentData: studentData, selectedItems : products, createdBy
        };

        allotProductToStudents(
            postBody
        ).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setMessage({
                    header: "Product alloted",
                    message: "Product has been successfully alloted.",
                    color: "green",
                });
            } else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                });
            }
            setReload(true);
        }).catch((error) => {

            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
            });
            // ..
        });
    }
    return (
        <>
            <ModalPortal
                title='Allot Product'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default AllotProduct;