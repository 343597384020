import React, { useContext, useRef, useState, useEffect } from 'react';
import GenericLoader from '../generic/GenericLoader';
import { Dropdown, Button, Icon, Table, Tab } from 'semantic-ui-react';
import { AppContext } from '../../AppContext';
import Toast from '../generic/Toast';
import { getActiveLeads } from '../../apiclients/CustomersClient';
import { colors } from '@material-ui/core';

const ActiveLeadDataTable = ({
    listOfLeads,
    setReload,
    setMessage,
    isLoading,
    history,
     types
}) => {
    const [showDetails, setShowDetails] = useState(false); // to show student detail modal
    const [leadId, setLeadId] = useState();
    const openLeadDetail = (data, id) => () => {
        setShowDetails(data);
        setLeadId(id);
    };

    return (
        <>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>S.No</Table.HeaderCell>
                        <Table.HeaderCell>Mobile</Table.HeaderCell>
                        <Table.HeaderCell>Created Date & Time</Table.HeaderCell>
                        <Table.HeaderCell>Interest Sport</Table.HeaderCell>
                        <Table.HeaderCell>Interest Area</Table.HeaderCell>
                        <Table.HeaderCell>New Customer</Table.HeaderCell>
                        <Table.HeaderCell>Source</Table.HeaderCell>
                        <Table.HeaderCell>Stage</Table.HeaderCell>
                        <Table.HeaderCell>Detail</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {listOfLeads && listOfLeads.map((x, i) => {
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{x.customerPhone}</Table.Cell>
                                <Table.Cell>{new Date(x.createdAt.seconds * 1000).toLocaleDateString()} | {new Date(x.createdAt.seconds * 1000).toLocaleTimeString()}</Table.Cell>
                                <Table.Cell>{x.sportName}</Table.Cell>
                               <Table.Cell>{x.interestArea}</Table.Cell>
                                <Table.Cell>{x.newCustomer ? 'Yes' :'No'}</Table.Cell>
                                <Table.Cell>{x.source}</Table.Cell>
                                <Table.Cell>{x.stage}</Table.Cell>
                                <Table.Cell >
                                    <div
                                        style={{
                                            textDecoration: "underline",
                                            color: colors.blue[500],
                                        }}
                                        onClick={openLeadDetail(x, x.id)}
                                    >
                                        {x.id}
                                    </div>
                                </Table.Cell>
                            </Table.Row>
                        );
                    })}
                </Table.Body>
            
            </Table>


            {/* {!!showDetails ? (
                <LeadDetails
                    trialData={showDetails}
                    leadId={leadId}
                    showModal={setShowDetails}
                    setReload={setReload}
                    setMessage={setMessage}
                    isLoading={isLoading}
                    history={history}
                    type={types}
                />
            ) : null} */}
        </>
    );
};

const OpenLeadManagement = (props) => {
    const { history } = props;
    const context = useContext(AppContext);
    const { selectedFacility, selectedArena, } = context;
    const [activeIndex, setActiveIndex] = useState(0);
    const [listOfLeads, setListOfLeads] = useState([]);
    const [reload, setReload] = useState(false);
    const [isLoading, setisLoading] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [messageContent, setMessageContent] = useState({});
    const types = ['active',  'followup',];

    useEffect(() => {
        if (selectedFacility && selectedArena) {
          loadData();
        }
    }, [selectedFacility, selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps
    
    useEffect(() => {
        loadData();
    }, [activeIndex]);

    useEffect(() => {
        if (reload) {
            loadData().then(() => {
                setReload(false);
                setShowMessage(true);
                setTimeout(() => {
                    setShowMessage(false);
                }, 2000);
            });
        }
    }, [reload]);// eslint-disable-line react-hooks/exhaustive-deps


    const loadData = async () => {
        setisLoading(true);

        console.log('the data is ', 
        selectedFacility,
        selectedArena.arenaId,
        types[activeIndex],);
            return await getActiveLeads(
                selectedFacility,
                selectedArena.arenaId,
                types[activeIndex],
            ).then((response) => {
                const { result } = response.data;
                setListOfLeads(result);
            }).then(() => {
                setisLoading(false);
            });
     
    };

    const panes = [
        {
            menuItem: "Active Leads",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <ActiveLeadDataTable
                        listOfLeads={listOfLeads}
                        setReload={setReload}
                        history={history}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                        types={types[activeIndex]}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Follow Ups",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <ActiveLeadDataTable
                        listOfLeads={listOfLeads}
                        setReload={setReload}
                        history={history}
                        setMessage={setMessageContent}
                        isLoading={setisLoading}
                        types={types[activeIndex]}
                    />
                </Tab.Pane>
            ),
        },

    ];

    return isLoading ? (
        <GenericLoader />
    ) : (
        <div style={{ padding: "16px" }}>
            <Toast
                showMessage={showMessage}
                setShowMessage={setShowMessage}
                message={messageContent.message}
                messageHeader={messageContent.header}
                color={messageContent.color}
            />
            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Open Leads</span>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>
                           
                            <Button
                                style={{ marginLeft: "20px" }}
                                // onClick={() => setAddModal(true)}
                                name={"Add Package"}
                            >
                                <Icon name={"add"} />
                               Add a Lead
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
            <br />
            {isLoading ? (
                <GenericLoader />
            ) : (
                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    renderActiveOnly={true}
                    menu={{
                        color: "orange",
                        pointing: true,
                    }}
                    panes={panes}
                />
            )}

            
            {/* {addModal ? (
                <AddTrial
                    history={history}
                    showModal={setAddModal}
                    setReload={setReload}
                    setMessage={setMessageContent}
                    isLoading={setisLoading}
                    selectedSport={selectedSport}
                />
            ) : null} */}
        </div>
    );
}

export default OpenLeadManagement;