import React, { useContext, useReducer } from "react";
import { Icon } from "semantic-ui-react";

import "../dashboard/style.scss";
import { colors } from "@material-ui/core";
import { getUserName } from "../../apiclients/DashboardApiClient";
import { AppContext } from "../../AppContext";

const initialState = {
  name: "",
  number: "",
};
const ACTION_TYPE = {
  ADD_NAME: "addName",
  ADD_NUMBER: "addNumber",
};

const reducer = (state, action) => {
  if (action.type === ACTION_TYPE.ADD_NAME) {
    return { ...state, name: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_NUMBER) {
    return { ...state, number: action.payload };
  }
  if (action.type === ACTION_TYPE.CLEAN) {
    return initialState;
  }

  return state;
};

const AddUser = ({ single, userArray, setUserArray }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { name, number } = state;
  const context = useContext(AppContext);

  const onChange = (type, data) => {
    dispatch({
      type,
      payload: data.target.value,
    });
  };

  const handleChange = (evt) => {
    const numericValue = evt.target.validity.valid ? evt.target.value : number;
    dispatch({ type: ACTION_TYPE.ADD_NUMBER, payload: numericValue });
  };

  const onKeyPress = (event) => {
    console.log("Keycode", event.keyCode);
    if (name && number && event.key === "Enter") {
      addPaymentOption();
    }
  };
  const addPaymentOption = () => {
    const val = {
      name,
      number,
    };
    const updatedArray = [...userArray, val];
    setUserArray(updatedArray);
    dispatch({
      type: ACTION_TYPE.CLEAN,
      payload: {
        name: "",
        number: "",
      },
    });
  };

  const fetchUserName = () => {
    if (number && !name) {
      getUserName(context.selectedFacility, number).then((response) => {
        console.log(response.data);
        dispatch({ type: ACTION_TYPE.ADD_NAME, payload: response.data.name });
      });
    }
  };

  const removePaymentOption = (deleteIndex) => () => {
    const updatedArray = userArray.filter((x, index) => deleteIndex !== index);
    setUserArray(updatedArray);
  };

  return (
    <div>
      <div style={{ flexDirection: "row", display: "flex", flexWrap: "wrap" }}>
        {userArray.map((x, index) => {
          return (
            <div
              key={index}
              style={{
                display: "flex",
                flexDirection: "row",
                margin: "7px",
                padding: "2px",
                borderRadius: "4pxs",
              }}
            >
              <div
                style={{
                  backgroundColor: colors.blueGrey["50"],
                  padding: "3px",
                }}
              >
                <b>+</b>
                {x.name + "   "}
                {x.number && "  -  " + x.number + "   "}
                <Icon
                  onClick={removePaymentOption(index)}
                  name={"minus square"}
                />
              </div>
            </div>
          );
        })}
      </div>
      {(single ? userArray.length === 0 : true) && (
        <div className={"row-center"}>
          <input
            pattern='[0-9]*'
            type='text'
            onInput={handleChange}
            value={number}
            placeholder='e.g., 9999XXXXXX'
          />
          <input
            onKeyPress={onKeyPress}
            type='text'
            onFocus={fetchUserName}
            onInput={(d) => onChange(ACTION_TYPE.ADD_NAME, d)}
            value={name}
            placeholder='e.g., Rahul'
          />

          <div>
            <Icon
              onClick={addPaymentOption}
              name={"add square"}
              className={"add-minus-button"}
              disabled = {!(name && number)}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default AddUser;
