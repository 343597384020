import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import {Helmet} from "react-helmet";

import { Fade, Zoom } from 'react-reveal';
const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>THIS IS HOW</h4>
        <h1 data-aos='fade-up'>
          We Turn Your Ideas Into High-Performing Facilities{" "}
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#creativity'></use>
          </svg>
          <h5> Innovative Ideas </h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#data'></use>
          </svg>
          <h5> Best Tech</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#support'></use>
          </svg>
          <h5> Satisfied Clients</h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>
      <section  class='partnersec badmintondeco'>
        <div class='container'>
          <div class='innerbx'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade left><img src='images/cons1.png' alt=''/></Fade>
                <h5 class='pr-4 pl-4'>
                  <Zoom>While our specialty lies in sports lighting and flooring, we
                  also offer customized turnkey solutions to ensure that you
                  receive the benefit of optimal cost and quality</Zoom>
                </h5>
              </div>
            </div>

            <div class='contentarea '>
              <Zoom><h4 >SPORTS INFRASTRUCTURE</h4>
              <h2 >
                Single-Source Solution For All Sports Requirements
              </h2></Zoom>

              <span>
                We are changing the sports infra consulting industry by
                combining our three-decade-long cumulative industry experience
                with in-the-field expertise and advanced technology. That’s how
                we deliver a single-source solution for any sports
                infrastructure project.{" "}
              </span>
              <div class='btarea'>
                <button class='btorng'>Email - infra@playall.in</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec cricketdeco'>
        <div class='container'>
          <div class='innerbx'>
            <div class='contentarea '>
             <Zoom> <h4 >MONETIZATION AND BEYOND </h4>
              <h2>We See Things The Sports Way </h2></Zoom>

              <span>
                We know what it takes to design a venue that energizes the
                audience while maximizing revenue prospects. So, when you want
                to make the most of your existing sports infrastructure, know
                that we can help you monetize it too.{" "}
              </span>
              <div class='btarea'>
                <button class='btorng'>Email - infra@playall.in</button>
              </div>
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
               <Fade right> <img src='images/cons2.png' alt=''/></Fade>
                <h5 class='pr-4 pl-4'>
                 <Zoom> At Play All, we see things differently. We see them through
                  the sports filter, which is why our solutions help you embrace
                  the sports spirit to the fullest.{" "}</Zoom>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class=' techsection '>
        <div class='container'>
          <div class='headarea'>
          <h4  class=' sectiontitle '>
            HOW DO WE
            </h4>
            <Zoom ><h2  class=' sectionhead '>
              Serve At Every Step?
            </h2></Zoom>
            <span>
              For us, sport is everything. To help you build a legacy out of
              your complex ideas, we assist with:
            </span>
          </div>
          <div class='techarea sportsarea'>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#whistle'></use>
                </svg>
              </div>
             <Zoom> <h4>
                Planning, Designing & Construction
              </h4></Zoom>
              <span>
                We help transform your facility concept into reality with our
                new-age plans, designs, and construction solutions.
              </span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#growth'></use>
                </svg>
              </div>
             <Zoom> <h4 >Improving Existing Infra</h4></Zoom>
              <span>
                We know how challenging it is to renovate existing facilities.
                That’s why we help with extensive renovations, reconstructions,
                and repositioning of existing sports venues.
              </span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#fitness'></use>
                </svg>
              </div>
              <Zoom><h4>Management</h4></Zoom>
              <span>
                We notice the sweat, time, and dedication you put into your
                sports facilities. And, so, we are all geared to manage what you
                have built so passionately.
              </span>
            </div>
          </div>
        </div>
      </section>
    </main>{" "}
  </>
);

const ConsultancyPage = () => {
  return (
    <>

<Helmet>
            <title>Sports Infrastructure consultancy</title>
            <meta name="description" content="Play all offers consulting service for sports infrastrucutre development including turnkey projects for badminton flooring, badminton lighting, futsal turf, football turf, box cricket truf and others." />
        </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner infraconsultancybg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"consultancy"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default ConsultancyPage;
