import React, { useContext} from 'react';
import {
    Button,
} from "semantic-ui-react";

import { colors } from "@material-ui/core";
import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { noShowTrial } from "../../../apiclients/AcademyClient";

const NoShow = (props) => {
    const {  showModal, setReload, trialData, setMessage,isLoading, trialId, sportId } = props;
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;

    const modalContent = (
        <div>
            Are you sure you  want to mark the Trial for - {trialData.parentName} as a No-Show ?
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            
                <Button  style={{
                                                backgroundColor: colors.orange["800"],
                                                color: "#fff",
                                            }}
                                             onClick={markNoShow}>  Mark No Show</Button>
                </React.Fragment>
        );
    };

    const markNoShow = () => {

        showModal(false);
        isLoading(true);

        const postBody = {
            facilityId, arenaId, sportId, trialId, 
            statusUpdatedBy: {
                userName: context.user.userName,
                userId: context.user.userId,
            }
        };

        noShowTrial(
            postBody
        ).then((response) => {
            if( response.status === 200 || response.status === 201){
                setMessage({
                    header: "Trial Updated",
                    message: "Trial Marked as a No-Show.",
                    color: "grey",
                  });
            }  else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
              });
            // ..
          });
    }
    return (
        <>
            <ModalPortal
                title='No-Show Confirmation'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default NoShow;