import React, { useState, useEffect, useContext } from "react";
import {
  Form,
  Segment,
  Modal,
  Button,
  Input,
} from "semantic-ui-react";
import { getLastReceiptNumber, getComplimentaryProducts } from "../../../apiclients/DashboardApiClient";
import { complimentaryProduct,  } from "../../../apiclients/ProductApiClient";
import { AppContext } from "../../../AppContext";
import GenericLoader from "../../generic/GenericLoader";
import CustomerDetail from "../../common/CustomerDetail";
import { Typeahead } from 'react-bootstrap-typeahead'; // ES2015


const Footer = ({ setOpen, type, setType, disabled, onConfirm }) => {
  return (
    <>
      <Button onClick={() => setType("request")}>Go Back</Button>
      <Button primary disabled={disabled} onClick={onConfirm}>
        Save
      </Button>
    </>
  );
};

const ComplimentaryProducts = ({
  facility,
  booking,
  setOpen,
  setType,
  reload,
  setMessage,
}) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [receiptNo, setReceiptNo] = useState();
  const [loader, setLoader] = useState(false);
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [data, setData] = useState([]);

  const context = useContext(AppContext);

  useEffect(() => {
    getComplimentaryProducts(facility.id, facility.arenaId, context.selectedSportDetails.sportId).then((response) => {
      const dataWithValue = response.data.data.map((x) => ({
        ...x,
        text: x.productName,
        value: x.id,
        key: x.id,
      }));
      setData(dataWithValue);
    });
    getLastReceiptNumber(facility.id, facility.arenaId).then((response) => {
      setReceiptNo(response.data.nextReceipt);
    });
  }, []);// eslint-disable-line react-hooks/exhaustive-deps



  const onSaveClicked = () => {
    setLoader(true);
  
    const products = selectedItems.map((x) => { 
      const price = 0; // because its complimentary
      const discount = +x.discount ? +x.discount/100 : 0;
      const netAmount = Math.round(x.complimentary.qtyPerBooking*price*(1-discount)*100)/100;
      const totalGST = Math.round((x.cgst+x.sgst+x.igst)*100)/100;
      const totalTax = Math.round((x.cgst+x.sgst+x.igst+x.cess)*100)/100;
      const totalAmount =  x.complimentary.qtyPerBooking*price;
      const cgst = x.cgst ? Math.round(x.cgst*(netAmount/(1+totalTax))*100)/100 : 0;
      const sgst = x.sgst ? Math.round(x.sgst*(netAmount/(1+totalTax))*100)/100 : 0;
      const igst = x.igst ? Math.round(x.igst*(netAmount/(1+totalTax))*100)/100 : 0;
      const cess = x.cess ? Math.round(x.cess*(netAmount/(1+totalTax))*100)/100 : 0;
      const taxableAmount = Math.round((netAmount - cgst - sgst - igst - cess)*100)/100;
      return {id: x.id,name: x.productName,
      quantity: +x.complimentary.qtyPerBooking,discount,isRental: !!x.isRenting,totalAmount,netAmount,
      price,cgst,sgst,igst,cess,taxableAmount,hsn : x.hsn ? x.hsn : '9996',gst:totalGST ? totalGST : 0,
    }
  });
    let postBody = {
      arenaId: facility.arenaId,
      sport: context.selectedSportDetails.sportId,
      products: JSON.stringify(products),
      courtInfo: {
        courtId : booking && booking.courtId ? booking.courtId : 'NA',
        courtName :booking && booking.courtName? booking.courtName : 'NA'
      },
      slot : booking && booking.slot ? booking.slot : 'NA',
      receiptNumber: Number(receiptNo),
    };

    postBody.userName = booking ? booking.userName : name;
    postBody.mobileNumber = booking ? booking.userMobileNumber : number;
    postBody.bookingId = booking ? booking.id : undefined;
    postBody.createdBy = {
      userName: context.user.userName,
      userId: context.user.userId,
    };
    postBody.bookingDate = booking ? booking.bookingDate : undefined;
    complimentaryProduct(facility.id, facility.arenaId, postBody)
      .then(() => {
        setLoader(false);
        setOpen(false);
        setMessage &&
          setMessage({
            header: "Complimentary Product given!",
            message: "You transaction has been recorded.",
            color: "teal",
          });
          reload(true);
      })
      .catch((err) => {
        setLoader(false);
        setMessage &&
          setMessage({
            header: "Error",
            message: `${err.response}`,
            color: "red",
          });
          reload(true);
      });
  };



  function handleNumberChange(evt) {
    const numericValue = evt.target.validity.valid ? evt.target.value : number;
    setNumber(numericValue);
  }
  return ( 
    <>

{loader ? <GenericLoader /> : null}
      <Modal.Content>
        {booking ? (
          <Segment.Group>
            <div className={"info-cell"}>
              <div className={"pr10-bold"}>User Name:</div>
              <div>{booking.userName}</div>
            </div>
            <div className={"info-cell"}>
              <div className={"pr10-bold"}>Mobile Number:</div>
              <div>{booking.userMobileNumber}</div>
            </div>
          </Segment.Group>
        ) : (
          <Form>
            <CustomerDetail
              name={name}
              setName={setName}
              number={number}
              setNumber={handleNumberChange}
            />
          </Form>
        )}
        <Form>
        <Form.Group widths='equal'>
        <Form.Field>
          <label>Search a Complimentary Product</label>
          <Typeahead
            id="basic-typeahead-multiple"
            labelKey="productName"
            multiple
            onChange={setSelectedItems}
            options={data}
            placeholder="Type a product..."
            selected={selectedItems}
          />
        </Form.Field>
      </Form.Group>
        </Form>
        {selectedItems &&
        selectedItems.map((x) => {
          return (
            <Segment key={x.id}>
             
              <div>Product Name : {x.text}</div>

              <Form.Group wproductIdths='equal'>
                <Form.Field>
                  <label>Quantity</label>
                  <Input
                  type="number"
                    value={x.complimentary.qtyPerBooking}
                    disabled
                  />
                 
                </Form.Field>
             
              </Form.Group>
            </Segment>
          );
        })}
      </Modal.Content>
      <Modal.Actions>
        <Footer
          onConfirm={onSaveClicked}
          setType={setType}
          setOpen={setOpen}
          disabled={selectedItems.length >0? false : true}
        />
      </Modal.Actions>
    </>
 );
};

export default ComplimentaryProducts;
