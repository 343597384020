import React, { useContext} from 'react';
import {
    Button,
} from "semantic-ui-react";

import { colors } from "@material-ui/core";
import ModalPortal from '../../generic/ModalPortal';
import { AppContext } from '../../../AppContext';
import { deleteStudent } from "../../../apiclients/AcademyClient";

const DeleteStudent = (props) => {
    const {  showModal, setReload, studentData, setMessage,isLoading, selectedSport } = props;
  
    const context = useContext(AppContext);
    const facilityId = context.selectedFacility;
    const arenaId = context.selectedArena.arenaId;
    const sportId = selectedSport.key;
    const deletedBy =  {
        userName: context.user.userName,
        userId: context.user.userId,
    };

    const modalContent = (
        <div>
            Are you sure you  want to delete the Student - {studentData.studentName} ?
        </div>
    );

    const renderAction = () => {
        return (
            <React.Fragment>
                <Button onClick={() => showModal(false)} className='ui button'>Cancel</Button>
            
                <Button  style={{
                                                backgroundColor: colors.orange["800"],
                                                color: "#fff",
                                            }}
                                             onClick={deleteAStudent}>  Delete</Button>
                </React.Fragment>
        );
    };

    const deleteAStudent = () => {

        showModal(false);
        isLoading(true);

        const postBody = {
            facilityId, arenaId, sportId, studentId : studentData.studentId, enrollmentId : studentData.enrollmentId,deletedBy
        };

        deleteStudent(
            postBody
        ).then((response) => {
            if( response.status === 200 || response.status === 201){
                setMessage({
                    header: "Student Deleted",
                    message: "Student has been successfully deleted.",
                    color: "green",
                  });
            }  else {
                setMessage({
                    header: "Error!",
                    message: response.data.error.message,
                    color: "red",
                  });
            }
            setReload(true);
        }).catch((error) => {
    
            setMessage({
                header: "Error!",
                message: error.message,
                color: "red",
              });
            // ..
          });
    }
    return (
        <>
            <ModalPortal
                title='Delete Student'
                content={modalContent}
                action={renderAction()}
                onDismiss={() => showModal(false)}
            />
        </>

    );


}

export default DeleteStudent;