import React, { useEffect, useContext, useState, useReducer } from "react";

import "../dashboard/style.scss";
import {
  Button,
  Dropdown,
  Form,
  Checkbox,
  Radio,
} from "semantic-ui-react";
import { AppContext } from "../../AppContext";
import { colors } from "@material-ui/core";
import { getPnPSportsOfArena } from "../../apiclients/BookingApiClient";
import GenericLoader from "../generic/GenericLoader";
import NumberInput from "../../lib/NumberInput";
import { createNewPackage } from "../../apiclients/MembershipApiClient";

const daysArray = [
  { label: "Sunday", value: 0 },
  { label: "Monday", value: 1 },
  { label: "Tuesday", value: 2 },
  { label: "Wednesday", value: 3 },
  { label: "Thursday", value: 4 },
  { label: "Friday", value: 5 },
  { label: "Saturday", value: 6 },
];
const initialState = {
  sport: "",
  dayCount: 0,
  days: [],
  courts: [],
  startSlot: "",
  endSlot: "",
  type: "individual",
  name: "",
};

const ACTION_TYPE = {
  ADD_NAME: "addName",
  ADD_TYPE: "addType",
  ADD_SPORT: "addSport",
  ADD_COURTS: "addCourts",
  ADD_NUMBER_OF_DAYS: "addNumberOfDays",
  ADD_DAYS: "addDays",
  ADD_START_SLOT: "addStartSlot",
  ADD_END_SLOT: "addEndSlot",
  ADD_PRICE: "addPrice",
};

const reducer = (state, action) => {
  if (action.type === ACTION_TYPE.ADD_NAME) {
    return { ...state, name: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_TYPE) {
    return { ...state, type: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_PRICE) {
    return { ...state, price: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_SPORT) {
    return { ...state, sport: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_COURTS) {
    return { ...state, courts: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_NUMBER_OF_DAYS) {
    return { ...state, dayCount: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_DAYS) {
    return { ...state, days: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_START_SLOT) {
    return { ...state, startSlot: action.payload };
  }
  if (action.type === ACTION_TYPE.ADD_END_SLOT) {
    return { ...state, endSlot: action.payload };
  }

  return state;
};

const AddPackage = (props) => {
  const { history } = props;
  const [state, dispatch] = useReducer(reducer, initialState);

  const context = useContext(AppContext);
  const [sportDetail, setSportDetail] = useState([]);
  const [showBookingWindow, setShowBookingWindow] = useState(false);
  const [availableSlots, setAvailableSlots] = useState();
  const [loader, setLoader] = useState(false);

  const dropdownSportsData = context.arenaData[
    context.selectedArena.arenaId
  ]?.sports.map((x, index) => ({
    key: x.sportId,
    value: x.sportId,
    text: x.sportName,
  }));

  const {
    sport,
    type,
    days,
    courts,
    startSlot,
    endSlot,
    price,
    name,
    dayCount,
  } = state;
  const isMandatoryFieldsSelected =
    sport &&
    name &&
    dayCount &&
    type &&
    days &&
    courts &&
    startSlot &&
    endSlot &&
    price &&
    startSlot.key <= endSlot.key;
  useEffect(() => {
    getPnPSportsOfArena(
      context.selectedFacility,
      context.selectedArena.arenaId
    ).then((response) => {
      const sports = response.data;
      const initialSport = sports[0];
      dispatch({
        type: ACTION_TYPE.ADD_SPORT,
        payload: {
          key: initialSport.sportId,
          value: initialSport.sportId,
          text: initialSport.sportName,
        },
      });
      setSportDetail(sports);
    });
  }, [context.selectedArena.arenaId, context.selectedFacility]);
  console.log("time", startSlot);
  const changeSport = (event, { value }) => {
    const data = dropdownSportsData.find(
      (facility) => facility.value === value
    );
    dispatch({ type: ACTION_TYPE.ADD_SPORT, payload: data });
  };

  const changeSlot =
    (type) =>
    (event, { value }) => {
      const slots = type === ACTION_TYPE.ADD_START_SLOT ? startSlots : endSlots;
      const data = slots.find((s) => s.value === value);
      dispatch({ type, payload: data });
    };

  const updateSelectedCourt = (mode, checked) => {
    const courts = state.courts;
    if (mode === "all") {
      dispatch({
        type: ACTION_TYPE.ADD_COURTS,
        payload: !checked ? [] : selectedSport.inventoryCourt,
      });
    } else {
      const isAdded = courts.some((x) => x.courtId === mode.courtId);
      if (isAdded) {
        dispatch({
          type: ACTION_TYPE.ADD_COURTS,
          payload: courts.filter((x) => x.courtId !== mode.courtId),
        });
      } else {
        dispatch({
          type: ACTION_TYPE.ADD_COURTS,
          payload: [...courts, mode],
        });
      }
    }
  };

  const updateSelectedDays = (mode, checked) => {
    const daysSelected = state.days;
    if (mode === "all") {
      dispatch({
        type: ACTION_TYPE.ADD_DAYS,
        payload: !checked ? [] : daysArray,
      });
    } else {
      const isAdded = daysSelected.some((x) => x.value === mode.value);
      if (isAdded) {
        dispatch({
          type: ACTION_TYPE.ADD_DAYS,
          payload: daysSelected.filter((x) => x.value !== mode.value),
        });
      } else {
        dispatch({
          type: ACTION_TYPE.ADD_DAYS,
          payload: [...daysSelected, mode],
        });
      }
    }
  };

  const createPackage = () => {
    setLoader(true);
    let slotPeriods = [];
    slotPeriods.push(startSlot.value);
    for (let i = 0; i <= state.endSlot.key; i++) {
      if (startSlot.value !== endSlots[i].value) {
        slotPeriods.push(endSlots[i].value);
      }
    }
    const postBody = {
      days: JSON.stringify(state.days.map((x) => x.label.toLowerCase())),
      sport: sport.value,
      price: state.price,
      name: state.name,
      courtRange: JSON.stringify(courts.map((x) => x.courtId)),
      isIndividual: state.type === "individual",
      numberOfDays: state.dayCount,
      slotRange: JSON.stringify(slotPeriods),
    };

    createNewPackage(
      context.selectedFacility,
      context.selectedArena.arenaId,
      postBody
    ).then((response) => {
      history.push("/extranet/membership");
      setLoader(false);
      console.log("Booking created successfully!");
    });
  };

  const selectedSport =
    state.sport && sportDetail
      ? sportDetail.find((x) => x.sportId === state.sport.value)
      : sportDetail && sportDetail[0];
  const startSlots =
    selectedSport &&
    selectedSport.timings.map((x, index) => ({
      key: index,
      value: x.timeId,
      text: x.interval.start,
    }));
  let counter = 0;
  const endSlots =
    selectedSport &&
    selectedSport.timings
      .filter(function (x) {
        if (state.startSlot.value <= x.timeId) {
          return true;
        }
        return false;
      })
      .map(function (x) {
        return {
          key: counter++,
          value: x.timeId,
          text: x.interval.end,
        };
      });

  const courtsOption =
    selectedSport && selectedSport.inventoryCourt
      ? selectedSport.inventoryCourt
      : [];

  return (
    <>
      {loader ? <GenericLoader /> : null}
      <div style={{ padding: "16px" }}>
        <div className='dashboard'>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div>
              <span>Create New Package</span>
            </div>
          </div>
        </div>
      </div>

      <div
        style={{
          padding: "16px",
          alignItems: "center",
          display: "flex",
        }}
      >
        <div>
          <div>
            <div
              style={{
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Sport
            </div>
            <Dropdown
              onChange={changeSport}
              placeholder='Select...'
              openOnFocus
              selection
              value={state.sport && state.sport.value}
              options={dropdownSportsData}
            />
          </div>
          <div style={{ marginTop: "20px", marginBottom: "10px" }}>
            <div
              style={{
                marginBottom: "10px",
                fontWeight: "bold",
              }}
            >
              Type of package
            </div>
            <Form>
              <Form.Group inline>
                <Radio
                  label='Individual'
                  name='Individual'
                  value='this'
                  checked={state.type === "individual"}
                  onChange={() =>
                    dispatch({
                      type: ACTION_TYPE.ADD_TYPE,
                      payload: "individual",
                    })
                  }
                />

                <Radio
                  label='Group'
                  name='Group'
                  value='that'
                  checked={true}
                  style={{ marginLeft: "20px" }}
                  checked={state.type === "group"}
                  onChange={() =>
                    dispatch({
                      type: ACTION_TYPE.ADD_TYPE,
                      payload: "group",
                    })
                  }
                />
              </Form.Group>
            </Form>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "10px" }}>
            <div
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Name of the package
            </div>

            <Form>
              <Form.Group>
                <Form.Field>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                    }}
                  >
                    <input
                      style={{ marginRight: "6px" }}
                      type='text'
                      value={state.name}
                      placeholder='Weekly plan'
                      onChange={(evt) =>
                        dispatch({
                          type: ACTION_TYPE.ADD_NAME,
                          payload: evt.target.value,
                        })
                      }
                    />
                  </span>
                </Form.Field>
              </Form.Group>
            </Form>
          </div>

          <div style={{ marginTop: "20px", marginBottom: "10px" }}>
            <div
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Number of Days
            </div>

            <Form>
              <Form.Group>
                <Form.Field>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                    }}
                  >
                    <NumberInput
                      style={{ marginRight: "6px" }}
                      type='text'
                      value={state.dayCount}
                      placeholder='5'
                      onChange={(num) =>
                        dispatch({
                          type: ACTION_TYPE.ADD_NUMBER_OF_DAYS,
                          payload: +num,
                        })
                      }
                    />
                    <Button
                      style={{ marginLeft: "10px" }}
                      onClick={() =>
                        dispatch({
                          type: ACTION_TYPE.ADD_NUMBER_OF_DAYS,
                          payload: state.dayCount + 1,
                        })
                      }
                    >
                      +
                    </Button>
                    <Button
                      onClick={() =>
                        dispatch({
                          type: ACTION_TYPE.ADD_NUMBER_OF_DAYS,
                          payload: state.dayCount - 1,
                        })
                      }
                    >
                      -
                    </Button>
                  </span>
                </Form.Field>
              </Form.Group>
            </Form>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "10px" }}>
            <div
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Price
            </div>

            <Form>
              <Form.Group>
                <Form.Field>
                  <span
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      flex: 1,
                    }}
                  >
                    <NumberInput
                      style={{ marginRight: "6px" }}
                      type='text'
                      value={state.price}
                      placeholder='Rs.5000/-'
                      onChange={(num) =>
                        dispatch({
                          type: ACTION_TYPE.ADD_PRICE,
                          payload: +num,
                        })
                      }
                    />
                  </span>
                </Form.Field>
              </Form.Group>
            </Form>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div>
              <div
                style={{
                  marginBottom: "10px",
                  marginTop: "20px",
                  fontWeight: "bold",
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Courts
                <Checkbox
                  checked={
                    courts.length === selectedSport?.inventoryCourt.length
                  }
                  style={{ marginRight: "7px", marginLeft: "7px" }}
                  onClick={(e, { checked }) => {
                    updateSelectedCourt("all", checked);
                  }}
                />
                <span style={{ fontWeight: "lighter" }}> All Courts </span>
              </div>
              {courtsOption.map((x) => {
                const isActive = state.courts.some(
                  (mode) => mode.courtId === x.courtId
                );
                return (
                  <Button
                    onClick={() => updateSelectedCourt(x)}
                    style={{
                      marginRight: "10px",
                      backgroundColor: isActive
                        ? colors.orange[700]
                        : colors.grey[200],
                      color: isActive ? colors.blue[50] : colors.black,
                    }}
                  >
                    {x.courtName}
                  </Button>
                );
              })}
            </div>
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
            }}
          >
            <div>
              <div
                style={{
                  marginBottom: "10px",
                  marginTop: "20px",
                  fontWeight: "bold",
                  flex: 1,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                Days
                <Checkbox
                  checked={state.days.length === 7}
                  style={{ marginRight: "7px", marginLeft: "7px" }}
                  onClick={(e, { checked }) =>
                    updateSelectedDays("all", checked)
                  }
                />
                <span style={{ fontWeight: "lighter" }}> All Days </span>
              </div>
              {daysArray.map((x) => {
                const isActive = state.days.some(
                  (mode) => mode.value === x.value
                );
                return (
                  <Button
                    onClick={() => updateSelectedDays(x)}
                    style={{
                      marginRight: "10px",
                      backgroundColor: isActive
                        ? colors.orange[700]
                        : colors.grey[200],
                      color: isActive ? colors.blue[50] : colors.black,
                    }}
                  >
                    {x.label}
                  </Button>
                );
              })}
            </div>
          </div>
          <div style={{ marginTop: "20px", marginBottom: "20px" }}>
            <div
              style={{
                marginBottom: "10px",
                marginTop: "10px",
                fontWeight: "bold",
              }}
            >
              Slot timings
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Dropdown
                onChange={changeSlot(ACTION_TYPE.ADD_START_SLOT)}
                placeholder='Select...'
                openOnFocus
                selection
                value={state.startSlot && state.startSlot.value}
                options={startSlots}
                style={{ marginRight: "30px" }}
              />
              <span style={{ marginRight: "30px" }}>to</span>
              <Dropdown
                onChange={changeSlot(ACTION_TYPE.ADD_END_SLOT)}
                placeholder='Select...'
                openOnFocus
                selection
                value={state.endSlot && state.endSlot.value}
                options={endSlots}
              />
            </div>
            {startSlot.key > endSlot.key ? (
              <div style={{ color: colors.red["500"] }}>
                *Slot start time cannot be greater or equal to end slot time
              </div>
            ) : (
              ""
            )}
          </div>

          <Button
            primary
            disabled={!isMandatoryFieldsSelected}
            onClick={createPackage}
          >
            Submit
          </Button>
        </div>
      </div>
    </>
  );
};

export default AddPackage;
