import React, { useContext, useRef, useEffect, useState } from 'react';
import { AppContext } from '../../AppContext';
import { getLeadAnalyticsData } from '../../apiclients/AnalyticsClient';
import GenericLoader from '../generic/GenericLoader';
import { CardHeader, Dropdown } from 'semantic-ui-react';
import { Card, Grid } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Line, Pie } from 'react-chartjs-2';
import moment from 'moment';

const LeadAnalytics = (props) => {

    const { history } = props;
    const {
        selectedFacility,
        selectedArena,
    } = useContext(AppContext);


    const [isLoading, setisLoading] = useState(true);
    const [analyticsData, setAnalyticsData] = useState();
    const [weeklyAnalytics, setWeeklyAnalytics] = useState();
    const [graphData, setGraphData] = useState();
    const [sportGraphData, setsportGraphData] = useState();
    const [callsGraphData, setCallsGraphData] = useState();
    const [interestsGraphData, setInterestsGraphData] = useState();
    const [sourceGraphData, setSourceGraphData] = useState();
    const colorOne = 'rgba(26,60,54)';
    const colorTwo = 'rgba(86,168,226)';
    const colorThree = 'rgba(138,200,183)';
    const colorFour = 'rgba(208,231,214)';
    const colorFive = 'rgba(56,121,109)';
    const allColors = [colorOne, colorTwo, colorThree, colorFour, colorFive];
    const useStyles = makeStyles({
        root: {
            minWidth: 275,
        },
        bullet: {
            display: 'inline-block',
            margin: '0 2px',
            transform: 'scale(0.8)',
        },
        title: {
            fontSize: 14,
        },
        pos: {
            marginBottom: 12,
        },
    });


    const classes = useStyles();

    const loadData = () => {
        setisLoading(true);
        return getLeadAnalyticsData(
            selectedFacility, selectedArena.arenaId,
        ).then((response) => {
            const analyticData = response.data.weeklyData;
            let graphsData = createLeadLineItemData(analyticData);
            let sportGraphsData = createSportLeadLineItemData(analyticData);
            let callGraphsData = createCallLeadLineItemData(analyticData);
            let interestGraphsData = createInterestLeadLineItemData(analyticData);
            let sourceGraphsData = createSourceLeadLineItemData(analyticData);
            setGraphData(graphsData);
            setsportGraphData(sportGraphsData);
            setCallsGraphData(callGraphsData);
            setInterestsGraphData(interestGraphsData);
            setSourceGraphData(sourceGraphsData);
            setAnalyticsData(analyticData);
            setWeeklyAnalytics(analyticData[analyticData.length - 1])
            setisLoading(false);
        });

    };

    useEffect(() => {
        loadData();
    }, [selectedFacility, selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps


    const createLeadLineItemData = (data) => {
        let labels = [];
        let totalLeadDataSet = {
            label: 'Total Leads',
            data: [],
            borderColor: [colorOne],
            backgroundColor: [colorOne],
            tension: 0.3,
            fill: false,
        };
        let totalTrialDataSet = {
            label: 'Total Trials',
            data: [],
            borderColor: [colorTwo],
            backgroundColor: [colorTwo],
            tension: 0.3,
            fill: false,
        };
        let totalEnrolledDataSet = {
            label: 'Total Enrolled',
            data: [],
            borderColor: [colorThree],
            backgroundColor: [colorThree],
            tension: 0.3,
            fill: false,
        };
        let noFacilityDataSet = {
            label: 'No Facility Leads',
            data: [],
            borderColor: [colorFour],
            backgroundColor: [colorFour],
            tension: 0.3,
            fill: false,
        };
        if (data) {
            data.forEach(x => {
                x.endDate ? labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY')) : labels.push(moment(x.createdAt.seconds * 1000).format('DD-MMM-YY'));
                totalLeadDataSet.data.push((x.totalNumberOfLeads));
                totalTrialDataSet.data.push((x.academy.academy_trialScheduled));
                totalEnrolledDataSet.data.push((x.academy.academy_enrolled));
                noFacilityDataSet.data.push((x.totalUnallocatedLeads));
            });
        }
        let lineData = {
            labels: labels,
            datasets: [
                totalLeadDataSet, totalTrialDataSet,totalEnrolledDataSet, noFacilityDataSet,
            ]
        };
        return lineData;
    }
    const createSportLeadLineItemData = (data) => {
        let labels = [];
        let badmintonDataSet = {
            label: 'Badminton',
            data: [],
            borderColor: [colorTwo],
            backgroundColor: [colorTwo],
            tension: 0.3,
            fill: false,
        };
        let futsalDataSet = {
            label: 'Futsal Leads',
            data: [],
            borderColor: [colorThree],
            backgroundColor: [colorThree],
            tension: 0.3,
            fill: false,
        };
        let boxCricketDataSet = {
            label: 'Box-Cricket Leads',
            data: [],
            borderColor: [colorFour],
            backgroundColor: [colorFour],
            tension: 0.3,
            fill: false,
        };
        let otherSportDataSet = {
            label: 'Other Sports Leads',
            data: [],
            borderColor: [colorFive],
            backgroundColor: [colorFive],
            tension: 0.3,
            fill: false,
        };

        if (data) {
            data.forEach(x => {
                x.endDate ? labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY')) : labels.push(moment(x.createdAt.seconds * 1000).format('DD-MMM-YY'));
                badmintonDataSet.data.push((x.sports.badminton_leads));
                futsalDataSet.data.push((x.sports.futsal_leads));
                boxCricketDataSet.data.push((x.sports.box_cricket_leads));
                otherSportDataSet.data.push((x.sports.other_sports));
            });
        }
        let lineData = {
            labels: labels,
            datasets: [
                badmintonDataSet, futsalDataSet, boxCricketDataSet, otherSportDataSet
            ]
        };
        return lineData;
    }

    const createCallLeadLineItemData = (data) => {
        let labels = [];
        let completedDataSet = {
            label: 'Completed',
            data: [],
            borderColor: [colorOne],
            backgroundColor: [colorOne],
            tension: 0.3,
            fill: false,
        };
        let chDataSet = {
            label: 'Client Hangup',
            data: [],
            borderColor: [colorTwo],
            backgroundColor: [colorTwo],
            tension: 0.3,
            fill: false,
        };
        let caDataSet = {
            label: 'Call Attempt',
            data: [],
            borderColor: [colorThree],
            backgroundColor: [colorThree],
            tension: 0.3,
            fill: false,
        };
        let incompleteDataSet = {
            label: 'Incomplete',
            data: [],
            borderColor: [colorFour],
            backgroundColor: [colorFour],
            tension: 0.3,
            fill: false,
        };
        let voicemailDataSet = {
            label: 'Voicemail',
            data: [],
            borderColor: [colorFive],
            backgroundColor: [colorFive],
            tension: 0.3,
            fill: false,
        };

        if (data) {
            data.forEach(x => {
                x.endDate ? labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY')) : labels.push(moment(x.createdAt.seconds * 1000).format('DD-MMM-YY'));
                completedDataSet.data.push((x.callStatus.completed));
                chDataSet.data.push((x.callStatus.client_hangup));
                caDataSet.data.push((x.callStatus.call_attempt));
                incompleteDataSet.data.push((x.callStatus.incomplete));
                voicemailDataSet.data.push((x.callStatus.voicemail));
            });
        }
        let lineData = {
            labels: labels,
            datasets: [
                completedDataSet, chDataSet, caDataSet, incompleteDataSet, voicemailDataSet
            ]
        };
        return lineData;
    }
    const createInterestLeadLineItemData = (data) => {
        let labels = [];
        let academyDataSet = {
            label: 'Academy',
            data: [],
            borderColor: [colorOne],
            backgroundColor: [colorOne],
            tension: 0.3,
            fill: false,
        };
        let pnpDataSet = {
            label: 'Pay & Play',
            data: [],
            borderColor: [colorTwo],
            backgroundColor: [colorTwo],
            tension: 0.3,
            fill: false,
        };
        let ctDataSet = {
            label: 'Corporate Tournament',
            data: [],
            borderColor: [colorThree],
            backgroundColor: [colorThree],
            tension: 0.3,
            fill: false,
        };
        let tournamentDataSet = {
            label: 'Tournament',
            data: [],
            borderColor: [colorFour],
            backgroundColor: [colorFour],
            tension: 0.3,
            fill: false,
        };
        let othersDataSet = {
            label: 'Others',
            data: [],
            borderColor: [colorFive],
            backgroundColor: [colorFive],
            tension: 0.3,
            fill: false,
        };

        if (data) {
            data.forEach(x => {
                x.endDate ? labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY')) : labels.push(moment(x.createdAt.seconds * 1000).format('DD-MMM-YY'));
                academyDataSet.data.push((x.interestArea.interestArea_academy));
                pnpDataSet.data.push((x.interestArea.interestArea_pay_and_play));
                ctDataSet.data.push((x.interestArea.interestArea_corporate_event));
                tournamentDataSet.data.push((x.interestArea.interestArea_tournaments));
                othersDataSet.data.push((x.interestArea.interestArea_others));
            });
        }
        let lineData = {
            labels: labels,
            datasets: [
                academyDataSet, pnpDataSet, ctDataSet, tournamentDataSet, othersDataSet
            ]
        };
        return lineData;
    }
    const createSourceLeadLineItemData = (data) => {
        let labels = [];
        let gmDataSet = {
            label: 'Google Maps',
            data: [],
            borderColor: [colorOne],
            backgroundColor: [colorOne],
            tension: 0.3,
            fill: false,
        };
        let websiteDataSet = {
            label: 'Website',
            data: [],
            borderColor: [colorTwo],
            backgroundColor: [colorTwo],
            tension: 0.3,
            fill: false,
        };
        let wrDataSet = {
            label: 'Walk-in + Referral',
            data: [],
            borderColor: [colorThree],
            backgroundColor: [colorThree],
            tension: 0.3,
            fill: false,
        };
        let phgDataSet = {
            label: 'Pamphelete + Hoardings + Google Ads',
            data: [],
            borderColor: [colorFour],
            backgroundColor: [colorFour],
            tension: 0.3,
            fill: false,
        };
        let othersDataSet = {
            label: 'Others',
            data: [],
            borderColor: [colorFive],
            backgroundColor: [colorFive],
            tension: 0.3,
            fill: false,
        };

        if (data) {
            data.forEach(x => {
                x.endDate ? labels.push(moment(x.endDate.seconds * 1000).format('DD-MMM-YY')) : labels.push(moment(x.createdAt.seconds * 1000).format('DD-MMM-YY'));
                gmDataSet.data.push((x.source.source_google_maps));
                websiteDataSet.data.push((x.source.source_website));
                wrDataSet.data.push((x.source.source_walk_in + x.source.source_referral));
                phgDataSet.data.push((x.source.source_pamphelt + x.source.source_hoardings + x.source.source_google_ads));
                othersDataSet.data.push((x.source.source_others));
            });
        }
        let lineData = {
            labels: labels,
            datasets: [
                gmDataSet, websiteDataSet, wrDataSet, phgDataSet, othersDataSet
            ]
        };
        return lineData;
    }
    return isLoading ? <GenericLoader /> :
        (<div style={{ padding: "16px" }}>
            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>
                            Lead Analytics </span>
                    </div>
                </div>
            </div>
            <br />
            <div>
                <h3>Last Week Data</h3>
                <Grid container spacing={9}>
                    <Grid item md={2} spacing={2}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Total Leads - </span> {weeklyAnalytics.totalNumberOfLeads}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={2} spacing={2}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Total Corporate Leads - </span> {weeklyAnalytics.interestArea.interestArea_corporate_event}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Total Quoation Sent - </span> {weeklyAnalytics.corporateEvent.corp_event_quoationSent}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Total Others - </span> {weeklyAnalytics.corporateEvent.corp_event_quoationSent}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    
                    <Grid item md={2} spacing={2}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>No Facility Leads - </span> {weeklyAnalytics.totalUnallocatedLeads}
                                </Typography>

                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Other Interest Leads - </span> {weeklyAnalytics.interestArea.interestArea_others}
                                </Typography>

                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Other Sports Leads - </span> {weeklyAnalytics.sports.other_sports}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={2} spacing={2}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Total Academy Leads - </span> {weeklyAnalytics.interestArea.interestArea_academy}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Total Trial Scheduled - </span> {weeklyAnalytics.academy.academy_trialScheduled}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Total Enrolled - </span> {weeklyAnalytics.academy.academy_enrolled}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>No Show - </span> {weeklyAnalytics.academy.academy_no_show}
                                </Typography>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Lost Lead - </span> {weeklyAnalytics.academy.academy_lost_lead}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                <Grid container spacing={9}>
                    <Grid item md={3} spacing={2}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Sports Wise </span>
                                </Typography>
                                <Pie
                                    width={30}
                                    height={8}
                                    data={{
                                        labels: [
                                            'Badminton',
                                            'Futsal',
                                            'Box-Cricket',
                                            'Others'
                                        ],
                                        datasets: [{
                                            label: 'My First Dataset',
                                            data: [
                                                weeklyAnalytics.sports.badminton_leads,
                                                weeklyAnalytics.sports.futsal_leads,
                                                weeklyAnalytics.sports.box_cricket_leads,
                                                weeklyAnalytics.sports.other_sports,
                                            ],
                                            backgroundColor: [
                                                colorOne,
                                                colorTwo,
                                                colorThree,
                                                colorFour
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item md={3} spacing={2}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Call Type</span>
                                </Typography>
                                <Pie
                                    width={30}
                                    height={8}
                                    data={{
                                        labels: [
                                            'Completed',
                                            'Client Hangup',
                                            'Call Attempt',
                                            'Incomplete',
                                            'Voicemail'
                                        ],
                                        datasets: [{
                                            label: 'My First Dataset',
                                            data: [
                                                weeklyAnalytics.callStatus.completed,
                                                weeklyAnalytics.callStatus.client_hangup,
                                                weeklyAnalytics.callStatus.call_attempt,
                                                weeklyAnalytics.callStatus.incomplete,
                                                weeklyAnalytics.callStatus.voicemail,
                                            ],
                                            backgroundColor: [
                                                colorOne,
                                                colorTwo,
                                                colorThree,
                                                colorFour,
                                                colorFive
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3} spacing={2}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Interest Area </span>
                                </Typography>
                                <Pie
                                    width={30}
                                    height={8}
                                    data={{
                                        labels: [
                                            'Academy',
                                            'Pay & Play',
                                            'Corporate Event',
                                            'Tournament',
                                            'Others'
                                        ],
                                        datasets: [{
                                            label: 'My First Dataset',
                                            data: [
                                                weeklyAnalytics.interestArea.interestArea_academy,
                                                weeklyAnalytics.interestArea.interestArea_pay_and_play,
                                                weeklyAnalytics.interestArea.interestArea_corporate_event,
                                                weeklyAnalytics.interestArea.interestArea_tournaments,
                                                weeklyAnalytics.interestArea.interestArea_others,
                                            ],
                                            backgroundColor: [
                                                colorOne,
                                                colorTwo,
                                                colorThree,
                                                colorFour,
                                                colorFive
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3} spacing={2}>
                        <Card className={classes.root}>
                            <CardContent>
                                <Typography variant="h5" component="h2">
                                    <span style={{ color: "grey" }}>Source </span>
                                </Typography>
                                <Pie
                                    width={30}
                                    height={8}
                                    data={{
                                        labels: [
                                            'Google Maps',
                                            'Website',
                                            'Walk-in + Referral',
                                            'Pamphelete + Hoardings + Google Ads',
                                            'Others'
                                        ],
                                        datasets: [{
                                            label: 'My First Dataset',
                                            data: [
                                                weeklyAnalytics.source.source_google_maps,
                                                weeklyAnalytics.source.source_website,
                                                (weeklyAnalytics.source.source_walk_in + weeklyAnalytics.source.source_referral),
                                                (weeklyAnalytics.source.source_pamphelt + weeklyAnalytics.source.source_hoardings + weeklyAnalytics.source.source_google_ads),
                                                weeklyAnalytics.source.source_others,
                                            ],
                                            backgroundColor: [
                                                colorOne,
                                                colorTwo,
                                                colorThree,
                                                colorFour,
                                                colorFive
                                            ],
                                            hoverOffset: 4
                                        }]
                                    }}
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <h3>Historic Lead Data (updates every Monday)</h3>
                <h6> Total Leads</h6>
                <Line
                    data={graphData}
                    width={100}
                    height={20}
                    pointRadius={1}
                    showLine={false}

                    options={{
                        // maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    show: true
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    show: false
                                }
                            }]
                        }
                    }}
                />
                <br />
                <h6> Sport Wise Leads</h6>
                <Line
                    data={sportGraphData}
                    width={100}
                    height={20}
                    pointRadius={1}
                    showLine={false}

                    options={{
                        // maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    show: true
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    show: false
                                }
                            }]
                        }
                    }}
                />
                <br />
                <h6> Interest Area Wise Leads</h6>
                <Line
                    data={interestsGraphData}
                    width={100}
                    height={20}
                    pointRadius={1}
                    showLine={false}

                    options={{
                        // maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    show: true
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    show: false
                                }
                            }]
                        }
                    }}
                />
                <br />
                <h6> Source Wise Leads</h6>
                <Line
                    data={sourceGraphData}
                    width={100}
                    height={20}
                    pointRadius={1}
                    showLine={false}

                    options={{
                        // maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    show: true
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    show: false
                                }
                            }]
                        }
                    }}
                />
                <br />
                <h6> Call Type Wise Leads</h6>
                <Line
                    data={callsGraphData}
                    width={100}
                    height={20}
                    pointRadius={1}
                    showLine={false}

                    options={{
                        // maintainAspectRatio: false,
                        scales: {
                            xAxes: [{
                                gridLines: {
                                    show: true
                                }
                            }],
                            yAxes: [{
                                gridLines: {
                                    show: false
                                }
                            }]
                        }
                    }}
                />
            </div>
        </div>
        );
}

export default LeadAnalytics;