import React, { useState, useRef, useContext, useEffect } from 'react';
import GenericLoader from '../../generic/GenericLoader';
import { Dropdown, Table, Tab, Checkbox } from 'semantic-ui-react';
import { DatePicker } from '@material-ui/pickers';
import { AppContext } from '../../../AppContext';
import { getAttendanceData, getAllAcademySportsOfArena, markAttendance } from '../../../apiclients/AcademyClient';
import { colors } from '@material-ui/core';
import moment from 'moment';

import { ROLES } from "../../../utils/constants";

const AttendanceDataTable = (props) => {
    const listOfStudents = props.listOfStudents;

    const markTheAttendnace = (checked, studentData, studentNumber) => {
        console.log('thechecked is ',checked);
        var postData = {
            facilityId : studentData.facilityId,
            arenaId : studentData.arenaId, 
            sportId : studentData.sportId, 
            selectedDate : moment(props.selectedDate).format('DD-MM-YYYY'), 
            active : props.active, 
            studentNumber, 
            attendance : checked,
            createdBy : {
                userName: props.user.userName,
                userId: props.user.userId,
            },
        };
        if(props.isAttendanceAllowed){
            props.setIsLoading(true);
            markAttendance(postData).then((response)=>{
                props.loadData();
                props.setIsLoading(false);
            });
        } 
    };
    return (
        <>
            <Table celled padded>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>S.No</Table.HeaderCell>
                        <Table.HeaderCell>Student Name</Table.HeaderCell>
                        <Table.HeaderCell>Batch Name</Table.HeaderCell>
                        <Table.HeaderCell>Parent Name</Table.HeaderCell>
                        <Table.HeaderCell>Mobile</Table.HeaderCell>
                        <Table.HeaderCell>End Date</Table.HeaderCell>
                        <Table.HeaderCell>Days Remainging</Table.HeaderCell>
                        <Table.HeaderCell>Classes Remaining</Table.HeaderCell>
                        <Table.HeaderCell>Amount Due</Table.HeaderCell>
                        <Table.HeaderCell>Attendance</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {listOfStudents !== undefined ? listOfStudents.map((y, i) => {
                        var x = y.data;
                        return (
                            <Table.Row key={i}>
                                <Table.Cell>{i + 1}</Table.Cell>
                                <Table.Cell>{x.studentName}</Table.Cell>
                                <Table.Cell>{x.batchName}</Table.Cell>
                                <Table.Cell>{x.parentName}</Table.Cell>
                                <Table.Cell>{x.mobile}</Table.Cell>
                                <Table.Cell>{new Date(x.endDate.seconds * 1000).toLocaleDateString()}</Table.Cell>
                                <Table.Cell>{
                                    x.daysRemaining < 0 ? <span style={{ color: 'red' }}>{x.daysRemaining}</span> : x.daysRemaining
                                }</Table.Cell>
                                <Table.Cell>{
                                    x.availableNoOfClasses < 0 ? <span style={{ color: 'red' }}>{x.availableNoOfClasses}</span> : x.availableNoOfClasses
                                }</Table.Cell>
                                <Table.Cell>
                                    {x.amountDue > 0 ?
                                        <div><span style={{ color: colors.red[500] }}> Rs. {parseFloat(x.amountDue).toFixed(2)}/-</span></div>
                                        :
                                        <div> Rs. {parseFloat(x.amountDue).toFixed(2)}/-</div>}

                                </Table.Cell>

                                <Table.Cell >
                                    <Checkbox 
                                        checked={
                                            y.attendance
                                        }
                                        style={{ marginRight: "7px", marginLeft: "7px" }}
                                        onClick={(e, { checked }) => {
                                            markTheAttendnace(checked, x, i);
                                        }}
                                        toggle
                                        disabled={!props.isAttendanceAllowed}
                                    />
                                </Table.Cell>
                            </Table.Row>
                        );
                    }) : null}
                </Table.Body>
            </Table>



        </>
    );
};

const AttendanceManagement = (props) => {

    const { history } = props;
    const context = useContext(AppContext);
    const { selectedFacility, selectedArena, user } = context;
    const sports = useRef([]);
    const [activeIndex, setActiveIndex] = useState(0);
    const [selectedSport, setSelectedSport] = useState({});
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [isLoading, setIsLoading] = useState(false);
    const [attendanceData, setAttendanceData] = useState({ 'activeStudentArray': [], 'inactiveStudentArray': [] });
    const [allAcademySports, setAllAcademySports] = useState([]);

    const types = ['activeStudentArray', 'inactiveStudentArray'];
    const isAttendanceAllowed = moment(selectedDate).format('DD-MM-YYYY') === moment().format('DD-MM-YYYY') || user.role === ROLES.ADMIN 
     ;

    useEffect(() => {
        if (selectedFacility && selectedArena) {
            getAllAcademySportsOfArena(selectedFacility, selectedArena.arenaId).then(
                (response) => {
                    if (response.status === 200) {
                        const sport = response.data;
                        const dropdownSportsData = sport.map(
                            (x, index) => ({
                                key: x.sportId,
                                value: x.sportId,
                                text: x.sportName,
                            })
                        );
                        setAllAcademySports(dropdownSportsData);
                        sports.current = dropdownSportsData;
                        setSelectedSport(dropdownSportsData[0]);
                        loadData(dropdownSportsData[0]);
                    } else {
                        history.push("/extranet/dashboard");
                    }

                }
            ).catch((err) => {
                history.push("/extranet/dashboard");
            });

        }
    }, [selectedFacility, selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps


    const loadData = async (sportData) => {
        setIsLoading(true);
         return await getAttendanceData(
            selectedFacility,
            selectedArena.arenaId,
            sportData.value,
            moment(selectedDate).format('DD-MM-YYYY'),
        ).then((response) => {
            const data = response.data;
            console.log('the data is ', data.data);
            setAttendanceData(data.data);
            setIsLoading(false);
        });
    };


    const dateChange = (date) => {
        setSelectedDate(date);
        loadData(selectedSport);
        
    }

    const changeSport = (event, { value }) => {
        const data = sports.current.find((facility) => facility.value === value);
        setSelectedSport(data);
        loadData(data);
    };

    const panes = [
        {
            menuItem: "Active Students",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <AttendanceDataTable
                        listOfStudents={attendanceData[types[activeIndex]]}
                        selectedDate={selectedDate}
                        active={types[activeIndex]}
                        loadData = {()=>loadData(selectedSport)}
                        setIsLoading={setIsLoading}
                        isAttendanceAllowed={isAttendanceAllowed}
                        user={user}
                    />
                </Tab.Pane>
            ),
        },
        {
            menuItem: "Inactive Students(Last 30 Days) ",
            render: () => (
                <Tab.Pane className={"tabBox"}>
                    <AttendanceDataTable
                        listOfStudents={attendanceData[types[activeIndex]]}
                        selectedDate={selectedDate}
                        active={types[activeIndex]}
                        loadData = {()=>loadData(selectedSport)}
                        setIsLoading={setIsLoading}
                        isAttendanceAllowed={isAttendanceAllowed}
                        user={user}
                    />
                </Tab.Pane>
            ),
        },

    ];

    return isLoading ? (
        <GenericLoader />
    ) : (
        <div style={{ padding: "16px" }}>

            <div className='dashboard'>
                <div
                    style={{
                        flex: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                    }}
                >
                    <div>
                        <span>Student Attendance</span>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>
                            <Dropdown
                                onChange={changeSport}
                                placeholder='Select...'
                                openOnFocus
                                selection
                                value={selectedSport && selectedSport.value}
                                options={sports.current}
                            />


                        </div>
                    </div>
                    <div style={{ alignItems: "center" }}>
                        <div style={{ fontSize: "14px" }}>

                            <DatePicker
                                autoOk
                                disableFuture
                                variant='inline'
                                value={selectedDate}
                                placeholder='Select Date...'
                                onChange={dateChange}
                                format='DD MMM yyyy'
                            />

                        </div>
                    </div>
                </div>
            </div>

            <div style={{ paddingTop: "16px" }}>
                <Tab
                    activeIndex={activeIndex}
                    onTabChange={(e, { activeIndex }) => setActiveIndex(activeIndex)}
                    renderActiveOnly={true}
                    menu={{
                        color: "orange",
                        pointing: true,
                    }}
                    panes={panes}
                />
            </div>
            <br/>
                                How to Mark Attendance ?
                                <div className="player">
                                    <iframe width="300" height="150" src="https://www.youtube.com/embed/XK6r9i5orBM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allow="fullscreen;"></iframe>
                                    </div>
                                    

        </div>
    );
};

export default AttendanceManagement;