import React from "react";

import { Table } from "semantic-ui-react";
import "../dashboard/style.scss";


const WalletHistory = (props) => {
    const {transactions, mobile} = props.location.state;

  

    return (
        <div style={{ padding: "16px" }}>
          <div className='dashboard'>
            <div
              style={{
                flex: 1,
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-end",
              }}
            >
              <div>
                <span>Wallet Transaction Details for {mobile}</span>
              </div>
              </div>
          </div>
          
            <div style={{ paddingTop: "16px" }}>
                <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>S.No</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Type</Table.HeaderCell>
            <Table.HeaderCell>Credits</Table.HeaderCell>
            <Table.HeaderCell>Outstanding Credits</Table.HeaderCell>
            <Table.HeaderCell>Remarks</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transactions.map((x, index) => {

            return (
              <Table.Row key={index+1}>
                <Table.Cell>{index+1}</Table.Cell>
                <Table.Cell>{x.createdAt}  </Table.Cell>
                <Table.Cell>{x.type}</Table.Cell>
                <Table.Cell>Rs. {x.credits}/- worth </Table.Cell>
                <Table.Cell>Rs. {x.outstandingCredits}/- worth </Table.Cell>
                <Table.Cell>{x.remarks}  </Table.Cell>
                 </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    
            </div>
          
        </div>
      );
}


export default WalletHistory;