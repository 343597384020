import React, { useEffect, useState } from "react";
import { Checkbox, Dropdown, Form, Segment } from "semantic-ui-react";
import NumberInput from "../../lib/NumberInput";
import { getProducts } from "../../apiclients/DashboardApiClient";

const ProductSelection = ({
  facility,
  selectedItems,
  setSelectedItems,
  requestFromBookingModal,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (data.length === 0) {
      getProducts(facility.id, facility.arenaId).then((response) => {
        const dataWithValue = response.data.products.map((x) => ({
          ...x,
          text: x.productName,
          value: x.productName,
          key: x.productId,
          isRenting: !!x.rent,
        }));
        setData(dataWithValue);
      });
    }
    console.log('the data is ', data);
  }, []);// eslint-disable-line react-hooks/exhaustive-deps
  const changeItem = (event, { value }) => {
    const selectedData = value.map((x) => {
      const existing = selectedItems.find((item) => item.value === x);
      if (existing) {
        return existing;
      }
      return data.find((datum) => datum.value === x);
    });

    setSelectedItems(selectedData);
  };

  const updateProductArray = (type, id) => (value) => {
    const updatedItems = selectedItems.map((x) => {
      if (x.key === id) {
        return { ...x, [type]: type === "isRenting" ? !x.isRenting : value };
      }
      return x;
    });

    setSelectedItems(updatedItems);
  };

  const backUpText = requestFromBookingModal ? null : (
    <div>
      No Products added yet. Please add product from Rates and Inventory
      section.
    </div>
  );
  return data.length > 0 ? (
    <Form style={{ marginBottom: "12px" }}>
      <Form.Field>
        <label>Product Sale/Rental</label>
        <Dropdown
          onChange={changeItem}
          placeholder='Select...'
          openOnFocus
          fluid
          multiple
          selection
          options={data}
        />
      </Form.Field>
      {selectedItems &&
        selectedItems.map((x) => {
          return (
            <Segment key={x.productId}>
              {x.rent && x.price ? (
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>Rent?</div>
                  <Checkbox
                    checked={x.isRenting}
                    style={{ marginLeft: "10px" }}
                    toggle
                    onClick={updateProductArray("isRenting", x.productId)}
                  />
                </div>
              ) : null}
              <div>Product Name: {x.value}</div>
              <div>
                {x.isRenting ? "Rental Price" : "Selling Price"}: Rs.
                {x.isRenting ? x.rent : x.price}/-
              </div>

              <Form.Group wproductIdths='equal'>
                <Form.Field>
                  <label>Quantity</label>
                  <NumberInput
                    placeholder='Please enter a number'
                    onChange={updateProductArray("quantityAdded", x.productId)}
                  />
                </Form.Field>
                <Form.Field>
                  <label>Discount</label>
                  <NumberInput
                    placeholder='Discount'
                    onChange={updateProductArray("discount", x.productId)}
                  />
                </Form.Field>
              </Form.Group>
            </Segment>
          );
        })}
    </Form>
  ) : (
    backUpText
  );
};

export default ProductSelection;
