import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";


export const getAllAcademySportsOfArena = (facilityId, arenaId) => {
    const url = getURLWithQueryParams(API_URLS.FACILITY_ARENA_ACADEMY_SPORTS, null, {
      facilityId,
      arenaId,
    });
  
    return tokenAxiosInstance.get(url);
  };


  export const getAllStudentData = (facilityId, arenaId, sportId) => {
    const url = getURLWithQueryParams(API_URLS.FACILITY_ARENA_ACADEMY_STUDENTS, null, {
      facilityId,
      arenaId,
      sportId
    });
  
    return tokenAxiosInstance.get(url);
  };


  export const getStudentDetails = (facilityId, arenaId, sportId, studentId) => {
    const url = getURLWithQueryParams(API_URLS.FACILITY_ARENA_ACADEMY_STUDENT_DETAIL, null, {
      facilityId,
      arenaId,
      sportId,
      studentId
    });
  
    return tokenAxiosInstance.get(url);
  };

///// batches api
export const getBatches = (facilityId, arenaId, sportId) => {
    const url = getURLWithQueryParams(
        API_URLS.GET_BATCHES_DATA, undefined, {
            facilityId, arenaId, sportId 
        }
    );
    return tokenAxiosInstance.get(url);
}

export const addBatch = (postBody) => {
    const url = getURLWithQueryParams(API_URLS.ADD_BATCH);
      return tokenAxiosInstance.post(url, postBody);
}

export const editBatch = (postBody) => {
    const url = getURLWithQueryParams(API_URLS.EDIT_BATCH,);
      return tokenAxiosInstance.patch(url, postBody);
}

export const deleteBatch = (postBody) => {
    const url = getURLWithQueryParams(API_URLS.DELETE_BATCH);
      return tokenAxiosInstance.delete(url, {data : postBody});
}

///// student API
export const getStudentData = (facilityId, arenaId, sportId, type) => {
  const url = getURLWithQueryParams(
      API_URLS.GET_STUDENTS_DATA, undefined, {
          facilityId, arenaId, sportId , type
      }
  );
  return tokenAxiosInstance.get(url);
}

export const enrollStudent = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.ENROLL_STUDENT);
  return tokenAxiosInstance.post(url, postBody);
}

export const deleteStudent = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.DELETE_STUDENT);
  return tokenAxiosInstance.delete(url, {data : postBody});
}


export const getDataForExistingParent = (facilityId, arenaId, sportId, mobile) => {
  const url = getURLWithQueryParams(
      API_URLS.GET_DATA_FOR_EXISTING_PARENT, undefined, {
          facilityId, arenaId, sportId , mobile
      }
  );
  return tokenAxiosInstance.get(url);
}

export const addPayments = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.ADD_PAYMENTS);
  return tokenAxiosInstance.patch(url, postBody);
}

/// attendance APIs
export const getAttendanceData = (facilityId, arenaId, sportId, selectedDate) => {
  const url = getURLWithQueryParams(
      API_URLS.GET_ATTENDANCE_DATA, {selectedDate}, {
          facilityId, arenaId, sportId 
      }
  );
  return tokenAxiosInstance.get(url);
}

export const markAttendance = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.MARK_ATTENDANCE);
  return tokenAxiosInstance.patch(url, postBody);
}

//// trial management
export const addTrial = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.ADD_TRIAL);
  return tokenAxiosInstance.post(url, postBody);
}

export const editTrial = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.EDIT_TRIAL,);
    return tokenAxiosInstance.patch(url, postBody);
}

export const noShowTrial = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.NO_SHOW_TRIAL,);
    return tokenAxiosInstance.patch(url, postBody);
}

export const lostLeadTrial = (postBody) => {
  const url = getURLWithQueryParams(API_URLS.LOST_LEAD_TRIAL,);
    return tokenAxiosInstance.patch(url, postBody);
}



export const getTrialData = (facilityId, arenaId, sportId, type) => {
  const url = getURLWithQueryParams(
      API_URLS.GET_TRIALS_DATA, undefined, {
          facilityId, arenaId, sportId , type
      }
  );
  return tokenAxiosInstance.get(url);
}