import React, { useState, useEffect, useContext } from "react";
import moment from "moment";

import {
  Button,
  Modal,
  Loader,
  Form,
  Dropdown,
  Divider,
} from "semantic-ui-react";
import { AppContext } from "../../../AppContext";
import {
  getRescheduleAvailableSlots,
  rescheduleBooking,
} from "../../../apiclients/BookingApiClient";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { colors } from "@material-ui/core";
import Payment from "../Payment";
import { getAllPolicy } from "../../../apiclients/BookingApiClient";
import { getKeyValue } from "../../../utils/helperFunctions";
import { ROLES } from "../../../utils/constants";

const RescheduleBooking = ({
  booking,
  facility,
  setOpen,
  setType,
  reload,
  setMessage,
}) => {
  const context = useContext(AppContext);
  const user = context.user;
  const [showLoader, setShowLoader] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment());
  const [slots, setSlots] = useState();
  const [selectedTime, setSelectedTime] = useState();
  const [selectedCourt, setSelectedCourt] = useState();
  const [paymentArray, setPaymentArray] = useState([]);
  const [policyRule, setPolicyRule] = useState({});
  const [rescheduleButtonDisabled, setRescheduleButtonDisabled] = useState(false);
  let isRescheduleAllowed = booking.rescheduleAllowed === 'true' ? true : false;
  if (
    moment.duration(moment().diff(moment(booking.createdDate))).asMinutes() < 15 // if the created time and reschedule request time is  within 15 minutes allow reschedule
  ) {
    isRescheduleAllowed = true;
  }
  useEffect(() => {
    setShowLoader(true);
    const timings = getKeyValue(context, "selectedSportDetails.detail.timings");
    const currentSlotTime = timings
      ? timings.find((t) => t.timeId === booking.slotTiming)
      : undefined;
    getAllPolicy(
      context.selectedFacility,
      context.selectedArena.arenaId,
      context.selectedSportDetails.sportId,
      "reschedule"
    ).then((response) => {
      const data = response.data;
      const today = moment();
      const ms = moment(
        moment(booking.bookingDate, "MM-DD-YYYY").format("DD-MM-YYYY") +
        " " +
        currentSlotTime.interval.end.toString() +
        ":00",
        "DD/MM/YYYY HH:mm:ss"
      ).diff(moment(today, "DD/MM/YYYY HH:mm:ss"));
      const d = moment.duration(ms);
      if (
        (data.isAllowed && data.hourRemaining < Math.floor(d.asHours())) ||
        user.role === ROLES.ADMIN || isRescheduleAllowed || user.role === ROLES.REGIONAL_MANAGER
      ) {
        setPolicyRule({ isAllowed: true, data });
        return;
      }
      setPolicyRule({ isAllowed: false });
    });
    getRescheduleAvailableSlots(
      context.selectedFacility,
      context.selectedArena.arenaId,
      context.selectedSportDetails.sportId,
      selectedDate.format("DD-MM-YYYY")
    ).then((response) => {
      setShowLoader(false);
      const data = response.data;
      if (Object.keys(data).length > 0) {
        const courtInfo = {};
        const slotTime = data.result.map((x) => {
          courtInfo[x.slot.timeId] = x.availableCourts;
          return {
            key: x.slot.timeId,
            value: x.slot.timeId,
            text: x.slot.interval.start + " - " + x.slot.interval.end,
          };
        });
        setSelectedTime(slotTime[0].key);
        setSlots({ slotTime, courtInfo });
      }
    });
  }, [selectedDate]);// eslint-disable-line react-hooks/exhaustive-deps

  const changeTime = (event, data) => {
    setSelectedTime(data.value);
  };

  const changeCourt = (event, { value }) => {
    const selected = slots.courtInfo[selectedTime].find(
      (x) => x.courtId === value
    );
    setSelectedCourt(selected);
  };

  const onRescheduleClick = () => {
    setRescheduleButtonDisabled(true);
    setShowLoader(true);
    const arenaData = context.arenaData[context.selectedArena.arenaId];
    const postBody = {
      bookingId: booking.id,
      userName: booking.userName,
      userMobileNumber: booking.userMobileNumber || booking.mobileNumber,
      rescheduledDate: selectedDate.format("DD-MM-YYYY"),
      rescheduledTime: selectedTime,
      rescheduledCourt: selectedCourt.courtId,
      paymentInfo: JSON.stringify(paymentArray),
      sport: context.selectedSportDetails.sportId,
      role: ROLES.ADMIN,
      createdBy: {
        userName: context.user.userName,
        userId: context.user.userId,
      },
      arenaData,
      rescheduleAllowed: isRescheduleAllowed
    };
    rescheduleBooking(
      context.selectedFacility,
      context.selectedArena.arenaId,
      postBody
    ).then(() => {
      setShowLoader(false);
      setOpen(false);
      reload(true);
      setMessage({
        header: "Booking Rescheduled!",
        message: "You booking has been rescheuled.",
        color: "blue",
      });
    });
  };

  const availableCourts =
    slots &&
    slots.courtInfo &&
    slots.courtInfo[selectedTime].map((x) => ({
      key: x.courtId,
      value: x.courtId,
      text: x.courtName,
      bookingPrice: x.bookingPrice,
    }));

  const payableAmount = selectedCourt
    ? selectedCourt.bookingPrice - booking.bookingPrice
    : 0;

  const totalAmount = payableAmount > 0 ? payableAmount : 0;
  const totalPayment = paymentArray
    .map((x) => +x.amount)
    .reduce((x, sum) => sum + x, 0);
  return (
    <>
      <Modal.Content>
        {showLoader ? (
          <Loader size={"small"} style={{ padding: "10px" }} active inline />
        ) : (
          <div style={{ padding: "10px" }}>
            {policyRule.isAllowed ? (
              <Form>
                <Form.Group widths='equal'>
                  <Form.Field>
                    <label>Date</label>
                    <KeyboardDatePicker
                      autoOk
                      disablePast
                      variant='inline'
                      value={selectedDate}
                      placeholder='Select Date...'
                      onChange={setSelectedDate}
                      format='DD MMM yyyy'
                      style={{ padding: "10px", borderColor: "transparent" }}
                    />
                  </Form.Field>
                  {slots ? (
                    <>
                      <Form.Field>
                        <label style={{ marginBottom: "14px" }}>
                          Slot timing
                        </label>
                        <Dropdown
                          onChange={changeTime}
                          placeholder='Select...'
                          openOnFocus
                          selection
                          value={selectedTime}
                          options={slots.slotTime}
                        />
                      </Form.Field>
                      <Form.Field>
                        <label style={{ marginBottom: "14px" }}>Court</label>
                        <Dropdown
                          onChange={changeCourt}
                          placeholder={availableCourts.length > 0 ? 'Select...' : 'Not available for this slot'}
                          openOnFocus
                          selection
                          value={selectedCourt && selectedCourt.courtId}
                          options={availableCourts}
                          disabled={availableCourts.length <= 0}
                        />
                      </Form.Field>

                    </>
                  ) : null}
                </Form.Group>
                {totalAmount > 0 ? (
                  <>
                    <Divider horizontal>
                      <span style={{ color: colors.blue["400"] }}>Payment</span>
                    </Divider>
                    <div style={{ paddingBottom: "10px" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Total Amount to be paid:{" "}
                      </span>
                      <span>Rs.{totalAmount}/-</span>
                    </div>
                    <Payment
                      paymentArray={paymentArray}
                      setPaymentArray={setPaymentArray}
                    />
                    <div style={{ paddingTop: "10px" }}>
                      <span style={{ fontWeight: "bold" }}>
                        Total Payment:{" "}
                      </span>
                      <span>Rs.{totalPayment}/-</span>
                    </div>
                  </>
                ) : null}
              </Form>
            ) : (
              "Reschedule not allowed."
            )}
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={() => setType("request")}
          disabled={rescheduleButtonDisabled}
        >
          Go Back
        </Button>
        {((totalAmount === totalPayment) || (totalAmount === 0)) && selectedCourt ? (
          <Button
            primary
            onClick={onRescheduleClick}
            disabled={rescheduleButtonDisabled}
          >
            Reschedule
          </Button>
        ) : null}
      </Modal.Actions>
    </>
  );
};

export default RescheduleBooking;
