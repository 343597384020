import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import {Helmet} from "react-helmet";
import { Fade, Zoom, } from 'react-reveal';

const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>PLAY BETTER</h4>
        <h1 data-aos='fade-up'>
          We Are Redefining Sports. Experience The Difference.
          <br />  Now book your sports venue for prices starting from ₹100.
        </h1>
        <div class='btarea' data-aos='zoom-in'></div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> Best in Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#park'></use>
          </svg>
          <h5> 6 Cities</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#football'></use>
          </svg>
          <h5> 5 Sports</h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>
 
    <section class='partnersec badmintondeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade left><img src='images/gallery/lg-21-gn.png' alt='' /></Fade>
                <div class=' imgthumbs'>
                  <h5>Gaurcity Gallery</h5>
                  <span class='imgspan'>
                    <img
                      onclick='openModal();currentSlide(1)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-21.png'
                      alt=''
                    />
                    <img
                      onclick='openModal();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-22.png'
                      alt=''
                    />
                    <img
                      onclick='openModal2();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-23.png'
                    />
                    <img
                      onclick='openModal2();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-24.png'
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class='contentarea '>
              <Zoom><h4 > PlayAll Sports Complex</h4>
              <h2 >Gaur City - Greater Noida</h2>
                
                </Zoom>
              <span class='address'>
              Gaur City Sports Complex, E Block, Gaur City 1, Sector 4, Greater Noida, Uttar Pradesh 201009 </span>
              <span class='address'>
              Contact - 011-411-87822
              </span>
              <div class='btarea mb-3'>
                <a href='https://goo.gl/maps/XRjs1h6FZjYUoPtv7  ' class='btwhite1'>
                  <Fade right>Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble '>

                <Zoom>Sports</Zoom>
                <div class='divider'></div>{" "}
                <svg>

                  <use href='#badmintonico'></use>

                </svg>

                <div class='divider'></div>{" "}
                <svg>
                  <use href='#cricico '></use>
                </svg>
                <div class='divider'></div>
                <svg>
                  <use href='#football'></use>
                </svg>
                <div class='divider'></div>
                <svg>
                  <use href='#ttico'></use>
                </svg>
              </div>
              <div class='sportsavailble '>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://hudle.in/venues/play-all-sports-complex-gaur-city/180250'
 >
                  <img src='images/huddle.svg' alt='' />
                </a>
                <div class='divider'></div>
                <a
                  href='https://playo.co/venues/greater-noida-west-ncr/play-all-sports-@-gaur-city-sports-complex-greater-noida-west-ncr'
                  class='btorng'

                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playo_logo.png' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      
      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >Play All</h4>
                <h2 >Faridabad</h2></Zoom>
              <span class='address'>
                Near Sai Mandir, Sector 86, Faridabad, Haryana 121002
              </span>

              <span class='address'>
                Contact - 01141187780
              </span>
              <div class='btarea mb-3'>
                <a
                  href='https://g.page/play-all-sports-faridabad?share'
                  class='btwhite1'
                >

                  <Fade left> Directions</Fade>
                </a>
              </div>
              <div class='sportsavailble '>
                <Zoom>Sports</Zoom>
                <div class='divider'></div>{" "}
                <svg>
                  <use href='#badmintonico'></use>
                </svg>
                <div class='divider'></div>{" "}
                <svg>
                  <use href='#cricico '></use>
                </svg>
                <div class='divider'></div>
                <svg>
                  <use href='#football'></use>
                </svg>
                <div class='divider'></div>
                <svg>
                  <use href='#ttico'></use>
                </svg>
                <div class='divider'></div>
                <svg>
                  <use href='#skating'></use>
                </svg>
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <button
                  class='btorng'
                  type='button'
                  data-toggle='collapse'
                  data-target='#demo'
                >
                  {" "}
                  <img alt='' src='images/sportido.png' />
                </button>
                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://hudle.in/venues/playall-faridabad/237563'
                >
                  <img src='images/huddle.svg' alt='' />
                </a>
              </div>

              <div id='demo' class='collapse '>
                <div class='bookbox'>
                  {" "}
                  <a
                    href='http://book.sportido.com/landing/Faridabad,_Haryana,_India/Badminton_Court/Play.All_Sports_Complex_Faridabad/3489_2584'
                    class='btwhite1 smbt'
                  >
                    {" "}
                    Badminton
                  </a>
                  <div class='divider'></div>
                  <a
                    href='http://book.sportido.com/landing/Faridabad,_Haryana,_India/Mini_Football/Play.All_Sports_Complex_Faridabad/3492_2584'
                    class='btwhite1 smbt'
                  >
                    {" "}
                    Football
                  </a>
                  <div class='divider'></div>
                  <a
                    href='http://book.sportido.com/landing/Faridabad,_Haryana,_India/Box_Cricket/Play.All_Sports_Complex_Faridabad/3491_2584'
                    class='btwhite1 smbt'
                  >
                    {" "}
                    Cricket
                  </a>
                </div>
              </div>
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade right><img src='images/IMG_0125.jpg' alt='' /></Fade>

                <div class=' imgthumbs'>
                  <h5>Faridabad Gallery</h5>
                  <span class='imgspan'>
                    <img
                      onclick='openModal1();currentSlide(1)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-5.png'
                      alt=''
                    />
                    <img
                      onclick='openModal1();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-6.png'
                      alt=''
                    />
                    <img
                      onclick='openModal1();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-7.png'
                      alt=''
                    />
                    <img
                      onclick='openModal1();currentSlide(4)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-8.png'
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec cricketdeco'>
        <div className='container'>
          <div class='innerbx pb-5'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade left><img src='images/gallery/lg-15g.png' alt='' /></Fade>
                <div class=' imgthumbs'>
                  <h5>Gurgaon Gallery</h5>
                  <span class='imgspan'>
                    <img
                      onclick='openModal();currentSlide(1)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-1.png'
                      alt=''
                    />
                    <img
                      onclick='openModal();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-14g.png'
                      alt=''
                    />
                    <img
                      onclick='openModal2();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-10.png'
                    />
                    <img
                      onclick='openModal2();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-11.png'
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class='contentarea '>
              <Zoom><h4 > Play All</h4>
                <h2 >Gurgaon</h2></Zoom>
              <span class='address'>
                CRPF Road, Opposite Heritage School, Sector 62, Gurgaon, 122005, Haryana
              </span>
              <span class='address'>
                Contact - 01141179906
              </span>
              <div class='btarea mb-3'>
                <a href='https://goo.gl/maps/ehmfU6Qtyw82  ' class='btwhite1'>
                  <Fade right>Directions</Fade>
                </a>
              </div>

              <div class='sportsavailble '>

                <Zoom>Sports</Zoom>
                <div class='divider'></div>{" "}
                <svg>

                  <use href='#badmintonico'></use>

                </svg>

                <div class='divider'></div>{" "}
                <svg>
                  <use href='#cricico '></use>
                </svg>
                <div class='divider'></div>
                <svg>
                  <use href='#football'></use>
                </svg>
              </div>
              <div class='sportsavailble '>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://hudle.in/venues/playall-badminton-arena-gurgaon/270698'
                >
                  <img src='images/huddle.svg' alt='' />
                </a>
                <div class='divider'></div>
                <a
                  href='http://book.sportido.com/landing/Gurgaon,_Haryana,_India/Badminton_Court/Play.All_Badminton_Arena_Gurgaon/3107_2318'
                  class='btorng'
                >
                  {" "}
                  <img alt='' src='images/sportido.png' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll Sports Arena</h4>
                <h2 >Orbit Mall - Jaipur </h2>
                </Zoom>
              <span class='address'>
              Civil Lines Metro Station, Orbit Mall, Ajmer Rd, Madrampur, Civil Lines, Jaipur, Rajasthan 302006 </span>

              <span class='address'>
                Contact - 011-411-86654
              </span>
              <div class='btarea mb-3'>
                <a
                  href='https://goo.gl/maps/4VAo7j8ZzX9rBojQ9'
                  class='btwhite1'
                >

                  <Fade left> Directions</Fade>
                </a>
              </div>
              <div class='sportsavailble '>
                <Zoom>Sports</Zoom>
                <div class='divider'></div>{" "}
                <svg>
                  <use href='#cricico '></use>
                </svg>
                <div class='divider'></div>
                <svg>
                  <use href='#football'></use>
                </svg>
              
              </div>

              <div class='sportsavailble '>
                <Zoom>Book via</Zoom>
              
                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://hudle.in/venues/playall-sports-complex-orbit-mall-jaipur/840513'

                  target="_blank"
                >
                  <img src='images/huddle.svg' alt='' />
                </a>

                <div class='divider'></div>
                <a
                  href='https://playo.co/venues/madrampur-jaipur/playall-sports-orbit-mall-madrampur-jaipur'
                  class='btorng'

                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playo_logo.png' />
                </a> </div>
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade right><img src='images/gallery/lg-23-om.png' alt='' /></Fade>

                <div class=' imgthumbs'>
                  <h5>Jaipur Gallery</h5>
                   <span class='imgspan'>
                    <img
                      onclick='openModal1();currentSlide(1)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-28.png'
                      alt=''
                    />
                    <img
                      onclick='openModal1();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-29.png'
                      alt=''
                    />
                    <img
                      onclick='openModal1();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-30.png'
                      alt=''
                    />
                    <img
                      onclick='openModal1();currentSlide(4)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-31.png'
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
   
     <section class='partnersec badmintondeco'>
        <div class='container'>
          <div class='innerbx'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade left> <img src='images/gallery/lg-16n.png' alt='' /></Fade>
                <div class=' imgthumbs'>
                  <h5>Noida Gallery</h5>
                  <span class='imgspan'>
                    <img
                      onclick='openModal2();currentSlide(1)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-17.png'
                      alt=''
                    />
                    <img
                      alt=''
                      onclick='openModal2();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-18.png'
                    />
                    <img
                      alt=''
                      onclick='openModal2();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-19.png'
                    />
                    <img
                      alt=''
                      onclick='openModal2();currentSlide(4)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-20.png'
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class='contentarea '>
              <Zoom><h4 >Play All </h4>
                <h2 >Noida </h2></Zoom>
              <span class='address'>
                Near Noida Pet Clinic, Sector 73, Noida, Uttar Pradesh 201301
              </span>
              <span class='address'>
                Contact - 01140849901
              </span>
              <div class='btarea mb-3'>
                <a href='https://goo.gl/maps/MHyikEmmcw22' class='btwhite1'>
                  <Fade right> Directions</Fade>
                </a>
              </div>
              <div class='sportsavailble '>
                <Zoom>Sports</Zoom>
                <div class='divider'></div>{" "}
                <svg>
                  <use href='#badmintonico'></use>
                </svg>
                <div class='divider'></div>{" "}
                <svg>
                  <use href='#cricico '></use>
                </svg>
              </div>
              <div class='sportsavailble '>
                <Zoom> Book via</Zoom>
                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://hudle.in/venues/playall-badminton-arena/840648'
                >
                  <img src='images/huddle.svg' alt='' />
                </a>
                <div class='divider'></div>
                <a
                  href='http://book.sportido.com/landing/Noida/Badminton_Court/Play.All_Badminton_Arena_Noida/3103_2317'
                  class='btorng'
                >
                  {" "}
                  <img alt='' src='images/sportido.png' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      

      <section class='partnersec '>
        <div class='container'>
          <div class='innerbx pb-5'>
            <div class='contentarea '>
              <Zoom>  <h4 >PlayAll</h4>
                <h2 >Alaknanda Delhi</h2>
                </Zoom>
              <span class='address'>
              Kalka Public School, Kalka Public School Rd, Alaknanda, New Delhi, Delhi </span>

              <span class='address'>
                Contact - 011-411-87784
              </span>
              <div class='btarea mb-3'>
                <a
                  href='https://goo.gl/maps/hdrrcpF2sTk5EvCE6'
                  class='btwhite1'
                >

                  <Fade left> Directions</Fade>
                </a>
              </div>
              <div class='sportsavailble '>
                <Zoom>Sports</Zoom>
                <div class='divider'></div>{" "}
                <svg>
                  <use href='#cricico '></use>
                </svg>
                <div class='divider'></div>
                <svg>
                  <use href='#football'></use>
                </svg>
               
              </div>

              <div class='sportsavailble'>
                <Zoom>Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://playo.co/venues/moti-bagh-club-house-ncr/playall-sports-arena-alaknanda-delhi-alaknanda-ncr'
                  class='btorng'

                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playo_logo.png' />
                </a>
               
            
                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://hudle.in/venues/playall-sports-arena-alaknanda-delhi/373523'
                  
                  target="_blank"
                >
                  <img src='images/huddle.svg' alt='' />
                </a>
              </div>
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade right><img src='images/gallery/lg-24.png' alt='' /></Fade>

                <div class=' imgthumbs'>
                  <h5>Alaknanda Gallery</h5>
                  <span class='imgspan'>
                    <img
                      onclick='openModal1();currentSlide(1)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-32.png'
                      alt=''
                    />
                    <img
                      onclick='openModal1();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-33.png'
                      alt=''
                    />
                    <img
                      onclick='openModal1();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-34.png'
                      alt=''
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>



     <section class='partnersec cricketdeco'>
        <div class='container'>
          <div class='innerbx'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade left> <img src='images/gallery/lg-22-ms.png' alt='' /></Fade>
                <div class=' imgthumbs'>
                  <h5>Modner School Noida Gallery</h5>
                  <span class='imgspan'>
                    <img
                      onclick='openModal2();currentSlide(1)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-25.png'
                      alt=''
                    />
                    <img
                      alt=''
                      onclick='openModal2();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-26.png'
                    />
                    <img
                      alt=''
                      onclick='openModal2();currentSlide(2)'
                      class='hover-shadow cursor thumb'
                      src='images/gallery/sm-27.png'
                    />
                  </span>
                </div>
              </div>
            </div>
            <div class='contentarea '>
              <Zoom><h4 >PlayAll </h4>
                <h2 > @ Modern School - Sector 11 Noida</h2></Zoom>
              <span class='address'>
              S1 Modern School, R Block, Sector 11, Noida, Uttar Pradesh 201301</span>
              <span class='address'>
                Contact - 01140849901
              </span>
              <div class='btarea mb-3'>
                <a href='https://g.page/modern_noida?share' class='btwhite1'>
                  <Fade right> Directions</Fade>
                </a>
              </div>
              <div class='sportsavailble '>
                <Zoom>Sports</Zoom>
                <div class='divider'></div>{" "}
                <svg>
                  <use href='#badmintonico'></use>
                </svg>
               
              </div>
              <div class='sportsavailble '>
                <Zoom> Book via</Zoom>
                <div class='divider'></div>
                <a
                  href='https://playo.co/venues/sector-11-noida-ncr/play-all-@-modern-school-sector-11-noida-ncr'
                  class='btorng'

                  target="_blank"
                >
                  {" "}
                  <img alt='' src='images/playo_logo.png' />
                </a>
                
            
                <div class='divider'></div>
                <a
                  class='btorng justify-content-center'
                  href='https://hudle.in/venues/play-all-modern-school-noida/930734'
                  
                  target="_blank"
                >
                  <img src='images/huddle.svg' alt='' />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>

      

      <section class=' techsection footballdeco'>
        <div class='container'>
          <div class='headarea'>
            <h4 class=' sectiontitle '>
              PlayAll
            </h4>
            <Fade bottom cascade><h2 class=' sectionhead '>
              One-Stop-Destination for Sports Lovers
            </h2></Fade>
          </div>
          <div class='techarea sportsarea'>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#football'></use>
                </svg>
              </div>
              <Zoom><h4 >Football</h4></Zoom>
              <span>6-A-SIDE, 50 MM FUTSAL TURF FIFA APPROVED</span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#badmintonico'></use>
                </svg>
              </div>
              <Zoom><h4 >Badminton</h4></Zoom>
              <span>
                BWF APPROVED FLOORING, INTERNATIONAL LIGHTING, 30 FT HEIGHT
              </span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#cricico'></use>
                </svg>
              </div>
              <Zoom><h4 >Cricket</h4></Zoom>
              <span>
                6-A-SIDE BOX-CRICKET, COVERED FROM ALL SIDES, LED LIGHITNG FOR
                NIGHT GAMES
              </span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#ttico'></use>
                </svg>
              </div>
              <Zoom><h4 >Table Tennis</h4></Zoom>
              <span>6-A-SIDE, 50 MM FUTSAL TURF FIFA APPROVED</span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#chess'></use>
                </svg>
              </div>
              <Zoom><h4 >Board Games</h4></Zoom>
              <span>CHESS & CARROM ETC</span>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#teamsport'></use>
                </svg>
              </div>
              <Zoom><h4>Team Building Games</h4></Zoom>
              <span>SACK RACE, LEMON RACE, TUG-OF-WAR ETC.</span>
            </div>
          </div>
        </div>
      </section>


    </main>
  </>
);

const BookNow = () => {
  return (
    <>
    <Helmet>
            <title>Book Now - Badminton, Futsal, Box-Cricket, Table Tennis</title>
            <meta name="description" content="Book badminton courts, football ground, cricekt ground, futsal turf, TT table, and box-cricket truf online or offline." />
        </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner startplayingbg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"booknow"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default BookNow;
