import React from "react";
import { topSectionData, footerSection, NavSection } from "./HeaderAndFooter";
import {Helmet} from "react-helmet";

import { Fade, Zoom } from 'react-reveal';
const contentSection = (
  <>
    <div class='dispfbanner'>
      <div class='bannerarea'>
        <h4 data-aos='zoom-in-down'>WE MAKE</h4>
        <h1 data-aos='fade-up'>Corporate Sports Events Hassle-Free</h1>
        <div class='btarea' data-aos='zoom-in'>
          <button class='btwhite mr-4'>Email - events@playall.in</button>
        </div>
      </div>
    </div>
    <div class='aboutarea ' data-aos='zoom-in'>
      <div class='aboutbox '>
        <div class='boxes '>
          <svg viewBox='0 0 55.558 47.814'>
            <use href='#stadium'></use>
          </svg>
          <h5> Best in Class Infra</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#price'></use>
          </svg>
          <h5> Custom Solutions</h5>
        </div>
        <div class='boxes '>
          <svg>
            <use href='#support'></use>
          </svg>
          <h5> 100+ Healthy Companies</h5>
        </div>
      </div>
    </div>
  </>
);

const mainSection = (
  <>
    <main>
      <section  class='partnersec badmintondeco'>
        <div class='container'>
          <div class='innerbx'>
            <div class=' rightbx startplayarea d-flex justify-content-center'>
              <div class='startplaybox'>
                <Fade left><img src='images/sportsday.png' alt='' /></Fade>
                <h5 class='pr-4 pl-4'>
                 <Zoom> Whether you are a startup with a talented team of ten, or a
                  multi-national that’s a thousand people strong, we can create
                  the perfect sports day for your company with our end-to-end
                  sports event management.{" "}</Zoom>
                </h5>
              </div>
            </div>

            <div class='contentarea '>
              <h4 >SPORTS DAY EVENTS </h4>
              
             <Fade right> <h2 >One Day Events With Year-Long Impact</h2></Fade>

             <span> <Zoom>
                Let sports be the roadmap you need to improve the wellness and
                teamwork within your company. We help corporates unlock growth
                by fostering teambuilding through our well-organized sports days
                and other sporting events.{" "}</Zoom>
              </span>
              <div class='btarea'>
                <button class='btorng'>Email - events@playall.in</button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='partnersec cricketdeco'>
        <div class='container'>
          <div class='innerbx'>
            <div class='contentarea '>
              <h4>
                INTER- & INTRA-COMPANY TOURNAMENTS{" "}
              </h4>
              <Fade left><h2 >
                Boost Your Team’s Competitive Spirit With Sports
              </h2></Fade>

              <span>
               <Zoom> From inter- and intra-company tournaments to weekly leagues,
                private competitions, and more,{" "}
                <span class='playallst'>Play All</span> delivers tailored
                solutions to keep your staff’s adrenaline kicking. Our sports
                experiences are built off of our years of industry knowledge and
                advanced technology.</Zoom>
              </span>
              <div class='btarea'>
                <button class='btorng'>Email - events@playall.in</button>
              </div>
            </div>
            <div class=' rightbx startplayarea1 d-flex justify-content-center'>
              <div class='startplaybox'>
               <Fade right> <img src='images/comturny.png' alt=''/></Fade>
                <h5 class='pr-4 pl-4'>
                 <Zoom> You bring your team; we will bring everything else. From meals
                  and trophies to personalized merchandise and exciting team
                  building activities, we’ve got your corporate sporting events’
                  needs covered.{" "}</Zoom>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class=' techsection '>
        <div class='container'>
          <div class='headarea'>
            <h4  class=' sectiontitle '>
              HOW DO WE{" "}
            </h4>
            <Fade bottom><h2  class=' sectionhead fadeInDown wow'>
              Make Corporate Sports Events Memorable?{" "}
            </h2></Fade>
            <span>
              <span class='playallst'>Play All</span><Fade left> believes in creating
              impact through sports that last for more than a day. We do this
              with our expertise in:</Fade>
            </span>
          </div>
          <div class='techarea sportsarea'>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#community'></use>
                </svg>
              </div>
              <h4 >Logistics & Operations</h4>
              <Zoom><span>
                To make your events successful beyond limits, we do the
                preparation and the execution
              </span></Zoom>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#creativity'></use>
                </svg>
              </div>
              <h4 >Communication & Marketing</h4>
              <Zoom><span>
                We coordinate with the vendors, attendees, and every possible
                entity so that you can let the game begin
              </span></Zoom>
            </div>
            <div class='sportsbox '>
              <div class='iconarea'>
                <svg>
                  <use href='#relationship'></use>
                </svg>
              </div>
              <h4 >Sponsorship & Hospitality</h4>
              <Zoom><span>
                Besides delivering excellent hospitality to sponsors, we also
                turn the table sometimes and undertake CSR sponsorships
              </span></Zoom>
            </div>
          </div>
        </div>
      </section>
    </main>{" "}
  </>
);

const EventsPage = () => {
  return (
    <>
    <Helmet>
                <title>Sports Day - Corporate Events, Team building activity, Inter-company tournament, and Intra-company tournament</title>
                <meta name="description" content="Play all manages end to end corporate event, sports day, sports event for companies and also host inter-company or intra-company tournament." />
            </Helmet>
      <div style={{ overflowY: "scroll", height: "calc(100vh)" }}>
        {topSectionData}
        <div class='headbanner eventbg' data-aos='fade'>
          <div class='container '>
            <NavSection current={"events"} />
            {contentSection}
          </div>
        </div>
        {mainSection}
        {footerSection}
      </div>
    </>
  );
};

export default EventsPage;
