import React, { useReducer} from "react";

export const AppContext = React.createContext();

const defaultState = {
  user: null,
  facilities: [],
  selectedFacility: null,
  facilitySports: [],
  arenas: [],
  selectedArena: {},
  arenaData: {},
  selectedSportDetails: null,
};

function reducer(state, action) {
  switch (action.type) {
    case "addUser":
      return {
        ...state,
        user: action.payload,
        arenas: action.payload.facilityIncharge,
        selectedFacility: action.payload.facilityId,
        selectedArena: action.payload.facilityIncharge[0],
      };

    case "updateSelectedFacility":
      return {
        ...state,
        selectedArena: action.payload,
      };
    case "upsertArenaData":
      return {
        ...state,
        arenaData: {
          ...state.arenaData,
          [action.payload.arenaId]: action.payload,
        },
      };
    case "upsertSelectedSportDetails":
      return {
        ...state,
        selectedSportDetails: action.payload,
      };
    case "addCurrentFacilitySports":
      return {
        ...state,
        facilitySports: action.payload,
      };
    case "clearContext":
      return defaultState;
    default:
      return state;
  }
}

const AppContextProvider = (props) => {
  const [appState, dispatch] = useReducer(reducer, defaultState);
  return (
    <AppContext.Provider
      value={{
        ...appState,
        dispatch,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
