export const bookedBookingOptions = [
  {
    name: "Reschedule",
    description: "To reschedule the booked slot for a user.",
    type: "reschedule",
  },
  {
    name: "Extend Booking",
    description: "To allow user to extend to next hour.",
    type: "extend",
  },
  // {
  //   name: "Early Booking",
  //   description: "To allow user to book for a prior hour.",
  //   type: "early",
  // },
  {
    name: "Product Sale",
    description: "To allow user to buy/rent merchandise(s).",
    type: "sale",
  },
  {
    name: "Cafe Sale",
    description: "To allow user to buy cafe products.",
    type: "cafe",
  },
  {
    name: "Cancel Booking",
    description: "To cancel the booked slot.",
    type: "cancel",
  },
  {
    name: "Pay Now",
    description: "Pay dues",
    type: "paynow",
  },
  {
    name: "Change Payment Mode",
    description: "Change the mode of payment",
    type: "changepaymode",
  },
  {
    name: "Release Slot",
    description: "Make this slot available for booking",
    type: "releaseSlot",
  },
  {
    name: "Complimentary Product",
    description: "To give product along with booking",
    type: "complimentary",
  },
];
