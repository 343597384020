import React, { useState, useEffect, useContext } from "react";
import { Button, Modal, Loader, Grid, Form } from "semantic-ui-react";
import moment from "moment";
import BookinInfo from "../BookingInfo";
import { AppContext } from "../../../AppContext";
import {
  releaseSlot
} from "../../../apiclients/BookingApiClient";
import GenericLoader from "../../generic/GenericLoader";

const ReleaseSlot = ({
  booking,
  facility,
  setOpen,
  setType,
  reload,
  setMessage,
}) => {
  const context = useContext(AppContext);
  const [loader, setLoader] = useState(false);
  const [reason, setReason] = useState('');
  const releaseSlots = () => {
    setLoader(true);
    const postBody = {
      arenaName : context.selectedArena.arenaName, 
      sportName:context.selectedSportDetails.detail.sportName, 
      slotName:booking.slot.interval.start+"-"+booking.slot.interval.end, 
      courtName:booking.courtName,
      bookingType:booking.bType,
      facilityId: context.selectedFacility,
      arenaId: context.selectedArena.arenaId,
      sportId: context.selectedSportDetails.sportId,
      bookingDate: moment(booking.bookingDate).format('DD-MM-YYYY'),
      slotId: booking.slotTiming,
      courtId: booking.courtId,
      createdBy: {
        userName: context.user.userName,
        userId: context.user.userId,
      },
      reason: reason,
      transactionId: booking.id
    }
    releaseSlot(
      postBody
    )
      .then(() => {
        setLoader(false);
        setOpen(false);
        setMessage({
          header: "Slot Released!",
          message: "You slot has been released.",
          color: "grey",
        });
        reload(true);
      })
      .catch((err) => {
        setLoader(false);
        setOpen(false);
        setMessage({
          header: "Error : Slot not Released!",
          message: `${err.response.data}`,
          color: "red",
        });
        reload(true);
      });
  };
  return (
    <>
      <Modal.Content>
        <BookinInfo booking={booking} facility={facility} />
        {loader ? <GenericLoader /> :
          <div style={{ padding: "10px" }}>
            Are you sure you want to release this booking?
            <Grid.Row>
              <Grid.Column width={4}>
                <span style={{ fontWeight: "bold" }}>Reason: </span>
              </Grid.Column>
              <Grid.Column width={12}>
                <Form>
                  <input
                    type='text'
                    onInput={(data) => setReason(data.target.value)}
                    value={reason}
                    placeholder='e.g., type reason here'
                  />
                </Form>
              </Grid.Column>
            </Grid.Row>
          </div>
        }

      </Modal.Content>
      <Modal.Actions>
        <Button disabled={loader} onClick={() => setType("request")}>Go Back</Button>
        <Button
          negative
          disabled={loader || reason === ""}
          onClick={releaseSlots}
        >
          Release Booking
        </Button>
      </Modal.Actions>
    </>
  );
};

export default ReleaseSlot;
