import moment from "moment";
import { BOOKING_SOURCES } from "./../utils/constants";

export const isIndividualBooking = (y) => {
  return y && y.individualBooking;
};

export const isAcademyBooking = (id) => {
  return id.includes("academy-");
};

export const isBulkBooking = (y) => {
  return y && y.bookingStatus === "bulk";
};

export const isBlockedBooking = (id) => {
  return id.includes("blocked-");
};

export const convertFirstoreDate = (
  date,
  format = "DD MMM YYYY",
  currentformat,
) => {
  if (!date) {
    return undefined;
  }
  const dd = currentformat ? moment(date, currentformat) : moment(date);
  return dd.format(format);
};

export const convertFirstoreDateForExcel = (
  date,
  format = "DD-MMM-YY",
  currentformat,
) => {
  if (!date) {
    return undefined;
  }
  const dd = currentformat ? moment(date, currentformat) : moment(date);
  return dd.format(format);
};

export const getCurrentMonthYear = (date) => {
  return moment(date).format("MM-YYYY");
};

export const addDays = (date, days) => {
  if (!date) {
    date = moment();
  }
  return moment(date).add(days, "days");
};

export const getKeyValue = (object, keyString) => {
  return keyString.split(".").reduce(function (o, x) {
    return typeof o === "undefined" || o === null ? o : o[x];
  }, object);
};

export const getSourceLabel = (source) => {
  if (source === BOOKING_SOURCES.PLAY_ALL_WEB) {
    return "Play.All";
  }
  if (source === "playAllApp") {
    return "Play.All";
  }
  return source;
};

export const getSubtypeLabel = (subtype) => {
  if (subtype === "regularBooking") {
    return "Regular Booking";
  }
  return subtype;
};

export const getBookingStatusLabel = (status) => {
  if (status === "extended") {
    return ", Extension";
  }
  if (status === "rescheduled") {
    return ", Reschedule";
  }
  if (status === "cancelled") {
    return ", Cancelled";
  }
  // if (status !== "booked") {
  //   return ", " + status;
  // }
  return "";
};
export const objectToArray = (obj) => {
  if (!obj) {
    return [];
  }
  const keys = Object.keys(obj);
  return keys.map((k) => ({ key: k, ...obj[k] }));
};

export const getNumberOfDays = (startDate, endDate, daysArray) => {
  let numberOfDays = 0;
  while(endDate>=startDate){
    let dayNumber = startDate.day();
    if (daysArray.some(e => e.key === dayNumber)) {
    numberOfDays++;
      /* vendors contains the element we're looking for */
    }
    startDate = moment(startDate).add(1,'days');
  }
  return numberOfDays;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}