import { colors } from "@material-ui/core";
import React, { useEffect, useContext, useState } from "react";
import { Segment, Grid } from "semantic-ui-react";
import { getBlockingDetail } from "../../apiclients/BlockingApiClient";
import { AppContext } from "../../AppContext";
import moment from "moment";
import "../dashboard/style.scss";
import GenericLoader from "../generic/GenericLoader";
import { convertFirstoreDate } from "../../utils/helperFunctions";

const COURTS = {
  c1: "COURT 1",
  c2: "COURT 2",
  c3: "COURT 3",
  c4: "COURT 4",
  c5: "COURT 5",
  c6: "COURT 6",
  c7: "COURT 7",
};

const TIMINGS = {
  t000: "6:00 - 7:00",
  t001: "7:00 - 8:00",
  t002: "8:00 - 9:00",
  t003: "9:00 - 10:00",
  t004: "10:00 - 11:00",
  t005: "11:00 - 12:00",
  t006: "12:00 - 13:00",
  t007: "13:00 - 14:00",
  t008: "14:00 - 15:00",
  t009: "15:00 - 16:00",
  t010: "16:00 - 17:00",
  t011: "17:00 - 18:00",
  t012: "18:00 - 19:00",
  t013: "19:00 - 20:00",
  t014: "20:00 - 21:00",
  t015: "21:00 - 22:00",
  t016: "22:00 - 23:00",
};

const BlockingDetail = (props) => {
  const { history } = props;
  const context = useContext(AppContext);
  const [data, setData] = useState({
    blockingDetails: {},
  });
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    setLoader(true);
    getBlockingDetail(
      context.selectedFacility,
      context.selectedArena.arenaId,
      props.match.params?.id
    ).then((response) => {
      setData(response.data);
      setLoader(false);
    });
  }, [context.selectedFacility, context.selectedArena]);
  const currentSport =
    context.facilitySports &&
    context.facilitySports.find(
      (x) => x.sportId === data.blockingDetails?.sportId
    );
  return (
    <div style={{ padding: "16px" }}>
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>
              Blocking Detail:{" "}
              <span style={{ fontWeight: "normal" }}>
                {props.match.params?.id}
              </span>
            </span>
          </div>
        </div>
      </div>
      {loader ? (
        <GenericLoader />
      ) : (
        <>
          <div
            style={{
              textDecoration: "underline",
              color: colors.blue[500],
              cursor: "pointer",
            }}
            onClick={() => history.goBack()}
          >
            {"< Back"}
          </div>
          <Segment.Group style={{ padding: "12px" }}>
            <Grid>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>SportId:</span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{currentSport?.sportName}</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Dates: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {data.blockingDetails.datePeriod &&
                      data.blockingDetails.datePeriod[0] +
                        "  -  " +
                        data.blockingDetails.datePeriod[
                          data.blockingDetails.datePeriod.length - 1
                        ]}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Courts Selected: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {data.blockingDetails.courtNameArray
                      ?.map((c) => c)
                      .join(", ")}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>
                    Slot timings (24hours format):{" "}
                  </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>
                    {data.blockingDetails.slots}
                  </span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column width={4}>
                  <span style={{ fontWeight: "bold" }}>Reason: </span>
                </Grid.Column>
                <Grid.Column width={12}>
                  <span>{data.blockingDetails.bookingReason}</span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Segment.Group>
        </>
      )}
    </div>
  );
};

export default BlockingDetail;
