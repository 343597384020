import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams } from "./url";


export const getActiveLeads = (facilityId, arenaId) => {
    const url = getURLWithQueryParams(API_URLS.GET_ACTIVE_LEADS ,null, {
      facilityId,
      arenaId,
    });
  
    return tokenAxiosInstance.get(url);
  };
