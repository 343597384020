import React, { useEffect, useContext, useState } from "react";
import { NavLink } from "react-router-dom";
import { colors } from "@material-ui/core";
import { Button,  Icon, Tab, Table } from "semantic-ui-react";
import "../dashboard/style.scss";
import "./Blocking.scss";
import {
  getBlockings,
  cancelBlocking,
} from "../../apiclients/BlockingApiClient";
import { AppContext } from "../../AppContext";
import {
  objectToArray,
  convertFirstoreDate,
} from "../../utils/helperFunctions";
import GenericLoader from "../generic/GenericLoader";
import CustomModal from "../generic/CustomModal";
import { ROLES } from "../../utils/constants";

const BlockingDataTable = ({ type }) => {
  const context = useContext(AppContext);
  const user = context.user;
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [showCancel, setShowCancel] = useState(false);
  const [reload, setReload] = useState(true);

  useEffect(() => {
    if (!reload) {
      setReload(true);
    }
  }, [context.selectedArena]);// eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (reload) {
      setLoader(true);
      getBlockings(
        context.selectedFacility,
        context.selectedArena.arenaId,
        type
      ).then((response) => {
        setLoader(false);
        setReload(false);
        const arr = objectToArray(response.data.result);
        setData(arr);
      });
    }
  }, [type, reload, context.selectedFacility, context.selectedArena]);

  const cancelBlockedSlots = () => {
    setLoader(true);
    cancelBlocking(
      context.selectedFacility,
      context.selectedArena.arenaId,
      showCancel.key,
      showCancel.sportId
    ).then(() => {
      setReload(true);
      setLoader(false);
      setShowCancel(false);
    });
  };

  return loader ? (
    <GenericLoader />
  ) : (
    <>
      {showCancel ? (
        <CustomModal
          header={"Cancel Blocking"}
          content={
            <div style={{ paddingBottom: "10px" }}>
              <span style={{ fontWeight: "bold" }}>
                Do you want to cancel the blocked slots?
              </span>
            </div>
          }
          footer={<Button onClick={cancelBlockedSlots}>CANCEL BLOCKING</Button>}
          setOpen={setShowCancel}
          open={showCancel}
        />
      ) : null}
      <Table celled padded>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Blocking Date</Table.HeaderCell>
            <Table.HeaderCell>Start Date</Table.HeaderCell>
            <Table.HeaderCell>End Date</Table.HeaderCell>
            <Table.HeaderCell>Sport</Table.HeaderCell>
            <Table.HeaderCell>Blocking ID</Table.HeaderCell>
            <Table.HeaderCell>Created By</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.map((x) => {
            const currentSport =
              context.facilitySports &&
              context.facilitySports.find((s) => s.sportId === x.sportId);
            return (
              <Table.Row>
                <Table.Cell>
                  {" "}
                  {x.createdDate ? x.createdDate.seconds ?(
                    <div>
                      {new Date(x.createdDate.seconds * 1000).toLocaleDateString()}
                    </div>
                  ) : (
                    <div>{convertFirstoreDate(x.createdDate)}</div>
                  ) : null}{" "}
                </Table.Cell>
                <Table.Cell>{x.datePeriod[0]}</Table.Cell>
                <Table.Cell>{x.datePeriod[x.datePeriod.length - 1]}</Table.Cell>
                <Table.Cell>
                  {currentSport && currentSport.sportName}
                </Table.Cell>
                <Table.Cell>
                  <div>
                    <NavLink
                      style={{
                        textDecoration: "underline",
                        color: colors.blue[500],
                      }}
                      to={`/extranet/blocking/${x.key}`}
                    >
                      {x.key}
                    </NavLink>
                    {x.expiringIn ? (
                      <div style={{ color: "red" }}>
                        Expiring in {x.expiringIn} days
                      </div>
                    ) : null}
                  </div>
                </Table.Cell>
                    {x.createdBy ?
                        <Table.Cell>{x.createdBy.userName}</Table.Cell> : 
                        <Table.Cell></Table.Cell> 
                    }
                {type === "expired" ? (
                  <Table.Cell
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    {" "}
                    <div style={{ color: colors.red["500"] }}>
                      BLOCKING EXPIRED
                    </div>{" "}
                  </Table.Cell>
                ) : (
                  <Table.Cell
                    style={{
                      flex: 1,
                      justifyContent: "center",
                      flexDirection: "column",
                      display: "flex",
                    }}
                  >
                    {x.cancelled ? (
                      <div style={{ color: colors.red["500"] }}>CANCELLED</div>
                    ) : (
                      <Button
                        style={{
                          padding: "8px",
                          fontSize: "12px",
                          marginBottom: "8px",
                        }}
                        negative
                        onClick={() => setShowCancel(x)}
                      >
                        Cancel Blocking
                      </Button>
                    )}
                  </Table.Cell>
                )}
              </Table.Row>
            );
          })}
        </Table.Body>
      </Table>
    </>
  );
};

const panes = [
  {
    menuItem: "Expiring Soon Blockings",
    render: () => (
      // <div>
      <Tab.Pane className={"tabBox"}>
        <BlockingDataTable type={"expiringsoon"} />
      </Tab.Pane>
      // </div>
    ),
  },
  {
    menuItem: "All Blockings",
    render: () => <BlockingDataTable />,
  },
  // {
  //   menuItem: "Expired Blockings",
  //   render: () => (
  //     <Tab.Pane className={"tabBox"}>
  //       <BlockingDataTable type={"expired"} />
  //     </Tab.Pane>
  //   ),
  // },
];
const Blocking = (props) => {
  const { history } = props;
  const context = useContext(AppContext);
  const user = context.user;
  return (
    <div style={{ padding: "16px" }}>
      <div className='dashboard'>
        <div
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          <div>
            <span>Blocking</span>
          </div>
          <div style={{ alignItems: "center" }}>
           { (user.role === ROLES.ADMIN || user.role === ROLES.REGIONAL_MANAGER) && 
           <span>
              <Button
                onClick={() => history.push("/extranet/releaseBlocking")}
                name={"Release Blocking"}
              >
                <Icon name={"minus"} />
                Release Blocking
              </Button>
            </span> 
}
            <span>
              <Button
                onClick={() => history.push("/extranet/addblocking")}
                name={"Add Blocking"}
              >
                <Icon name={"add"} />
                Add Blocking
              </Button>
            </span>
          </div>
        </div>
      </div>

      <div style={{ paddingTop: "16px" }}>
        <Tab
          renderActiveOnly={true}
          menu={{
            color: "orange",
            pointing: true,
          }}
          panes={panes}
        />
      </div>
    </div>
  );
};

export default Blocking;
