import { tokenAxiosInstance } from "./ApiClient";
import { API_URLS, getURLWithQueryParams} from "./url";
import { BOOKING_SOURCES } from "./../utils/constants";

export const getFacilityDetail = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(API_URLS.FACILITY_DATA, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.get(url);
};

export const getFacilityBookings = (facilityId, arenaId, sport, date) => {
  const url = getURLWithQueryParams(API_URLS.FACILITY_BOOKINGS, {
    facilityId,
    arenaId,
    sport,
    date: date.format("DD-MM-YYYY"),
  });
  console.log("url",url)
  return tokenAxiosInstance.get(url);
};

export const getUserName = (facilityId, number) => {
  const url = getURLWithQueryParams(
    API_URLS.USER_DETAIL,
    {
      number,
    },
    { facilityId }
  );
  return tokenAxiosInstance.get(url);
};

export const getMembersDetails = (facilityId, arenaId, sportId, number,bookingDate,timeId) => {
  const url = getURLWithQueryParams(
    API_URLS.MEMBER_DETAIL,
    {
      number,bookingDate,timeId
    },
    { facilityId,arenaId, sportId, }
  );
  return tokenAxiosInstance.get(url);
};

export const getLastReceiptNumber = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(
    API_URLS.RECEIPT_INFO,
    {
      arenaId,
    },
    { facilityId }
  );
  return tokenAxiosInstance.get(url);
};

export const getProducts = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(API_URLS.FETCH_PRODUCTS, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.get(url);
};

export const getComplimentaryProducts = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(API_URLS.FETCH_COMPLIMENTARY_PRODUCTS, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.get(url);
};

export const getCafeProducts = (facilityId, arenaId) => {
  const url = getURLWithQueryParams(API_URLS.FETCH_CAFE_PRODUCTS, null, {
    facilityId,
    arenaId,
  });
  return tokenAxiosInstance.get(url);
};

export const getAcademyProducts = (facilityId, arenaId, sportId) => {
  const url = getURLWithQueryParams(API_URLS.FETCH_ACADEMY_PRODUCTS, null, {
    facilityId, arenaId, sportId
  });
  return tokenAxiosInstance.get(url);
};

export const createBooking = (facilityId, postBody) => {
  const url = getURLWithQueryParams(API_URLS.CREATE_BOOKING, null, {
    facilityId,
  });
  const body = { ...postBody, source: BOOKING_SOURCES.PLAY_ALL_WEB };

  return tokenAxiosInstance.post(url, body);
};

export const postFlexiMemberBooking = ( postBody) => {
  const url = getURLWithQueryParams(API_URLS.POST_FLEXI_MEMBERSHIP_BOOKING, null, {});
  const body = postBody;
  return tokenAxiosInstance.post(url, body);
};
